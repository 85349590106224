import type { FC } from 'react';
import { Fragment } from 'react';
import { StyleSheet, View } from 'react-native';

import { messages as t } from '@/assets/i18n/ja';
import { Button } from '@/components/Button';
import { Text } from '@/components/Text';

type Props = {
  question: string;
  answers: Answer[];
  onPress: () => void;
};

type Answer = {
  subQuestion?: string;
  answer: string;
  unit?: string;
};

export const ListItem: FC<Props> = (props) => {
  const { question, answers, onPress } = props;

  return (
    <>
      <Text.Body>{question}</Text.Body>
      {answers.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={`answer-${index}`}>
          {item.subQuestion ? <Text.Subtext style={styles.subQuestion}>{item.subQuestion}</Text.Subtext> : null}
          <Text.Subtext style={styles.answer}>
            {t.myPage.pastQuestionnaireAnswers.replied}
            {item.answer}
            {item.unit ? item.unit : null}
          </Text.Subtext>
        </Fragment>
      ))}
      <View style={styles.buttonContainer}>
        <Button type="text" onPress={onPress}>
          {t.myPage.pastQuestionnaireAnswers.edit}
        </Button>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  subQuestion: {
    marginTop: 12,
  },
  answer: {
    marginTop: 4,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 4,
  },
});
