import { type FC } from 'react';
import {
  type ImageSourcePropType,
  type TextStyle,
  type ViewStyle,
  ImageBackground,
  ScrollView,
  StyleSheet,
  View,
} from 'react-native';

import { messages as t } from '@/assets/i18n/ja';
import IMAGE_URI from '@/assets/images/tutorial_2.png';
import { Text } from '@/components/Text';
import { Footer } from '@/features/tutorial/components/Tutorial/parts/Footer';
import { styleType } from '@/utils/styleType';

type Props = {
  onPressLeft: () => void;
  onPressRight: () => void;
};

export const TutorialSecondPage: FC<Props> = (props) => {
  const { onPressLeft, onPressRight } = props;

  const imageSource: ImageSourcePropType = { uri: IMAGE_URI };

  return (
    <View style={styles.container}>
      <ScrollView style={styles.scrollView}>
        <ImageBackground source={imageSource} resizeMode="contain" style={styles.image} />
        <View style={styles.content}>
          <Text.Headline style={styles.title}>{t.tutorial.secondPage.meritTitle}</Text.Headline>

          <Text.BoldBody>{t.tutorial.secondPage.meritFirstPointTitle}</Text.BoldBody>
          <Text.Body>{t.tutorial.secondPage.meritFirstPointText}</Text.Body>

          <Text.BoldBody>{t.tutorial.secondPage.meritSecondPointTitle}</Text.BoldBody>
          <Text.Body>{t.tutorial.secondPage.meritSecondPointText}</Text.Body>

          <Text.BoldBody>{t.tutorial.secondPage.meritThirdPointTitle}</Text.BoldBody>
          <Text.Body>{t.tutorial.secondPage.meritThirdPointText}</Text.Body>
        </View>
      </ScrollView>
      <View style={styles.footerContainer}>
        <Footer pageIndicatorIndex={2} onPressLeft={onPressLeft} onPressRight={onPressRight} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: styleType<ViewStyle>({
    flex: 1,
    justifyContent: 'flex-start',
  }),
  scrollView: styleType<ViewStyle>({
    paddingBottom: 92,
  }),
  image: styleType<ViewStyle>({
    paddingTop: 244, // "image resolution's height / width * container width" in order to calculate necessary top space: 731 / 1284 * 428 = 244
  }),
  content: styleType<ViewStyle>({
    paddingHorizontal: 20,
  }),
  title: styleType<TextStyle>({
    paddingTop: 42,
    paddingBottom: 8,
  }),
  footerContainer: styleType<ViewStyle>({
    bottom: 0,
  }),
});
