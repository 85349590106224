import { type FC, useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { NotFound } from '@/features/error/components/NotFound';
import { AmountOfDrinkingPerDay } from '@/features/questionnaire/components/AmountOfDrinkingPerDay';
import { CanEatFasterThan } from '@/features/questionnaire/components/CanEatFasterThan';
import { CanGetEnoughRestWithSleep } from '@/features/questionnaire/components/CanGetEnoughRestWithSleep';
import { CanWalkFasterThan } from '@/features/questionnaire/components/CanWalkFasterThan';
import { CurrentSmokingHabit } from '@/features/questionnaire/components/CurrentSmokingHabit';
import { EnvironmentAnswersComplete } from '@/features/questionnaire/components/EnvironmentAnswersComplete';
import { FamilyStructure } from '@/features/questionnaire/components/FamilyStructure';
import { FrequencyOfDrinking } from '@/features/questionnaire/components/FrequencyOfDrinking';
import { FrequencyOfDriving } from '@/features/questionnaire/components/FrequencyOfDriving';
import { HasCar } from '@/features/questionnaire/components/HasCar';
import { HasHabitOfEatDinnerBeforeSleep } from '@/features/questionnaire/components/HasHabitOfEatDinnerBeforeSleep';
import { HasHabitOfEatSnackAfterDinner } from '@/features/questionnaire/components/HasHabitOfEatSnackAfterDinner';
import { HasHabitOfSkipBreakfast } from '@/features/questionnaire/components/HasHabitOfSkipBreakfast';
import { HasHabitOfSweatyExerciseMoreThanOneYear } from '@/features/questionnaire/components/HasHabitOfSweatyExerciseMoreThanOneYear';
import { HasHabitOfWalking } from '@/features/questionnaire/components/HasHabitOfWalking';
import { HasSmokingHabit } from '@/features/questionnaire/components/HasSmokingHabit';
import { HasSmokingHistory } from '@/features/questionnaire/components/HasSmokingHistory';
import { HousingType } from '@/features/questionnaire/components/HousingType';
import { HowToSpendHolidays } from '@/features/questionnaire/components/HowToSpendHolidays';
import { LifeStyleAnswersComplete } from '@/features/questionnaire/components/LifeStyleAnswersComplete';
import { MeansOfTransportation } from '@/features/questionnaire/components/MeansOfTransportation';
import { MedicalHistory } from '@/features/questionnaire/components/MedicalHistory';
import { NumberOfFriends } from '@/features/questionnaire/components/NumberOfFriends';
import { Occupation } from '@/features/questionnaire/components/Occupation';
import { PastSmokingHabit } from '@/features/questionnaire/components/PastSmokingHabit';
import { PredictingHealthyLifeSpan } from '@/features/questionnaire/components/PredictingHealthyLifeSpan';
import { QuestionnaireStart } from '@/features/questionnaire/components/QuestionnaireStart';
import { CannotPredictHealthLimit } from '@/features/tutorial/components/CannotPredictHealthLimit';
import { Tutorial } from '@/features/tutorial/components/Tutorial';
import { useNavigationLogger } from '@/hooks/useNavigationLogger';
import type { UserStatus } from '@/types/graphql';

type Props = {
  userStatus: Omit<UserStatus, '__typename'> | undefined;
};

export const AnswersNotCompletedRoutes: FC<Props> = (props) => {
  const { userStatus } = props;
  const location = useLocation();
  const { sendViewEvent } = useNavigationLogger();

  useEffect(() => {
    sendViewEvent(location);
  }, [sendViewEvent, location]);

  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route
        path="tutorial"
        element={
          !userStatus?.isAnsweredLifestyle ? (
            <Tutorial userStatus={userStatus} />
          ) : (
            <Navigate replace to="/questionnaire/family-structure" />
          )
        }
      />
      <Route path="cannot-predict-health-limit" element={<CannotPredictHealthLimit />} />
      {/* ========== questionnaire ========== */}
      <Route
        path="/questionnaire/start"
        element={
          !userStatus?.isAnsweredLifestyle ? (
            <QuestionnaireStart />
          ) : (
            <Navigate replace to="/questionnaire/family-structure" />
          )
        }
      />
      {/* Life style */}
      <Route
        path="/questionnaire/has-smoking-history"
        element={
          !userStatus?.isAnsweredLifestyle ? (
            <HasSmokingHistory />
          ) : (
            <Navigate replace to="/questionnaire/family-structure" />
          )
        }
      />
      <Route
        path="/questionnaire/has-smoking-habit"
        element={
          !userStatus?.isAnsweredLifestyle ? (
            <HasSmokingHabit />
          ) : (
            <Navigate replace to="/questionnaire/family-structure" />
          )
        }
      />
      <Route
        path="/questionnaire/current-smoking-habit"
        element={
          !userStatus?.isAnsweredLifestyle ? (
            <CurrentSmokingHabit />
          ) : (
            <Navigate replace to="/questionnaire/family-structure" />
          )
        }
      />
      <Route
        path="/questionnaire/past-smoking-habit"
        element={
          !userStatus?.isAnsweredLifestyle ? (
            <PastSmokingHabit />
          ) : (
            <Navigate replace to="/questionnaire/family-structure" />
          )
        }
      />
      <Route
        path="/questionnaire/has-habit-of-walking"
        element={
          !userStatus?.isAnsweredLifestyle ? (
            <HasHabitOfWalking />
          ) : (
            <Navigate replace to="/questionnaire/family-structure" />
          )
        }
      />
      <Route
        path="/questionnaire/can-walk-faster-than"
        element={
          !userStatus?.isAnsweredLifestyle ? (
            <CanWalkFasterThan />
          ) : (
            <Navigate replace to="/questionnaire/family-structure" />
          )
        }
      />
      <Route
        path="/questionnaire/can-eat-faster-than"
        element={
          !userStatus?.isAnsweredLifestyle ? (
            <CanEatFasterThan />
          ) : (
            <Navigate replace to="/questionnaire/family-structure" />
          )
        }
      />
      <Route
        path="/questionnaire/has-habit-of-eat-dinner-before-sleep"
        element={
          !userStatus?.isAnsweredLifestyle ? (
            <HasHabitOfEatDinnerBeforeSleep />
          ) : (
            <Navigate replace to="/questionnaire/family-structure" />
          )
        }
      />
      <Route
        path="/questionnaire/has-habit-of-eat-snack-after-dinner"
        element={
          !userStatus?.isAnsweredLifestyle ? (
            <HasHabitOfEatSnackAfterDinner />
          ) : (
            <Navigate replace to="/questionnaire/family-structure" />
          )
        }
      />
      <Route
        path="/questionnaire/has-habit-of-skip-breakfast"
        element={
          !userStatus?.isAnsweredLifestyle ? (
            <HasHabitOfSkipBreakfast />
          ) : (
            <Navigate replace to="/questionnaire/family-structure" />
          )
        }
      />
      <Route
        path="/questionnaire/frequency-of-drinking"
        element={
          !userStatus?.isAnsweredLifestyle ? (
            <FrequencyOfDrinking />
          ) : (
            <Navigate replace to="/questionnaire/family-structure" />
          )
        }
      />
      <Route
        path="/questionnaire/amount-of-drinking-per-day"
        element={
          !userStatus?.isAnsweredLifestyle ? (
            <AmountOfDrinkingPerDay />
          ) : (
            <Navigate replace to="/questionnaire/family-structure" />
          )
        }
      />
      <Route
        path="/questionnaire/can-get-enough-rest-with-sleep"
        element={
          !userStatus?.isAnsweredLifestyle ? (
            <CanGetEnoughRestWithSleep />
          ) : (
            <Navigate replace to="/questionnaire/family-structure" />
          )
        }
      />
      <Route
        path="/questionnaire/has-habit-of-sweaty-exercise-more-than-one-year"
        element={
          !userStatus?.isAnsweredLifestyle ? (
            <HasHabitOfSweatyExerciseMoreThanOneYear />
          ) : (
            <Navigate replace to="/questionnaire/family-structure" />
          )
        }
      />
      <Route
        path="/questionnaire/life-style-answers-complete"
        element={
          !userStatus?.isAnsweredLifestyle ? (
            <LifeStyleAnswersComplete />
          ) : (
            <Navigate replace to="/questionnaire/family-structure" />
          )
        }
      />
      {/* Environment */}
      <Route
        path="/questionnaire/family-structure"
        element={
          !userStatus?.isAnsweredEnvironment ? (
            <FamilyStructure />
          ) : (
            <Navigate replace to="/questionnaire/medical-history" />
          )
        }
      />
      <Route
        path="/questionnaire/occupation"
        element={
          !userStatus?.isAnsweredEnvironment ? <Occupation /> : <Navigate replace to="/questionnaire/medical-history" />
        }
      />
      <Route
        path="/questionnaire/means-of-transportation"
        element={
          !userStatus?.isAnsweredEnvironment ? (
            <MeansOfTransportation />
          ) : (
            <Navigate replace to="/questionnaire/medical-history" />
          )
        }
      />
      <Route
        path="/questionnaire/housing-type"
        element={
          !userStatus?.isAnsweredEnvironment ? (
            <HousingType />
          ) : (
            <Navigate replace to="/questionnaire/medical-history" />
          )
        }
      />
      <Route
        path="/questionnaire/has-car"
        element={
          !userStatus?.isAnsweredEnvironment ? <HasCar /> : <Navigate replace to="/questionnaire/medical-history" />
        }
      />
      <Route
        path="/questionnaire/frequency-of-driving"
        element={
          !userStatus?.isAnsweredEnvironment ? (
            <FrequencyOfDriving />
          ) : (
            <Navigate replace to="/questionnaire/medical-history" />
          )
        }
      />
      <Route
        path="/questionnaire/how-to-spend-holidays"
        element={
          !userStatus?.isAnsweredEnvironment ? (
            <HowToSpendHolidays />
          ) : (
            <Navigate replace to="/questionnaire/medical-history" />
          )
        }
      />
      <Route
        path="/questionnaire/number-of-friends"
        element={
          !userStatus?.isAnsweredEnvironment ? (
            <NumberOfFriends />
          ) : (
            <Navigate replace to="/questionnaire/medical-history" />
          )
        }
      />
      <Route
        path="/questionnaire/environment-answers-complete"
        element={!userStatus?.isAnsweredEnvironment ? <EnvironmentAnswersComplete /> : <Navigate replace to="/" />}
      />
      {/* MedicalHistory */}
      <Route
        path="/questionnaire/medical-history"
        element={!userStatus?.isAnsweredMedicalHistory ? <MedicalHistory /> : <Navigate replace to="/" />}
      />
      <Route
        path="predicting-healthy-life-span"
        element={!userStatus?.isAnsweredMedicalHistory ? <PredictingHealthyLifeSpan /> : <Navigate replace to="/" />}
      />
      {/* ==============================
        以下リダイレクト
      ============================== */}
      <Route
        path="/"
        element={
          !userStatus?.isAnsweredLifestyle ? (
            <Navigate replace to="/tutorial" />
          ) : !userStatus?.isAnsweredEnvironment ? (
            <Navigate replace to="/questionnaire/family-structure" />
          ) : !userStatus?.isAnsweredMedicalHistory ? (
            <Navigate replace to="/questionnaire/medical-history" />
          ) : null
        }
      />
      <Route
        path="/notifications"
        element={
          !userStatus?.isAnsweredLifestyle ? (
            <Navigate replace to="/tutorial" />
          ) : !userStatus?.isAnsweredEnvironment ? (
            <Navigate replace to="/questionnaire/family-structure" />
          ) : !userStatus?.isAnsweredMedicalHistory ? (
            <Navigate replace to="/questionnaire/medical-history" />
          ) : null
        }
      />
      <Route
        path="my-page"
        element={
          !userStatus?.isAnsweredLifestyle ? (
            <Navigate replace to="/tutorial" />
          ) : !userStatus?.isAnsweredEnvironment ? (
            <Navigate replace to="/questionnaire/family-structure" />
          ) : !userStatus?.isAnsweredMedicalHistory ? (
            <Navigate replace to="/questionnaire/medical-history" />
          ) : null
        }
      />
      {/* ========== past-questionnaire ========== */}
      <Route
        path="/past-questionnaire"
        element={
          !userStatus?.isAnsweredLifestyle ? (
            <Navigate replace to="/tutorial" />
          ) : !userStatus?.isAnsweredEnvironment ? (
            <Navigate replace to="/questionnaire/family-structure" />
          ) : !userStatus?.isAnsweredMedicalHistory ? (
            <Navigate replace to="/questionnaire/medical-history" />
          ) : null
        }
      />
      {/* Life style */}
      <Route
        path="/past-questionnaire/has-smoking-history"
        element={
          !userStatus?.isAnsweredLifestyle ? (
            <Navigate replace to="/tutorial" />
          ) : !userStatus?.isAnsweredEnvironment ? (
            <Navigate replace to="/questionnaire/family-structure" />
          ) : !userStatus?.isAnsweredMedicalHistory ? (
            <Navigate replace to="/questionnaire/medical-history" />
          ) : null
        }
      />
      <Route
        path="/past-questionnaire/has-smoking-habit"
        element={
          !userStatus?.isAnsweredLifestyle ? (
            <Navigate replace to="/tutorial" />
          ) : !userStatus?.isAnsweredEnvironment ? (
            <Navigate replace to="/questionnaire/family-structure" />
          ) : !userStatus?.isAnsweredMedicalHistory ? (
            <Navigate replace to="/questionnaire/medical-history" />
          ) : null
        }
      />
      <Route
        path="/past-questionnaire/current-smoking-habit"
        element={
          !userStatus?.isAnsweredLifestyle ? (
            <Navigate replace to="/tutorial" />
          ) : !userStatus?.isAnsweredEnvironment ? (
            <Navigate replace to="/questionnaire/family-structure" />
          ) : !userStatus?.isAnsweredMedicalHistory ? (
            <Navigate replace to="/questionnaire/medical-history" />
          ) : null
        }
      />
      <Route
        path="/past-questionnaire/past-smoking-habit"
        element={
          !userStatus?.isAnsweredLifestyle ? (
            <Navigate replace to="/tutorial" />
          ) : !userStatus?.isAnsweredEnvironment ? (
            <Navigate replace to="/questionnaire/family-structure" />
          ) : !userStatus?.isAnsweredMedicalHistory ? (
            <Navigate replace to="/questionnaire/medical-history" />
          ) : null
        }
      />
      <Route
        path="/past-questionnaire/has-habit-of-walking"
        element={
          !userStatus?.isAnsweredLifestyle ? (
            <Navigate replace to="/tutorial" />
          ) : !userStatus?.isAnsweredEnvironment ? (
            <Navigate replace to="/questionnaire/family-structure" />
          ) : !userStatus?.isAnsweredMedicalHistory ? (
            <Navigate replace to="/questionnaire/medical-history" />
          ) : null
        }
      />
      <Route
        path="/past-questionnaire/can-walk-faster-than"
        element={
          !userStatus?.isAnsweredLifestyle ? (
            <Navigate replace to="/tutorial" />
          ) : !userStatus?.isAnsweredEnvironment ? (
            <Navigate replace to="/questionnaire/family-structure" />
          ) : !userStatus?.isAnsweredMedicalHistory ? (
            <Navigate replace to="/questionnaire/medical-history" />
          ) : null
        }
      />
      <Route
        path="/past-questionnaire/can-eat-faster-than"
        element={
          !userStatus?.isAnsweredLifestyle ? (
            <Navigate replace to="/tutorial" />
          ) : !userStatus?.isAnsweredEnvironment ? (
            <Navigate replace to="/questionnaire/family-structure" />
          ) : !userStatus?.isAnsweredMedicalHistory ? (
            <Navigate replace to="/questionnaire/medical-history" />
          ) : null
        }
      />
      <Route
        path="/past-questionnaire/has-habit-of-eat-dinner-before-sleep"
        element={
          !userStatus?.isAnsweredLifestyle ? (
            <Navigate replace to="/tutorial" />
          ) : !userStatus?.isAnsweredEnvironment ? (
            <Navigate replace to="/questionnaire/family-structure" />
          ) : !userStatus?.isAnsweredMedicalHistory ? (
            <Navigate replace to="/questionnaire/medical-history" />
          ) : null
        }
      />
      <Route
        path="/past-questionnaire/has-habit-of-eat-snack-after-dinner"
        element={
          !userStatus?.isAnsweredLifestyle ? (
            <Navigate replace to="/tutorial" />
          ) : !userStatus?.isAnsweredEnvironment ? (
            <Navigate replace to="/questionnaire/family-structure" />
          ) : !userStatus?.isAnsweredMedicalHistory ? (
            <Navigate replace to="/questionnaire/medical-history" />
          ) : null
        }
      />
      <Route
        path="/past-questionnaire/has-habit-of-skip-breakfast"
        element={
          !userStatus?.isAnsweredLifestyle ? (
            <Navigate replace to="/tutorial" />
          ) : !userStatus?.isAnsweredEnvironment ? (
            <Navigate replace to="/questionnaire/family-structure" />
          ) : !userStatus?.isAnsweredMedicalHistory ? (
            <Navigate replace to="/questionnaire/medical-history" />
          ) : null
        }
      />
      <Route
        path="/past-questionnaire/frequency-of-drinking"
        element={
          !userStatus?.isAnsweredLifestyle ? (
            <Navigate replace to="/tutorial" />
          ) : !userStatus?.isAnsweredEnvironment ? (
            <Navigate replace to="/questionnaire/family-structure" />
          ) : !userStatus?.isAnsweredMedicalHistory ? (
            <Navigate replace to="/questionnaire/medical-history" />
          ) : null
        }
      />
      <Route
        path="/past-questionnaire/amount-of-drinking-per-day"
        element={
          !userStatus?.isAnsweredLifestyle ? (
            <Navigate replace to="/tutorial" />
          ) : !userStatus?.isAnsweredEnvironment ? (
            <Navigate replace to="/questionnaire/family-structure" />
          ) : !userStatus?.isAnsweredMedicalHistory ? (
            <Navigate replace to="/questionnaire/medical-history" />
          ) : null
        }
      />
      <Route
        path="/past-questionnaire/can-get-enough-rest-with-sleep"
        element={
          !userStatus?.isAnsweredLifestyle ? (
            <Navigate replace to="/tutorial" />
          ) : !userStatus?.isAnsweredEnvironment ? (
            <Navigate replace to="/questionnaire/family-structure" />
          ) : !userStatus?.isAnsweredMedicalHistory ? (
            <Navigate replace to="/questionnaire/medical-history" />
          ) : null
        }
      />
      <Route
        path="/past-questionnaire/has-habit-of-sweaty-exercise-more-than-one-year"
        element={
          !userStatus?.isAnsweredLifestyle ? (
            <Navigate replace to="/tutorial" />
          ) : !userStatus?.isAnsweredEnvironment ? (
            <Navigate replace to="/questionnaire/family-structure" />
          ) : !userStatus?.isAnsweredMedicalHistory ? (
            <Navigate replace to="/questionnaire/medical-history" />
          ) : null
        }
      />
      {/* Environment */}
      <Route
        path="/past-questionnaire/postal-code"
        element={
          !userStatus?.isAnsweredLifestyle ? (
            <Navigate replace to="/tutorial" />
          ) : !userStatus?.isAnsweredEnvironment ? (
            <Navigate replace to="/questionnaire/family-structure" />
          ) : !userStatus?.isAnsweredMedicalHistory ? (
            <Navigate replace to="/questionnaire/medical-history" />
          ) : null
        }
      />
      <Route
        path="/past-questionnaire/family-structure"
        element={
          !userStatus?.isAnsweredLifestyle ? (
            <Navigate replace to="/tutorial" />
          ) : !userStatus?.isAnsweredEnvironment ? (
            <Navigate replace to="/questionnaire/family-structure" />
          ) : !userStatus?.isAnsweredMedicalHistory ? (
            <Navigate replace to="/questionnaire/medical-history" />
          ) : null
        }
      />
      <Route
        path="/past-questionnaire/number-of-children"
        element={
          !userStatus?.isAnsweredLifestyle ? (
            <Navigate replace to="/tutorial" />
          ) : !userStatus?.isAnsweredEnvironment ? (
            <Navigate replace to="/questionnaire/family-structure" />
          ) : !userStatus?.isAnsweredMedicalHistory ? (
            <Navigate replace to="/questionnaire/medical-history" />
          ) : null
        }
      />
      <Route
        path="/past-questionnaire/youngest-child-age"
        element={
          !userStatus?.isAnsweredLifestyle ? (
            <Navigate replace to="/tutorial" />
          ) : !userStatus?.isAnsweredEnvironment ? (
            <Navigate replace to="/questionnaire/family-structure" />
          ) : !userStatus?.isAnsweredMedicalHistory ? (
            <Navigate replace to="/questionnaire/medical-history" />
          ) : null
        }
      />
      <Route
        path="/past-questionnaire/occupation"
        element={
          !userStatus?.isAnsweredLifestyle ? (
            <Navigate replace to="/tutorial" />
          ) : !userStatus?.isAnsweredEnvironment ? (
            <Navigate replace to="/questionnaire/family-structure" />
          ) : !userStatus?.isAnsweredMedicalHistory ? (
            <Navigate replace to="/questionnaire/medical-history" />
          ) : null
        }
      />
      <Route
        path="/past-questionnaire/work-place"
        element={
          !userStatus?.isAnsweredLifestyle ? (
            <Navigate replace to="/tutorial" />
          ) : !userStatus?.isAnsweredEnvironment ? (
            <Navigate replace to="/questionnaire/family-structure" />
          ) : !userStatus?.isAnsweredMedicalHistory ? (
            <Navigate replace to="/questionnaire/medical-history" />
          ) : null
        }
      />
      <Route
        path="/past-questionnaire/means-of-transportation"
        element={
          !userStatus?.isAnsweredLifestyle ? (
            <Navigate replace to="/tutorial" />
          ) : !userStatus?.isAnsweredEnvironment ? (
            <Navigate replace to="/questionnaire/family-structure" />
          ) : !userStatus?.isAnsweredMedicalHistory ? (
            <Navigate replace to="/questionnaire/medical-history" />
          ) : null
        }
      />
      <Route
        path="/past-questionnaire/housing-type"
        element={
          !userStatus?.isAnsweredLifestyle ? (
            <Navigate replace to="/tutorial" />
          ) : !userStatus?.isAnsweredEnvironment ? (
            <Navigate replace to="/questionnaire/family-structure" />
          ) : !userStatus?.isAnsweredMedicalHistory ? (
            <Navigate replace to="/questionnaire/medical-history" />
          ) : null
        }
      />
      <Route
        path="/past-questionnaire/has-car"
        element={
          !userStatus?.isAnsweredLifestyle ? (
            <Navigate replace to="/tutorial" />
          ) : !userStatus?.isAnsweredEnvironment ? (
            <Navigate replace to="/questionnaire/family-structure" />
          ) : !userStatus?.isAnsweredMedicalHistory ? (
            <Navigate replace to="/questionnaire/medical-history" />
          ) : null
        }
      />
      <Route
        path="/past-questionnaire/frequency-of-driving"
        element={
          !userStatus?.isAnsweredLifestyle ? (
            <Navigate replace to="/tutorial" />
          ) : !userStatus?.isAnsweredEnvironment ? (
            <Navigate replace to="/questionnaire/family-structure" />
          ) : !userStatus?.isAnsweredMedicalHistory ? (
            <Navigate replace to="/questionnaire/medical-history" />
          ) : null
        }
      />
      <Route
        path="/past-questionnaire/how-to-spend-holidays"
        element={
          !userStatus?.isAnsweredLifestyle ? (
            <Navigate replace to="/tutorial" />
          ) : !userStatus?.isAnsweredEnvironment ? (
            <Navigate replace to="/questionnaire/family-structure" />
          ) : !userStatus?.isAnsweredMedicalHistory ? (
            <Navigate replace to="/questionnaire/medical-history" />
          ) : null
        }
      />
      <Route
        path="/past-questionnaire/number-of-friends"
        element={
          !userStatus?.isAnsweredLifestyle ? (
            <Navigate replace to="/tutorial" />
          ) : !userStatus?.isAnsweredEnvironment ? (
            <Navigate replace to="/questionnaire/family-structure" />
          ) : !userStatus?.isAnsweredMedicalHistory ? (
            <Navigate replace to="/questionnaire/medical-history" />
          ) : null
        }
      />
      {/* MedicalHistory */}
      <Route
        path="/past-questionnaire/medical-history"
        element={
          !userStatus?.isAnsweredLifestyle ? (
            <Navigate replace to="/tutorial" />
          ) : !userStatus?.isAnsweredEnvironment ? (
            <Navigate replace to="/questionnaire/family-structure" />
          ) : !userStatus?.isAnsweredMedicalHistory ? (
            <Navigate replace to="/questionnaire/medical-history" />
          ) : null
        }
      />{' '}
    </Routes>
  );
};
