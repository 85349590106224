import { type FC, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { NavigationHeader } from '@/components/NavigationHeader';
import { useTutorial } from '@/features/tutorial/hooks/useTutorial';
import { Logger } from '@/lib/Logger';
import { TutorialBeginEvent, TutorialCompleteEvent } from '@/lib/Logger/events';
import { type UserStatus } from '@/types/graphql';

import { TutorialTemplate } from './template';

type Props = {
  userStatus?: UserStatus;
};

export const Tutorial: FC<Props> = (props) => {
  const { userStatus } = props;
  const navigate = useNavigate();
  const { index, setIndex } = useTutorial();

  const handleTermsConfirmPress = useCallback(() => {
    Logger.logEvent(TutorialCompleteEvent());
    if (userStatus?.shouldPredictHealthLimit) {
      navigate('/questionnaire/start');
    } else {
      navigate('/cannot-predict-health-limit');
    }
  }, [navigate, userStatus]);

  useEffect(() => {
    Logger.logEvent(TutorialBeginEvent());
  }, []);

  return (
    <>
      <NavigationHeader title="" headerLeft="logo" headerColor="white" />
      <TutorialTemplate onTermsConfirmPress={handleTermsConfirmPress} onUpdateIndex={setIndex} index={index} />
    </>
  );
};
