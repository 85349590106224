export const byNumberDescending =
  <T>(getNumberProperty: (obj: T) => number) =>
  (firstObj: T, secondObj: T) => {
    const first = getNumberProperty(firstObj);
    const second = getNumberProperty(secondObj);
    if (first < second) {
      return 1;
    } else if (first > second) {
      return -1;
    } else {
      return 0;
    }
  };
