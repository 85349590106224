import { type FC } from 'react';

import { Colors } from '@/constants/Colors';

type Props = {
  size?: number;
  fill?: string;
};

export const RankSecond: FC<Props> = (props) => {
  const { size = 24, fill = Colors.black100 } = props;

  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="transparent" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2_308)">
        <path
          d="M1 3.73913L7.31053 5.74783L12 1L16.6895 5.74783L23 3.73913L21.3789 22H2.62105L1 3.73913Z"
          fill={fill}
        />
        <path
          d="M8.96494 18H14.9169V16.512H12.9969C12.5769 16.512 11.9889 16.572 11.5449 16.62C13.1649 15.036 14.5089 13.308 14.5089 11.688C14.5089 10.032 13.3809 8.964 11.7129 8.964C10.5009 8.964 9.69694 9.432 8.88094 10.308L9.85294 11.268C10.3089 10.764 10.8249 10.344 11.4849 10.344C12.3369 10.344 12.8049 10.896 12.8049 11.784C12.8049 13.152 11.3889 14.82 8.96494 16.992V18Z"
          fill="white"
        />
      </g>

      <defs>
        <clipPath id="clip0_2_308">
          <rect width={size} height={size} fill="transparent" transform="matrix(-1 0 0 1 24 0)" />
        </clipPath>
      </defs>
    </svg>
  );
};
