import { type graphqlContext, type MockedRequest, type ResponseResolver } from 'msw';

import { type GetEnvironmentQuestionnaireQuery } from '@/hooks/GetQuestionnaire.generated';

export const mockGetEnvironmentQuestionnairePayload: GetEnvironmentQuestionnaireQuery = {
  getQuestionnaire: {
    id: 'UXVlc3Rpb25uYWlyZUlkOjIxMw==',
    items: [
      {
        name: 'FAMILY_STRUCTURE',
        displayNumber: '1',
        sortIndex: 1,
        question: 'お住まいの家族構成を選択してください。',
        supplement: '',
        forms: [
          {
            title: null,
            name: 'familyStructure',
            items: [
              {
                label: '一人暮らし',
                value: 1,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: '配偶者と同居',
                value: 2,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: '配偶者・子供と同居',
                value: 3,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: '子供と同居',
                value: 4,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: '親・配偶者と同居',
                value: 5,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: '親・配偶者・子供と同居',
                value: 6,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: '親・子供と同居',
                value: 7,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: 'そのほか',
                value: 99,
                selected: false,
                otherItemSupplement: {
                  value: null,
                  placeholder: 'そのほかの場合はこちらへ入力',
                  __typename: 'OtherItemSupplement',
                },
                __typename: 'SelectTypeItem',
              },
            ],
            __typename: 'SelectTypeForm',
          },
        ],
        subItems: null,
        __typename: 'QuestionnaireItem',
      },
      {
        name: 'OCCUPATION',
        displayNumber: '2',
        sortIndex: 2,
        question: '現在の職業を選択してください。',
        supplement: '',
        forms: [
          {
            title: null,
            name: 'occupation',
            items: [
              {
                label: '自営業',
                value: 1,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: '会社員',
                value: 2,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: '会社役員',
                value: 3,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: '派遣社員',
                value: 4,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: '家族従事者',
                value: 5,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: 'パート・アルバイト',
                value: 6,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: '学生',
                value: 7,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: '専業主婦・専業主夫',
                value: 8,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: '無職',
                value: 9,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: 'そのほか',
                value: 99,
                selected: false,
                otherItemSupplement: {
                  value: null,
                  placeholder: 'そのほかの場合はこちらへ入力',
                  __typename: 'OtherItemSupplement',
                },
                __typename: 'SelectTypeItem',
              },
            ],
            __typename: 'SelectTypeForm',
          },
        ],
        subItems: null,
        __typename: 'QuestionnaireItem',
      },
      {
        name: 'MEANS_OF_TRANSPORTATION',
        displayNumber: '3',
        sortIndex: 3,
        question: '現在の通勤交通手段を選択してください（複数ある方は一番利用頻度が高い交通手段をご選択ください）。',
        supplement: '',
        forms: [
          {
            title: null,
            name: 'meansOfTransportation',
            items: [
              {
                label: '徒歩',
                value: 1,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: '自転車',
                value: 2,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: 'バス',
                value: 3,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: '自家用車',
                value: 4,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: '電車',
                value: 5,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: 'タクシー',
                value: 6,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: 'そのほか',
                value: 99,
                selected: false,
                otherItemSupplement: {
                  value: null,
                  placeholder: 'そのほかの場合はこちらへ入力',
                  __typename: 'OtherItemSupplement',
                },
                __typename: 'SelectTypeItem',
              },
            ],
            __typename: 'SelectTypeForm',
          },
        ],
        subItems: null,
        __typename: 'QuestionnaireItem',
      },
      {
        name: 'HOUSING_TYPE',
        displayNumber: '4',
        sortIndex: 4,
        question: '現在の住宅形態を選択してください。',
        supplement: '',
        forms: [
          {
            title: null,
            name: 'housingType',
            items: [
              {
                label: '持ち家（一戸建て）',
                value: 1,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: '持ち家（集合住宅）',
                value: 2,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: '借家（一戸建て）',
                value: 3,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: '借家（集合住宅）',
                value: 4,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: '寮・社宅',
                value: 5,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: 'そのほか',
                value: 99,
                selected: false,
                otherItemSupplement: {
                  value: null,
                  placeholder: 'そのほかの場合はこちらへ入力',
                  __typename: 'OtherItemSupplement',
                },
                __typename: 'SelectTypeItem',
              },
            ],
            __typename: 'SelectTypeForm',
          },
        ],
        subItems: null,
        __typename: 'QuestionnaireItem',
      },
      {
        name: 'HAS_CAR',
        displayNumber: '5',
        sortIndex: 5,
        question: '自動車は保有していますか。',
        supplement: '',
        forms: [
          {
            title: null,
            name: 'hasCar',
            items: [
              {
                label: '保有している',
                value: 1,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: '保有していない',
                value: 2,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
            ],
            __typename: 'SelectTypeForm',
          },
        ],
        subItems: null,
        __typename: 'QuestionnaireItem',
      },
      {
        name: 'FREQUENCY_OF_DRIVING',
        displayNumber: '6',
        sortIndex: 6,
        question: '自転車の運転頻度を選択してください。',
        supplement: '',
        forms: [
          {
            title: null,
            name: 'frequencyOfDriving',
            items: [
              {
                label: 'ほぼ毎日',
                value: 1,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: '週に 2,3 回',
                value: 2,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: '週に 1 回程度',
                value: 3,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: '月に 1 回程度',
                value: 4,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: '運転しない',
                value: 5,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: 'そのほか',
                value: 99,
                selected: false,
                otherItemSupplement: {
                  value: null,
                  placeholder: 'そのほかの場合はこちらへ入力',
                  __typename: 'OtherItemSupplement',
                },
                __typename: 'SelectTypeItem',
              },
            ],
            __typename: 'SelectTypeForm',
          },
        ],
        subItems: null,
        __typename: 'QuestionnaireItem',
      },
      {
        name: 'HOW_TO_SPEND_HOLIDAYS',
        displayNumber: '7',
        sortIndex: 7,
        question: '休日の過ごし方を選択してください。',
        supplement: '',
        forms: [
          {
            title: null,
            name: 'howToSpendHolidays',
            items: [
              {
                label: '家事',
                name: 'housework',
                value: 1,
                selected: false,
                otherItemSupplement: null,
                __typename: 'MultiSelectTypeItem',
              },
              {
                label: '仕事',
                name: 'work',
                value: 2,
                selected: false,
                otherItemSupplement: null,
                __typename: 'MultiSelectTypeItem',
              },
              {
                label: '育児',
                name: 'childcare',
                value: 3,
                selected: false,
                otherItemSupplement: null,
                __typename: 'MultiSelectTypeItem',
              },
              {
                label: '読書',
                name: 'reading',
                value: 4,
                selected: false,
                otherItemSupplement: null,
                __typename: 'MultiSelectTypeItem',
              },
              {
                label: 'ゲーム',
                name: 'game',
                value: 5,
                selected: false,
                otherItemSupplement: null,
                __typename: 'MultiSelectTypeItem',
              },
              {
                label: 'テレビ・ビデオ鑑賞',
                name: 'watchingTvAndVideos',
                value: 6,
                selected: false,
                otherItemSupplement: null,
                __typename: 'MultiSelectTypeItem',
              },
              {
                label: '趣味',
                name: 'hobby',
                value: 7,
                selected: false,
                otherItemSupplement: null,
                __typename: 'MultiSelectTypeItem',
              },
              {
                label: 'ネットサーフィン',
                name: 'netSurfing',
                value: 8,
                selected: false,
                otherItemSupplement: null,
                __typename: 'MultiSelectTypeItem',
              },
              {
                label: 'そのほか',
                name: 'otherWays',
                value: 99,
                selected: false,
                otherItemSupplement: {
                  name: 'otherWaysSupplement',
                  value: '',
                  placeholder: 'そのほかの場合はこちらへ入力',
                  __typename: 'OtherItemSupplement',
                },
                __typename: 'MultiSelectTypeItem',
              },
            ],
            __typename: 'SelectTypeForm',
          },
        ],
        subItems: null,
        __typename: 'QuestionnaireItem',
      },
      {
        name: 'NUMBER_OF_FRIENDS',
        displayNumber: '8',
        sortIndex: 8,
        question: 'プライベートで付き合いのある友人の数を選択してください。',
        supplement: '',
        forms: [
          {
            title: null,
            name: 'numberOfFriends',
            items: [
              {
                label: 'いない',
                value: 1,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: '1〜3 人',
                value: 2,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: '4〜10 人',
                value: 3,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: '11〜20 人',
                value: 4,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: '21〜50 人',
                value: 5,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: '51 人以上',
                value: 6,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
            ],
            __typename: 'SelectTypeForm',
          },
        ],
        subItems: null,
        __typename: 'QuestionnaireItem',
      },
    ],
    __typename: 'Questionnaire',
  },
};

export const mockGetEnvironmentQuestionnaireAnsweredPayload: GetEnvironmentQuestionnaireQuery = {
  getQuestionnaire: {
    id: 'UXVlc3Rpb25uYWlyZUlkOjIxMw==',
    items: [
      {
        name: 'FAMILY_STRUCTURE',
        displayNumber: '1',
        sortIndex: 1,
        question: 'お住まいの家族構成を選択してください。',
        supplement: '',
        forms: [
          {
            title: null,
            name: 'familyStructure',
            items: [
              {
                label: '一人暮らし',
                value: 1,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: '配偶者と同居',
                value: 2,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: '配偶者・子供と同居',
                value: 3,
                selected: true,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: '子供と同居',
                value: 4,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: '親・配偶者と同居',
                value: 5,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: '親・配偶者・子供と同居',
                value: 6,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: '親・子供と同居',
                value: 7,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: 'そのほか',
                value: 99,
                selected: false,
                otherItemSupplement: {
                  value: null,
                  placeholder: 'そのほかの場合はこちらへ入力',
                  __typename: 'OtherItemSupplement',
                },
                __typename: 'SelectTypeItem',
              },
            ],
            __typename: 'SelectTypeForm',
          },
        ],
        subItems: null,
        __typename: 'QuestionnaireItem',
      },
      {
        name: 'OCCUPATION',
        displayNumber: '2',
        sortIndex: 2,
        question: '現在の職業を選択してください。',
        supplement: '',
        forms: [
          {
            title: null,
            name: 'occupation',
            items: [
              {
                label: '自営業',
                value: 1,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: '会社員',
                value: 2,
                selected: true,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: '会社役員',
                value: 3,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: '派遣社員',
                value: 4,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: '家族従事者',
                value: 5,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: 'パート・アルバイト',
                value: 6,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: '学生',
                value: 7,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: '専業主婦・専業主夫',
                value: 8,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: '無職',
                value: 9,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: 'そのほか',
                value: 99,
                selected: false,
                otherItemSupplement: {
                  value: null,
                  placeholder: 'そのほかの場合はこちらへ入力',
                  __typename: 'OtherItemSupplement',
                },
                __typename: 'SelectTypeItem',
              },
            ],
            __typename: 'SelectTypeForm',
          },
        ],
        subItems: null,
        __typename: 'QuestionnaireItem',
      },
      {
        name: 'MEANS_OF_TRANSPORTATION',
        displayNumber: '3',
        sortIndex: 3,
        question: '現在の通勤交通手段を選択してください（複数ある方は一番利用頻度が高い交通手段をご選択ください）。',
        supplement: '',
        forms: [
          {
            title: null,
            name: 'meansOfTransportation',
            items: [
              {
                label: '徒歩',
                value: 1,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: '自転車',
                value: 2,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: 'バス',
                value: 3,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: '自家用車',
                value: 4,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: '電車',
                value: 5,
                selected: true,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: 'タクシー',
                value: 6,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: 'そのほか',
                value: 99,
                selected: false,
                otherItemSupplement: {
                  value: null,
                  placeholder: 'そのほかの場合はこちらへ入力',
                  __typename: 'OtherItemSupplement',
                },
                __typename: 'SelectTypeItem',
              },
            ],
            __typename: 'SelectTypeForm',
          },
        ],
        subItems: null,
        __typename: 'QuestionnaireItem',
      },
      {
        name: 'HOUSING_TYPE',
        displayNumber: '4',
        sortIndex: 4,
        question: '現在の住宅形態を選択してください。',
        supplement: '',
        forms: [
          {
            title: null,
            name: 'housingType',
            items: [
              {
                label: '持ち家（一戸建て）',
                value: 1,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: '持ち家（集合住宅）',
                value: 2,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: '借家（一戸建て）',
                value: 3,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: '借家（集合住宅）',
                value: 4,
                selected: true,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: '寮・社宅',
                value: 5,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: 'そのほか',
                value: 99,
                selected: false,
                otherItemSupplement: {
                  value: null,
                  placeholder: 'そのほかの場合はこちらへ入力',
                  __typename: 'OtherItemSupplement',
                },
                __typename: 'SelectTypeItem',
              },
            ],
            __typename: 'SelectTypeForm',
          },
        ],
        subItems: null,
        __typename: 'QuestionnaireItem',
      },
      {
        name: 'HAS_CAR',
        displayNumber: '5',
        sortIndex: 5,
        question: '自動車は保有していますか。',
        supplement: '',
        forms: [
          {
            title: null,
            name: 'hasCar',
            items: [
              {
                label: '保有している',
                value: 1,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: '保有していない',
                value: 2,
                selected: true,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
            ],
            __typename: 'SelectTypeForm',
          },
        ],
        subItems: null,
        __typename: 'QuestionnaireItem',
      },
      {
        name: 'FREQUENCY_OF_DRIVING',
        displayNumber: '6',
        sortIndex: 6,
        question: '自転車の運転頻度を選択してください。',
        supplement: '',
        forms: [
          {
            title: null,
            name: 'frequencyOfDriving',
            items: [
              {
                label: 'ほぼ毎日',
                value: 1,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: '週に 2,3 回',
                value: 2,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: '週に 1 回程度',
                value: 3,
                selected: true,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: '月に 1 回程度',
                value: 4,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: '運転しない',
                value: 5,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: 'そのほか',
                value: 99,
                selected: false,
                otherItemSupplement: {
                  value: null,
                  placeholder: 'そのほかの場合はこちらへ入力',
                  __typename: 'OtherItemSupplement',
                },
                __typename: 'SelectTypeItem',
              },
            ],
            __typename: 'SelectTypeForm',
          },
        ],
        subItems: null,
        __typename: 'QuestionnaireItem',
      },
      {
        name: 'HOW_TO_SPEND_HOLIDAYS',
        displayNumber: '7',
        sortIndex: 7,
        question: '休日の過ごし方を選択してください。',
        supplement: '',
        forms: [
          {
            title: null,
            name: 'howToSpendHolidays',
            items: [
              {
                label: '家事',
                name: 'housework',
                value: 1,
                selected: true,
                otherItemSupplement: null,
                __typename: 'MultiSelectTypeItem',
              },
              {
                label: '仕事',
                name: 'work',
                value: 2,
                selected: false,
                otherItemSupplement: null,
                __typename: 'MultiSelectTypeItem',
              },
              {
                label: '育児',
                name: 'childcare',
                value: 3,
                selected: true,
                otherItemSupplement: null,
                __typename: 'MultiSelectTypeItem',
              },
              {
                label: '読書',
                name: 'reading',
                value: 4,
                selected: false,
                otherItemSupplement: null,
                __typename: 'MultiSelectTypeItem',
              },
              {
                label: 'ゲーム',
                name: 'game',
                value: 5,
                selected: false,
                otherItemSupplement: null,
                __typename: 'MultiSelectTypeItem',
              },
              {
                label: 'テレビ・ビデオ鑑賞',
                name: 'watchingTvAndVideos',
                value: 6,
                selected: false,
                otherItemSupplement: null,
                __typename: 'MultiSelectTypeItem',
              },
              {
                label: '趣味',
                name: 'hobby',
                value: 7,
                selected: false,
                otherItemSupplement: null,
                __typename: 'MultiSelectTypeItem',
              },
              {
                label: 'ネットサーフィン',
                name: 'netSurfing',
                value: 8,
                selected: false,
                otherItemSupplement: null,
                __typename: 'MultiSelectTypeItem',
              },
              {
                label: 'そのほか',
                name: 'otherWays',
                value: 99,
                selected: true,
                otherItemSupplement: {
                  name: 'otherWaysSupplement',
                  value: 'キャンプ',
                  placeholder: 'そのほかの場合はこちらへ入力',
                  __typename: 'OtherItemSupplement',
                },
                __typename: 'MultiSelectTypeItem',
              },
            ],
            __typename: 'SelectTypeForm',
          },
        ],
        subItems: null,
        __typename: 'QuestionnaireItem',
      },
      {
        name: 'NUMBER_OF_FRIENDS',
        displayNumber: '8',
        sortIndex: 8,
        question: 'プライベートで付き合いのある友人の数を選択してください。',
        supplement: '',
        forms: [
          {
            title: null,
            name: 'numberOfFriends',
            items: [
              {
                label: 'いない',
                value: 1,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: '1〜3 人',
                value: 2,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: '4〜10 人',
                value: 3,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: '11〜20 人',
                value: 4,
                selected: true,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: '21〜50 人',
                value: 5,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: '51 人以上',
                value: 6,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
            ],
            __typename: 'SelectTypeForm',
          },
        ],
        subItems: null,
        __typename: 'QuestionnaireItem',
      },
    ],
    __typename: 'Questionnaire',
  },
};

export const mockGetEnvironmentQuestionnaire: ResponseResolver<MockedRequest, typeof graphqlContext> = (
  req,
  res,
  ctx
) => res(ctx.data(mockGetEnvironmentQuestionnaireAnsweredPayload));
