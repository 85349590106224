import type { ComponentProps, FC } from 'react';
import { useMemo } from 'react';
import type { TextStyle, ViewStyle } from 'react-native';
import { StyleSheet, View } from 'react-native';

import { messages as t } from '@/assets/i18n/ja';
import { Icon } from '@/components/Icon';
import { Text } from '@/components/Text';
import { Touchable } from '@/components/Touchable';
import { Colors } from '@/constants/Colors';
import type { Organ, OrganInfo } from '@/features/home/types/Organ';
import { organTypeToText } from '@/features/home/utils/OrganTypeToText';
import { type HealthyLifeSpan, type HealthyLifeSpanComparison } from '@/types/graphql';
import { styleType } from '@/utils/styleType';

type Props = {
  rank: number;
  healthLifeSpan: HealthyLifeSpan;
  organInfo: OrganInfo;
  onPress: () => void;
};

const COLORS_BY_LIFE_EXPECTANCY_STATUS = {
  shorter: {
    rankIconFill: Colors.black100,
    containerBackground: Colors.gray2,
  },
  average: {
    rankIconFill: Colors.black54,
    containerBackground: Colors.white100,
  },
  longer: {
    rankIconFill: Colors.healthactionGreen,
    containerBackground: Colors.white100,
  },
};

export const OrganRankingItem: FC<Props> = (props) => {
  const { rank, healthLifeSpan, organInfo, onPress } = props;

  const averageLifeExpectancyStatus = useMemo(
    () => setAverageLifeExpectancyStatus(healthLifeSpan.comparisonOfSameGeneration),
    [healthLifeSpan.comparisonOfSameGeneration]
  );

  const AverageLifeExpectancyText = useMemo(
    () => renderAverageLifeExpectancyText(healthLifeSpan.comparisonOfSameGeneration, averageLifeExpectancyStatus),
    [averageLifeExpectancyStatus, healthLifeSpan.comparisonOfSameGeneration]
  );

  return (
    <Touchable
      style={[
        styles.container,
        { backgroundColor: COLORS_BY_LIFE_EXPECTANCY_STATUS[averageLifeExpectancyStatus].containerBackground },
      ]}
      onPress={onPress}
    >
      <View style={styles.content}>
        <View style={styles.mainContent}>
          <View style={styles.contentIcons}>
            <RankIcon
              rank={numberToEnglishNumbers(rank)}
              colorFill={COLORS_BY_LIFE_EXPECTANCY_STATUS[averageLifeExpectancyStatus].rankIconFill}
            />
            <OrganIcon iconType={organInfo.organ} />
          </View>
          <View>
            <View style={styles.subContentTexts}>
              <Text.Headline style={styles.organText}>{organTypeToText(organInfo.organ)}</Text.Headline>
              <Text.Headline>
                {t.home.organRanking.healthyLifeExpectancy(healthLifeSpan.age.year, healthLifeSpan.age.month)}
              </Text.Headline>
            </View>
            <View>
              <Text.Headline style={styles.remainingLifeExpectancyText}>
                {t.home.organRanking.remainingHealthyLifeExpectancy(
                  healthLifeSpan.remaining.year,
                  healthLifeSpan.remaining.month
                )}
              </Text.Headline>
              {AverageLifeExpectancyText}
            </View>
          </View>
        </View>

        <View style={styles.buttonIcon}>
          <Icon type="ArrowRight" size={32} />
        </View>
      </View>
    </Touchable>
  );
};

type LifeExpectancyStatus = 'shorter' | 'longer' | 'average';
const setAverageLifeExpectancyStatus = (healthyLifeSpanComparison: HealthyLifeSpanComparison) => {
  const { isAverage, year, month } = healthyLifeSpanComparison;

  if (isAverage) {
    return 'average';
  } else if (year < 0 || month < 0) {
    return 'shorter';
  } else {
    return 'longer';
  }
};

/**
 * ー平均と同じ場合；平均的な健康寿命
 * ー平均より短い場合：同世代よりyy年mm月短い
 * ー平均より長い場合：同世代よりyy年mm月長い
 * @param healthyLifeSpanComparison
 * @param averageLifeExpectancyStatus
 */
const renderAverageLifeExpectancyText = (
  healthyLifeSpanComparison: HealthyLifeSpanComparison,
  averageLifeExpectancyStatus: LifeExpectancyStatus
) => {
  const { year, month } = healthyLifeSpanComparison;

  if (averageLifeExpectancyStatus === 'shorter') {
    // Math.absはマイナスを消すのため
    return (
      <Text.Subtext style={styles.lowerLifeExpectancy}>
        {t.home.organRanking.shorterThanAverage(Math.abs(year), Math.abs(month))}
      </Text.Subtext>
    );
  } else if (averageLifeExpectancyStatus === 'longer') {
    return (
      <Text.Subtext style={styles.longerLifeExpectancy}>
        {t.home.organRanking.longerThanAverage(Math.abs(year), Math.abs(month))}
      </Text.Subtext>
    );
  }

  return <Text.Subtext>{t.home.organRanking.average}</Text.Subtext>;
};

type RankIconType = ComponentProps<typeof Icon>['type'];
const RankIcon = ({ rank, colorFill }: { rank: string; colorFill: string }) => {
  const iconType = `Rank${rank}` as RankIconType;

  return (
    <View style={styles.rankIcon}>
      <Icon type={iconType} fill={colorFill} size={24} />
    </View>
  );
};

type OrganIconType = {
  iconType: Organ;
};
/**
 * アイコンの名前はOrganのTypesと同じです
 * @param props
 */
const OrganIcon = (props: OrganIconType) => {
  const { iconType } = props;
  return (
    <View style={styles.organIcon}>
      <Icon type={iconType} size={40} />
    </View>
  );
};

/**
 * アイコンの名前は "Rank" + string rank
 * 例えば：RankFirst
 * @param rank
 */
const numberToEnglishNumbers = (rank: number) => {
  switch (rank) {
    case 1:
      return 'First';
    case 2:
      return 'Second';
    case 3:
      return 'Third';
    case 4:
      return 'Fourth';
    case 5:
      return 'Fifth';
    default:
      throw new Error('Rank number is incorrect');
  }
};

const styles = StyleSheet.create({
  container: styleType<ViewStyle>({
    minHeight: 110,
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderRadius: 8,
    marginVertical: 4,
    paddingBottom: 16,
    paddingTop: 20,
    paddingLeft: 16,
    borderColor: Colors.black12,
    borderWidth: 1,
  }),
  content: styleType<ViewStyle>({
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignSelf: 'flex-start',
    flex: 1,
  }),
  contentIcons: styleType<ViewStyle>({
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'flex-start',
    height: 40,
  }),
  mainContent: styleType<ViewStyle>({
    flexDirection: 'row',
    alignSelf: 'flex-start',
  }),
  subContentTexts: styleType<ViewStyle>({
    flexDirection: 'row',
    marginTop: 8,
    alignItems: 'center',
  }),
  rankIcon: styleType<ViewStyle>({
    height: 24,
    width: 24,
    marginRight: 16,
  }),
  organIcon: styleType<ViewStyle>({
    height: 40,
    width: 40,
    marginRight: 16,
  }),
  buttonIcon: styleType<ViewStyle>({
    height: 32,
    width: 32,
    marginRight: 16,
    marginTop: 12,
    alignItems: 'center',
    alignContent: 'flex-end',
    padding: 4,
    backgroundColor: Colors.white100,
    borderRadius: 90,
  }),
  organText: styleType<TextStyle>({
    minWidth: 30,
    marginRight: 12,
  }),
  remainingLifeExpectancyText: styleType<TextStyle>({
    marginBottom: 4,
    marginTop: 8,
  }),
  lowerLifeExpectancy: styleType<TextStyle>({
    color: Colors.systemRed,
  }),
  longerLifeExpectancy: styleType<TextStyle>({
    color: Colors.healthactionGreen,
  }),
});
