import type { ComponentProps, FC } from 'react';

import { HasHabitOfEatSnackAfterDinner } from '@/components/questionnaire/form/lifeStyle';

import { QuestionnaireContainer } from '../parts/QuestionnaireContainer';
import type { QuestionNumber } from '../parts/QuestionNumber';

type Props = Omit<ComponentProps<typeof QuestionNumber>, 'style'> &
  ComponentProps<typeof HasHabitOfEatSnackAfterDinner>;

export const HasHabitOfEatSnackAfterDinnerTemplate: FC<Props> = (props) => {
  const { questionNumber, allQuestionNumber, question, selections, defaultValue, onSubmit } = props;

  return (
    <QuestionnaireContainer questionNumber={questionNumber} allQuestionNumber={allQuestionNumber}>
      <HasHabitOfEatSnackAfterDinner
        question={question}
        selections={selections}
        defaultValue={defaultValue}
        onSubmit={onSubmit}
      />
    </QuestionnaireContainer>
  );
};
