import { type ComponentProps } from 'react';
import { type FieldValues, type UseControllerProps, Controller } from 'react-hook-form';

import { SelectionList } from '../SelectionList';

type Props<T extends FieldValues> = UseControllerProps<T> & ComponentProps<typeof SelectionList>;

export const SelectionListControlled = <T extends FieldValues>(props: Props<T>) => {
  const { name, control, type, selections, onPress } = props;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value } }) => (
        <SelectionList type={type} onPress={onPress} selections={selections} selectedValue={value} />
      )}
    />
  );
};
