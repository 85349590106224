import { useAtom } from 'jotai';
import type { ComponentProps } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { messages as t } from '@/assets/i18n/ja';
import { NavigationHeader } from '@/components/NavigationHeader';
import { ServerErrorView } from '@/components/ServerErrorView';
import { BuildConfig } from '@/constants/BuildConfig';
import { ENVIRONMENT } from '@/features/questionnaire/constants/QuestionnaireGroupName';
import { useCreateOrUpdateEnvironmentAnswerMutation } from '@/hooks/CreateOrUpdateAnswer.generated';
import { useUserStatus } from '@/hooks/useUserStatus';
import { Logger } from '@/lib/Logger';
import { QuestionnaireCompleteEvent } from '@/lib/Logger/events';
import { environmentFormValuesAtom } from '@/store/formValues';
import { hasGraphQLServerError } from '@/utils/hasGraphQlError';
import { openURL } from '@/utils/OpenUrl';

import { EnvironmentAnswersCompleteTemplate } from './template';

export const EnvironmentAnswersComplete = () => {
  const [formValues, setFormValues] = useAtom(environmentFormValuesAtom);
  const [mutationStatus, setMutationStatus] =
    useState<ComponentProps<typeof EnvironmentAnswersCompleteTemplate>['mutationStatus']>('pending');

  const { userStatus, getUserStatus } = useUserStatus();
  const [createOrUpdateAnswerResult, createOrUpdateAnswer] = useCreateOrUpdateEnvironmentAnswerMutation();

  const navigate = useNavigate();

  const handleNextButtonPress = useCallback(() => {
    navigate('/questionnaire/medical-history');
  }, [navigate]);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const handleContactUsListItemPress = () => openURL(BuildConfig.PEPUP_CONTACT_URL);

  // ブラウザバック防止用
  useEffect(() => {
    if (!userStatus.data) {
      getUserStatus();
    } else {
      if (userStatus.data.getUserStatus.isAnsweredEnvironment && mutationStatus === 'pending') {
        window.history.pushState(null, '');
      }
    }
  }, [userStatus.data, getUserStatus, mutationStatus]);

  useEffect(() => {
    if (userStatus.data) {
      if (!userStatus.data.getUserStatus.isAnsweredEnvironment && formValues) {
        createOrUpdateAnswer(formValues)
          .then((res) => {
            if (res.data) {
              setMutationStatus('succeed');
              setFormValues(undefined).catch(() => undefined);
            }
          })
          .catch((e) => {
            setMutationStatus('failed');
            Logger.logException(e);
          });
      }
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [!!userStatus.data]);

  useEffect(() => {
    if (createOrUpdateAnswerResult.error && hasGraphQLServerError(createOrUpdateAnswerResult.error)) {
      Logger.logException(createOrUpdateAnswerResult.error.message);
    }
  }, [createOrUpdateAnswerResult.error]);

  useEffect(() => {
    Logger.logEvent(QuestionnaireCompleteEvent({ group: ENVIRONMENT }));
  }, []);

  return (
    <>
      <NavigationHeader title={t.navigation.completeAnswers} />
      {createOrUpdateAnswerResult.error ? (
        <ServerErrorView onContactUsListItemPress={handleContactUsListItemPress} />
      ) : (
        <EnvironmentAnswersCompleteTemplate mutationStatus={mutationStatus} onPress={handleNextButtonPress} />
      )}
    </>
  );
};
