import { type FC } from 'react';

import { Colors } from '@/constants/Colors';

type Props = {
  size?: number;
  stroke?: string;
};

export const Cancel: FC<Props> = (props) => {
  const { size = 24, stroke = Colors.black100 } = props;

  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18 6L6 18" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6 6L18 18" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
