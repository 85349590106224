import { messages as t } from '@/assets/i18n/ja';
import { type Organ } from '@/features/home/types/Organ';

export const organTypeToText = (organ: Organ) => {
  switch (organ) {
    case 'Brain':
      return t.home.organRanking.organs.brain;
    case 'Heart':
      return t.home.organRanking.organs.heart;
    case 'Lung':
      return t.home.organRanking.organs.lung;
    case 'Liver':
      return t.home.organRanking.organs.liver;
    case 'Kidney':
      return t.home.organRanking.organs.kidney;
    default:
      throw new Error('Organ string is incorrect');
  }
};
