import type { graphqlContext, MockedRequest, ResponseResolver } from 'msw';

import type { GetLifeStyleQuestionnaireQuery } from '@/hooks/GetQuestionnaire.generated';

export const mockGetLifeStyleQuestionnairePayload: GetLifeStyleQuestionnaireQuery = {
  getQuestionnaire: {
    id: null,
    items: [
      {
        name: 'HAS_SMOKING_HISTORY',
        displayNumber: '1',
        sortIndex: 1,
        question: '喫煙歴がありますか。',
        supplement: '',
        forms: [
          {
            title: null,
            name: 'hasSmokingHistory',
            items: [
              {
                label: 'はい',
                value: 1,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: 'いいえ',
                value: 2,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
            ],
            __typename: 'SelectTypeForm',
          },
        ],
        subItems: [
          {
            name: 'HAS_SMOKING_HABIT',
            displayNumber: '1-1',
            sortIndex: 2,
            question: '現在たばこを習慣的に吸っていますか。',
            supplement:
              '※「現在、習慣的に喫煙している」とは、「合計 100 本以上、または 6 ヶ月以上喫煙している」かつ「最近 1 ヶ月間も喫煙している」状態です。',
            forms: [
              {
                title: null,
                name: 'hasSmokingHabit',
                items: [
                  {
                    label: 'はい',
                    value: 1,
                    selected: false,
                    otherItemSupplement: null,
                    __typename: 'SelectTypeItem',
                  },
                  {
                    label: 'いいえ',
                    value: 2,
                    selected: false,
                    otherItemSupplement: null,
                    __typename: 'SelectTypeItem',
                  },
                ],
                __typename: 'SelectTypeForm',
              },
            ],
            __typename: 'QuestionnaireItem',
          },
          {
            name: 'CURRENT_SMOKING_HABIT',
            displayNumber: '1-2',
            sortIndex: 3,
            question: '1 日あたり喫煙する本数と喫煙年数はどのくらいですか。',
            supplement: '',
            forms: [
              {
                title: '1 日あたりの喫煙本数',
                name: 'numberOfCigarettesPerDayCurrent',
                value: null,
                placeholder: '例）20',
                unit: '本',
                __typename: 'InputTypeForm',
              },
              {
                title: '喫煙年数',
                name: 'yearsOfSmokingCurrent',
                value: null,
                placeholder: '例）10',
                unit: '年',
                __typename: 'InputTypeForm',
              },
            ],
            __typename: 'QuestionnaireItem',
          },
          {
            name: 'PAST_SMOKING_HABIT',
            displayNumber: '1-2',
            sortIndex: 4,
            question: '1 日あたり喫煙していた本数と喫煙年数はどのくらいですか。',
            supplement: '',
            forms: [
              {
                title: '1 日あたりの喫煙本数',
                name: 'numberOfCigarettesPerDayPast',
                value: null,
                placeholder: '例）20',
                unit: '本',
                __typename: 'InputTypeForm',
              },
              {
                title: '喫煙年数',
                name: 'yearsOfSmokingPast',
                value: null,
                placeholder: '例）10',
                unit: '年',
                __typename: 'InputTypeForm',
              },
            ],
            __typename: 'QuestionnaireItem',
          },
        ],
        __typename: 'QuestionnaireItem',
      },
      {
        name: 'HAS_HABIT_OF_WALKING',
        displayNumber: '2',
        sortIndex: 5,
        question: '日常生活において歩行または同等の身体活動を 1 日 1 時間以上実施していますか。',
        supplement: '',
        forms: [
          {
            title: null,
            name: 'hasHabitOfWalking',
            items: [
              {
                label: 'はい',
                value: 1,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: 'いいえ',
                value: 2,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
            ],
            __typename: 'SelectTypeForm',
          },
        ],
        subItems: null,
        __typename: 'QuestionnaireItem',
      },
      {
        name: 'CAN_WALK_FASTER_THAN',
        displayNumber: '3',
        sortIndex: 6,
        question: 'ほぼ同じ年齢の同性と比較して歩く速度が速いですか。',
        supplement: '',
        forms: [
          {
            title: null,
            name: 'canWalkFasterThan',
            items: [
              {
                label: 'はい',
                value: 1,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: 'いいえ',
                value: 2,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
            ],
            __typename: 'SelectTypeForm',
          },
        ],
        subItems: null,
        __typename: 'QuestionnaireItem',
      },
      {
        name: 'CAN_EAT_FASTER_THAN',
        displayNumber: '4',
        sortIndex: 7,
        question: '人と比較して食べる速度が速いですか。',
        supplement: '',
        forms: [
          {
            title: null,
            name: 'canEatFasterThan',
            items: [
              {
                label: 'はい',
                value: 1,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: 'いいえ',
                value: 2,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
            ],
            __typename: 'SelectTypeForm',
          },
        ],
        subItems: null,
        __typename: 'QuestionnaireItem',
      },
      {
        name: 'HAS_HABIT_OF_EAT_DINNER_BEFORE_SLEEP',
        displayNumber: '5',
        sortIndex: 8,
        question: '就寝前の 2 時間以内に夕食を取ることが週に 3 回以上ありますか。',
        supplement: '',
        forms: [
          {
            title: null,
            name: 'hasHabitOfEatDinnerBeforeSleep',
            items: [
              {
                label: 'はい',
                value: 1,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: 'いいえ',
                value: 2,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
            ],
            __typename: 'SelectTypeForm',
          },
        ],
        subItems: null,
        __typename: 'QuestionnaireItem',
      },
      {
        name: 'HAS_HABIT_OF_EAT_SNACK_AFTER_DINNER',
        displayNumber: '6',
        sortIndex: 9,
        question: '夕食後に間食（3 食以外の夜食）をとることが週に 3 回以上あリますか。',
        supplement: '',
        forms: [
          {
            title: null,
            name: 'hasHabitOfEatSnackAfterDinner',
            items: [
              {
                label: 'はい',
                value: 1,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: 'いいえ',
                value: 2,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
            ],
            __typename: 'SelectTypeForm',
          },
        ],
        subItems: null,
        __typename: 'QuestionnaireItem',
      },
      {
        name: 'HAS_HABIT_OF_SKIP_BREAKFAST',
        displayNumber: '7',
        sortIndex: 10,
        question: '朝食を抜くことが週に 3 回以上ありますか。',
        supplement: '',
        forms: [
          {
            title: null,
            name: 'hasHabitOfSkipBreakfast',
            items: [
              {
                label: 'はい',
                value: 1,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: 'いいえ',
                value: 2,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
            ],
            __typename: 'SelectTypeForm',
          },
        ],
        subItems: null,
        __typename: 'QuestionnaireItem',
      },
      {
        name: 'FREQUENCY_OF_DRINKING',
        displayNumber: '8',
        sortIndex: 11,
        question: 'お酒を飲む頻度はどのくらいですか。',
        supplement: '',
        forms: [
          {
            title: null,
            name: 'frequencyOfDrinking',
            items: [
              {
                label: '毎日',
                value: 1,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: '時々',
                value: 2,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: 'ほとんど飲まない（飲めない）',
                value: 3,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
            ],
            __typename: 'SelectTypeForm',
          },
        ],
        subItems: null,
        __typename: 'QuestionnaireItem',
      },
      {
        name: 'AMOUNT_OF_DRINKING_PER_DAY',
        displayNumber: '9',
        sortIndex: 12,
        question: '飲酒日は、1 日あたり何合ほど飲酒しますか。',
        supplement:
          '※1 合の目安：ビール中瓶 1 本（約 500ml）、焼酎 35 度 1 杯（80ml）、ウィスキーダブル 1 杯（60ml）、ワイン 2 杯（240ml）',
        forms: [
          {
            title: null,
            name: 'amountOfDrinkingPerDay',
            items: [
              {
                label: '1 合未満',
                value: 1,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: '1 から 2 合未満',
                value: 2,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: '3 合以上',
                value: 3,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
            ],
            __typename: 'SelectTypeForm',
          },
        ],
        subItems: null,
        __typename: 'QuestionnaireItem',
      },
      {
        name: 'CAN_GET_ENOUGH_REST_WITH_SLEEP',
        displayNumber: '10',
        sortIndex: 13,
        question: '睡眠で十分休養がとれていますか。',
        supplement: '',
        forms: [
          {
            title: null,
            name: 'canGetEnoughRestWithSleep',
            items: [
              {
                label: 'はい',
                value: 1,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: 'いいえ',
                value: 2,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
            ],
            __typename: 'SelectTypeForm',
          },
        ],
        subItems: null,
        __typename: 'QuestionnaireItem',
      },
      {
        name: 'HAS_HABIT_OF_SWEATY_EXERCISE_MORE_THAN_ONE_YEAR',
        displayNumber: '11',
        sortIndex: 14,
        question: '1 回 30 分以上の軽く汗をかく運動を週 2 日以上、1 年以上実施していますか。',
        supplement: '',
        forms: [
          {
            title: null,
            name: 'hasHabitOfSweatyExerciseMoreThanOneYear',
            items: [
              {
                label: 'はい',
                value: 1,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: 'いいえ',
                value: 2,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
            ],
            __typename: 'SelectTypeForm',
          },
        ],
        subItems: null,
        __typename: 'QuestionnaireItem',
      },
    ],
    __typename: 'Questionnaire',
  },
};

export const mockGetLifeStyleQuestionnaireAnsweredPayload: GetLifeStyleQuestionnaireQuery = {
  getQuestionnaire: {
    id: null,
    items: [
      {
        name: 'HAS_SMOKING_HISTORY',
        displayNumber: '1',
        sortIndex: 1,
        question: '喫煙歴がありますか。',
        supplement: '',
        forms: [
          {
            title: null,
            name: 'hasSmokingHistory',
            items: [
              {
                label: 'はい',
                value: 1,
                selected: true,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: 'いいえ',
                value: 2,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
            ],
            __typename: 'SelectTypeForm',
          },
        ],
        subItems: [
          {
            name: 'HAS_SMOKING_HABIT',
            displayNumber: '1-1',
            sortIndex: 2,
            question: '現在たばこを習慣的に吸っていますか。',
            supplement:
              '※「現在、習慣的に喫煙している」とは、「合計 100 本以上、または 6 ヶ月以上喫煙している」かつ「最近 1 ヶ月間も喫煙している」状態です。',
            forms: [
              {
                title: null,
                name: 'hasSmokingHabit',
                items: [
                  {
                    label: 'はい',
                    value: 1,
                    selected: false,
                    otherItemSupplement: null,
                    __typename: 'SelectTypeItem',
                  },
                  {
                    label: 'いいえ',
                    value: 2,
                    selected: true,
                    otherItemSupplement: null,
                    __typename: 'SelectTypeItem',
                  },
                ],
                __typename: 'SelectTypeForm',
              },
            ],
            __typename: 'QuestionnaireItem',
          },
          {
            name: 'CURRENT_SMOKING_HABIT',
            displayNumber: '1-2',
            sortIndex: 3,
            question: '1 日あたり喫煙する本数と喫煙年数はどのくらいですか。',
            supplement: '',
            forms: [
              {
                title: '1 日あたりの喫煙本数',
                name: 'numberOfCigarettesPerDayCurrent',
                value: null,
                placeholder: '例）20',
                unit: '本',
                __typename: 'InputTypeForm',
              },
              {
                title: '喫煙年数',
                name: 'yearsOfSmokingCurrent',
                value: null,
                placeholder: '例）10',
                unit: '年',
                __typename: 'InputTypeForm',
              },
            ],
            __typename: 'QuestionnaireItem',
          },
          {
            name: 'PAST_SMOKING_HABIT',
            displayNumber: '1-2',
            sortIndex: 4,
            question: '1 日あたり喫煙していた本数と喫煙年数はどのくらいですか。',
            supplement: '',
            forms: [
              {
                title: '1 日あたりの喫煙本数',
                name: 'numberOfCigarettesPerDayPast',
                value: '10',
                placeholder: '例）20',
                unit: '本',
                __typename: 'InputTypeForm',
              },
              {
                title: '喫煙年数',
                name: 'yearsOfSmokingPast',
                value: '15',
                placeholder: '例）10',
                unit: '年',
                __typename: 'InputTypeForm',
              },
            ],
            __typename: 'QuestionnaireItem',
          },
        ],
        __typename: 'QuestionnaireItem',
      },
      {
        name: 'HAS_HABIT_OF_WALKING',
        displayNumber: '2',
        sortIndex: 5,
        question: '日常生活において歩行または同等の身体活動を 1 日 1 時間以上実施していますか。',
        supplement: '',
        forms: [
          {
            title: null,
            name: 'hasHabitOfWalking',
            items: [
              {
                label: 'はい',
                value: 1,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: 'いいえ',
                value: 2,
                selected: true,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
            ],
            __typename: 'SelectTypeForm',
          },
        ],
        subItems: null,
        __typename: 'QuestionnaireItem',
      },
      {
        name: 'CAN_WALK_FASTER_THAN',
        displayNumber: '3',
        sortIndex: 6,
        question: 'ほぼ同じ年齢の同性と比較して歩く速度が速いですか。',
        supplement: '',
        forms: [
          {
            title: null,
            name: 'canWalkFasterThan',
            items: [
              {
                label: 'はい',
                value: 1,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: 'いいえ',
                value: 2,
                selected: true,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
            ],
            __typename: 'SelectTypeForm',
          },
        ],
        subItems: null,
        __typename: 'QuestionnaireItem',
      },
      {
        name: 'CAN_EAT_FASTER_THAN',
        displayNumber: '4',
        sortIndex: 7,
        question: '人と比較して食べる速度が速いですか。',
        supplement: '',
        forms: [
          {
            title: null,
            name: 'canEatFasterThan',
            items: [
              {
                label: 'はい',
                value: 1,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: 'いいえ',
                value: 2,
                selected: true,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
            ],
            __typename: 'SelectTypeForm',
          },
        ],
        subItems: null,
        __typename: 'QuestionnaireItem',
      },
      {
        name: 'HAS_HABIT_OF_EAT_DINNER_BEFORE_SLEEP',
        displayNumber: '5',
        sortIndex: 8,
        question: '就寝前の 2 時間以内に夕食を取ることが週に 3 回以上ありますか。',
        supplement: '',
        forms: [
          {
            title: null,
            name: 'hasHabitOfEatDinnerBeforeSleep',
            items: [
              {
                label: 'はい',
                value: 1,
                selected: true,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: 'いいえ',
                value: 2,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
            ],
            __typename: 'SelectTypeForm',
          },
        ],
        subItems: null,
        __typename: 'QuestionnaireItem',
      },
      {
        name: 'HAS_HABIT_OF_EAT_SNACK_AFTER_DINNER',
        displayNumber: '6',
        sortIndex: 9,
        question: '夕食後に間食（3 食以外の夜食）をとることが週に 3 回以上あリますか。',
        supplement: '',
        forms: [
          {
            title: null,
            name: 'hasHabitOfEatSnackAfterDinner',
            items: [
              {
                label: 'はい',
                value: 1,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: 'いいえ',
                value: 2,
                selected: true,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
            ],
            __typename: 'SelectTypeForm',
          },
        ],
        subItems: null,
        __typename: 'QuestionnaireItem',
      },
      {
        name: 'HAS_HABIT_OF_SKIP_BREAKFAST',
        displayNumber: '7',
        sortIndex: 10,
        question: '朝食を抜くことが週に 3 回以上ありますか。',
        supplement: '',
        forms: [
          {
            title: null,
            name: 'hasHabitOfSkipBreakfast',
            items: [
              {
                label: 'はい',
                value: 1,
                selected: true,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: 'いいえ',
                value: 2,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
            ],
            __typename: 'SelectTypeForm',
          },
        ],
        subItems: null,
        __typename: 'QuestionnaireItem',
      },
      {
        name: 'FREQUENCY_OF_DRINKING',
        displayNumber: '8',
        sortIndex: 11,
        question: 'お酒を飲む頻度はどのくらいですか。',
        supplement: '',
        forms: [
          {
            title: null,
            name: 'frequencyOfDrinking',
            items: [
              {
                label: '毎日',
                value: 1,
                selected: true,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: '時々',
                value: 2,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: 'ほとんど飲まない（飲めない）',
                value: 3,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
            ],
            __typename: 'SelectTypeForm',
          },
        ],
        subItems: null,
        __typename: 'QuestionnaireItem',
      },
      {
        name: 'AMOUNT_OF_DRINKING_PER_DAY',
        displayNumber: '9',
        sortIndex: 12,
        question: '飲酒日は、1 日あたり何合ほど飲酒しますか。',
        supplement:
          '※1 合の目安：ビール中瓶 1 本（約 500ml）、焼酎 35 度 1 杯（80ml）、ウィスキーダブル 1 杯（60ml）、ワイン 2 杯（240ml）',
        forms: [
          {
            title: null,
            name: 'amountOfDrinkingPerDay',
            items: [
              {
                label: '1 合未満',
                value: 1,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: '1 から 2 合未満',
                value: 2,
                selected: true,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: '3 合以上',
                value: 3,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
            ],
            __typename: 'SelectTypeForm',
          },
        ],
        subItems: null,
        __typename: 'QuestionnaireItem',
      },
      {
        name: 'CAN_GET_ENOUGH_REST_WITH_SLEEP',
        displayNumber: '10',
        sortIndex: 13,
        question: '睡眠で十分休養がとれていますか。',
        supplement: '',
        forms: [
          {
            title: null,
            name: 'canGetEnoughRestWithSleep',
            items: [
              {
                label: 'はい',
                value: 1,
                selected: true,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: 'いいえ',
                value: 2,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
            ],
            __typename: 'SelectTypeForm',
          },
        ],
        subItems: null,
        __typename: 'QuestionnaireItem',
      },
      {
        name: 'HAS_HABIT_OF_SWEATY_EXERCISE_MORE_THAN_ONE_YEAR',
        displayNumber: '11',
        sortIndex: 14,
        question: '1 回 30 分以上の軽く汗をかく運動を週 2 日以上、1 年以上実施していますか。',
        supplement: '',
        forms: [
          {
            title: null,
            name: 'hasHabitOfSweatyExerciseMoreThanOneYear',
            items: [
              {
                label: 'はい',
                value: 1,
                selected: false,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
              {
                label: 'いいえ',
                value: 2,
                selected: true,
                otherItemSupplement: null,
                __typename: 'SelectTypeItem',
              },
            ],
            __typename: 'SelectTypeForm',
          },
        ],
        subItems: null,
        __typename: 'QuestionnaireItem',
      },
    ],
    __typename: 'Questionnaire',
  },
};

export const mockGetLifeStyleQuestionnaire: ResponseResolver<MockedRequest, typeof graphqlContext> = (req, res, ctx) =>
  res(ctx.data(mockGetLifeStyleQuestionnaireAnsweredPayload));
