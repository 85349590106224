import { type FC } from 'react';
import { type ViewStyle, StyleSheet } from 'react-native';

import { Icon } from '@/components/Icon';
import { Text } from '@/components/Text';
import { Touchable } from '@/components/Touchable';
import { styleType } from '@/utils/styleType';

type Props = {
  index?: number;
  label: string;
  isActive: boolean;
  onPress: () => void;
  style?: ViewStyle;
};

export const Checkbox: FC<Props> = (props) => {
  const { index, onPress, label, isActive, style } = props;

  return (
    <Touchable
      onPress={onPress}
      style={index ? [styles.container, styles.containerNotFirstIndex, style] : [styles.container, style]}
      withoutEffect={true}
    >
      <>
        {isActive ? <Icon type="CheckboxActive" /> : <Icon type="CheckboxInactive" />}
        <Text.Body style={styles.label}>{label}</Text.Body>
      </>
    </Touchable>
  );
};

const styles = StyleSheet.create({
  container: styleType<ViewStyle>({
    flexDirection: 'row',
    alignItems: 'center',
  }),
  containerNotFirstIndex: styleType<ViewStyle>({
    marginTop: 12,
  }),
  label: styleType<ViewStyle>({
    marginLeft: 8,
  }),
});
