import type { ComponentProps, FC } from 'react';
import { ActivityIndicator as RNActivityIndicator } from 'react-native';

import { Colors } from '@/constants/Colors';

type Props = ComponentProps<typeof RNActivityIndicator>;

export const ActivityIndicator: FC<Props> = (props) => {
  const { size = 24, style } = props;

  return <RNActivityIndicator size={size} color={Colors.healthactionGreen} style={style} />;
};
