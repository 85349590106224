import { type FC } from 'react';
import { type TextStyle, StyleSheet } from 'react-native';

import { Text } from '@/components/Text';
import { Colors } from '@/constants/Colors';
import { styleType } from '@/utils/styleType';

type Props = {
  title: string;
};

export const Header: FC<Props> = (props) => {
  const { title } = props;

  return <Text.Headline style={styles.header}>{title}</Text.Headline>;
};

const styles = StyleSheet.create({
  header: styleType<TextStyle>({
    color: Colors.black54,
  }),
});
