import { useAtomValue } from 'jotai';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import type { HasHabitOfSweatyExerciseMoreThanOneYearSchema } from '@/formSchemas/lifeStyleSchema';
import { useGetLifeStyleQuestionnaireQuery } from '@/hooks/GetQuestionnaire.generated';
import { useQuestionnaire } from '@/hooks/useQuestionnaire';
import { lifeStyleFormValuesAtom } from '@/store/formValues';

export const useHasHabitOfSweatyExerciseMoreThanOneYear = () => {
  const [getLifeStyleQuestionnaireResult] = useGetLifeStyleQuestionnaireQuery();
  const { data, fetching, error } = getLifeStyleQuestionnaireResult;

  const formValues = useAtomValue(lifeStyleFormValuesAtom);

  const { getQuestionnaireItem, createSelections, getSelectedValue, perpetuateLifeStyleFormValues } =
    useQuestionnaire();

  const questionnaireItem = useMemo(
    () => data && getQuestionnaireItem(data, 'HAS_HABIT_OF_SWEATY_EXERCISE_MORE_THAN_ONE_YEAR'),
    [data, getQuestionnaireItem]
  );
  const selections = useMemo(
    () => questionnaireItem?.forms[0].__typename === 'SelectTypeForm' && createSelections(questionnaireItem.forms[0]),
    [questionnaireItem, createSelections]
  );
  const selectedValue = useMemo(() => {
    if (formValues?.hasHabitOfSweatyExerciseMoreThanOneYear) {
      return formValues?.hasHabitOfSweatyExerciseMoreThanOneYear;
    }
    if (questionnaireItem?.forms[0].__typename === 'SelectTypeForm') {
      return getSelectedValue(questionnaireItem.forms[0]);
    }
    return undefined;
  }, [questionnaireItem?.forms, getSelectedValue, formValues?.hasHabitOfSweatyExerciseMoreThanOneYear]);

  const navigate = useNavigate();

  const handleSubmit = useCallback(
    (values: HasHabitOfSweatyExerciseMoreThanOneYearSchema) => {
      perpetuateLifeStyleFormValues(values);

      navigate('/questionnaire/life-style-answers-complete');
    },
    [perpetuateLifeStyleFormValues, navigate]
  );

  return {
    fetching,
    data,
    error,
    questionnaireItem,
    selections,
    selectedValue,
    handleSubmit,
  };
};
