import { type AuthConfig } from '@urql/exchange-auth';
import { makeOperation } from 'urql';

import { type AuthToken, getTokenFromStorage } from '@/store/authToken';

/*
  設定は公式を参考
  https://github.com/FormidableLabs/urql/tree/main/exchanges/auth#quick-start-guide
*/
export const authConfig: AuthConfig<AuthToken> = {
  addAuthToOperation: ({ authState, operation }) => {
    if (!authState || !authState.token) {
      return operation;
    }

    const fetchOptions =
      typeof operation.context.fetchOptions === 'function'
        ? operation.context.fetchOptions()
        : operation.context.fetchOptions || {};

    return makeOperation(operation.kind, operation, {
      ...operation.context,
      fetchOptions: {
        ...fetchOptions,
        headers: {
          ...fetchOptions.headers,
          Authorization: `Bearer ${authState.token}`,
        },
      },
    });
  },
  willAuthError: ({ authState }) => {
    if (!authState) return true;

    if (authState.expiresAt && new Date(authState.expiresAt) < new Date()) {
      return true;
    }
    return false;
  },
  didAuthError: ({ error }) =>
    // check if the error was an auth error (this can be implemented in various ways, e.g. 401 or a special error code)
    error.graphQLErrors.some((e) => e.extensions?.code === 'FORBIDDEN'),

  getAuth: async () => {
    const { token, expiresAt } = await getTokenFromStorage();

    if (token && expiresAt) {
      return { token, expiresAt };
    }
    return null;
  },
};
