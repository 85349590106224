import { useEffect } from 'react';
import { type ViewStyle, StyleSheet, View } from 'react-native';
import { Link, useNavigate } from 'react-router-dom';

import { messages as t } from '@/assets/i18n/ja';
import { Icon } from '@/components/Icon';
import { LoadingView } from '@/components/LoadingView';
import { NavigationHeader } from '@/components/NavigationHeader';
import { ServerErrorView } from '@/components/ServerErrorView';
import { BuildConfig } from '@/constants/BuildConfig';
import { Colors } from '@/constants/Colors';
import { openURL } from '@/utils/OpenUrl';
import { styleType } from '@/utils/styleType';

import { useFetchPastQuestionnaireAnswers } from '../../hooks/useFetchPastQuestionnaireAnswers';
import { PastQuestionnaireAnswersTemplate } from './template';

export const PastQuestionnaireAnswers = () => {
  const { isLoading, result, refresh, hasError } = useFetchPastQuestionnaireAnswers();

  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const handleContactUsListItemPress = () => openURL(BuildConfig.PEPUP_CONTACT_URL);

  useEffect(() => {
    const unsubscribe = refresh();

    return () => unsubscribe;
  }, [refresh]);

  if (isLoading) return <LoadingView />;

  return (
    <>
      <View style={styles.navigationHeaderContainer}>
        <NavigationHeader
          title={t.navigation.pastQuestionnaireAnswers}
          headerLeft={() => (
            <Link to="/my-page">
              <View style={styles.headerLeftIconContainer}>
                <Icon type="ArrowLeft" stroke={Colors.white100} />
              </View>
            </Link>
          )}
        />
      </View>
      {hasError || !result ? (
        <ServerErrorView onContactUsListItemPress={handleContactUsListItemPress} />
      ) : (
        <PastQuestionnaireAnswersTemplate data={result} onPressNavigateToMyPageButton={() => navigate('/my-page')} />
      )}
    </>
  );
};

const styles = StyleSheet.create({
  navigationHeaderContainer: styleType<ViewStyle>({
    zIndex: 1,
  }),
  headerLeftIconContainer: styleType<ViewStyle>({
    marginLeft: 16,
  }),
});
