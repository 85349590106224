import { useAtomValue } from 'jotai';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import type { HousingTypeSchema } from '@/formSchemas/environmentSchema';
import { useGetEnvironmentQuestionnaireQuery } from '@/hooks/GetQuestionnaire.generated';
import { useQuestionnaire } from '@/hooks/useQuestionnaire';
import { environmentFormValuesAtom } from '@/store/formValues';

export const useHousingType = () => {
  const [getEnvironmentQuestionnaireResult] = useGetEnvironmentQuestionnaireQuery();
  const { data, fetching, error } = getEnvironmentQuestionnaireResult;

  const formValues = useAtomValue(environmentFormValuesAtom);

  const {
    getQuestionnaireItem,
    createSelections,
    getSelectedValue,
    getOtherItemSupplement,
    perpetuateEnvironmentFormValues,
  } = useQuestionnaire();

  const questionnaireItem = useMemo(
    () => data && getQuestionnaireItem(data, 'HOUSING_TYPE'),
    [data, getQuestionnaireItem]
  );
  const selections = useMemo(
    () => questionnaireItem?.forms[0].__typename === 'SelectTypeForm' && createSelections(questionnaireItem.forms[0]),
    [questionnaireItem, createSelections]
  );

  const selectedValue = useMemo(() => {
    if (formValues?.housingType) {
      return formValues?.housingType;
    }
    if (questionnaireItem?.forms[0].__typename === 'SelectTypeForm') {
      return getSelectedValue(questionnaireItem.forms[0]);
    }
    return undefined;
  }, [questionnaireItem?.forms, getSelectedValue, formValues?.housingType]);

  const otherItemSupplement = useMemo(() => {
    if (questionnaireItem) {
      const otherItemSupplement = getOtherItemSupplement(questionnaireItem);

      // 空文字は空文字のまま反映する
      return formValues?.housingTypeSupplement !== undefined
        ? {
            value: formValues?.housingTypeSupplement || '',
            placeholder: otherItemSupplement?.placeholder || '',
          }
        : otherItemSupplement;
    }
    return undefined;
  }, [formValues?.housingTypeSupplement, questionnaireItem, getOtherItemSupplement]);

  const navigate = useNavigate();

  const handleSubmit = useCallback(
    (values: HousingTypeSchema) => {
      perpetuateEnvironmentFormValues(values);

      navigate('/questionnaire/has-car');
    },
    [perpetuateEnvironmentFormValues, navigate]
  );

  return {
    fetching,
    data,
    error,
    questionnaireItem,
    selections,
    selectedValue,
    otherItemSupplement,
    handleSubmit,
  };
};
