import type { graphqlContext, MockedRequest, ResponseResolver } from 'msw';

import type { GetMedicalHistoryQuestionnaireQuery } from '@/hooks/GetQuestionnaire.generated';

export const mockGetMedicalHistoryPayload: GetMedicalHistoryQuestionnaireQuery = {
  getQuestionnaire: {
    id: 'UXVlc3Rpb25uYWlyZUlkOjIxMw==',
    items: [
      {
        name: 'MEDICAL_HISTORY',
        displayNumber: '1',
        sortIndex: 1,
        question: '祖父母・両親・兄弟の中で、該当する既往歴がありましたら選択してください。',
        supplement: '',
        forms: [
          {
            title: null,
            name: 'medicalHistory',
            items: [
              {
                label: '脳卒中',
                name: 'stroke',
                value: 1,
                selected: false,
                otherItemSupplement: null,
                __typename: 'MultiSelectTypeItem',
              },
              {
                label: '一過性脳虚血発作（TIA）',
                name: 'transientIschemicAttack',
                value: 2,
                selected: false,
                otherItemSupplement: null,
                __typename: 'MultiSelectTypeItem',
              },
              {
                label: '虚血性心疾患',
                name: 'ischemicHeartDisease',
                value: 3,
                selected: false,
                otherItemSupplement: null,
                __typename: 'MultiSelectTypeItem',
              },
              {
                label: '心不全',
                name: 'heartFailure',
                value: 4,
                selected: false,
                otherItemSupplement: null,
                __typename: 'MultiSelectTypeItem',
              },
              {
                label: '肝不全',
                name: 'liverFailure',
                value: 5,
                selected: false,
                otherItemSupplement: null,
                __typename: 'MultiSelectTypeItem',
              },
              {
                label: '肝硬変',
                name: 'liverCirrhosis',
                value: 6,
                selected: false,
                otherItemSupplement: null,
                __typename: 'MultiSelectTypeItem',
              },
              {
                label: '慢性閉塞症肺疾患',
                name: 'chronicObstructivePulmonaryDisease',
                value: 7,
                selected: false,
                otherItemSupplement: null,
                __typename: 'MultiSelectTypeItem',
              },
              {
                label: '人工透析',
                name: 'dialysis',
                value: 8,
                selected: false,
                otherItemSupplement: null,
                __typename: 'MultiSelectTypeItem',
              },
              {
                label: '癌',
                name: 'cancer',
                value: 9,
                selected: false,
                otherItemSupplement: {
                  name: 'cancer',
                  value: null,
                  placeholder: '例）〇〇癌',
                  __typename: 'OtherItemSupplement',
                },
                __typename: 'MultiSelectTypeItem',
              },
              {
                label: '高血圧',
                name: 'highBloodPressure',
                value: 10,
                selected: false,
                otherItemSupplement: null,
                __typename: 'MultiSelectTypeItem',
              },
              {
                label: '糖尿病',
                name: 'diabetes',
                value: 11,
                selected: false,
                otherItemSupplement: null,
                __typename: 'MultiSelectTypeItem',
              },
              {
                label: '脂質異常症（高脂血症）',
                name: 'dyslipidemia',
                value: 12,
                selected: false,
                otherItemSupplement: null,
                __typename: 'MultiSelectTypeItem',
              },
              {
                label: 'そのほか',
                name: 'otherDisease',
                value: 99,
                selected: false,
                otherItemSupplement: {
                  name: 'otherDiseaseSupplement',
                  value: null,
                  placeholder: 'そのほかの場合はこちらへ入力',
                  __typename: 'OtherItemSupplement',
                },
                __typename: 'MultiSelectTypeItem',
              },
            ],
            __typename: 'SelectTypeForm',
          },
        ],
        subItems: null,
        __typename: 'QuestionnaireItem',
      },
    ],
    __typename: 'Questionnaire',
  },
};
export const mockGetMedicalHistoryAnsweredPayload: GetMedicalHistoryQuestionnaireQuery = {
  getQuestionnaire: {
    id: 'UXVlc3Rpb25uYWlyZUlkOjIxMw==',
    items: [
      {
        name: 'MEDICAL_HISTORY',
        displayNumber: '1',
        sortIndex: 1,
        question: '祖父母・両親・兄弟の中で、該当する既往歴がありましたら選択してください。',
        supplement: '',
        forms: [
          {
            title: null,
            name: 'medicalHistory',
            items: [
              {
                label: '脳卒中',
                name: 'stroke',
                value: 1,
                selected: false,
                otherItemSupplement: null,
                __typename: 'MultiSelectTypeItem',
              },
              {
                label: '一過性脳虚血発作（TIA）',
                name: 'transientIschemicAttack',
                value: 2,
                selected: false,
                otherItemSupplement: null,
                __typename: 'MultiSelectTypeItem',
              },
              {
                label: '虚血性心疾患',
                name: 'ischemicHeartDisease',
                value: 3,
                selected: false,
                otherItemSupplement: null,
                __typename: 'MultiSelectTypeItem',
              },
              {
                label: '心不全',
                name: 'heartFailure',
                value: 4,
                selected: false,
                otherItemSupplement: null,
                __typename: 'MultiSelectTypeItem',
              },
              {
                label: '肝不全',
                name: 'liverFailure',
                value: 5,
                selected: false,
                otherItemSupplement: null,
                __typename: 'MultiSelectTypeItem',
              },
              {
                label: '肝硬変',
                name: 'liverCirrhosis',
                value: 6,
                selected: false,
                otherItemSupplement: null,
                __typename: 'MultiSelectTypeItem',
              },
              {
                label: '慢性閉塞症肺疾患',
                name: 'chronicObstructivePulmonaryDisease',
                value: 7,
                selected: false,
                otherItemSupplement: null,
                __typename: 'MultiSelectTypeItem',
              },
              {
                label: '人工透析',
                name: 'dialysis',
                value: 8,
                selected: false,
                otherItemSupplement: null,
                __typename: 'MultiSelectTypeItem',
              },
              {
                label: '癌',
                name: 'cancer',
                value: 9,
                selected: false,
                otherItemSupplement: {
                  name: 'cancer',
                  value: null,
                  placeholder: '例）〇〇癌',
                  __typename: 'OtherItemSupplement',
                },
                __typename: 'MultiSelectTypeItem',
              },
              {
                label: '高血圧',
                name: 'highBloodPressure',
                value: 10,
                selected: false,
                otherItemSupplement: null,
                __typename: 'MultiSelectTypeItem',
              },
              {
                label: '糖尿病',
                name: 'diabetes',
                value: 11,
                selected: false,
                otherItemSupplement: null,
                __typename: 'MultiSelectTypeItem',
              },
              {
                label: '脂質異常症（高脂血症）',
                name: 'dyslipidemia',
                value: 12,
                selected: false,
                otherItemSupplement: null,
                __typename: 'MultiSelectTypeItem',
              },
              {
                label: 'そのほか',
                name: 'otherDisease',
                value: 99,
                selected: true,
                otherItemSupplement: {
                  name: 'otherDiseaseSupplement',
                  value: 'うつ病',
                  placeholder: 'そのほかの場合はこちらへ入力',
                  __typename: 'OtherItemSupplement',
                },
                __typename: 'MultiSelectTypeItem',
              },
            ],
            __typename: 'SelectTypeForm',
          },
        ],
        subItems: null,
        __typename: 'QuestionnaireItem',
      },
    ],
    __typename: 'Questionnaire',
  },
};

export const mockGetMedicalHistoryQuestionnaire: ResponseResolver<MockedRequest, typeof graphqlContext> = (
  req,
  res,
  ctx
) => res(ctx.data(mockGetMedicalHistoryAnsweredPayload));
