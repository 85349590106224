import * as Types from '../types/graphql';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetLifeStyleQuestionnaireQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetLifeStyleQuestionnaireQuery = { __typename?: 'Query', getQuestionnaire: { __typename?: 'Questionnaire', id?: string | null, items: Array<{ __typename?: 'QuestionnaireItem', name: Types.QuestionnaireItemName, displayNumber: string, sortIndex: number, question: string, supplement?: string | null, subItems?: Array<{ __typename?: 'QuestionnaireItem', name: Types.QuestionnaireItemName, displayNumber: string, sortIndex: number, question: string, supplement?: string | null, forms: Array<{ __typename?: 'InputTypeForm', title?: string | null, name: Types.FormName, value?: string | null, placeholder: string, unit?: string | null } | { __typename?: 'SelectTypeForm', title?: string | null, name: Types.FormName, items: Array<{ __typename?: 'MultiSelectTypeItem', name: Types.FormName, label: string, value: number, selected: boolean, otherItemSupplement?: { __typename?: 'OtherItemSupplement', name: Types.FormName, value?: string | null, placeholder?: string | null } | null } | { __typename?: 'SelectTypeItem', label: string, value: number, selected: boolean, otherItemSupplement?: { __typename?: 'OtherItemSupplement', value?: string | null, placeholder?: string | null } | null }> }> }> | null, forms: Array<{ __typename?: 'InputTypeForm', title?: string | null, name: Types.FormName, value?: string | null, placeholder: string, unit?: string | null } | { __typename?: 'SelectTypeForm', title?: string | null, name: Types.FormName, items: Array<{ __typename?: 'MultiSelectTypeItem', name: Types.FormName, label: string, value: number, selected: boolean, otherItemSupplement?: { __typename?: 'OtherItemSupplement', name: Types.FormName, value?: string | null, placeholder?: string | null } | null } | { __typename?: 'SelectTypeItem', label: string, value: number, selected: boolean, otherItemSupplement?: { __typename?: 'OtherItemSupplement', value?: string | null, placeholder?: string | null } | null }> }> }> } };

export type GetEnvironmentQuestionnaireQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetEnvironmentQuestionnaireQuery = { __typename?: 'Query', getQuestionnaire: { __typename?: 'Questionnaire', id?: string | null, items: Array<{ __typename?: 'QuestionnaireItem', name: Types.QuestionnaireItemName, displayNumber: string, sortIndex: number, question: string, supplement?: string | null, subItems?: Array<{ __typename?: 'QuestionnaireItem', name: Types.QuestionnaireItemName, displayNumber: string, sortIndex: number, question: string, supplement?: string | null, forms: Array<{ __typename?: 'InputTypeForm', title?: string | null, name: Types.FormName, value?: string | null, placeholder: string, unit?: string | null } | { __typename?: 'SelectTypeForm', title?: string | null, name: Types.FormName, items: Array<{ __typename?: 'MultiSelectTypeItem', name: Types.FormName, label: string, value: number, selected: boolean, otherItemSupplement?: { __typename?: 'OtherItemSupplement', name: Types.FormName, value?: string | null, placeholder?: string | null } | null } | { __typename?: 'SelectTypeItem', label: string, value: number, selected: boolean, otherItemSupplement?: { __typename?: 'OtherItemSupplement', value?: string | null, placeholder?: string | null } | null }> }> }> | null, forms: Array<{ __typename?: 'InputTypeForm', title?: string | null, name: Types.FormName, value?: string | null, placeholder: string, unit?: string | null } | { __typename?: 'SelectTypeForm', title?: string | null, name: Types.FormName, items: Array<{ __typename?: 'MultiSelectTypeItem', name: Types.FormName, label: string, value: number, selected: boolean, otherItemSupplement?: { __typename?: 'OtherItemSupplement', name: Types.FormName, value?: string | null, placeholder?: string | null } | null } | { __typename?: 'SelectTypeItem', label: string, value: number, selected: boolean, otherItemSupplement?: { __typename?: 'OtherItemSupplement', value?: string | null, placeholder?: string | null } | null }> }> }> } };

export type GetMedicalHistoryQuestionnaireQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetMedicalHistoryQuestionnaireQuery = { __typename?: 'Query', getQuestionnaire: { __typename?: 'Questionnaire', id?: string | null, items: Array<{ __typename?: 'QuestionnaireItem', name: Types.QuestionnaireItemName, displayNumber: string, sortIndex: number, question: string, supplement?: string | null, subItems?: Array<{ __typename?: 'QuestionnaireItem', name: Types.QuestionnaireItemName, displayNumber: string, sortIndex: number, question: string, supplement?: string | null, forms: Array<{ __typename?: 'InputTypeForm', title?: string | null, name: Types.FormName, value?: string | null, placeholder: string, unit?: string | null } | { __typename?: 'SelectTypeForm', title?: string | null, name: Types.FormName, items: Array<{ __typename?: 'MultiSelectTypeItem', name: Types.FormName, label: string, value: number, selected: boolean, otherItemSupplement?: { __typename?: 'OtherItemSupplement', name: Types.FormName, value?: string | null, placeholder?: string | null } | null } | { __typename?: 'SelectTypeItem', label: string, value: number, selected: boolean, otherItemSupplement?: { __typename?: 'OtherItemSupplement', value?: string | null, placeholder?: string | null } | null }> }> }> | null, forms: Array<{ __typename?: 'InputTypeForm', title?: string | null, name: Types.FormName, value?: string | null, placeholder: string, unit?: string | null } | { __typename?: 'SelectTypeForm', title?: string | null, name: Types.FormName, items: Array<{ __typename?: 'MultiSelectTypeItem', name: Types.FormName, label: string, value: number, selected: boolean, otherItemSupplement?: { __typename?: 'OtherItemSupplement', name: Types.FormName, value?: string | null, placeholder?: string | null } | null } | { __typename?: 'SelectTypeItem', label: string, value: number, selected: boolean, otherItemSupplement?: { __typename?: 'OtherItemSupplement', value?: string | null, placeholder?: string | null } | null }> }> }> } };

export type QuestionnaireFieldsFragment = { __typename?: 'Questionnaire', id?: string | null, items: Array<{ __typename?: 'QuestionnaireItem', name: Types.QuestionnaireItemName, displayNumber: string, sortIndex: number, question: string, supplement?: string | null, subItems?: Array<{ __typename?: 'QuestionnaireItem', name: Types.QuestionnaireItemName, displayNumber: string, sortIndex: number, question: string, supplement?: string | null, forms: Array<{ __typename?: 'InputTypeForm', title?: string | null, name: Types.FormName, value?: string | null, placeholder: string, unit?: string | null } | { __typename?: 'SelectTypeForm', title?: string | null, name: Types.FormName, items: Array<{ __typename?: 'MultiSelectTypeItem', name: Types.FormName, label: string, value: number, selected: boolean, otherItemSupplement?: { __typename?: 'OtherItemSupplement', name: Types.FormName, value?: string | null, placeholder?: string | null } | null } | { __typename?: 'SelectTypeItem', label: string, value: number, selected: boolean, otherItemSupplement?: { __typename?: 'OtherItemSupplement', value?: string | null, placeholder?: string | null } | null }> }> }> | null, forms: Array<{ __typename?: 'InputTypeForm', title?: string | null, name: Types.FormName, value?: string | null, placeholder: string, unit?: string | null } | { __typename?: 'SelectTypeForm', title?: string | null, name: Types.FormName, items: Array<{ __typename?: 'MultiSelectTypeItem', name: Types.FormName, label: string, value: number, selected: boolean, otherItemSupplement?: { __typename?: 'OtherItemSupplement', name: Types.FormName, value?: string | null, placeholder?: string | null } | null } | { __typename?: 'SelectTypeItem', label: string, value: number, selected: boolean, otherItemSupplement?: { __typename?: 'OtherItemSupplement', value?: string | null, placeholder?: string | null } | null }> }> }> };

export type QuestionnaireItemFieldsFragment = { __typename?: 'QuestionnaireItem', name: Types.QuestionnaireItemName, displayNumber: string, sortIndex: number, question: string, supplement?: string | null, forms: Array<{ __typename?: 'InputTypeForm', title?: string | null, name: Types.FormName, value?: string | null, placeholder: string, unit?: string | null } | { __typename?: 'SelectTypeForm', title?: string | null, name: Types.FormName, items: Array<{ __typename?: 'MultiSelectTypeItem', name: Types.FormName, label: string, value: number, selected: boolean, otherItemSupplement?: { __typename?: 'OtherItemSupplement', name: Types.FormName, value?: string | null, placeholder?: string | null } | null } | { __typename?: 'SelectTypeItem', label: string, value: number, selected: boolean, otherItemSupplement?: { __typename?: 'OtherItemSupplement', value?: string | null, placeholder?: string | null } | null }> }> };

export type InputTypeFormFieldsFragment = { __typename?: 'InputTypeForm', title?: string | null, name: Types.FormName, value?: string | null, placeholder: string, unit?: string | null };

export type SelectTypeFormFieldsFragment = { __typename?: 'SelectTypeForm', title?: string | null, name: Types.FormName, items: Array<{ __typename?: 'MultiSelectTypeItem', name: Types.FormName, label: string, value: number, selected: boolean, otherItemSupplement?: { __typename?: 'OtherItemSupplement', name: Types.FormName, value?: string | null, placeholder?: string | null } | null } | { __typename?: 'SelectTypeItem', label: string, value: number, selected: boolean, otherItemSupplement?: { __typename?: 'OtherItemSupplement', value?: string | null, placeholder?: string | null } | null }> };

export type SelectTypeItemFieldsFragment = { __typename?: 'SelectTypeItem', label: string, value: number, selected: boolean, otherItemSupplement?: { __typename?: 'OtherItemSupplement', value?: string | null, placeholder?: string | null } | null };

export type MultiSelectTypeItemFieldsFragment = { __typename?: 'MultiSelectTypeItem', name: Types.FormName, label: string, value: number, selected: boolean, otherItemSupplement?: { __typename?: 'OtherItemSupplement', name: Types.FormName, value?: string | null, placeholder?: string | null } | null };

export const InputTypeFormFieldsFragmentDoc = gql`
    fragment inputTypeFormFields on InputTypeForm {
  title
  name
  value
  placeholder
  unit
}
    `;
export const SelectTypeItemFieldsFragmentDoc = gql`
    fragment selectTypeItemFields on SelectTypeItem {
  label
  value
  selected
  otherItemSupplement {
    value
    placeholder
  }
}
    `;
export const MultiSelectTypeItemFieldsFragmentDoc = gql`
    fragment multiSelectTypeItemFields on MultiSelectTypeItem {
  name
  label
  value
  selected
  otherItemSupplement {
    name
    value
    placeholder
  }
}
    `;
export const SelectTypeFormFieldsFragmentDoc = gql`
    fragment selectTypeFormFields on SelectTypeForm {
  title
  name
  items {
    ... on SelectTypeItem {
      ...selectTypeItemFields
    }
    ... on MultiSelectTypeItem {
      ...multiSelectTypeItemFields
    }
  }
}
    ${SelectTypeItemFieldsFragmentDoc}
${MultiSelectTypeItemFieldsFragmentDoc}`;
export const QuestionnaireItemFieldsFragmentDoc = gql`
    fragment questionnaireItemFields on QuestionnaireItem {
  name
  displayNumber
  sortIndex
  question
  supplement
  forms {
    ... on InputTypeForm {
      ...inputTypeFormFields
    }
    ... on SelectTypeForm {
      ...selectTypeFormFields
    }
  }
}
    ${InputTypeFormFieldsFragmentDoc}
${SelectTypeFormFieldsFragmentDoc}`;
export const QuestionnaireFieldsFragmentDoc = gql`
    fragment questionnaireFields on Questionnaire {
  id
  items {
    ...questionnaireItemFields
    subItems {
      ...questionnaireItemFields
    }
  }
}
    ${QuestionnaireItemFieldsFragmentDoc}`;
export const GetLifeStyleQuestionnaireDocument = gql`
    query GetLifeStyleQuestionnaire {
  getQuestionnaire(category: LIFESTYLE) {
    ...questionnaireFields
  }
}
    ${QuestionnaireFieldsFragmentDoc}`;

export function useGetLifeStyleQuestionnaireQuery(options?: Omit<Urql.UseQueryArgs<GetLifeStyleQuestionnaireQueryVariables>, 'query'>) {
  return Urql.useQuery<GetLifeStyleQuestionnaireQuery, GetLifeStyleQuestionnaireQueryVariables>({ query: GetLifeStyleQuestionnaireDocument, ...options });
};
export const GetEnvironmentQuestionnaireDocument = gql`
    query GetEnvironmentQuestionnaire {
  getQuestionnaire(category: ENVIRONMENT) {
    ...questionnaireFields
  }
}
    ${QuestionnaireFieldsFragmentDoc}`;

export function useGetEnvironmentQuestionnaireQuery(options?: Omit<Urql.UseQueryArgs<GetEnvironmentQuestionnaireQueryVariables>, 'query'>) {
  return Urql.useQuery<GetEnvironmentQuestionnaireQuery, GetEnvironmentQuestionnaireQueryVariables>({ query: GetEnvironmentQuestionnaireDocument, ...options });
};
export const GetMedicalHistoryQuestionnaireDocument = gql`
    query GetMedicalHistoryQuestionnaire {
  getQuestionnaire(category: MEDICAL_HISTORY) {
    ...questionnaireFields
  }
}
    ${QuestionnaireFieldsFragmentDoc}`;

export function useGetMedicalHistoryQuestionnaireQuery(options?: Omit<Urql.UseQueryArgs<GetMedicalHistoryQuestionnaireQueryVariables>, 'query'>) {
  return Urql.useQuery<GetMedicalHistoryQuestionnaireQuery, GetMedicalHistoryQuestionnaireQueryVariables>({ query: GetMedicalHistoryQuestionnaireDocument, ...options });
};