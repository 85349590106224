import { z } from 'zod';

import { halfWidthNumbersRequired } from './validationRules';

export const hasSmokingHistorySchema = z.object({
  hasSmokingHistory: z.number().gte(1).lte(2),
});
export type HasSmokingHistorySchema = z.infer<typeof hasSmokingHistorySchema>;

export const currentSmokingHabitSchema = z
  .object({
    numberOfCigarettesPerDayCurrent: halfWidthNumbersRequired,
    yearsOfSmokingCurrent: halfWidthNumbersRequired,
  })
  .transform((values) => ({
    numberOfCigarettesPerDayCurrent: Number(values.numberOfCigarettesPerDayCurrent),
    yearsOfSmokingCurrent: Number(values.yearsOfSmokingCurrent),
  }));

export type CurrentSmokingHabitSchema = z.infer<typeof currentSmokingHabitSchema>;

export const pastSmokingHabitSchema = z
  .object({
    numberOfCigarettesPerDayPast: halfWidthNumbersRequired,
    yearsOfSmokingPast: halfWidthNumbersRequired,
  })
  .transform((values) => ({
    numberOfCigarettesPerDayPast: Number(values.numberOfCigarettesPerDayPast),
    yearsOfSmokingPast: Number(values.yearsOfSmokingPast),
  }));

export type PastSmokingHabitSchema = z.infer<typeof pastSmokingHabitSchema>;

export const hasSmokingHabitSchema = z.object({
  hasSmokingHabit: z.number().gte(1).lte(2),
});
export type HasSmokingHabitSchema = z.infer<typeof hasSmokingHabitSchema>;

export const hasHabitOfWalkingSchema = z.object({
  hasHabitOfWalking: z.number().gte(1).lte(2),
});
export type HasHabitOfWalkingSchema = z.infer<typeof hasHabitOfWalkingSchema>;

export const canWalkFasterThanSchema = z.object({
  canWalkFasterThan: z.number().gte(1).lte(2),
});
export type CanWalkFasterThanSchema = z.infer<typeof canWalkFasterThanSchema>;

export const canEatFasterThanSchema = z.object({
  canEatFasterThan: z.number().gte(1).lte(3),
});
export type CanEatFasterThanSchema = z.infer<typeof canEatFasterThanSchema>;

export const hasHabitOfEatDinnerBeforeSleepSchema = z.object({
  hasHabitOfEatDinnerBeforeSleep: z.number().gte(1).lte(2),
});
export type HasHabitOfEatDinnerBeforeSleepSchema = z.infer<typeof hasHabitOfEatDinnerBeforeSleepSchema>;

export const hasHabitOfEatSnackAfterDinnerSchema = z.object({
  hasHabitOfEatSnackAfterDinner: z.number().gte(1).lte(2),
});
export type HasHabitOfEatSnackAfterDinnerSchema = z.infer<typeof hasHabitOfEatSnackAfterDinnerSchema>;

export const hasHabitOfSkipBreakfastSchema = z.object({
  hasHabitOfSkipBreakfast: z.number().gte(1).lte(2),
});
export type HasHabitOfSkipBreakfastSchema = z.infer<typeof hasHabitOfSkipBreakfastSchema>;

export const frequencyOfDrinkingSchema = z.object({
  frequencyOfDrinking: z.number().gte(1).lte(3),
});
export type FrequencyOfDrinkingSchema = z.infer<typeof frequencyOfDrinkingSchema>;

export const amountOfDrinkingPerDaySchema = z.object({
  amountOfDrinkingPerDay: z.number().gte(1).lte(3),
});
export type AmountOfDrinkingPerDaySchema = z.infer<typeof amountOfDrinkingPerDaySchema>;

export const canGetEnoughRestWithSleepSchema = z.object({
  canGetEnoughRestWithSleep: z.number().gte(1).lte(2),
});
export type CanGetEnoughRestWithSleepSchema = z.infer<typeof canGetEnoughRestWithSleepSchema>;

export const hasHabitOfSweatyExerciseMoreThanOneYearSchema = z.object({
  hasHabitOfSweatyExerciseMoreThanOneYear: z.number().gte(1).lte(2),
});
export type HasHabitOfSweatyExerciseMoreThanOneYearSchema = z.infer<
  typeof hasHabitOfSweatyExerciseMoreThanOneYearSchema
>;

export type LifeStyleSchema = {
  hasSmokingHistory: HasSmokingHistorySchema['hasSmokingHistory'];
  numberOfCigarettesPerDayCurrent?: CurrentSmokingHabitSchema['numberOfCigarettesPerDayCurrent'];
  yearsOfSmokingCurrent?: CurrentSmokingHabitSchema['yearsOfSmokingCurrent'];
  numberOfCigarettesPerDayPast?: PastSmokingHabitSchema['numberOfCigarettesPerDayPast'];
  yearsOfSmokingCurrentPast?: PastSmokingHabitSchema['yearsOfSmokingPast'];
  hasSmokingHabit?: HasSmokingHabitSchema['hasSmokingHabit'];
  hasHabitOfWalking: HasHabitOfWalkingSchema['hasHabitOfWalking'];
  canWalkFasterThan: CanWalkFasterThanSchema['canWalkFasterThan'];
  canEatFasterThan: CanEatFasterThanSchema['canEatFasterThan'];
  hasHabitOfEatDinnerBeforeSleep: HasHabitOfEatDinnerBeforeSleepSchema['hasHabitOfEatDinnerBeforeSleep'];
  hasHabitOfEatSnackAfterDinner: HasHabitOfEatSnackAfterDinnerSchema['hasHabitOfEatSnackAfterDinner'];
  hasHabitOfSkipBreakfast: HasHabitOfSkipBreakfastSchema['hasHabitOfSkipBreakfast'];
  frequencyOfDrinking: FrequencyOfDrinkingSchema['frequencyOfDrinking'];
  amountOfDrinkingPerDay: AmountOfDrinkingPerDaySchema['amountOfDrinkingPerDay'];
  canGetEnoughRestWithSleep: CanGetEnoughRestWithSleepSchema['canGetEnoughRestWithSleep'];
  hasHabitOfSweatyExerciseMoreThanOneYear: HasHabitOfSweatyExerciseMoreThanOneYearSchema['hasHabitOfSweatyExerciseMoreThanOneYear'];
};
