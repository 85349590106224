import type { graphqlContext, MockedRequest, ResponseResolver } from 'msw';

import type { UserStatus } from '@/types/graphql';

const userStatusPayload: { getUserStatus: UserStatus } = {
  getUserStatus: {
    isCreatedUser: false,
    isAnsweredLifestyle: false,
    isAnsweredEnvironment: false,
    isAnsweredMedicalHistory: false,
    isCompleted: false,
    shouldPredictHealthLimit: true,
  },
};

export const mockUserStatus: ResponseResolver<MockedRequest, typeof graphqlContext> = (req, res, ctx) =>
  res(ctx.data(userStatusPayload));
