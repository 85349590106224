import { type ComponentProps } from 'react';
import { useLocation } from 'react-router-dom';

import { messages as t } from '@/assets/i18n/ja';
import { NavigationHeader } from '@/components/NavigationHeader';
import { ScrollView } from '@/components/ScrollView';
import { OrganDetailTemplate } from '@/features/home/components/OrganDetail/template';
import { organTypeToText } from '@/features/home/utils/OrganTypeToText';

export const OrganDetail = () => {
  // Get data from home using the option parameter of useNavigate
  const location = useLocation();

  const navigationState = location.state as ComponentProps<typeof OrganDetailTemplate>;

  return (
    <ScrollView>
      <NavigationHeader
        title={`${organTypeToText(navigationState.organInfo.organ)}${t.navigation.organDetail}`}
        headerLeft="back"
      />
      <OrganDetailTemplate {...navigationState} />
    </ScrollView>
  );
};
