import type { ComponentProps, FC } from 'react';
import type { TextStyle, ViewStyle } from 'react-native';
import { StyleSheet, View } from 'react-native';

import { messages as t } from '@/assets/i18n/ja';
import { Container } from '@/components/Container';
import { Text } from '@/components/Text';
import { Colors } from '@/constants/Colors';
import { ReturnToPepup } from '@/features/home/components/Home/ReturnToPepup';
import { OrganRankingItem } from '@/features/home/components/parts/OrganRankingItem';
import type { HealthyLifeSpan } from '@/types/graphql';
import { byNumberAscending } from '@/utils/sort/byNumberAscending';
import { styleType } from '@/utils/styleType';

import { HealthLimit } from '../parts/HealthLimit';
import { NoHealthData } from './NoHealthData';

type Props = {
  worstOrganItem: HealthyLifeSpan | undefined;
  organRankingItems: Array<ComponentProps<typeof OrganRankingItem>> | undefined;
  onReturnToPepUpPress: () => void;
};

export const HomeTemplate: FC<Props> = (props) => {
  const { worstOrganItem, organRankingItems, onReturnToPepUpPress } = props;

  return worstOrganItem && organRankingItems ? (
    <Container style={styles.container}>
      <HealthLimit healthLifeSpan={worstOrganItem} isComparisonOfSameGenerationDisplayed={!worstOrganItem.isAdjusted} />
      <View style={styles.contentArea}>
        <Text.Headline style={styles.title}>{t.home.explanation.title}</Text.Headline>
        <Text.Subtext>{t.home.explanation.text}</Text.Subtext>
      </View>
      <View style={styles.contentArea}>
        <Text.Headline style={styles.title}>{t.home.organRanking.title}</Text.Headline>
        {organRankingItems.sort(byNumberAscending((item) => item.rank))?.map((item) => (
          <OrganRankingItem key={item.rank} {...item} />
        ))}
      </View>
      <View style={styles.contentArea}>
        <ReturnToPepup onReturnToPepUpPress={onReturnToPepUpPress} />
      </View>
    </Container>
  ) : (
    <Container>
      <NoHealthData onReturnToPepUpPress={onReturnToPepUpPress} />
    </Container>
  );
};

const styles = StyleSheet.create({
  container: styleType<ViewStyle>({
    backgroundColor: Colors.gray1,
  }),
  contentArea: styleType<ViewStyle>({
    marginBottom: 5,
    paddingHorizontal: 20,
    paddingVertical: 32,
    backgroundColor: Colors.white100,
  }),
  title: styleType<TextStyle>({
    marginBottom: 20,
  }),
});
