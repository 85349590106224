import type { FC } from 'react';
import { useState } from 'react';
import type { TextStyle, ViewStyle } from 'react-native';
import { ScrollView, StyleSheet, View } from 'react-native';

import { messages as t } from '@/assets/i18n/ja';
import { Text } from '@/components/Text';
import { Colors } from '@/constants/Colors';
import { Footer } from '@/features/tutorial/components/Tutorial/parts/Footer';
import { styleType } from '@/utils/styleType';

type Props = {
  onPressLeft: () => void;
  onPressRight: () => void;
};

export const TutorialThirdPage: FC<Props> = (props) => {
  const { onPressLeft, onPressRight } = props;
  const [termsRead, setTermsRead] = useState(false);

  return (
    <View style={styles.container}>
      <Text.Body>{t.tutorial.thirdPage.termsPleaseReadText}</Text.Body>
      <ScrollView
        style={styles.scrollViewContainer}
        onScroll={({ nativeEvent }) => {
          if (isBottomReached(nativeEvent)) {
            setTermsRead(true);
          }
        }}
        scrollEventThrottle={400}
      >
        <Text.Headline style={styles.topTitle}>{t.tutorial.thirdPage.termsTitle}</Text.Headline>
        <Text.Body>{t.tutorial.thirdPage.termsTopText}</Text.Body>
        <Text.Headline style={styles.title}>{t.tutorial.thirdPage.termsAccuracyTitle}</Text.Headline>
        <Text.Body>{t.tutorial.thirdPage.termsAccuracyText}</Text.Body>

        <Text.Headline style={styles.title}>{t.tutorial.thirdPage.useOfDataTitle}</Text.Headline>
        <Text.Body>{t.tutorial.thirdPage.useOfDataText}</Text.Body>
      </ScrollView>
      <View style={styles.footerContainer}>
        <Footer pageIndicatorIndex={3} onPressLeft={onPressLeft} onPressRight={onPressRight} disabled={!termsRead} />
      </View>
    </View>
  );
};

type Size = {
  height: number;
  width: number;
};

type Offset = {
  x: number;
  y: number;
};

const isBottomReached = ({
  layoutMeasurement,
  contentOffset,
  contentSize,
}: {
  layoutMeasurement: Size;
  contentOffset: Offset;
  contentSize: Size;
}) => {
  const paddingToBottom = 20;
  return layoutMeasurement.height + contentOffset.y >= contentSize.height - paddingToBottom;
};

const styles = StyleSheet.create({
  container: styleType<ViewStyle>({
    flex: 1,
    paddingTop: 12,
    padding: 20,
  }),
  scrollViewContainer: styleType<ViewStyle>({
    maxHeight: 400,
    padding: 14,
    marginTop: 20,
    marginBottom: 80,
    borderColor: Colors.black12,
    borderWidth: 1,
    borderRadius: 8,
  }),
  topTitle: styleType<TextStyle>({
    paddingBottom: 16,
    alignSelf: 'center',
  }),
  title: styleType<TextStyle>({
    paddingTop: 16,
    paddingBottom: 4,
  }),
  footerContainer: styleType<ViewStyle>({
    ...StyleSheet.absoluteFillObject,
    top: 'auto',
    bottom: 0,
  }),
});
