import { type CombinedError } from 'urql';

export const hasGraphQLForbiddenError = (error: CombinedError) =>
  error.graphQLErrors
    .map((error) => {
      const statusCode = error.extensions.status as number;
      return statusCode === 403;
    })
    .includes(true);

export const hasGraphQLServerError = (error: CombinedError) =>
  error.graphQLErrors
    .map((error) => {
      const statusCode = error.extensions.status as number;
      return statusCode >= 500 && statusCode < 600 && statusCode !== 504;
    })
    .includes(true);
