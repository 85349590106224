import { type FC } from 'react';

import { Colors } from '@/constants/Colors';

type Props = {
  size?: number;
  stroke?: string;
};

export const CheckboxInactive: FC<Props> = (props) => {
  const { size = 24, stroke = Colors.black26 } = props;

  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="2.5" y="2.5" width="19" height="19" rx="1.5" stroke={stroke} />
    </svg>
  );
};
