import { graphqlContext, MockedRequest, ResponseResolver } from 'msw';

import { LogoutPayload } from '@/types/graphql';

const logoutPayload: { logout: LogoutPayload } = {
  logout: {
    userId: 'hoge1234',
  },
};

export const mockLogout: ResponseResolver<MockedRequest, typeof graphqlContext> = (req, res, ctx) =>
  res(ctx.data(logoutPayload));
