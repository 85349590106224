import { type ComponentProps, type FC } from 'react';

import { messages as t } from '@/assets/i18n/ja';
import { NumberOfFriends } from '@/components/questionnaire/form/environment';
import { QuestionnaireContainer } from '@/features/myPage/components/parts/QuestionnaireContainer';

type Props = ComponentProps<typeof NumberOfFriends>;

export const NumberOfFriendsTemplate: FC<Props> = (props) => {
  const { question, selections, defaultValue, onSubmit } = props;

  return (
    <QuestionnaireContainer>
      <NumberOfFriends
        question={question}
        selections={selections}
        onSubmit={onSubmit}
        defaultValue={defaultValue}
        submitButtonLabel={t.myPage.pastQuestionnaireAnswers.save}
        submitButtonPosition="absolute"
      />
    </QuestionnaireContainer>
  );
};
