import type { ComponentProps, FC } from 'react';
import type { ViewStyle } from 'react-native';
import { StyleSheet } from 'react-native';
import { Tabs, TabScreen } from 'react-native-paper-tabs';

import { messages as t } from '@/assets/i18n/ja';
import { Button } from '@/components/Button';
import { Container } from '@/components/Container';
import { Colors } from '@/constants/Colors';
import { styleType } from '@/utils/styleType';

import { ListByCategory } from './parts/ListByCategory';
import type { ListItem } from './parts/ListItem';

type Props = {
  data: Data;
  onPressNavigateToMyPageButton: () => void;
};

type Data = {
  lifeStyle: ComponentProps<typeof ListItem>[];
  environment: ComponentProps<typeof ListItem>[];
  medicalHistory: ComponentProps<typeof ListItem>[];
};

export const PastQuestionnaireAnswersTemplate: FC<Props> = (props) => {
  const {
    data: { lifeStyle, environment, medicalHistory },
    onPressNavigateToMyPageButton,
  } = props;

  return (
    <Container>
      <Tabs
        theme={{
          colors: { primary: Colors.healthactionGreen, text: Colors.black100 },
          fonts: { medium: { fontWeight: 'bold', fontFamily: 'Noto Sans JP' } },
        }}
        style={styles.tabs}
      >
        <TabScreen label={t.myPage.pastQuestionnaireAnswers.lifeStyle}>
          <ListByCategory
            title={t.myPage.pastQuestionnaireAnswers.lifeStyle}
            data={lifeStyle}
            style={styles.listByCategory}
          />
        </TabScreen>
        <TabScreen label={t.myPage.pastQuestionnaireAnswers.environment}>
          <ListByCategory
            title={t.myPage.pastQuestionnaireAnswers.environment}
            data={environment}
            style={styles.listByCategory}
          />
        </TabScreen>
        <TabScreen label={t.myPage.pastQuestionnaireAnswers.medicalHistory}>
          <ListByCategory
            title={t.myPage.pastQuestionnaireAnswers.medicalHistory}
            data={medicalHistory}
            style={styles.listByCategory}
          />
        </TabScreen>
      </Tabs>
      <Button type="active" onPress={onPressNavigateToMyPageButton} style={styles.button}>
        {t.myPage.pastQuestionnaireAnswers.toMyPage}
      </Button>
    </Container>
  );
};

const styles = StyleSheet.create({
  tabs: styleType<ViewStyle>({
    backgroundColor: Colors.white100,
    shadowColor: 'transparent',
  }),
  listByCategory: styleType<ViewStyle>({
    paddingTop: 20,
    paddingHorizontal: 20,
  }),
  button: styleType<ViewStyle>({
    marginTop: 32,
    marginBottom: 20,
    marginHorizontal: 20,
  }),
});
