import { useAtomValue } from 'jotai';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import type { NumberOfFriendsSchema } from '@/formSchemas/environmentSchema';
import { useGetEnvironmentQuestionnaireQuery } from '@/hooks/GetQuestionnaire.generated';
import { useQuestionnaire } from '@/hooks/useQuestionnaire';
import { environmentFormValuesAtom } from '@/store/formValues';

export const useNumberOfFriends = () => {
  const [getEnvironmentQuestionnaireResult] = useGetEnvironmentQuestionnaireQuery();
  const { data, fetching, error } = getEnvironmentQuestionnaireResult;

  const formValues = useAtomValue(environmentFormValuesAtom);

  const { getQuestionnaireItem, createSelections, getSelectedValue, perpetuateEnvironmentFormValues } =
    useQuestionnaire();

  const questionnaireItem = useMemo(
    () => data && getQuestionnaireItem(data, 'NUMBER_OF_FRIENDS'),
    [data, getQuestionnaireItem]
  );
  const selections = useMemo(
    () => questionnaireItem?.forms[0].__typename === 'SelectTypeForm' && createSelections(questionnaireItem.forms[0]),
    [questionnaireItem, createSelections]
  );
  const selectedValue = useMemo(() => {
    if (formValues?.numberOfFriends) {
      return formValues?.numberOfFriends;
    }
    if (questionnaireItem?.forms[0].__typename === 'SelectTypeForm') {
      return getSelectedValue(questionnaireItem.forms[0]);
    }
    return undefined;
  }, [questionnaireItem?.forms, getSelectedValue, formValues?.numberOfFriends]);

  const navigate = useNavigate();

  const handleSubmit = useCallback(
    (values: NumberOfFriendsSchema) => {
      perpetuateEnvironmentFormValues(values);

      navigate('/questionnaire/environment-answers-complete');
    },
    [perpetuateEnvironmentFormValues, navigate]
  );

  return {
    fetching,
    data,
    error,
    questionnaireItem,
    selections,
    selectedValue,
    handleSubmit,
  };
};
