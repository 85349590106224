import { type ComponentProps, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { BuildConfig } from '@/constants/BuildConfig';
import type { OrganRankingItem } from '@/features/home/components/parts/OrganRankingItem';
import { usePredictHealthyLifeSpanQuery } from '@/hooks/PredictHealthyLifeSpan.generated';
import type { HealthyLifeSpan, PredictHealthyLifeSpanPayload } from '@/types/graphql';
import { openURL } from '@/utils/OpenUrl';

import { type HealthyLifeSpanItem, createOrganRankingItems } from './rankingItems';

export const useHome = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [worstOrganItem, setWorstOrganItem] = useState<HealthyLifeSpan>();
  const [organRankingItems, setOrganRankingItems] = useState<ComponentProps<typeof OrganRankingItem>[]>();

  const navigate = useNavigate();

  const context = useMemo(() => ({ additionalTypenames: ['HealthyLifeSpan'] }), []);
  const [predictHealthyLifeSpanResult] = usePredictHealthyLifeSpanQuery({
    requestPolicy: 'cache-first',
    context,
  });
  const { data, error } = predictHealthyLifeSpanResult;

  const handleReturnToPepUpPress = useCallback(() => {
    /* eslint-disable-next-line @typescript-eslint/no-unsafe-argument */
    openURL(BuildConfig.PEPUP_URL);
  }, []);

  useEffect(() => {
    if (data) {
      const { result, brain, heart, kidney, liver, lung } = data.predictHealthyLifeSpan;

      if (result) {
        setWorstOrganItem(result);
      }

      if (brain || heart || kidney || liver || lung) {
        const healthyLifeSpanItems = convertHealthyLifeSpanToArray(data.predictHealthyLifeSpan);
        setOrganRankingItems(createOrganRankingItems(healthyLifeSpanItems, navigate));
      }

      setIsLoaded(true);
    }
  }, [navigate, data]);

  return {
    isLoaded,
    error,
    worstOrganItem,
    organRankingItems,
    handleReturnToPepUpPress,
  };
};

const convertHealthyLifeSpanToArray = (data: Omit<PredictHealthyLifeSpanPayload, 'userId'>) => {
  const result: HealthyLifeSpanItem[] = [];

  if (data.brain) result.push({ ...data.brain, organInfo: { organ: 'Brain' } });
  if (data.heart) result.push({ ...data.heart, organInfo: { organ: 'Heart' } });
  if (data.kidney) result.push({ ...data.kidney, organInfo: { organ: 'Kidney' } });
  if (data.liver) result.push({ ...data.liver, organInfo: { organ: 'Liver' } });
  if (data.lung) result.push({ ...data.lung, organInfo: { organ: 'Lung' } });

  return result;
};
