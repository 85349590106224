import React, { type ComponentProps } from 'react';
import { type ViewStyle, StyleSheet } from 'react-native';

import { Container } from '@/components/Container';
import { Colors } from '@/constants/Colors';
import { NotificationItem } from '@/features/notifications/components/Notifications/NotificationItem';
import { byNumberDescending } from '@/utils/sort/byNumberDescending';
import { styleType } from '@/utils/styleType';

type Props = {
  notifications: Array<ComponentProps<typeof NotificationItem> & { id: string }>;
};

export const NotificationsTemplate: React.FC<Props> = (props) => {
  const { notifications } = props;

  return (
    <Container style={styles.container}>
      {notifications.sort(byNumberDescending((item) => Number(item.id)))?.map((item) => (
        <NotificationItem date={item.date} title={item.title} description={item.description} />
      ))}
    </Container>
  );
};

const styles = StyleSheet.create({
  container: styleType<ViewStyle>({
    backgroundColor: Colors.gray1,
    paddingTop: 14,
    paddingHorizontal: 20,
  }),
});
