import { type ComponentProps, type FC } from 'react';

import { messages as t } from '@/assets/i18n/ja';
import { CurrentSmokingHabit } from '@/components/questionnaire/form/lifeStyle';
import { QuestionnaireContainer } from '@/features/myPage/components/parts/QuestionnaireContainer';

type Props = ComponentProps<typeof CurrentSmokingHabit>;

export const CurrentSmokingHabitTemplate: FC<Props> = (props) => {
  const { question, fields, onSubmit } = props;

  return (
    <QuestionnaireContainer>
      <CurrentSmokingHabit
        question={question}
        fields={fields}
        onSubmit={onSubmit}
        submitButtonLabel={t.myPage.pastQuestionnaireAnswers.save}
        submitButtonPosition="absolute"
      />
    </QuestionnaireContainer>
  );
};
