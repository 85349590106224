import { useCallback, useRef } from 'react';
import type { Location } from 'react-router-dom';

import { Logger } from '@/lib/Logger';
import { PageViewEvent } from '@/lib/Logger/events';

export const useNavigationLogger = () => {
  const prevPathname = useRef<string | undefined>();
  const currentPathname = useRef<string | undefined>();

  const sendViewEvent = useCallback((location: Location) => {
    prevPathname.current = currentPathname.current;
    const currentRouteName = location.pathname;

    if (prevPathname.current !== currentRouteName && currentRouteName) {
      const event = PageViewEvent({
        page_location: currentRouteName,
        page_referrer: prevPathname.current,
      });

      Logger.logEvent(event);
    }

    currentPathname.current = currentRouteName;
  }, []);

  return {
    sendViewEvent,
  };
};
