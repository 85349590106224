import { type FC } from 'react';

import { Colors } from '@/constants/Colors';

type Props = {
  size?: number;
  fill?: string;
  stroke?: string;
};

export const ArrowRight: FC<Props> = (props) => {
  const { size = 24, fill = 'transparent', stroke = Colors.black100 } = props;

  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2_308)">
        <path d="M9 18L15 12L9 6" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_2_308">
          <rect width={size} height={size} fill={fill} transform="matrix(-1 0 0 1 24 0)" />
        </clipPath>
      </defs>
    </svg>
  );
};
