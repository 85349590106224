import { useAtomValue } from 'jotai';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import type { AmountOfDrinkingPerDaySchema } from '@/formSchemas/lifeStyleSchema';
import { useGetLifeStyleQuestionnaireQuery } from '@/hooks/GetQuestionnaire.generated';
import { useQuestionnaire } from '@/hooks/useQuestionnaire';
import { lifeStyleFormValuesAtom } from '@/store/formValues';

export const useAmountOfDrinkingPerDay = () => {
  const [getLifeStyleQuestionnaireResult] = useGetLifeStyleQuestionnaireQuery();
  const { data, fetching, error } = getLifeStyleQuestionnaireResult;
  const formValues = useAtomValue(lifeStyleFormValuesAtom);

  const { getQuestionnaireItem, createSelections, getSelectedValue, perpetuateLifeStyleFormValues } =
    useQuestionnaire();

  const questionnaireItem = useMemo(
    () => data && getQuestionnaireItem(data, 'AMOUNT_OF_DRINKING_PER_DAY'),
    [data, getQuestionnaireItem]
  );
  const selections = useMemo(
    () =>
      questionnaireItem?.forms[0].__typename === 'SelectTypeForm'
        ? createSelections(questionnaireItem.forms[0])
        : undefined,
    [questionnaireItem?.forms, createSelections]
  );

  const selectedValue = useMemo(() => {
    if (formValues?.amountOfDrinkingPerDay) {
      return formValues?.amountOfDrinkingPerDay;
    }
    if (questionnaireItem?.forms[0].__typename === 'SelectTypeForm') {
      return getSelectedValue(questionnaireItem.forms[0]);
    }
    return undefined;
  }, [questionnaireItem?.forms, getSelectedValue, formValues?.amountOfDrinkingPerDay]);

  const navigate = useNavigate();

  const handleSubmit = useCallback(
    (values: AmountOfDrinkingPerDaySchema) => {
      perpetuateLifeStyleFormValues(values);

      navigate('/questionnaire/can-get-enough-rest-with-sleep');
    },
    [perpetuateLifeStyleFormValues, navigate]
  );

  return {
    fetching,
    data,
    error,
    questionnaireItem,
    selections,
    selectedValue,
    handleSubmit,
  };
};
