import { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { NotFound } from '@/features/error/components/NotFound';
import { Home } from '@/features/home/components/Home';
import { OrganDetail } from '@/features/home/components/OrganDetail';
import { AboutAgeExpectancy } from '@/features/myPage/components/AboutAgeExpectancy';
import { AboutSpecialistSupervision } from '@/features/myPage/components/AboutSpecialistSupervision';
import { AmountOfDrinkingPerDay } from '@/features/myPage/components/AmountOfDrinkingPerDay';
import { CanEatFasterThan } from '@/features/myPage/components/CanEatFasterThan';
import { CanGetEnoughRestWithSleep } from '@/features/myPage/components/CanGetEnoughRestWithSleep';
import { CanWalkFasterThan } from '@/features/myPage/components/CanWalkFasterThan';
import { CurrentSmokingHabit } from '@/features/myPage/components/CurrentSmokingHabit';
import { FamilyStructure } from '@/features/myPage/components/FamilyStructure';
import { FrequencyOfDrinking } from '@/features/myPage/components/FrequencyOfDrinking';
import { FrequencyOfDriving } from '@/features/myPage/components/FrequencyOfDriving';
import { HasCar } from '@/features/myPage/components/HasCar';
import { HasHabitOfEatDinnerBeforeSleep } from '@/features/myPage/components/HasHabitOfEatDinnerBeforeSleep';
import { HasHabitOfEatSnackAfterDinner } from '@/features/myPage/components/HasHabitOfEatSnackAfterDinner';
import { HasHabitOfSkipBreakfast } from '@/features/myPage/components/HasHabitOfSkipBreakfast';
import { HasHabitOfSweatyExerciseMoreThanOneYear } from '@/features/myPage/components/HasHabitOfSweatyExerciseMoreThanOneYear';
import { HasHabitOfWalking } from '@/features/myPage/components/HasHabitOfWalking';
import { HasSmokingHabit } from '@/features/myPage/components/HasSmokingHabit';
import { HasSmokingHistory } from '@/features/myPage/components/HasSmokingHistory';
import { HousingType } from '@/features/myPage/components/HousingType';
import { HowToSpendHolidays } from '@/features/myPage/components/HowToSpendHolidays';
import { MeansOfTransportation } from '@/features/myPage/components/MeansOfTransportation';
import { MedicalHistory } from '@/features/myPage/components/MedicalHistory';
import { NumberOfFriends } from '@/features/myPage/components/NumberOfFriends';
import { Occupation } from '@/features/myPage/components/Occupation';
import { PastQuestionnaireAnswers } from '@/features/myPage/components/PastQuestionnaireAnswers';
import { PastSmokingHabit } from '@/features/myPage/components/PastSmokingHabit';
import { Top as MyPageTop } from '@/features/myPage/components/Top';
import { Notifications } from '@/features/notifications/components/Notifications';
import { useNavigationLogger } from '@/hooks/useNavigationLogger';

export const AnswersCompletedRoutes = () => {
  const location = useLocation();
  const { sendViewEvent } = useNavigationLogger();

  useEffect(() => {
    sendViewEvent(location);
  }, [sendViewEvent, location]);

  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route path="/" element={<Home />} />
      <Route path="/organ-detail" element={<OrganDetail />} />
      <Route path="/notifications" element={<Notifications />} />
      <Route path="/about-specialist-supervision" element={<AboutSpecialistSupervision />} />
      <Route path="/about-age-expectancy" element={<AboutAgeExpectancy />} />
      <Route path="my-page" element={<MyPageTop />} />

      {/* ========== past-questionnaire ========== */}
      <Route path="/past-questionnaire" element={<PastQuestionnaireAnswers />} />

      {/* Life style */}
      <Route path="/past-questionnaire/has-smoking-history" element={<HasSmokingHistory />} />
      <Route path="/past-questionnaire/has-smoking-habit" element={<HasSmokingHabit />} />
      <Route path="/past-questionnaire/current-smoking-habit" element={<CurrentSmokingHabit />} />
      <Route path="/past-questionnaire/past-smoking-habit" element={<PastSmokingHabit />} />
      <Route path="/past-questionnaire/has-habit-of-walking" element={<HasHabitOfWalking />} />
      <Route path="/past-questionnaire/can-walk-faster-than" element={<CanWalkFasterThan />} />
      <Route path="/past-questionnaire/can-eat-faster-than" element={<CanEatFasterThan />} />
      <Route
        path="/past-questionnaire/has-habit-of-eat-dinner-before-sleep"
        element={<HasHabitOfEatDinnerBeforeSleep />}
      />
      <Route
        path="/past-questionnaire/has-habit-of-eat-snack-after-dinner"
        element={<HasHabitOfEatSnackAfterDinner />}
      />
      <Route path="/past-questionnaire/has-habit-of-skip-breakfast" element={<HasHabitOfSkipBreakfast />} />
      <Route path="/past-questionnaire/frequency-of-drinking" element={<FrequencyOfDrinking />} />
      <Route path="/past-questionnaire/amount-of-drinking-per-day" element={<AmountOfDrinkingPerDay />} />
      <Route path="/past-questionnaire/can-get-enough-rest-with-sleep" element={<CanGetEnoughRestWithSleep />} />
      <Route
        path="/past-questionnaire/has-habit-of-sweaty-exercise-more-than-one-year"
        element={<HasHabitOfSweatyExerciseMoreThanOneYear />}
      />

      {/* Environment */}
      <Route path="/past-questionnaire/family-structure" element={<FamilyStructure />} />
      <Route path="/past-questionnaire/occupation" element={<Occupation />} />
      <Route path="/past-questionnaire/means-of-transportation" element={<MeansOfTransportation />} />
      <Route path="/past-questionnaire/housing-type" element={<HousingType />} />
      <Route path="/past-questionnaire/has-car" element={<HasCar />} />
      <Route path="/past-questionnaire/frequency-of-driving" element={<FrequencyOfDriving />} />
      <Route path="/past-questionnaire/how-to-spend-holidays" element={<HowToSpendHolidays />} />
      <Route path="/past-questionnaire/number-of-friends" element={<NumberOfFriends />} />

      {/* MedicalHistory */}
      <Route path="/past-questionnaire/medical-history" element={<MedicalHistory />} />

      {/* ==============================
        以下リダイレクト
      ============================== */}
      <Route path="tutorial" element={<Navigate replace to="/" />} />

      {/* ========== questionnaire ========== */}
      <Route path="/questionnaire/start" element={<Navigate replace to="/" />} />

      {/* Life style */}
      <Route path="/questionnaire/has-smoking-history" element={<Navigate replace to="/" />} />
      <Route path="/questionnaire/has-smoking-habit" element={<Navigate replace to="/" />} />
      <Route path="/questionnaire/current-smoking-habit" element={<Navigate replace to="/" />} />
      <Route path="/questionnaire/past-smoking-habit" element={<Navigate replace to="/" />} />
      <Route path="/questionnaire/has-habit-of-walking" element={<Navigate replace to="/" />} />
      <Route path="/questionnaire/can-walk-faster-than" element={<Navigate replace to="/" />} />
      <Route path="/questionnaire/can-eat-faster-than" element={<Navigate replace to="/" />} />
      <Route path="/questionnaire/has-habit-of-eat-dinner-before-sleep" element={<Navigate replace to="/" />} />
      <Route path="/questionnaire/has-habit-of-eat-snack-after-dinner" element={<Navigate replace to="/" />} />
      <Route path="/questionnaire/has-habit-of-skip-breakfast" element={<Navigate replace to="/" />} />
      <Route path="/questionnaire/frequency-of-drinking" element={<Navigate replace to="/" />} />
      <Route path="/questionnaire/amount-of-drinking-per-day" element={<Navigate replace to="/" />} />
      <Route path="/questionnaire/can-get-enough-rest-with-sleep" element={<Navigate replace to="/" />} />
      <Route
        path="/questionnaire/has-habit-of-sweaty-exercise-more-than-one-year"
        element={<Navigate replace to="/" />}
      />
      <Route path="/questionnaire/life-style-answers-complete" element={<Navigate replace to="/" />} />

      {/* Environment */}
      <Route path="/questionnaire/family-structure" element={<Navigate replace to="/" />} />
      <Route path="/questionnaire/occupation" element={<Navigate replace to="/" />} />
      <Route path="/questionnaire/means-of-transportation" element={<Navigate replace to="/" />} />
      <Route path="/questionnaire/housing-type" element={<Navigate replace to="/" />} />
      <Route path="/questionnaire/has-car" element={<Navigate replace to="/" />} />
      <Route path="/questionnaire/frequency-of-driving" element={<Navigate replace to="/" />} />
      <Route path="/questionnaire/how-to-spend-holidays" element={<Navigate replace to="/" />} />
      <Route path="/questionnaire/number-of-friends" element={<Navigate replace to="/" />} />
      <Route path="/questionnaire/environment-answers-complete" element={<Navigate replace to="/" />} />

      {/* MedicalHistory */}
      <Route path="/questionnaire/medical-history" element={<Navigate replace to="/" />} />

      <Route path="predicting-healthy-life-span" element={<Navigate replace to="/" />} />
    </Routes>
  );
};
