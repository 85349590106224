import { type FC, type ReactNode, useEffect, useRef } from 'react';
import { type ViewStyle, Platform, ScrollView as RNScrollView } from 'react-native';

type ContainerProps = {
  children: ReactNode;
  style?: ViewStyle;
  styleContainer?: ViewStyle;
};

type ScrollBehavior = 'auto' | 'smooth';

export const ScrollView: FC<ContainerProps> = (props) => {
  const { children, style, styleContainer } = props;
  const scrollViewRef = useRef<RNScrollView>(null);

  useEffect(() => {
    if (Platform.OS === 'web') {
      // scroll animationをoffにする値が型定義にないので
      // https://github.com/microsoft/TypeScript/issues/46654
      window.scrollTo({ left: 0, top: 0, behavior: 'instant' as ScrollBehavior });
      return;
    }

    if (scrollViewRef.current) {
      scrollViewRef.current.scrollTo({ x: 0, y: 0, animated: false });
    }
  }, []);

  return (
    <RNScrollView ref={scrollViewRef} style={style} contentContainerStyle={styleContainer}>
      {children}
    </RNScrollView>
  );
};
