import { zodResolver } from '@hookform/resolvers/zod';
import { type FC } from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { type TextStyle, type ViewStyle, StyleSheet, View } from 'react-native';

import { messages as t } from '@/assets/i18n/ja';
import { Button } from '@/components/Button';
import { type Selection } from '@/components/SelectionList';
import { SelectionListControlled } from '@/components/SelectionListControlled';
import { Text } from '@/components/Text';
import { Colors } from '@/constants/Colors';
import { type NumberOfFriendsSchema, numberOfFriendsSchema } from '@/formSchemas/environmentSchema';
import { styleType } from '@/utils/styleType';

type Props = {
  question: string;
  selections: Selection[];
  onSubmit: SubmitHandler<NumberOfFriendsSchema>;
  defaultValue?: number;
  submitButtonLabel?: string;
  submitButtonPosition?: 'relative' | 'absolute';
};

export const NumberOfFriends: FC<Props> = (props) => {
  const {
    question,
    selections,
    onSubmit,
    defaultValue,
    submitButtonLabel = t.questionnaire.pagingButtonLabel,
    submitButtonPosition = 'relative',
  } = props;
  const {
    formState: { errors },
    handleSubmit,
    control,
    setValue,
    watch,
  } = useForm<NumberOfFriendsSchema>({
    defaultValues: {
      numberOfFriends: defaultValue,
    },
    mode: 'onChange',
    resolver: zodResolver(numberOfFriendsSchema),
  });

  const watchNumberOfFriends = watch('numberOfFriends');

  const handleSelectionListPress = (selection: Selection) => {
    setValue('numberOfFriends', selection.value);
  };

  return (
    <>
      <Text.Headline>{question}</Text.Headline>
      <View style={styles.answerContainer}>
        <SelectionListControlled
          type="radio"
          name="numberOfFriends"
          control={control}
          selections={selections}
          onPress={handleSelectionListPress}
        />
      </View>
      {errors.numberOfFriends?.message ? (
        <Text.Subtext style={styles.errorText} testID="numberOfFriendsFieldError">
          {errors.numberOfFriends?.message}
        </Text.Subtext>
      ) : null}
      <View style={submitButtonPosition === 'absolute' ? styles.absoluteButtonContainer : null}>
        <Button
          onPress={handleSubmit(onSubmit) /* eslint-disable-line @typescript-eslint/no-misused-promises */}
          type={watchNumberOfFriends ? 'active' : 'inactive'}
          accessibilityRole="button"
          accessibilityLabel="次へ"
          style={styles.button}
        >
          {submitButtonLabel}
        </Button>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  errorText: styleType<TextStyle>({
    color: Colors.systemRed,
    marginTop: 8,
  }),
  supplement: styleType<TextStyle>({
    marginTop: 8,
  }),
  answerContainer: styleType<ViewStyle>({
    marginTop: 20,
  }),
  button: styleType<ViewStyle>({
    marginTop: 40,
  }),
  absoluteButtonContainer: styleType<ViewStyle>({
    ...StyleSheet.absoluteFillObject,
    top: 'auto',
    bottom: 0,
  }),
});
