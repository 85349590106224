import { type FC } from 'react';
import { type TextStyle, type ViewStyle, StyleSheet } from 'react-native';

import { messages as t } from '@/assets/i18n/ja';
import { Button } from '@/components/Button';
import { Container } from '@/components/Container';
import { Text } from '@/components/Text';
import { styleType } from '@/utils/styleType';

type Props = {
  onPress: () => void;
};

export const QuestionnaireStartTemplate: FC<Props> = (props) => {
  const { onPress } = props;

  return (
    <Container style={styles.container}>
      <Text.Headline style={styles.title}>{t.questionnaire.start.title}</Text.Headline>
      <Text.Body style={styles.description}>{t.questionnaire.start.description}</Text.Body>
      <Button type="active" onPress={onPress} style={styles.button}>
        {t.questionnaire.start.buttonText}
      </Button>
    </Container>
  );
};

const styles = StyleSheet.create({
  container: styleType<ViewStyle>({
    paddingHorizontal: 20,
  }),
  title: styleType<TextStyle>({
    textAlign: 'center',
    marginTop: 40,
  }),
  description: styleType<TextStyle>({
    marginTop: 20,
  }),
  button: styleType<ViewStyle>({
    marginTop: 40,
  }),
});
