import Constants from 'expo-constants';

import { BuildConfig } from '@/constants/BuildConfig';

import SentryConfig from './config.json';

export const Config = {
  debug: Constants.debugMode,
  enableOutOfMemoryTracking: true,
  /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */
  environment: BuildConfig.ENVIRONMENT,
  autoSessionTracking: true,
  dsn: SentryConfig.dsn,
};
