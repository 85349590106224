import type { ComponentProps, FC } from 'react';

import { messages as t } from '@/assets/i18n/ja';
import { MedicalHistory } from '@/components/questionnaire/form/medicalHistory';
import { QuestionnaireContainer } from '@/features/myPage/components/parts/QuestionnaireContainer';

type Props = ComponentProps<typeof MedicalHistory>;

export const MedicalHistoryTemplate: FC<Props> = (props) => {
  const { question, fields, onSubmit } = props;

  return (
    <QuestionnaireContainer>
      <MedicalHistory
        question={question}
        fields={fields}
        onSubmit={onSubmit}
        submitButtonLabel={t.myPage.pastQuestionnaireAnswers.save}
      />
    </QuestionnaireContainer>
  );
};
