import { StatusBar } from 'expo-status-bar';
import { Suspense } from 'react';
import { SafeAreaProvider } from 'react-native-safe-area-context';

import { Registration } from '@/features/registration/components/Registration';
import { useInitializer } from '@/hooks/useInitializer';
import { ApiClientProvider } from '@/lib/ApiClient/ApiClientProvider';
import { Logger } from '@/lib/Logger';

import { useCachedResources } from './src/hooks/useCachedResources';
import { Navigation } from './src/navigation';
import { LoadingView } from '@/components/LoadingView';
import { worker } from '__mocks__/browser';
import { BuildConfig } from '@/constants/BuildConfig';
import { UIKitProvider } from '@/lib/UIKit/UIKitProvider';

Logger.init();

if (BuildConfig.ENABLE_MSW === 'true') {
  worker.start();
}

const App = () => {
  const isLoadingComplete = useCachedResources();

  if (!isLoadingComplete) {
    return null;
  } else {
    return (
      <ApiClientProvider>
        <SafeAreaProvider>
          <UIKitProvider>
            <Suspense fallback={<LoadingView />}>
              <NavigationOrRegistration />
              <StatusBar />
            </Suspense>
          </UIKitProvider>
        </SafeAreaProvider>
      </ApiClientProvider>
    );
  }
};

/* eslint-disable-next-line import/no-default-export */
export default App;

const NavigationOrRegistration = () => {
  const { authStatus, userStatus } = useInitializer();

  return authStatus === 'fetching' ? (
    <LoadingView />
  ) : authStatus === 'ssoLoginRequired' ? (
    <Registration />
  ) : (
    <Navigation userStatus={userStatus} authStatus={authStatus} />
  );
};
