import { zodResolver } from '@hookform/resolvers/zod';
import type { FC } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import type { TextStyle, ViewStyle } from 'react-native';
import { StyleSheet, View } from 'react-native';

import { messages as t } from '@/assets/i18n/ja';
import { Button } from '@/components/Button';
import { Text } from '@/components/Text';
import { TextInputControlled } from '@/components/TextInputControlled';
import { Colors } from '@/constants/Colors';
import type { CurrentSmokingHabitSchema } from '@/formSchemas/lifeStyleSchema';
import { currentSmokingHabitSchema } from '@/formSchemas/lifeStyleSchema';
import type { CurrentSmokingHabitFields } from '@/types/questionnaire/lifeStyle';
import { styleType } from '@/utils/styleType';

type Props = {
  question: string;
  fields: CurrentSmokingHabitFields;
  onSubmit: SubmitHandler<CurrentSmokingHabitSchema>;
  submitButtonLabel?: string;
  submitButtonPosition?: 'relative' | 'absolute';
};

export const CurrentSmokingHabit: FC<Props> = (props) => {
  const {
    question,
    fields: { numberOfCigarettesPerDayCurrent, yearsOfSmokingCurrent },
    onSubmit,
    submitButtonLabel = t.questionnaire.pagingButtonLabel,
    submitButtonPosition = 'relative',
  } = props;
  const {
    formState: { errors },
    handleSubmit,
    control,
    watch,
  } = useForm<CurrentSmokingHabitSchema>({
    defaultValues: {
      numberOfCigarettesPerDayCurrent: (numberOfCigarettesPerDayCurrent.value || '') as unknown as number,
      yearsOfSmokingCurrent: (yearsOfSmokingCurrent.value || '') as unknown as number,
    },
    mode: 'onChange',
    resolver: zodResolver(currentSmokingHabitSchema),
  });

  const watchNumberOfCigarettesPerDayCurrent = watch('numberOfCigarettesPerDayCurrent');
  const watchYearsOfSmokingCurrent = watch('yearsOfSmokingCurrent');

  return (
    <>
      <Text.Headline>{question}</Text.Headline>
      <View style={styles.answerContainer}>
        <Text.BoldBody>{numberOfCigarettesPerDayCurrent.title}</Text.BoldBody>
        <TextInputControlled
          keyboardType="number-pad"
          name="numberOfCigarettesPerDayCurrent"
          placeholder={numberOfCigarettesPerDayCurrent.placeholder}
          unit={numberOfCigarettesPerDayCurrent.unit}
          control={control}
          style={styles.input}
        />
        {errors.numberOfCigarettesPerDayCurrent?.message ? (
          <Text.Subtext style={styles.errorText} testID="numberOfCigarettesPerDayFieldError">
            {errors.numberOfCigarettesPerDayCurrent?.message}
          </Text.Subtext>
        ) : null}
      </View>
      <View style={styles.answerContainer}>
        <Text.BoldBody>{yearsOfSmokingCurrent.title}</Text.BoldBody>
        <TextInputControlled
          keyboardType="number-pad"
          name="yearsOfSmokingCurrent"
          placeholder={yearsOfSmokingCurrent.placeholder}
          unit={yearsOfSmokingCurrent.unit}
          control={control}
          style={styles.input}
        />
        {errors.yearsOfSmokingCurrent?.message ? (
          <Text.Subtext style={styles.errorText} testID="yearsOfSmokingFieldError">
            {errors.yearsOfSmokingCurrent?.message}
          </Text.Subtext>
        ) : null}
      </View>
      <View style={submitButtonPosition === 'absolute' ? styles.absoluteButtonContainer : null}>
        <Button
          onPress={handleSubmit(onSubmit) /* eslint-disable-line @typescript-eslint/no-misused-promises */}
          type={watchYearsOfSmokingCurrent && watchNumberOfCigarettesPerDayCurrent ? 'active' : 'inactive'}
          accessibilityRole="button"
          accessibilityLabel="次へ"
          style={styles.button}
        >
          {submitButtonLabel}
        </Button>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  errorText: styleType<TextStyle>({
    color: Colors.systemRed,
  }),
  answerContainer: styleType<ViewStyle>({
    marginTop: 20,
  }),
  input: styleType<ViewStyle>({
    marginTop: 4,
  }),
  button: styleType<ViewStyle>({
    marginTop: 40,
  }),
  absoluteButtonContainer: styleType<ViewStyle>({
    ...StyleSheet.absoluteFillObject,
    top: 'auto',
    bottom: 0,
  }),
});
