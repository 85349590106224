import { z } from 'zod';

import { withInOneHundredLength } from './validationRules';

export const medicalHistorySchema = z
  .object({
    stroke: z.boolean(),
    transientIschemicAttack: z.boolean(),
    ischemicHeartDisease: z.boolean(),
    heartFailure: z.boolean(),
    chronicObstructivePulmonaryDisease: z.boolean(),
    liverCirrhosis: z.boolean(),
    liverFailure: z.boolean(),
    dialysis: z.boolean(),
    cancer: withInOneHundredLength,
    highBloodPressure: z.boolean(),
    diabetes: z.boolean(),
    dyslipidemia: z.boolean(),
    otherDisease: z.boolean(),
    otherDiseaseSupplement: withInOneHundredLength,
  })
  // 「そのほか」を選択していないときは「そのほか」の補足入力欄を送信しない
  .transform((values) => (values.otherDisease ? values : { ...values, otherDiseaseSupplement: '' }));
export type MedicalHistorySchema = z.infer<typeof medicalHistorySchema>;
