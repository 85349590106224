import { type ComponentProps, type FC } from 'react';

import { AmountOfDrinkingPerDay } from '@/components/questionnaire/form/lifeStyle';
import { QuestionnaireContainer } from '@/features/myPage/components/parts/QuestionnaireContainer';

type Props = ComponentProps<typeof AmountOfDrinkingPerDay>;

export const AmountOfDrinkingPerDayTemplate: FC<Props> = (props) => {
  const { question, supplement, selections, defaultValue, onSubmit } = props;

  return (
    <QuestionnaireContainer>
      <AmountOfDrinkingPerDay
        question={question}
        supplement={supplement}
        selections={selections}
        defaultValue={defaultValue}
        onSubmit={onSubmit}
        isSubmitButtonPresent={true}
      />
    </QuestionnaireContainer>
  );
};
