import { type FC } from 'react';

import { messages as t } from '@/assets/i18n/ja';
import { NavigationHeader } from '@/components/NavigationHeader';
import { useCannotPredictHealthData } from '@/features/tutorial/hooks/useCannotPredictHealthData';

import { CannotPredictHealthLimitTemplate } from './template';

export const CannotPredictHealthLimit: FC = () => {
  const { handleReturnToPepUpPress } = useCannotPredictHealthData();

  return (
    <>
      <NavigationHeader title={t.navigation.cannotPredictHealthLimit} headerLeft="back" />
      <CannotPredictHealthLimitTemplate onReturnToPepUpPress={handleReturnToPepUpPress} />
    </>
  );
};
