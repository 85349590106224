import type { ComponentProps, FC } from 'react';

import { HasCar } from '@/components/questionnaire/form/environment';

import { QuestionnaireContainer } from '../parts/QuestionnaireContainer';
import type { QuestionNumber } from '../parts/QuestionNumber';

type Props = Omit<ComponentProps<typeof QuestionNumber>, 'style'> & ComponentProps<typeof HasCar>;

export const HasCarTemplate: FC<Props> = (props) => {
  const { questionNumber, allQuestionNumber, question, selections, defaultValue, onSubmit } = props;

  return (
    <QuestionnaireContainer questionNumber={questionNumber} allQuestionNumber={allQuestionNumber}>
      <HasCar question={question} selections={selections} defaultValue={defaultValue} onSubmit={onSubmit} />
    </QuestionnaireContainer>
  );
};
