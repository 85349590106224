import { useAtom } from 'jotai';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { type CombinedError } from 'urql';

import { messages as t } from '@/assets/i18n/ja';
import { NavigationHeader } from '@/components/NavigationHeader';
import { ServerErrorView } from '@/components/ServerErrorView';
import { BuildConfig } from '@/constants/BuildConfig';
import { MEDICAL_HISTORY } from '@/features/questionnaire/constants/QuestionnaireGroupName';
import { useCreateOrUpdateMedicalHistoryAnswerMutation } from '@/hooks/CreateOrUpdateAnswer.generated';
import { usePredictHealthyLifeSpanQuery } from '@/hooks/PredictHealthyLifeSpan.generated';
import { useUserStatus } from '@/hooks/useUserStatus';
import { Logger } from '@/lib/Logger';
import { QuestionnaireCompleteEvent } from '@/lib/Logger/events';
import { medicalHistoryFormValuesAtom } from '@/store/formValues';
import { hasGraphQLServerError } from '@/utils/hasGraphQlError';
import { openURL } from '@/utils/OpenUrl';

import { PredictingHealthyLifeSpanTemplate } from './template';

export const PredictingHealthyLifeSpan = () => {
  const [formValues, setFormValues] = useAtom(medicalHistoryFormValuesAtom);
  const [hasError, setHasError] = useState(false);

  const [, createOrUpdateAnswer] = useCreateOrUpdateMedicalHistoryAnswerMutation();
  const [predictHealthyLifeSpanResult, predictHealthyLifeSpan] = usePredictHealthyLifeSpanQuery({ pause: true });
  const { userStatus, getUserStatus } = useUserStatus();

  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const handleContactUsListItemPress = () => openURL(BuildConfig.PEPUP_CONTACT_URL);

  const handleError = useCallback((error: CombinedError) => {
    setHasError(true);

    if (hasGraphQLServerError(error)) {
      Logger.logException(error.message);
    }
  }, []);

  const createOrUpdateAnswerOperation = useCallback(() => {
    if (formValues) {
      createOrUpdateAnswer(formValues)
        .then((res) => {
          if (res.data) {
            setFormValues(undefined).catch(() => undefined);
            Logger.logEvent(QuestionnaireCompleteEvent({ group: MEDICAL_HISTORY }));

            predictHealthyLifeSpan();
          } else if (res.error) {
            handleError(res.error);
          }
        })
        .catch((e) => Logger.logException(e));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (predictHealthyLifeSpanResult.data) {
      getUserStatus();
    } else if (predictHealthyLifeSpanResult.error) {
      handleError(predictHealthyLifeSpanResult.error);
    }
  }, [predictHealthyLifeSpanResult, getUserStatus, handleError]);

  useEffect(() => {
    if (userStatus.data && userStatus.data.getUserStatus.isCompleted) {
      navigate('/');
    } else if (userStatus.error) {
      handleError(userStatus.error);
    }
  }, [userStatus, navigate, handleError]);

  useEffect(() => {
    createOrUpdateAnswerOperation();
  }, [createOrUpdateAnswerOperation]);

  return (
    <>
      <NavigationHeader title={t.navigation.completeAnswers} />
      {hasError ? (
        <ServerErrorView onContactUsListItemPress={handleContactUsListItemPress} />
      ) : (
        <PredictingHealthyLifeSpanTemplate />
      )}
    </>
  );
};
