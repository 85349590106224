import type { FC } from 'react';
import type { ImageStyle, TextStyle, ViewStyle } from 'react-native';
import { Image, StyleSheet, View } from 'react-native';

import { messages as t } from '@/assets/i18n/ja';
import IMAGE_URI from '@/assets/images/apologies_figure.png';
import { Button } from '@/components/Button';
import { Text } from '@/components/Text';
import { styleType } from '@/utils/styleType';

type Props = {
  onReturnToPepUpPress: () => void;
};

export const NoHealthData: FC<Props> = (props) => {
  const { onReturnToPepUpPress } = props;

  return (
    <View style={styles.container}>
      <Image source={{ uri: IMAGE_URI }} resizeMode="contain" style={styles.image} />

      <Text.Headline style={styles.title}>{t.home.noHealthData.cantPredictAge}</Text.Headline>
      <Text.Body style={styles.reasonsText}>{t.home.noHealthData.reasons}</Text.Body>
      <Text.Body style={styles.pleaseWaitText}>{t.home.noHealthData.pleaseWait}</Text.Body>
      <Button type="active" onPress={onReturnToPepUpPress} uppercase={false}>
        {t.home.noHealthData.returnToPepUp}
      </Button>
    </View>
  );
};

const styles = StyleSheet.create({
  container: styleType<ViewStyle>({
    flex: 1,
    paddingHorizontal: 20,
    paddingTop: 40,
  }),
  image: styleType<ImageStyle>({
    alignSelf: 'center',
    width: 60,
    height: 90,
  }),
  title: styleType<TextStyle>({
    marginTop: 8,
    textAlign: 'center',
    justifyContent: 'center',
  }),
  reasonsText: styleType<TextStyle>({
    marginVertical: 20,
  }),
  pleaseWaitText: styleType<TextStyle>({
    marginBottom: 20,
  }),
});
