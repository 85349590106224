import type { FC } from 'react';
import type { ViewStyle } from 'react-native';
import { ScrollView, StyleSheet, View } from 'react-native';
import { Divider } from 'react-native-paper';

import { messages as t } from '@/assets/i18n/ja';
import { Icon } from '@/components/Icon';
import { Touchable } from '@/components/Touchable';
import { Colors } from '@/constants/Colors';
import { styleType } from '@/utils/styleType';

import { ListItem } from './ListItem';

type Props = {
  onPressCloseButton: () => void;
  onPressHome: () => void;
  onPressMyPage: () => void;
  onPressAboutSpecialistSupervision: () => void;
  onPressAboutAgeExpectancy: () => void;
  onPressCompany: () => void;
  onPressContactUs: () => void;
};

export const Drawer: FC<Props> = (props) => {
  const {
    onPressCloseButton,
    onPressHome,
    onPressMyPage,
    onPressAboutSpecialistSupervision,
    onPressAboutAgeExpectancy,
    onPressCompany,
    onPressContactUs,
  } = props;

  return (
    <ScrollView style={styles.scrollView}>
      <Touchable onPress={onPressCloseButton} style={styles.closeButtonContainer}>
        <Icon type="Cancel" />
      </Touchable>
      <View style={styles.list}>
        <ListItem text={t.drawer.home} onPress={onPressHome} />
        <ListItem text={t.drawer.myPage} onPress={onPressMyPage} />
        <Divider style={styles.divider} />
        <ListItem text={t.drawer.aboutSpecialistSupervision} onPress={onPressAboutSpecialistSupervision} />
        <ListItem text={t.drawer.aboutAgeExpectancy} onPress={onPressAboutAgeExpectancy} />
        <ListItem text={t.drawer.company} onPress={onPressCompany} />
        <ListItem text={t.drawer.contactUs} onPress={onPressContactUs} />
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  scrollView: styleType<ViewStyle>({
    backgroundColor: Colors.white100,
    maxHeight: '100%',
  }),
  closeButtonContainer: styleType<ViewStyle>({
    paddingLeft: 16,
    paddingVertical: 18,
  }),
  list: styleType<ViewStyle>({
    marginHorizontal: 20,
  }),
  divider: styleType<ViewStyle>({
    backgroundColor: Colors.black12,
    marginVertical: 8,
  }),
});
