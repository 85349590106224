import { type ComponentProps, type FC } from 'react';

import { messages as t } from '@/assets/i18n/ja';
import { HowToSpendHolidays } from '@/components/questionnaire/form/environment';
import { QuestionnaireContainer } from '@/features/myPage/components/parts/QuestionnaireContainer';

type Props = ComponentProps<typeof HowToSpendHolidays>;

export const HowToSpendHolidaysTemplate: FC<Props> = (props) => {
  const { question, fields, onSubmit } = props;

  return (
    <QuestionnaireContainer>
      <HowToSpendHolidays
        question={question}
        fields={fields}
        onSubmit={onSubmit}
        submitButtonLabel={t.myPage.pastQuestionnaireAnswers.save}
      />
    </QuestionnaireContainer>
  );
};
