import type { FC } from 'react';
import type { ViewStyle } from 'react-native';
import { StyleSheet, View } from 'react-native';

import { messages as t } from '@/assets/i18n/ja';
import { Button } from '@/components/Button';
import { Colors } from '@/constants/Colors';
import { styleType } from '@/utils/styleType';

type Props = {
  onPressLeft?: () => void;
  onPressRight: () => void;
  pageIndicatorIndex: PageIndicatorIndex;
  disabled?: boolean;
};

type PageIndicatorIndex = 1 | 2 | 3;

export const Footer: FC<Props> = (props) => {
  const { onPressLeft, onPressRight, pageIndicatorIndex, disabled } = props;

  return (
    <View style={styles.container}>
      {pageIndicatorIndex === 1 ? (
        <View style={styles.button} />
      ) : (
        <Button type="active2" onPress={onPressLeft} style={styles.button}>
          {t.tutorial.backButton}
        </Button>
      )}
      <IndicatorView pageIndicatorIndex={pageIndicatorIndex} />
      <Button type="active" onPress={onPressRight} disabled={disabled} style={styles.button}>
        {pageIndicatorIndex === 3 ? t.tutorial.thirdPage.completeButton : t.tutorial.nextButton}
      </Button>
    </View>
  );
};

const IndicatorView = ({ pageIndicatorIndex }: { pageIndicatorIndex: number }) => (
  <View style={styles.indicatorContainer}>
    <View
      style={[
        styles.indicator,
        styles.indicatorRightSpace,
        pageIndicatorIndex === 1 ? styles.indicatorActive : styles.indicatorInactive,
      ]}
    />
    <View
      style={[
        styles.indicator,
        styles.indicatorRightSpace,
        pageIndicatorIndex === 2 ? styles.indicatorActive : styles.indicatorInactive,
      ]}
    />
    <View style={[styles.indicator, pageIndicatorIndex === 3 ? styles.indicatorActive : styles.indicatorInactive]} />
  </View>
);

const styles = StyleSheet.create({
  container: styleType<ViewStyle>({
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: Colors.white100,
    padding: 20,
  }),
  button: styleType<ViewStyle>({
    minWidth: 86,
    borderColor: Colors.healthactionGreen,
  }),
  indicatorContainer: styleType<ViewStyle>({
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
  indicator: styleType<ViewStyle>({
    width: 5,
    height: 5,
    borderRadius: 30,
  }),
  indicatorRightSpace: styleType<ViewStyle>({
    marginRight: 8,
  }),
  indicatorActive: styleType<ViewStyle>({
    backgroundColor: Colors.healthactionGreen,
  }),
  indicatorInactive: styleType<ViewStyle>({
    backgroundColor: Colors.black26,
  }),
});
