import { type FC } from 'react';
import { type TextStyle, type ViewStyle, StyleSheet } from 'react-native';

import { messages as t } from '@/assets/i18n/ja';
import { Container } from '@/components/Container';
import { LoadingView } from '@/components/LoadingView';
import { Text } from '@/components/Text';
import { styleType } from '@/utils/styleType';

import { AnswersComplete } from '../parts/AnswersComplete';

type Props = {
  mutationStatus: 'succeed' | 'failed' | 'pending';
  onPress: () => void;
};

export const LifeStyleAnswersCompleteTemplate: FC<Props> = (props) => {
  const { mutationStatus, onPress } = props;

  if (mutationStatus === 'pending') return <LoadingView />;

  if (mutationStatus === 'failed') {
    return (
      <Container style={styles.container}>
        <Text.Body style={styles.failedToSendAnswersText}>{t.questionnaire.failedToSendAnswers}</Text.Body>
      </Container>
    );
  }

  return (
    <Container style={styles.container}>
      <AnswersComplete
        text={t.questionnaire.lifestyleAnswersComplete}
        buttonLabel={t.questionnaire.pagingButtonLabel}
        onPress={onPress}
      />
    </Container>
  );
};

const styles = StyleSheet.create({
  container: styleType<ViewStyle>({
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 20,
  }),
  failedToSendAnswersText: styleType<TextStyle>({
    textAlign: 'center',
  }),
});
