import rnfAnalytics from '@react-native-firebase/analytics';
import { getAnalytics, logEvent, setUserId } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { Platform } from 'react-native';

import { BuildConfig } from '@/constants/BuildConfig';
import type { Event } from '@/lib/Logger/types/Event';

type Analytics = {
  logEvent: (event: Event) => void;
  setUserId: (id: string | null) => void;
};

initializeApp(BuildConfig.FIREBASE_WEB_CONFIG);
const webAnalytics: Analytics = {
  logEvent: (event: Event) => {
    logEvent(getAnalytics(), event.name, event.props);
  },

  setUserId: (id: string | null) => {
    setUserId(getAnalytics(), id ?? '');
  },
};

const nativeAnalytics: Analytics = {
  logEvent: (event: Event) => {
    // eslint-disable-next-line no-void
    void rnfAnalytics().logEvent(event.name, event.props);
  },

  setUserId: (id: string | null) => {
    // eslint-disable-next-line no-void
    void rnfAnalytics().setUserId(id);
  },
};

export const analytics: Analytics = Platform.OS === 'web' ? webAnalytics : nativeAnalytics;
