import { type FC } from 'react';

import { Colors } from '@/constants/Colors';

type Props = {
  size?: number;
  stroke?: string;
  hasBadge?: boolean;
};

export const Bell: FC<Props> = (props) => {
  const { size = 24, stroke = Colors.white100, hasBadge = false } = props;

  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.9798 10.5C18.4747 7 15.9495 5.00052 11.9091 5C7.6675 4.99946 5.34343 7 4.83838 10.5C4.39916 13.5438 3.95993 16.2095 3.1885 17.5104C3.02656 17.7835 3.06617 18.1601 3.35481 18.2924C6.86885 19.9025 16.9493 19.9025 20.4634 18.2924C20.752 18.1601 20.7895 17.7857 20.6226 17.5157C19.8708 16.2995 19.4783 13.9549 18.9798 10.5Z"
        stroke={stroke}
        strokeWidth="1.5"
      />
      <path
        d="M8.87885 19C8.87885 20.6569 10.2356 22 11.9092 22C13.5827 22 14.9395 20.6569 14.9395 19"
        stroke={stroke}
        strokeWidth="1.5"
      />
      <path
        d="M11.9095 2C13.0252 2 13.9297 2.89543 13.9297 4C13.9297 4.4587 13.7737 4.88133 13.5114 5.21875H10.3076C10.0453 4.88133 9.88928 4.4587 9.88928 4C9.88928 2.89543 10.7938 2 11.9095 2Z"
        fill={stroke}
      />
      {hasBadge ? <circle cx="3" cy="3" r="3" transform="matrix(-1 0 0 1 24 0)" fill={Colors.systemRed} /> : null}
    </svg>
  );
};
