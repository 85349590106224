import { useGetUserStatusQuery } from './GetUserStatus.generated';

export const useUserStatus = () => {
  const [userStatus, getUserStatus] = useGetUserStatusQuery({ pause: true, requestPolicy: 'network-only' });

  return {
    userStatus,
    getUserStatus,
  };
};
