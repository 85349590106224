import { type FC } from 'react';
import { type TextStyle, type ViewStyle, StyleSheet, View } from 'react-native';

import { messages as t } from '@/assets/i18n/ja';
import { Icon } from '@/components/Icon';
import { Text } from '@/components/Text';
import { Colors } from '@/constants/Colors';
import { type Organ, type OrganInfo } from '@/features/home/types/Organ';
import { organTypeToText } from '@/features/home/utils/OrganTypeToText';
import { type HealthyLifeSpan } from '@/types/graphql';
import { styleType } from '@/utils/styleType';

type Props = {
  healthLifeSpan: HealthyLifeSpan;
  organInfo?: OrganInfo;
};

type OrganIconType = {
  iconType: Organ;
};

export const AgeExpectancyItem: FC<Props> = (props) => {
  const { healthLifeSpan, organInfo } = props;

  return (
    <View style={styles.container}>
      {organInfo?.organ ? <OrganIcon iconType={organInfo.organ} /> : null}
      <Text.Headline>
        {organInfo?.organ ? organTypeToText(organInfo.organ) : t.home.healthyLifeExpectancy.ageExpectancy.your}
        {t.home.healthyLifeExpectancy.ageExpectancy.ageIs}
      </Text.Headline>
      <View style={styles.ageTextContainer}>
        <Text.Title1>{healthLifeSpan.age.year}</Text.Title1>
        <Text.Body style={styles.ageOldText}>{t.home.healthyLifeExpectancy.ageExpectancy.age}</Text.Body>
        <Text.Body>{t.home.healthyLifeExpectancy.ageExpectancy.is}</Text.Body>
        <View style={styles.markIcon}>
          <Icon type="AgeMark" stroke={Colors.black100} size={15} />
        </View>
      </View>
    </View>
  );
};

/**
 * アイコンの名前はOrganのTypesと同じです
 */
const OrganIcon = (props: OrganIconType) => {
  const { iconType } = props;

  return <Icon type={iconType} size={70} />;
};

const styles = StyleSheet.create({
  container: styleType<ViewStyle>({
    flexDirection: 'column',
    alignSelf: 'center',
    alignItems: 'center',
    marginBottom: 12,
    marginTop: 20,
  }),
  markIcon: styleType<ViewStyle>({
    height: 15,
    width: 15,
    alignSelf: 'flex-start',
    marginLeft: 4,
  }),
  ageTextContainer: styleType<ViewStyle>({
    flexDirection: 'row',
    alignItems: 'baseline',
    marginTop: 4,
  }),
  ageOldText: styleType<TextStyle>({
    marginHorizontal: 4,
  }),
});
