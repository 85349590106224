import { useAtom } from 'jotai';
import { useState } from 'react';
import type { FieldValues } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { ENVIRONMENT, LIFE_STYLE, MEDICAL_HISTORY } from '@/features/questionnaire/constants/QuestionnaireGroupName';
import {
  useCreateOrUpdateEnvironmentAnswerMutation,
  useCreateOrUpdateLifeStyleAnswerMutation,
  useCreateOrUpdateMedicalHistoryAnswerMutation,
} from '@/hooks/CreateOrUpdateAnswer.generated';
import { Logger } from '@/lib/Logger';
import { QuestionnaireUpdateEvent } from '@/lib/Logger/events';
import { environmentFormValuesAtom, lifeStyleFormValuesAtom, medicalHistoryFormValuesAtom } from '@/store/formValues';

export const useUpdateQuestionnaireAnswers = () => {
  const [lifeStyleFormValues, setLifeStyleFormValues] = useAtom(lifeStyleFormValuesAtom);
  const [environmentFormValues, setEnvironmentFormValues] = useAtom(environmentFormValuesAtom);
  const [medicalHistoryFormValues, setMedicalHistoryFormValues] = useAtom(medicalHistoryFormValuesAtom);

  const [hasError, setHasError] = useState(false);

  const [, updateLifeStyleAnswers] = useCreateOrUpdateLifeStyleAnswerMutation();
  const [, updateEnvironmentAnswers] = useCreateOrUpdateEnvironmentAnswerMutation();
  const [, updateMedicalHistoryAnswers] = useCreateOrUpdateMedicalHistoryAnswerMutation();

  const navigate = useNavigate();

  const updateLifeStyleQuestionnaireAnswers = (values: FieldValues, index: string) => {
    if (lifeStyleFormValues) {
      updateLifeStyleAnswers(
        {
          ...lifeStyleFormValues,
          ...values,
        },
        { additionalTypenames: ['HealthyLifeSpan'] }
      )
        .then((res) => {
          if (res.data) {
            Logger.logEvent(QuestionnaireUpdateEvent({ group: LIFE_STYLE, index }));
            setLifeStyleFormValues(undefined).catch(() => undefined);
            navigate('/past-questionnaire');
          } else if (res.error) {
            Logger.logException(res.error.message);
            setHasError(true);
          }
        })
        .catch((e) => {
          Logger.logException(e);
        });
    }
  };

  const updateEnvironmentQuestionnaireAnswers = (values: FieldValues, index: string) => {
    if (environmentFormValues) {
      updateEnvironmentAnswers(
        {
          ...environmentFormValues,
          ...values,
        },
        { additionalTypenames: ['HealthyLifeSpan'] }
      )
        .then((res) => {
          if (res.data) {
            Logger.logEvent(QuestionnaireUpdateEvent({ group: ENVIRONMENT, index }));
            setEnvironmentFormValues(undefined).catch(() => undefined);
            navigate('/past-questionnaire');
          } else if (res.error) {
            Logger.logException(res.error.message);
            setHasError(true);
          }
        })
        .catch((e) => {
          Logger.logException(e);
        });
    }
  };

  const updateMedicalHistoryQuestionnaireAnswers = (values: FieldValues, index: string) => {
    updateMedicalHistoryAnswers(
      {
        ...medicalHistoryFormValues,
        ...values,
      },
      { additionalTypenames: ['HealthyLifeSpan'] }
    )
      .then((res) => {
        if (res.data) {
          Logger.logEvent(QuestionnaireUpdateEvent({ group: MEDICAL_HISTORY, index }));
          setMedicalHistoryFormValues(undefined).catch(() => undefined);
          navigate('/past-questionnaire');
        } else if (res.error) {
          Logger.logException(res.error.message);
          setHasError(true);
        }
      })
      .catch((e) => {
        Logger.logException(e);
      });
  };

  return {
    updateLifeStyleQuestionnaireAnswers,
    updateEnvironmentQuestionnaireAnswers,
    updateMedicalHistoryQuestionnaireAnswers,
    hasError,
  };
};
