import type { FC } from 'react';
import type { ViewStyle } from 'react-native';
import { StyleSheet, View } from 'react-native';

import { Colors } from '@/constants/Colors';
import { AgeExpectancyItem } from '@/features/home/components/parts/AgeExpectancyItem';
import { HorizontalBarGraph } from '@/features/home/components/parts/HorizontalBarGraph';
import { RemainingHealthyLifeItem } from '@/features/home/components/parts/RemainingHealthyLifeItem';
import { type OrganInfo } from '@/features/home/types/Organ';
import type { HealthyLifeSpan } from '@/types/graphql';
import { styleType } from '@/utils/styleType';

type Props = {
  healthLifeSpan: HealthyLifeSpan;
  isComparisonOfSameGenerationDisplayed: boolean;
  organInfo?: OrganInfo;
};

export const HealthLimit: FC<Props> = (props) => {
  const { healthLifeSpan, isComparisonOfSameGenerationDisplayed, organInfo } = props;

  return (
    <View style={styles.contentArea}>
      <AgeExpectancyItem healthLifeSpan={healthLifeSpan} organInfo={organInfo} />
      <RemainingHealthyLifeItem healthLifeSpan={healthLifeSpan} />
      <HorizontalBarGraph
        healthLifeSpan={healthLifeSpan}
        isComparisonOfSameGenerationDisplayed={isComparisonOfSameGenerationDisplayed}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  contentArea: styleType<ViewStyle>({
    paddingHorizontal: 20,
    marginBottom: 5,
    backgroundColor: Colors.white100,
  }),
});
