import type { FC } from 'react';
import type { TextStyle } from 'react-native';
import { StyleSheet } from 'react-native';

import { messages as t } from '@/assets/i18n/ja';
import { Button } from '@/components/Button';
import { Text } from '@/components/Text';
import { styleType } from '@/utils/styleType';

type Props = {
  onReturnToPepUpPress: () => void;
};

export const ReturnToPepup: FC<Props> = (props) => {
  const { onReturnToPepUpPress } = props;

  return (
    <>
      <Text.Headline>{t.home.returnToPepUp.title}</Text.Headline>
      <Text.Subtext style={styles.caption}>{t.home.returnToPepUp.caption}</Text.Subtext>
      <Button type="active" onPress={onReturnToPepUpPress} uppercase={false}>
        {t.home.returnToPepUp.returnToPepUpButton}
      </Button>
    </>
  );
};

const styles = StyleSheet.create({
  caption: styleType<TextStyle>({
    marginVertical: 20,
  }),
});
