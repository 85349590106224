import React from 'react';

import { messages as t } from '@/assets/i18n/ja';
import { NavigationHeader } from '@/components/NavigationHeader';

import { AboutAgeExpectancyTemplate } from './template';

export const AboutAgeExpectancy: React.FC = () => (
  <>
    <NavigationHeader title={t.navigation.aboutAgeExpectancy} headerLeft="back" />
    <AboutAgeExpectancyTemplate />
  </>
);
