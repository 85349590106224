import { useCallback, useEffect, useState } from 'react';

import { BuildConfig } from '@/constants/BuildConfig';
import { Logger } from '@/lib/Logger';
import { getTokenFromStorage, removeTokenFromStorage, setTokenToStorage } from '@/store/authToken';
import { hasGraphQLForbiddenError } from '@/utils/hasGraphQlError';
import { openURL } from '@/utils/OpenUrl';

import { useGenerateAccessTokenMutation } from './GenerateAccessToken.generated';
import { useVerifyAccessTokenMutation } from './VerifyAccessToken.generated';

export type AuthStatus =
  | 'fetching'
  | 'authenticated'
  | 'authenticatedWithSso'
  | 'unauthorized'
  | 'ssoLoginRequired'
  | 'unavailable';

export const useAuth = () => {
  const [authStatus, setAuthStatus] = useState<AuthStatus>('fetching');
  const [, generateAccessToken] = useGenerateAccessTokenMutation();
  const [, verifyAccessToken] = useVerifyAccessTokenMutation();

  const verifyAccessTokenOperation = useCallback(() => {
    verifyAccessToken({})
      .then((res) => {
        if (res.data) {
          const { token } = res.data.verifyAccessToken;
          const expiresAt = res.data.verifyAccessToken.expiresAt as string;
          setTokenToStorage({ token, expiresAt }).catch(() => undefined);
          setAuthStatus('authenticated');
        } else if (res.error) {
          removeTokenFromStorage().catch(() => undefined);

          if (hasGraphQLForbiddenError(res.error)) {
            setAuthStatus('unauthorized');
          } else {
            setAuthStatus('ssoLoginRequired');
            Logger.setUserId(null);
            redirectToPepUpSSO();
          }
        }
      })
      .catch((e) => Logger.logException(e));
  }, [verifyAccessToken]);

  const generateAccessTokenOperation = useCallback(
    (authorizationCode: string) => {
      generateAccessToken({ authorizationCode })
        .then((res) => {
          if (res.data) {
            const { token } = res.data.generateAccessToken;
            const expiresAt = res.data.generateAccessToken.expiresAt as string;
            setTokenToStorage({ token, expiresAt }).catch(() => undefined);
            setAuthStatus('authenticatedWithSso');
          } else if (res.error) {
            if (hasGraphQLForbiddenError(res.error)) {
              setAuthStatus('unauthorized');
            } else {
              setAuthStatus('unavailable');
            }
          }
        })
        .catch((e) => Logger.logException(e));
    },
    [generateAccessToken]
  );

  const authOperation = useCallback(async () => {
    const { token, expiresAt } = await getTokenFromStorage();
    const isTokenPresent = token && expiresAt;
    const isTokenAvailable = isTokenPresent ? new Date() < new Date(expiresAt) : undefined;

    if (isTokenPresent) {
      if (isTokenAvailable) {
        setAuthStatus('authenticated');
      } else {
        // アクセストークンが有効期限外なら検証
        verifyAccessTokenOperation();
      }
    } else {
      // Pep Up SSOからリダイレクト時に付与される、アクセストークンの生成に用いるCode
      const authorizationCode = new URLSearchParams(window.location.search).get('code');

      if (authorizationCode) {
        generateAccessTokenOperation(authorizationCode);
      } else {
        setAuthStatus('ssoLoginRequired');
        redirectToPepUpSSO();
      }
    }
  }, [generateAccessTokenOperation, verifyAccessTokenOperation]);

  useEffect(() => {
    authOperation().catch(() => undefined);
  }, [authOperation]);

  return {
    authStatus,
  };
};

const redirectToPepUpSSO = () => {
  setTimeout(() => {
    /* eslint-disable-next-line @typescript-eslint/no-unsafe-argument */
    openURL(BuildConfig.PEPUP_SSO_URL);
  }, 1000); // 外部サイト連携メッセージを見せるため
};
