import type { StyleProp } from 'react-native';

import { Colors } from '@/constants/Colors';

export const NAVIGATION_HEIGHT = 60;

export const headerSettings = {
  headerStyle: {
    backgroundColor: Colors.healthactionGreen,
    height: NAVIGATION_HEIGHT,
    borderBottomWidth: 0,
  } as StyleProp<Record<string, unknown>>, // heightやborderBottomWidthを指定する方法がheaderStyle以外見つからないので
  headerTintColor: Colors.white100,
  headerTitleStyle: {
    fontSize: 15,
    fontWeight: 'bold' as const,
  },
  headerTitleAlign: 'center' as const,
};
