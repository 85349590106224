import type { graphqlContext, MockedRequest, ResponseResolver } from 'msw';

import type { PredictHealthyLifeSpanPayload } from '@/types/graphql';

const predictHealthyLifeSpanPayload: { predictHealthyLifeSpan: PredictHealthyLifeSpanPayload } = {
  predictHealthyLifeSpan: {
    userId: 'hogehoge12345678',
    result: {
      age: {
        year: 73,
        month: 3,
      },
      remaining: {
        year: 23,
        month: 3,
      },
      isAdjusted: false,
      comparisonOfSameGeneration: {
        isAverage: false,
        year: -1,
        month: 3,
      },
    },
    heart: {
      age: {
        year: 75,
        month: 2,
      },
      remaining: {
        year: 25,
        month: 2,
      },
      isAdjusted: false,
      comparisonOfSameGeneration: {
        isAverage: false,
        year: -1,
        month: 9,
      },
    },
    liver: {
      age: {
        year: 84,
        month: 2,
      },
      remaining: {
        year: 34,
        month: 2,
      },
      isAdjusted: false,
      comparisonOfSameGeneration: {
        isAverage: true,
        year: 0,
        month: 0,
      },
    },
    kidney: {
      age: {
        year: 86,
        month: 6,
      },
      remaining: {
        year: 34,
        month: 6,
      },
      isAdjusted: false,
      comparisonOfSameGeneration: {
        isAverage: false,
        year: 1,
        month: 5,
      },
    },
    lung: {
      age: {
        year: 79,
        month: 10,
      },
      remaining: {
        year: 29,
        month: 10,
      },
      isAdjusted: false,
      comparisonOfSameGeneration: {
        isAverage: false,
        year: -1,
        month: 0,
      },
    },
  },
};

export const mockPredictHealthyLifeSpan: ResponseResolver<MockedRequest, typeof graphqlContext> = (req, res, ctx) =>
  res(ctx.data(predictHealthyLifeSpanPayload));
