import AsyncStorage from '@react-native-async-storage/async-storage';

const TOKEN_STORAGE_KEY = 'token';
const EXPIRES_AT_STORAGE_KEY = 'expiresAt';

export type AuthToken = {
  [TOKEN_STORAGE_KEY]: string;
  [EXPIRES_AT_STORAGE_KEY]: string;
};

export const setTokenToStorage = async ({ token, expiresAt }: AuthToken) => {
  await AsyncStorage.setItem(TOKEN_STORAGE_KEY, token);
  await AsyncStorage.setItem(EXPIRES_AT_STORAGE_KEY, expiresAt);
};

export const getTokenFromStorage = async () => {
  const token = await AsyncStorage.getItem(TOKEN_STORAGE_KEY);
  const expiresAt = await AsyncStorage.getItem(EXPIRES_AT_STORAGE_KEY);

  return { [TOKEN_STORAGE_KEY]: token, [EXPIRES_AT_STORAGE_KEY]: expiresAt };
};

export const removeTokenFromStorage = async () => {
  await AsyncStorage.removeItem(TOKEN_STORAGE_KEY);
  await AsyncStorage.removeItem(EXPIRES_AT_STORAGE_KEY);
};
