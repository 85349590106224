import { useAtom } from 'jotai';
import { useCallback, useState } from 'react';

import notificationsJson from '@/features/notifications/data/notifications.json';
import { readNotificationsStorage } from '@/store/notificationsValues';
import { byNumberAscending } from '@/utils/sort/byNumberAscending';

type NotificationItems = Array<string>;

// From notifications/data/notifications.json
const allNotificationsId: NotificationItems = notificationsJson
  .map((item) => item.id)
  .sort(byNumberAscending((item) => Number(item)));

export const useNotifications = () => {
  const [hasUnreadNotifications, setHasUnreadNotifications] = useState(false);
  const [readNotifications, setReadNotifications] = useAtom(readNotificationsStorage);

  // ホーム画面が表示される時
  const checkForUnreadNotifications = useCallback(() => {
    setHasUnreadNotifications(readNotifications.toString() !== allNotificationsId.toString());
  }, [readNotifications]);

  // お知らせ画面が表示される時
  const markAllNotificationsAsRead = () => {
    setHasUnreadNotifications(false);
    setReadNotifications(allNotificationsId).catch(() => undefined);
  };

  return {
    markAllNotificationsAsRead,
    checkForUnreadNotifications,
    hasUnreadNotifications,
    readNotifications,
    allNotificationsId,
  };
};
