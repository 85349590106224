export const Colors = {
  black100: 'rgba(50, 58, 81, 1)',
  black54: 'rgba(50, 58, 81, 0.54)',
  black26: 'rgba(50, 58, 81, 0.26)',
  black12: 'rgba(50, 58, 81, 0.12)',
  white100: '#FFF',
  gray1: '#F1F4F9',
  gray2: '#F5F5F5',
  gray3: '#F6F5F1',
  healthactionGreen: '#2BA0A3',
  systemRed: '#EC4E3D',
  systemBlue: '#2F7CF6',
  systemGreen: '#34C759',
};
