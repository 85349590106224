import { type FC } from 'react';

import { Colors } from '@/constants/Colors';

type Props = {
  size?: number;
  stroke?: string;
};

export const AgeMark: FC<Props> = (props) => {
  const { size = 15, stroke = Colors.black100 } = props;

  return (
    <svg width={size} height={size} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width={size} height={size} transform="matrix(-1 0 0 1 15 0)" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.48374 4.67331C2.35089 4.60677 2.15797 4.4181 2.09382 3.48368L0.442531 3.59706C0.50806 4.55146 0.725727 5.64402 1.74257 6.15326C2.21459 6.38965 2.76247 6.4444 3.34474 6.3996C3.36472 6.39806 3.38483 6.3964 3.40505 6.39461C3.36199 6.53143 3.3307 6.67395 3.31714 6.82021C3.29311 7.07946 3.32347 7.36537 3.45508 7.64462C3.58719 7.92492 3.79684 8.1465 4.05011 8.31365C4.08867 8.3391 4.12846 8.36344 4.16946 8.38671C3.83342 8.74976 3.53482 9.19935 3.42378 9.71425C3.28646 10.351 3.459 10.9928 3.97434 11.513C4.44743 11.9906 5.17213 12.3317 6.10466 12.573L6.51929 10.9706C5.71297 10.7619 5.3207 10.5203 5.15022 10.3482C5.02198 10.2187 5.02434 10.1439 5.04176 10.0632C5.07189 9.92344 5.19078 9.7005 5.46205 9.4303C5.72286 9.17053 6.06987 8.92228 6.44117 8.72483L9.05817 7.33315L6.09876 7.16784C5.40275 7.12896 5.08798 7.01083 4.97095 6.93805C4.9895 6.85363 5.05191 6.69867 5.20363 6.46871C5.36815 6.21935 5.58239 5.96255 5.7994 5.71355L7.68901 3.54533L4.93601 4.37764C4.19182 4.60263 3.6342 4.71726 3.21775 4.7493C2.79833 4.78158 2.58658 4.72481 2.48374 4.67331Z"
        fill={stroke}
      />
    </svg>
  );
};
