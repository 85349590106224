import * as Linking from 'expo-linking';

import { Logger } from '@/lib/Logger';
import { ClickEvent } from '@/lib/Logger/events';

export const openURL = (url: string) => {
  Logger.logEvent(ClickEvent({ link_url: url }));

  Linking.openURL(url).catch((e) => {
    Logger.logException(e);
  });
};
