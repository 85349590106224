import { FontAwesome } from '@expo/vector-icons';
import * as Font from 'expo-font';
import * as SplashScreen from 'expo-splash-screen';
import { useEffect, useState } from 'react';

import NotoSansJPRegular from '../assets/fonts/NotoSansJP-Regular.otf';

export const useCachedResources = () => {
  const [isLoadingComplete, setLoadingComplete] = useState(false);

  // Load any resources or data that we need prior to rendering the app
  useEffect(() => {
    const loadResourcesAndDataAsync = async () => {
      try {
        SplashScreen.preventAutoHideAsync().catch(() => {});

        // Load fonts
        await Font.loadAsync({
          ...FontAwesome.font,
          'Noto Sans JP': NotoSansJPRegular, // eslint-disable-line @typescript-eslint/no-unsafe-assignment
        });
      } catch (e) {
        // We might want to provide this error information to an error reporting service
        console.warn(e); // eslint-disable-line no-console
      } finally {
        setLoadingComplete(true);
        SplashScreen.hideAsync().catch(() => {});
      }
    };

    loadResourcesAndDataAsync().catch(() => {});
  }, []);

  return isLoadingComplete;
};
