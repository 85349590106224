import { zodResolver } from '@hookform/resolvers/zod';
import { type FC } from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { type ViewStyle, StyleSheet, View } from 'react-native';

import { messages as t } from '@/assets/i18n/ja';
import { Button } from '@/components/Button';
import { type Selection } from '@/components/SelectionList';
import { SelectionListControlled } from '@/components/SelectionListControlled';
import { Text } from '@/components/Text';
import { type CanWalkFasterThanSchema, canWalkFasterThanSchema } from '@/formSchemas/lifeStyleSchema';
import { styleType } from '@/utils/styleType';

type Props = {
  question: string;
  selections: Selection[];
  defaultValue?: Selection['value'];
  onSubmit: SubmitHandler<CanWalkFasterThanSchema>;
  isSubmitButtonPresent?: boolean;
};

export const CanWalkFasterThan: FC<Props> = (props) => {
  const { question, selections, defaultValue, onSubmit, isSubmitButtonPresent } = props;
  const { handleSubmit, control, setValue } = useForm<CanWalkFasterThanSchema>({
    defaultValues: {
      canWalkFasterThan: defaultValue,
    },
    mode: 'onChange',
    resolver: zodResolver(canWalkFasterThanSchema),
  });

  const handlePressIfSubmitButtonPresent = (selection: Selection) => {
    setValue('canWalkFasterThan', selection.value);
  };

  const handlePressIfSubmitButtonNotPresent = (selection: Selection) => {
    setValue('canWalkFasterThan', selection.value);
    handleSubmit(onSubmit)(); // eslint-disable-line @typescript-eslint/no-floating-promises
  };

  return (
    <>
      <Text.Headline>{question}</Text.Headline>
      <View style={styles.answerContainer} accessibilityRole="radiogroup">
        <SelectionListControlled
          type="button"
          name="canWalkFasterThan"
          control={control}
          selections={selections}
          onPress={isSubmitButtonPresent ? handlePressIfSubmitButtonPresent : handlePressIfSubmitButtonNotPresent}
        />
      </View>
      {isSubmitButtonPresent ? (
        <View style={styles.buttonContainer}>
          <Button
            type="active"
            onPress={handleSubmit(onSubmit) /* eslint-disable-line @typescript-eslint/no-misused-promises */}
          >
            {t.myPage.pastQuestionnaireAnswers.save}
          </Button>
        </View>
      ) : null}
    </>
  );
};

const styles = StyleSheet.create({
  answerContainer: styleType<ViewStyle>({
    marginTop: 40,
  }),
  buttonContainer: styleType<ViewStyle>({
    ...StyleSheet.absoluteFillObject,
    top: 'auto',
    bottom: 0,
  }),
});
