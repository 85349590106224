import type { FC } from 'react';
import { Dimensions, StyleSheet } from 'react-native';
import { TabView } from 'react-native-tab-view';
import type { Route } from 'react-native-tab-view/src/types';

import { Container } from '@/components/Container';
import { Layout } from '@/constants/Layout';
import { TutorialFirstPage } from '@/features/tutorial/components/Tutorial/parts/TutorialFirstPage';
import { TutorialSecondPage } from '@/features/tutorial/components/Tutorial/parts/TutorialSecondPage';
import { TutorialThirdPage } from '@/features/tutorial/components/Tutorial/parts/TutorialThirdPage';
import { NAVIGATION_HEIGHT } from '@/lib/Navigation';
import { styleType } from '@/utils/styleType';

type Props = {
  onTermsConfirmPress: () => void;
  onUpdateIndex: (number: number) => void;
  index: number;
};

const routeNames = ['first', 'second', 'third'] as const;
const routes = routeNames.map((routeName) => ({ key: routeName }));

export const TutorialTemplate: FC<Props> = (props) => {
  const { onTermsConfirmPress, onUpdateIndex, index } = props;

  return (
    <Container style={styles.container}>
      <TabView
        swipeEnabled={false}
        renderTabBar={() => null}
        navigationState={{ index, routes }}
        renderScene={(renderSceneProps) => renderScene({ ...renderSceneProps, onTermsConfirmPress })}
        onIndexChange={onUpdateIndex}
        initialLayout={{ width: Layout.window.width }}
      />
    </Container>
  );
};

type RenderScene = {
  route: Route;
  jumpTo: (key: typeof routeNames[number]) => void;
  onTermsConfirmPress: () => void;
};

const renderScene = (props: RenderScene) => {
  const { route, jumpTo, onTermsConfirmPress } = props;

  switch (route.key) {
    case 'first':
      return <TutorialFirstPage onPressRight={() => jumpTo('second')} />;
    case 'second':
      return <TutorialSecondPage onPressLeft={() => jumpTo('first')} onPressRight={() => jumpTo('third')} />;
    case 'third':
      return <TutorialThirdPage onPressLeft={() => jumpTo('second')} onPressRight={onTermsConfirmPress} />;
    default:
      return null;
  }
};

const styles = StyleSheet.create({
  container: styleType({
    height: Dimensions.get('window').height - NAVIGATION_HEIGHT,
    paddingBottom: 0,
  }),
});
