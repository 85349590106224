import { useAtomValue } from 'jotai';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import type { PastSmokingHabitSchema } from '@/formSchemas/lifeStyleSchema';
import { useGetLifeStyleQuestionnaireQuery } from '@/hooks/GetQuestionnaire.generated';
import { useQuestionnaire } from '@/hooks/useQuestionnaire';
import { lifeStyleFormValuesAtom } from '@/store/formValues';
import type { PastSmokingHabitFields } from '@/types/questionnaire/lifeStyle';

export const usePastSmokingHabit = () => {
  const [getLifeStyleQuestionnaireResult] = useGetLifeStyleQuestionnaireQuery();
  const { data, fetching, error } = getLifeStyleQuestionnaireResult;

  const formValues = useAtomValue(lifeStyleFormValuesAtom);

  const { getQuestionnaireSubItem, perpetuateLifeStyleFormValues } = useQuestionnaire();

  const questionnaireItem = useMemo(
    () => data && getQuestionnaireSubItem(data, 'HAS_SMOKING_HISTORY', 'PAST_SMOKING_HABIT'),
    [data, getQuestionnaireSubItem]
  );

  const fields: PastSmokingHabitFields | undefined = (() => {
    const numberOfCigarettesPerDayPast = questionnaireItem?.forms.find(
      (form) => form.name === 'numberOfCigarettesPerDayPast'
    );
    const yearsOfSmokingPast = questionnaireItem?.forms.find((form) => form.name === 'yearsOfSmokingPast');

    if (
      numberOfCigarettesPerDayPast?.__typename === 'InputTypeForm' &&
      yearsOfSmokingPast?.__typename === 'InputTypeForm'
    ) {
      return {
        numberOfCigarettesPerDayPast: {
          title: numberOfCigarettesPerDayPast.title || '',
          value: formValues?.numberOfCigarettesPerDayPast?.toString() || numberOfCigarettesPerDayPast.value || '',
          placeholder: numberOfCigarettesPerDayPast.placeholder || '',
          unit: numberOfCigarettesPerDayPast.unit || '',
        },
        yearsOfSmokingPast: {
          title: yearsOfSmokingPast.title || '',
          value: formValues?.yearsOfSmokingPast?.toString() || yearsOfSmokingPast.value || '',
          placeholder: yearsOfSmokingPast.placeholder || '',
          unit: yearsOfSmokingPast.unit || '',
        },
      };
    }
    return undefined;
  })();

  const navigate = useNavigate();

  const handleSubmit = useCallback(
    (values: PastSmokingHabitSchema) => {
      perpetuateLifeStyleFormValues(values);

      navigate('/questionnaire/has-habit-of-walking');
    },
    [perpetuateLifeStyleFormValues, navigate]
  );

  return {
    data,
    fetching,
    error,
    questionnaireItem,
    fields,
    handleSubmit,
  };
};
