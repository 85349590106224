import { useCallback, useEffect } from 'react';

import { useAuth } from '@/features/registration/hooks/useAuth';
import { useCreateUserMutation } from '@/features/registration/hooks/useAuth/CreateUser.generated';
import { useLoginMutation } from '@/hooks/Login.generated';
import { Logger } from '@/lib/Logger';
import { LoginEvent } from '@/lib/Logger/events';
import { hasGraphQLServerError } from '@/utils/hasGraphQlError';

import { useUserStatus } from './useUserStatus';

export const useInitializer = () => {
  const { authStatus } = useAuth();
  const { userStatus, getUserStatus } = useUserStatus();
  const [loginResult, login] = useLoginMutation();
  const [createUserResult, createUser] = useCreateUserMutation();

  const loginOperation = useCallback(() => {
    login({})
      .then((res) => {
        if (res.data) {
          loginLogger(res.data.login.userId);
        } else if (res.error) {
          if (hasGraphQLServerError(res.error)) {
            Logger.logException(res.error.message);
          }
        }
      })
      .catch((e) => Logger.logException(e));
  }, [login]);

  const createUserOperation = useCallback(() => {
    createUser({})
      .then((res) => {
        if (res.data) {
          loginLogger(res.data.createUser.id);
        } else if (res.error) {
          if (hasGraphQLServerError(res.error)) {
            Logger.logException(res.error.message);
          }
        }
      })
      .catch((e) => Logger.logException(e));
  }, [createUser]);

  const initializeOperation = useCallback(() => {
    if (userStatus.data) {
      if (userStatus.data.getUserStatus.isCreatedUser) {
        if (authStatus === 'authenticatedWithSso') {
          loginOperation();
        }
      } else {
        createUserOperation();
      }
    } else if (userStatus.error) {
      if (hasGraphQLServerError(userStatus.error)) {
        Logger.logException(userStatus.error.message);
      }
    }
  }, [userStatus, authStatus, createUserOperation, loginOperation]);

  useEffect(() => {
    if (authStatus === 'authenticated' || authStatus === 'authenticatedWithSso') {
      getUserStatus();
    }
  }, [authStatus, getUserStatus]);

  useEffect(() => {
    if (userStatus.data && !loginResult.data && !createUserResult.data) {
      initializeOperation();
    }
  }, [userStatus.data, loginResult.data, createUserResult.data, initializeOperation]);

  return { authStatus, userStatus: userStatus.data?.getUserStatus };
};

const loginLogger = (userId: string) => {
  if (userId) {
    Logger.setUserId(userId);
  }
  Logger.logEvent(LoginEvent());
};
