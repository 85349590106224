import { useAtomValue } from 'jotai';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import type { HowToSpendHolidaysSchema } from '@/formSchemas/environmentSchema';
import { useGetEnvironmentQuestionnaireQuery } from '@/hooks/GetQuestionnaire.generated';
import { useQuestionnaire } from '@/hooks/useQuestionnaire';
import { environmentFormValuesAtom } from '@/store/formValues';
import type { HowToSpendHolidaysFields } from '@/types/questionnaire/environment';

export const useHowToSpendHolidays = () => {
  const [getEnvironmentQuestionnaireResult] = useGetEnvironmentQuestionnaireQuery();
  const { data, fetching, error } = getEnvironmentQuestionnaireResult;

  const environmentFormValues = useAtomValue(environmentFormValuesAtom);

  const { getQuestionnaireItem, createMultiSelectTypeField, getOtherItemSupplement, perpetuateEnvironmentFormValues } =
    useQuestionnaire();

  const questionnaireItem = useMemo(
    () => data && getQuestionnaireItem(data, 'HOW_TO_SPEND_HOLIDAYS'),
    [data, getQuestionnaireItem]
  )!;

  const isAnswerInStorage = useMemo(
    () =>
      [
        environmentFormValues?.housework,
        environmentFormValues?.work,
        environmentFormValues?.childcare,
        environmentFormValues?.reading,
        environmentFormValues?.game,
        environmentFormValues?.watchingTvAndVideos,
        environmentFormValues?.hobby,
        environmentFormValues?.netSurfing,
        environmentFormValues?.otherWays,
      ].includes(true),
    [environmentFormValues]
  );

  const fields: HowToSpendHolidaysFields | undefined = useMemo(
    () =>
      isAnswerInStorage
        ? {
            housework: {
              ...createMultiSelectTypeField(questionnaireItem, 'housework'),
              value: environmentFormValues?.housework || false,
            },
            work: {
              ...createMultiSelectTypeField(questionnaireItem, 'work'),
              value: environmentFormValues?.work || false,
            },
            childcare: {
              ...createMultiSelectTypeField(questionnaireItem, 'childcare'),
              value: environmentFormValues?.childcare || false,
            },
            reading: {
              ...createMultiSelectTypeField(questionnaireItem, 'reading'),
              value: environmentFormValues?.reading || false,
            },
            game: {
              ...createMultiSelectTypeField(questionnaireItem, 'game'),
              value: environmentFormValues?.game || false,
            },
            watchingTvAndVideos: {
              ...createMultiSelectTypeField(questionnaireItem, 'watchingTvAndVideos'),
              value: environmentFormValues?.watchingTvAndVideos || false,
            },
            hobby: {
              ...createMultiSelectTypeField(questionnaireItem, 'hobby'),
              value: environmentFormValues?.hobby || false,
            },
            netSurfing: {
              ...createMultiSelectTypeField(questionnaireItem, 'netSurfing'),
              value: environmentFormValues?.netSurfing || false,
            },
            otherWays: {
              ...createMultiSelectTypeField(questionnaireItem, 'otherWays'),
              value: environmentFormValues?.otherWays || false,
            },
            supplement: {
              ...getOtherItemSupplement(questionnaireItem)!,
              value: environmentFormValues?.otherWaysSupplement || '',
            },
          }
        : {
            housework: createMultiSelectTypeField(questionnaireItem, 'housework'),
            work: createMultiSelectTypeField(questionnaireItem, 'work'),
            childcare: createMultiSelectTypeField(questionnaireItem, 'childcare'),
            reading: createMultiSelectTypeField(questionnaireItem, 'reading'),
            game: createMultiSelectTypeField(questionnaireItem, 'game'),
            watchingTvAndVideos: createMultiSelectTypeField(questionnaireItem, 'watchingTvAndVideos'),
            hobby: createMultiSelectTypeField(questionnaireItem, 'hobby'),
            netSurfing: createMultiSelectTypeField(questionnaireItem, 'netSurfing'),
            otherWays: createMultiSelectTypeField(questionnaireItem, 'otherWays'),
            supplement: getOtherItemSupplement(questionnaireItem)!,
          },
    [questionnaireItem, environmentFormValues, isAnswerInStorage, createMultiSelectTypeField, getOtherItemSupplement]
  );

  const navigate = useNavigate();

  const handleSubmit = useCallback(
    (values: HowToSpendHolidaysSchema) => {
      perpetuateEnvironmentFormValues(values);

      navigate('/questionnaire/number-of-friends');
    },
    [perpetuateEnvironmentFormValues, navigate]
  );

  return {
    fetching,
    data,
    error,
    questionnaireItem,
    fields,
    handleSubmit,
  };
};
