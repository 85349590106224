import { NavigationHeader } from '@/components/NavigationHeader';
import { BuildConfig } from '@/constants/BuildConfig';
import { openURL } from '@/utils/OpenUrl';

import { NotFoundTemplate } from './template';

export const NotFound = () => {
  const handleContactUsListItemPress = () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    openURL(BuildConfig.PEPUP_CONTACT_URL);
  };

  return (
    <>
      <NavigationHeader title="" headerLeft="logo" headerColor="white" />
      <NotFoundTemplate onContactUsListItemPress={handleContactUsListItemPress} />;
    </>
  );
};
