import { useAtomValue } from 'jotai';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import type { HasHabitOfEatDinnerBeforeSleepSchema } from '@/formSchemas/lifeStyleSchema';
import { useGetLifeStyleQuestionnaireQuery } from '@/hooks/GetQuestionnaire.generated';
import { useQuestionnaire } from '@/hooks/useQuestionnaire';
import { lifeStyleFormValuesAtom } from '@/store/formValues';

export const useHasHabitOfEatDinnerBeforeSleep = () => {
  const [getLifeStyleQuestionnaireResult] = useGetLifeStyleQuestionnaireQuery();
  const { data, fetching, error } = getLifeStyleQuestionnaireResult;

  const formValues = useAtomValue(lifeStyleFormValuesAtom);

  const { getQuestionnaireItem, createSelections, getSelectedValue, perpetuateLifeStyleFormValues } =
    useQuestionnaire();

  const questionnaireItem = useMemo(
    () => data && getQuestionnaireItem(data, 'HAS_HABIT_OF_EAT_DINNER_BEFORE_SLEEP'),
    [data, getQuestionnaireItem]
  );
  const selections = useMemo(
    () => questionnaireItem?.forms[0].__typename === 'SelectTypeForm' && createSelections(questionnaireItem.forms[0]),
    [questionnaireItem, createSelections]
  );
  const selectedValue = useMemo(() => {
    if (formValues?.hasHabitOfEatDinnerBeforeSleep) {
      return formValues?.hasHabitOfEatDinnerBeforeSleep;
    }
    if (questionnaireItem?.forms[0].__typename === 'SelectTypeForm') {
      return getSelectedValue(questionnaireItem.forms[0]);
    }
    return undefined;
  }, [questionnaireItem?.forms, getSelectedValue, formValues?.hasHabitOfEatDinnerBeforeSleep]);

  const navigate = useNavigate();

  const handleSubmit = useCallback(
    (values: HasHabitOfEatDinnerBeforeSleepSchema) => {
      perpetuateLifeStyleFormValues(values);

      navigate('/questionnaire/has-habit-of-eat-snack-after-dinner');
    },
    [perpetuateLifeStyleFormValues, navigate]
  );

  return {
    fetching,
    data,
    error,
    questionnaireItem,
    selections,
    selectedValue,
    handleSubmit,
  };
};
