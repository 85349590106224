import { useNavigate } from 'react-router-dom';

export const useQuestionnaireStart = () => {
  const navigate = useNavigate();

  const handlePress = () => navigate('/questionnaire/has-smoking-history');

  return {
    handlePress,
  };
};
