import { type FC, useMemo } from 'react';

import { SelectionButton } from './SelectionButton';
import { SelectionRadio } from './SelectionRadio';

export type Selection = {
  label: string;
  value: number;
};

type SelectionListType = 'button' | 'radio';

type Props = {
  type: SelectionListType;
  onPress: (selection: Selection) => void;
  selections: Selection[];
  selectedValue?: number;
};

export const SelectionList: FC<Props> = (props) => {
  const { type, onPress, selectedValue, selections } = props;
  const Selections = useMemo(
    () => () => renderSelections(selections, type, onPress, selectedValue),
    [selections, type, onPress, selectedValue]
  );

  return <Selections />;
};

export const renderSelections = (
  selections: Selection[],
  type: SelectionListType,
  onPress: (selection: Selection) => void,
  selectedValue: number | undefined
) => (
  <>
    {selections.map((selection, index) => {
      switch (type) {
        case 'button':
          return (
            <SelectionButton
              key={selection.value}
              index={index}
              label={selection.label}
              isActive={selection.value === selectedValue}
              onPress={() => onPress(selection)}
            />
          );
        case 'radio':
          return (
            <SelectionRadio
              key={selection.value}
              index={index}
              label={selection.label}
              isActive={selection.value === selectedValue}
              onPress={() => onPress(selection)}
            />
          );
        default:
          throw new Error('type is undefined or illegal type');
      }
    })}
  </>
);
