import type { FC } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { ForbiddenErrorView } from '@/components/ForbiddenErrorView';
import { LoadingView } from '@/components/LoadingView';
import { NavigationHeader } from '@/components/NavigationHeader';
import { ServerErrorView } from '@/components/ServerErrorView';
import { BuildConfig } from '@/constants/BuildConfig';
import { type AuthStatus } from '@/features/registration/hooks/useAuth';
import type { UserStatus } from '@/types/graphql';
import { openURL } from '@/utils/OpenUrl';

import { AnswersCompletedRoutes } from './AnswersCompletedRoutes';
import { AnswersNotCompletedRoutes } from './AnswersNotCompletedRoutes';

type Props = {
  userStatus: Omit<UserStatus, '__typename'> | undefined;
  authStatus: AuthStatus;
};

export const Navigation: FC<Props> = (props) => {
  const { userStatus, authStatus } = props;

  return (
    <Router>
      {authStatus === 'fetching' ? (
        <LoadingView />
      ) : authStatus === 'unauthorized' || authStatus === 'unavailable' ? (
        <InitializeFailedView authStatus={authStatus} />
      ) : userStatus ? (
        userStatus.isCompleted ? (
          <AnswersCompletedRoutes />
        ) : (
          <AnswersNotCompletedRoutes userStatus={userStatus} />
        )
      ) : null}
    </Router>
  );
};

export const InitializeFailedView = ({ authStatus }: { authStatus: AuthStatus }) => {
  const handleContactUsListItemPress = () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    openURL(BuildConfig.PEPUP_CONTACT_URL);
  };

  return (
    <>
      <NavigationHeader title="" headerLeft="logo" headerColor="white" />
      {authStatus === 'unauthorized' ? (
        <ForbiddenErrorView />
      ) : (
        <ServerErrorView onContactUsListItemPress={handleContactUsListItemPress} />
      )}
    </>
  );
};
