import type { ComponentProps, Ref } from 'react';
import { forwardRef } from 'react';
import type { TextInput as RNTextInput, TextStyle, ViewStyle } from 'react-native';
import { StyleSheet, View } from 'react-native';
import { TextInput as RNPTextInput } from 'react-native-paper';

import { Colors } from '@/constants/Colors';
import { styleType } from '@/utils/styleType';

import { Text } from '../Text';

type Props = ComponentProps<typeof RNTextInput> & {
  unit?: string;
  disabled?: boolean;
};

export const TextInput = forwardRef((props: Props, ref: Ref<RNTextInput>) => {
  const { placeholder, value, unit, onChangeText, keyboardType, disabled, style } = props;

  return (
    <View style={[styles.container, style]}>
      <RNPTextInput
        ref={ref}
        mode="flat"
        placeholder={placeholder}
        value={value}
        style={styles.text}
        onChangeText={onChangeText}
        keyboardType={keyboardType}
        underlineColor="transparent"
        activeUnderlineColor={Colors.healthactionGreen}
        disabled={disabled}
      />
      {unit ? <Text.Body style={styles.unit}>{unit}</Text.Body> : null}
    </View>
  );
});

const styles = StyleSheet.create({
  container: styleType<ViewStyle>({
    flexDirection: 'row',
    alignItems: 'center',
  }),
  text: styleType<TextStyle>({
    flexGrow: 1,
    fontFamily: 'Noto Sans JP',
    fontSize: 14,
    paddingHorizontal: 16,
    height: 56, // 一旦MDGに合わせてる。デザインの52に合わせるか要確認
  }),
  unit: styleType<TextStyle>({
    fontSize: 14,
    marginLeft: 8,
  }),
});
