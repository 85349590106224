import { useAtom } from 'jotai';
import { useCallback } from 'react';
import { type FieldValues } from 'react-hook-form';

import {
  type GetLifeStyleQuestionnaireQuery,
  type QuestionnaireItemFieldsFragment,
  type SelectTypeFormFieldsFragment,
} from '@/hooks/GetQuestionnaire.generated';
import { Logger } from '@/lib/Logger';
import { environmentFormValuesAtom, lifeStyleFormValuesAtom, medicalHistoryFormValuesAtom } from '@/store/formValues';
import { type QuestionnaireItemName } from '@/types/graphql';
import { type HowToSpendHolidaysFields } from '@/types/questionnaire/environment';
import {
  type BrainFields,
  type CancerFields,
  type HeartFields,
  type KidneyFields,
  type LiverFields,
  type LungFields,
  type OtherDiseaseFields,
} from '@/types/questionnaire/medicalHistory';

type MultiSelectTypeField =
  | keyof HowToSpendHolidaysFields
  | keyof BrainFields
  | keyof HeartFields
  | keyof LungFields
  | keyof LiverFields
  | keyof KidneyFields
  | keyof CancerFields
  | keyof OtherDiseaseFields;

export const useQuestionnaire = () => {
  const [lifeStyleFormValues, setLifeStyleFormValues] = useAtom(lifeStyleFormValuesAtom);
  const [environmentFormValues, setEnvironmentFormValues] = useAtom(environmentFormValuesAtom);
  const [medicalHistoryFormValues, setMedicalHistoryFormValues] = useAtom(medicalHistoryFormValuesAtom);

  const getQuestionnaireItem = useCallback(
    (data: GetLifeStyleQuestionnaireQuery, itemName: QuestionnaireItemName) =>
      data?.getQuestionnaire.items.find((item) => item.name === itemName),
    []
  );

  const getQuestionnaireSubItem = useCallback(
    (data: GetLifeStyleQuestionnaireQuery, itemName: QuestionnaireItemName, subitemName: QuestionnaireItemName) =>
      getQuestionnaireItem(data, itemName)?.subItems?.find((item) => item.name === subitemName),
    [getQuestionnaireItem]
  );

  const createSelections = useCallback(
    (form: SelectTypeFormFieldsFragment) =>
      form.items
        .map((item) => (item.__typename === 'SelectTypeItem' ? { label: item.label, value: item.value } : undefined))
        .filter((item): item is Exclude<typeof item, undefined> => item !== undefined),
    []
  );

  const getSelectedValue = useCallback(
    (form: SelectTypeFormFieldsFragment) => form.items.filter((i) => i.selected).pop()?.value,
    []
  );

  const getMultiSelectTypeItem = useCallback(
    (item: QuestionnaireItemFieldsFragment, fieldName: string) =>
      item.forms
        .flatMap((f) => (f.__typename === 'SelectTypeForm' ? f.items : undefined))
        .filter((i) => (i?.__typename === 'MultiSelectTypeItem' ? i.name === fieldName : undefined))
        .pop(),
    []
  );

  const createMultiSelectTypeField = useCallback(
    (item: QuestionnaireItemFieldsFragment, fieldName: MultiSelectTypeField) => {
      const multiSelectTypeItem = getMultiSelectTypeItem(item, fieldName);

      return {
        label: multiSelectTypeItem?.label || '',
        value: multiSelectTypeItem?.selected || false,
      };
    },
    [getMultiSelectTypeItem]
  );

  const createMultiSelectTypeOtherItemSupplementField = useCallback(
    (item: QuestionnaireItemFieldsFragment, fieldName: string) => {
      const multiSelectTypeItem = getMultiSelectTypeItem(item, fieldName);

      return {
        value: multiSelectTypeItem?.otherItemSupplement?.value || '',
        placeholder: multiSelectTypeItem?.otherItemSupplement?.placeholder || '',
      };
    },
    [getMultiSelectTypeItem]
  );

  const getOtherItemSupplement = useCallback(
    (item: QuestionnaireItemFieldsFragment) =>
      item.forms
        .flatMap((f) => (f.__typename === 'SelectTypeForm' ? f.items : undefined))
        .map((i) => ({
          value: i?.otherItemSupplement?.value || '',
          placeholder: i?.otherItemSupplement?.placeholder || '',
        }))
        .pop(),
    []
  );

  const perpetuateLifeStyleFormValues = useCallback(
    (value: FieldValues) => {
      setLifeStyleFormValues({ ...lifeStyleFormValues!, ...value }).catch((e) => Logger.logException(e));
    },
    [lifeStyleFormValues, setLifeStyleFormValues]
  );

  const perpetuateEnvironmentFormValues = useCallback(
    (value: FieldValues) => {
      setEnvironmentFormValues({ ...environmentFormValues!, ...value }).catch((e) => Logger.logException(e));
    },
    [environmentFormValues, setEnvironmentFormValues]
  );

  const perpetuateMedicalHistoryFormValues = useCallback(
    (value: FieldValues) => {
      setMedicalHistoryFormValues({ ...medicalHistoryFormValues, ...value }).catch((e) => Logger.logException(e));
    },
    [medicalHistoryFormValues, setMedicalHistoryFormValues]
  );

  return {
    getQuestionnaireItem,
    getQuestionnaireSubItem,
    createSelections,
    getSelectedValue,
    createMultiSelectTypeField,
    createMultiSelectTypeOtherItemSupplementField,
    getOtherItemSupplement,
    perpetuateLifeStyleFormValues,
    perpetuateEnvironmentFormValues,
    perpetuateMedicalHistoryFormValues,
  };
};
