import { type FC, type ReactNode } from 'react';
import { type ViewStyle, Dimensions, Platform, StyleSheet, View } from 'react-native';

import { Colors } from '@/constants/Colors';
import { NAVIGATION_HEIGHT } from '@/lib/Navigation';
import { styleType } from '@/utils/styleType';

type ContainerProps = {
  children: ReactNode;
  style?: ViewStyle;
};

export const Container: FC<ContainerProps> = (props) => {
  const { children, style } = props;

  return (
    <View style={styles.wrapper}>
      <View style={[styles.container, style]}>{children}</View>
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: styleType<ViewStyle>({
    ...Platform.select({
      web: {
        backgroundColor: Colors.gray2,
      },
    }),
  }),
  container: styleType<ViewStyle>({
    backgroundColor: Colors.white100,
    width: '100%',
    paddingBottom: 40,
    ...Platform.select({
      web: {
        minHeight: Dimensions.get('window').height - NAVIGATION_HEIGHT,
        maxWidth: 428, // webはiPhone 13 Pro Maxの画面幅に
        marginHorizontal: 'auto',
      },
    }),
  }),
});
