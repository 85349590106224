import { zodResolver } from '@hookform/resolvers/zod';
import type { FC } from 'react';
import { useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import type { TextStyle, ViewStyle } from 'react-native';
import { StyleSheet } from 'react-native';

import { messages as t } from '@/assets/i18n/ja';
import { Button } from '@/components/Button';
import { CheckboxControlled } from '@/components/CheckboxControlled';
import { Text } from '@/components/Text';
import { TextInputControlled } from '@/components/TextInputControlled';
import { Colors } from '@/constants/Colors';
import type { MedicalHistorySchema } from '@/formSchemas/medicalHistorySchema';
import { medicalHistorySchema } from '@/formSchemas/medicalHistorySchema';
import type { MedicalHistoryFields } from '@/types/questionnaire/medicalHistory';
import { styleType } from '@/utils/styleType';

type Props = {
  question: string;
  fields: MedicalHistoryFields;
  onSubmit: SubmitHandler<MedicalHistorySchema>;
  submitButtonLabel?: string;
};

export const MedicalHistory: FC<Props> = (props) => {
  const {
    question,
    fields: { brain, heart, lung, liver, kidney, cancer, otherDisease },
    onSubmit,
    submitButtonLabel = t.questionnaire.pagingButtonLabel,
  } = props;

  // そのほかが選択されているのに補足がない場合のエラー表示制御用State
  const [supplementError, setSupplementError] = useState(false);

  const {
    formState: { errors },
    handleSubmit,
    control,
    setValue,
    getValues,
    watch,
  } = useForm<MedicalHistorySchema>({
    defaultValues: {
      stroke: brain.stroke.value,
      transientIschemicAttack: brain.transientIschemicAttack.value,
      ischemicHeartDisease: heart.ischemicHeartDisease.value,
      heartFailure: heart.heartFailure.value,
      chronicObstructivePulmonaryDisease: lung.chronicObstructivePulmonaryDisease.value,
      liverCirrhosis: liver.liverCirrhosis.value,
      liverFailure: liver.liverFailure.value,
      dialysis: kidney.dialysis.value,
      cancer: cancer.cancer.value || '',
      highBloodPressure: otherDisease.highBloodPressure.value,
      diabetes: otherDisease.diabetes.value,
      dyslipidemia: otherDisease.dyslipidemia.value,
      otherDisease: otherDisease.otherDisease.value,
      otherDiseaseSupplement: otherDisease.otherDiseaseSupplement.value || '',
    },
    mode: 'onChange',
    resolver: zodResolver(medicalHistorySchema),
  });

  const watchStroke = watch('stroke');
  const watchTransientIschemicAttack = watch('transientIschemicAttack');
  const watchIschemicHeartDisease = watch('ischemicHeartDisease');
  const watchHeartFailure = watch('heartFailure');
  const watchChronicObstructivePulmonaryDisease = watch('chronicObstructivePulmonaryDisease');
  const watchLiverCirrhosis = watch('liverCirrhosis');
  const watchLiverFailure = watch('liverFailure');
  const watchDialysis = watch('dialysis');
  const watchHighBloodPressure = watch('highBloodPressure');
  const watchDiabetes = watch('diabetes');
  const watchDyslipidemia = watch('dyslipidemia');
  const watchOtherDisease = watch('otherDisease');

  const handleSubmitButtonPress = () => {
    if (getValues('otherDisease') && !getValues('otherDiseaseSupplement')) {
      setSupplementError(true);
      return;
    }

    setSupplementError(false);
    handleSubmit(onSubmit)(); // eslint-disable-line @typescript-eslint/no-floating-promises
  };

  return (
    <>
      <Text.Headline>{question}</Text.Headline>

      <Text.Headline style={styles.title}>{brain.title}</Text.Headline>
      <CheckboxControlled
        name="stroke"
        control={control}
        label={brain.stroke.label}
        onPress={() => setValue('stroke', !watchStroke)}
        style={styles.checkboxNotFirst}
      />
      <CheckboxControlled
        name="transientIschemicAttack"
        control={control}
        label={brain.transientIschemicAttack.label}
        onPress={() => setValue('transientIschemicAttack', !watchTransientIschemicAttack)}
        style={styles.checkboxNotFirst}
      />

      <Text.Headline style={styles.title}>{heart.title}</Text.Headline>
      <CheckboxControlled
        name="ischemicHeartDisease"
        control={control}
        label={heart.ischemicHeartDisease.label}
        onPress={() => setValue('ischemicHeartDisease', !watchIschemicHeartDisease)}
        style={styles.checkboxNotFirst}
      />
      <CheckboxControlled
        name="heartFailure"
        control={control}
        label={heart.heartFailure.label}
        onPress={() => setValue('heartFailure', !watchHeartFailure)}
        style={styles.checkboxNotFirst}
      />

      <Text.Headline style={styles.title}>{lung.title}</Text.Headline>
      <CheckboxControlled
        name="chronicObstructivePulmonaryDisease"
        control={control}
        label={lung.chronicObstructivePulmonaryDisease.label}
        onPress={() => setValue('chronicObstructivePulmonaryDisease', !watchChronicObstructivePulmonaryDisease)}
        style={styles.checkboxNotFirst}
      />

      <Text.Headline style={styles.title}>{liver.title}</Text.Headline>
      <CheckboxControlled
        name="liverCirrhosis"
        control={control}
        label={liver.liverCirrhosis.label}
        onPress={() => setValue('liverCirrhosis', !watchLiverCirrhosis)}
        style={styles.checkboxNotFirst}
      />
      <CheckboxControlled
        name="liverFailure"
        control={control}
        label={liver.liverFailure.label}
        onPress={() => setValue('liverFailure', !watchLiverFailure)}
        style={styles.checkboxNotFirst}
      />

      <Text.Headline style={styles.title}>{kidney.title}</Text.Headline>
      <CheckboxControlled
        name="dialysis"
        control={control}
        label={kidney.dialysis.label}
        onPress={() => setValue('dialysis', !watchDialysis)}
        style={styles.checkboxNotFirst}
      />

      <Text.Headline style={styles.title}>{cancer.title}</Text.Headline>
      <TextInputControlled
        placeholder={cancer.cancer.placeholder}
        control={control}
        name="cancer"
        style={styles.textInput}
      />

      <Text.Headline style={styles.title}>{otherDisease.title}</Text.Headline>
      <CheckboxControlled
        name="highBloodPressure"
        control={control}
        label={otherDisease.highBloodPressure.label}
        onPress={() => setValue('highBloodPressure', !watchHighBloodPressure)}
        style={styles.checkboxNotFirst}
      />
      <CheckboxControlled
        name="diabetes"
        control={control}
        label={otherDisease.diabetes.label}
        onPress={() => setValue('diabetes', !watchDiabetes)}
        style={styles.checkboxNotFirst}
      />
      <CheckboxControlled
        name="dyslipidemia"
        control={control}
        label={otherDisease.dyslipidemia.label}
        onPress={() => setValue('dyslipidemia', !watchDyslipidemia)}
        style={styles.checkboxNotFirst}
      />
      <CheckboxControlled
        name="otherDisease"
        control={control}
        label={otherDisease.otherDisease.label}
        onPress={() => setValue('otherDisease', !watchOtherDisease)}
        style={styles.checkboxNotFirst}
      />
      <TextInputControlled
        placeholder={otherDisease.otherDiseaseSupplement.placeholder}
        control={control}
        name="otherDiseaseSupplement"
        disabled={watchOtherDisease !== true}
        style={styles.textInput}
      />
      {supplementError ? (
        <Text.Subtext style={styles.errorText} testID="emptySupplementError">
          {t.validationError.withInOneHundredLength}
        </Text.Subtext>
      ) : null}
      {errors.otherDiseaseSupplement?.message ? (
        <Text.Subtext style={styles.errorText} testID="otherDiseaseSupplementFieldError">
          {errors.otherDiseaseSupplement.message}
        </Text.Subtext>
      ) : null}
      <Button
        onPress={handleSubmitButtonPress}
        type="active"
        accessibilityRole="button"
        accessibilityLabel="次へ"
        style={styles.button}
      >
        {submitButtonLabel}
      </Button>
    </>
  );
};

const styles = StyleSheet.create({
  title: styleType<TextStyle>({
    marginTop: 20,
  }),
  errorText: styleType<TextStyle>({
    color: Colors.systemRed,
    marginTop: 8,
  }),
  textInput: styleType<TextStyle>({
    marginTop: 12,
  }),
  checkboxFirst: styleType<ViewStyle>({
    marginTop: 20,
  }),
  checkboxNotFirst: styleType<ViewStyle>({
    marginTop: 12,
  }),
  button: styleType<ViewStyle>({
    marginTop: 40,
  }),
});
