import { useEffect } from 'react';

import { messages as t } from '@/assets/i18n/ja';
import { NavigationHeader } from '@/components/NavigationHeader';
import notificationsJson from '@/features/notifications/data/notifications.json';
import { useNotifications } from '@/features/notifications/hooks/useNotifications';

import { NotificationsTemplate } from './template';

export const Notifications = () => {
  const { markAllNotificationsAsRead } = useNotifications();

  useEffect(() => {
    markAllNotificationsAsRead();
  }, [markAllNotificationsAsRead]);

  return (
    <>
      <NavigationHeader title={t.navigation.notifications} headerLeft="back" />
      <NotificationsTemplate notifications={notificationsJson} />
    </>
  );
};
