import { type ComponentProps, type FC } from 'react';

import { CurrentSmokingHabit } from '@/components/questionnaire/form/lifeStyle';

import { QuestionnaireContainer } from '../parts/QuestionnaireContainer';
import { type QuestionNumber } from '../parts/QuestionNumber';

type Props = Omit<ComponentProps<typeof QuestionNumber>, 'style'> & ComponentProps<typeof CurrentSmokingHabit>;

export const CurrentSmokingHabitTemplate: FC<Props> = (props) => {
  const { questionNumber, allQuestionNumber, question, fields, onSubmit } = props;

  return (
    <QuestionnaireContainer questionNumber={questionNumber} allQuestionNumber={allQuestionNumber}>
      <CurrentSmokingHabit question={question} fields={fields} onSubmit={onSubmit} />
    </QuestionnaireContainer>
  );
};
