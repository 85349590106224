import type { FC } from 'react';
import type { TextStyle, ViewStyle } from 'react-native';
import { StyleSheet, View } from 'react-native';

import { Text } from '@/components/Text';
import { Colors } from '@/constants/Colors';
import { styleType } from '@/utils/styleType';

type Props = {
  title: string;
  text: string;
};

export const ListItem: FC<Props> = (props) => {
  const { title, text } = props;

  return (
    <View style={styles.container}>
      <Text.Body style={styles.title}>{title}</Text.Body>
      <Text.Body style={styles.text}>{text}</Text.Body>
    </View>
  );
};

const styles = StyleSheet.create({
  container: styleType<ViewStyle>({
    flexDirection: 'row',
    marginBottom: 8,
  }),
  title: styleType<TextStyle>({
    color: Colors.black100,
    flex: 1,
    lineHeight: 26,
  }),
  text: styleType<TextStyle>({
    color: Colors.black100,
    flex: 2,
    lineHeight: 26,
  }),
});
