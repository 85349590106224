import { messages as t } from '@/assets/i18n/ja';
import { LoadingView } from '@/components/LoadingView';
import { NavigationHeader } from '@/components/NavigationHeader';
import { ServerErrorView } from '@/components/ServerErrorView';
import { BuildConfig } from '@/constants/BuildConfig';
import { useSmokingHistoryEdit } from '@/hooks/useSmokingHistoryEdit';
import { openURL } from '@/utils/OpenUrl';

import { HasSmokingHabitTemplate } from './template';

export const HasSmokingHabit = () => {
  const { fetching, currentFormName, hasSmokingHabit, currentSmokingHabit, pastSmokingHabit, hasError } =
    useSmokingHistoryEdit();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const handleContactUsListItemPress = () => openURL(BuildConfig.PEPUP_CONTACT_URL);

  if (fetching) return <LoadingView />;

  return (
    <>
      <NavigationHeader title={t.navigation.editAnswers} headerLeft="back" />
      {hasError || !hasSmokingHabit.selections || !currentSmokingHabit.fields || !pastSmokingHabit.fields ? (
        <ServerErrorView onContactUsListItemPress={handleContactUsListItemPress} />
      ) : (
        <HasSmokingHabitTemplate
          currentFormName={currentFormName || 'HAS_SMOKING_HABIT'}
          // バラさないで渡すとtypeガードが効かないよう
          hasSmokingHabit={{ ...hasSmokingHabit, selections: hasSmokingHabit.selections }}
          currentSmokingHabit={{ ...currentSmokingHabit, fields: currentSmokingHabit.fields }}
          pastSmokingHabit={{ ...pastSmokingHabit, fields: pastSmokingHabit.fields }}
        />
      )}
    </>
  );
};
