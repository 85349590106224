import { type FC } from 'react';

import { Colors } from '@/constants/Colors';

type Props = {
  size?: number;
  fill?: string;
};

export const RankThird: FC<Props> = (props) => {
  const { size = 24, fill = Colors.black100 } = props;

  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="transparent" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2_308)">
        <path
          d="M1 3.73913L7.31053 5.74783L12 1L16.6895 5.74783L23 3.73913L21.3789 22H2.62105L1 3.73913Z"
          fill={fill}
        />
        <path
          d="M11.7369 18.168C13.4409 18.168 14.8689 17.232 14.8689 15.6C14.8689 14.424 14.1009 13.692 13.0929 13.404V13.344C14.0409 12.984 14.5689 12.276 14.5689 11.328C14.5689 9.804 13.4049 8.964 11.6889 8.964C10.6569 8.964 9.80494 9.384 9.03694 10.044L9.93694 11.136C10.4649 10.644 10.9809 10.344 11.6169 10.344C12.3729 10.344 12.8049 10.764 12.8049 11.46C12.8049 12.252 12.2769 12.804 10.6449 12.804V14.076C12.5769 14.076 13.0929 14.616 13.0929 15.492C13.0929 16.284 12.4809 16.74 11.5689 16.74C10.7649 16.74 10.1289 16.332 9.60094 15.816L8.76094 16.932C9.38494 17.652 10.3329 18.168 11.7369 18.168Z"
          fill="white"
        />
      </g>

      <defs>
        <clipPath id="clip0_2_308">
          <rect width={size} height={size} fill="transparent" transform="matrix(-1 0 0 1 24 0)" />
        </clipPath>
      </defs>
    </svg>
  );
};
