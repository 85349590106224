import type { FC, ReactNode } from 'react';
import { DefaultTheme, Provider } from 'react-native-paper';

type Props = {
  children: ReactNode;
};

export const UIKitProvider: FC<Props> = (props) => {
  const { children } = props;

  return <Provider theme={DefaultTheme}>{children}</Provider>;
};
