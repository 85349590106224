import { messages as t } from '@/assets/i18n/ja';
import { NavigationHeader } from '@/components/NavigationHeader';
import { useQuestionnaireStart } from '@/features/questionnaire/hooks/useQuestionnaireStart';

import { QuestionnaireStartTemplate } from './template';

export const QuestionnaireStart = () => {
  const { handlePress } = useQuestionnaireStart();

  return (
    <>
      <NavigationHeader title={t.navigation.healthyLifespanMeasurement} headerLeft="back" />
      <QuestionnaireStartTemplate onPress={handlePress} />;
    </>
  );
};
