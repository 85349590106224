import { graphql } from 'msw';

import { mockCreateOrUpdateAnswer } from './resolvers/mockCreateOrUpdateAnswer';
import { mockCreateUser } from './resolvers/mockCreateUser';
import { mockGenerateAccessToken } from './resolvers/mockGenerateAccessToken';
import { mockGetEnvironmentQuestionnaire } from './resolvers/mockGetEnvironmentQuestionnaire';
import { mockGetLifeStyleQuestionnaire } from './resolvers/mockGetLifeStyleQuestionnaire';
import { mockGetMedicalHistoryQuestionnaire } from './resolvers/mockGetMedicalHistoryQuestionnaire';
import { mockLogin } from './resolvers/mockLogin';
import { mockLogout } from './resolvers/mockLogout';
import { mockPredictHealthyLifeSpan } from './resolvers/mockPredictHealthyLifeSpan';
import { mockUserStatus } from './resolvers/mockUserStatus';
import { mockVerifyAccessToken } from './resolvers/mockVerifyAccessToken';

export const handlers = [
  graphql.mutation('GenerateAccessToken', mockGenerateAccessToken),
  graphql.mutation('Login', mockLogin),
  graphql.mutation('Logout', mockLogout),
  graphql.mutation('CreateUser', mockCreateUser),
  graphql.mutation('VerifyAccessToken', mockVerifyAccessToken),
  graphql.mutation('CreateOrUpdateLifeStyleAnswer', mockCreateOrUpdateAnswer),
  graphql.mutation('CreateOrUpdateEnvironmentAnswer', mockCreateOrUpdateAnswer),
  graphql.mutation('CreateOrUpdateMedicalHistoryAnswer', mockCreateOrUpdateAnswer),
  graphql.query('PredictHealthyLifeSpan', mockPredictHealthyLifeSpan),
  graphql.query('GetUserStatus', mockUserStatus),
  graphql.query('GetLifeStyleQuestionnaire', mockGetLifeStyleQuestionnaire),
  graphql.query('GetEnvironmentQuestionnaire', mockGetEnvironmentQuestionnaire),
  graphql.query('GetMedicalHistoryQuestionnaire', mockGetMedicalHistoryQuestionnaire),
];
