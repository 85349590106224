import * as Types from '../../../../types/graphql';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GenerateAccessTokenMutationVariables = Types.Exact<{
  authorizationCode: Types.Scalars['String'];
}>;


export type GenerateAccessTokenMutation = { __typename?: 'Mutation', generateAccessToken: { __typename?: 'GenerateAccessTokenPayload', token: string, expiresAt: any } };


export const GenerateAccessTokenDocument = gql`
    mutation GenerateAccessToken($authorizationCode: String!) {
  generateAccessToken(authorizationCode: $authorizationCode) {
    token
    expiresAt
  }
}
    `;

export function useGenerateAccessTokenMutation() {
  return Urql.useMutation<GenerateAccessTokenMutation, GenerateAccessTokenMutationVariables>(GenerateAccessTokenDocument);
};