import { type ComponentProps } from 'react';
import { type FieldValues, type UseControllerProps, Controller } from 'react-hook-form';

import { Checkbox } from '@/components/Checkbox';

type Props<T extends FieldValues = FieldValues> = UseControllerProps<T> &
  Omit<ComponentProps<typeof Checkbox>, 'isActive'>;

export const CheckboxControlled = <T extends FieldValues>(props: Props<T>) => {
  const { name, control, label, onPress, style } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value } }) => <Checkbox label={label} isActive={value} onPress={onPress} style={style} />}
    />
  );
};
