import type { FC } from 'react';

import { Colors } from '@/constants/Colors';

type Props = {
  size?: number;
  fill?: string;
  stroke?: string;
};

export const CheckCircle: FC<Props> = (props) => {
  const { size = 20, fill = Colors.healthactionGreen, stroke = 'transparent' } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill={fill} stroke={stroke} viewBox="0 0 60 60">
      <path d="M30.0002 59.1666C13.8914 59.1666 0.833496 46.1087 0.833496 30C0.833496 13.8912 13.8914 0.833313 30.0002 0.833313C46.1089 0.833313 59.1668 13.8912 59.1668 30C59.1668 46.1087 46.1089 59.1666 30.0002 59.1666ZM27.0922 41.6666L47.7131 21.0429L43.5889 16.9187L27.0922 33.4183L18.841 25.1671L14.7168 29.2912L27.0922 41.6666Z" />
    </svg>
  );
};
