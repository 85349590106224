import { type FC } from 'react';

import { Colors } from '@/constants/Colors';

type Props = {
  size?: number;
  fill?: string;
};

export const RankFifth: FC<Props> = (props) => {
  const { size = 24, fill = Colors.black100 } = props;

  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="transparent" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2_308)">
        <path
          d="M1 3.73913L7.31053 5.74783L12 1L16.6895 5.74783L23 3.73913L21.3789 22H2.62105L1 3.73913Z"
          fill={fill}
        />
        <path
          d="M11.7729 18.168C13.4049 18.168 14.8809 17.028 14.8809 15.06C14.8809 13.116 13.6329 12.24 12.1449 12.24C11.7369 12.24 11.4249 12.324 11.0769 12.48L11.2449 10.596H14.4729V9.12H9.72094L9.46894 13.428L10.2849 13.956C10.8009 13.62 11.0889 13.488 11.6169 13.488C12.5049 13.488 13.1049 14.064 13.1049 15.096C13.1049 16.152 12.4689 16.74 11.5329 16.74C10.7169 16.74 10.0809 16.332 9.58894 15.84L8.76094 16.956C9.43294 17.616 10.3689 18.168 11.7729 18.168Z"
          fill="white"
        />
      </g>

      <defs>
        <clipPath id="clip0_2_308">
          <rect width={size} height={size} fill="transparent" transform="matrix(-1 0 0 1 24 0)" />
        </clipPath>
      </defs>
    </svg>
  );
};
