import AsyncStorage from '@react-native-async-storage/async-storage';
import { atomWithStorage, createJSONStorage } from 'jotai/utils';

import type {
  CreateOrUpdateEnvironmentAnswerMutationVariables,
  CreateOrUpdateLifeStyleAnswerMutationVariables,
  CreateOrUpdateMedicalHistoryAnswerMutationVariables,
} from '@/hooks/CreateOrUpdateAnswer.generated';

const lifeStyleFormValuesStorage = createJSONStorage<CreateOrUpdateLifeStyleAnswerMutationVariables | undefined>(
  () => AsyncStorage
);

const lifeStyleFormValuesContent = undefined;

export const lifeStyleFormValuesAtom = atomWithStorage<CreateOrUpdateLifeStyleAnswerMutationVariables | undefined>(
  'lifeStyleFormValues',
  lifeStyleFormValuesContent,
  lifeStyleFormValuesStorage
);

const environmentFormValuesStorage = createJSONStorage<CreateOrUpdateEnvironmentAnswerMutationVariables | undefined>(
  () => AsyncStorage
);

const environmentFormValuesContent = undefined;

export const environmentFormValuesAtom = atomWithStorage<CreateOrUpdateEnvironmentAnswerMutationVariables | undefined>(
  'environmentFormValues',
  environmentFormValuesContent,
  environmentFormValuesStorage
);

const medicalHistoryFormValuesStorage = createJSONStorage<
  CreateOrUpdateMedicalHistoryAnswerMutationVariables | undefined
>(() => AsyncStorage);

const medicalHistoryFormValuesContent = undefined;

export const medicalHistoryFormValuesAtom = atomWithStorage<
  CreateOrUpdateMedicalHistoryAnswerMutationVariables | undefined
>('medicalHistoryFormValues', medicalHistoryFormValuesContent, medicalHistoryFormValuesStorage);
