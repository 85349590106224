import { type TextStyle, type ViewStyle, StyleSheet, View } from 'react-native';

import { type Disease } from '@/assets/i18n';
import { messages as t } from '@/assets/i18n/ja';
import { Container } from '@/components/Container';
import { Text } from '@/components/Text';
import { Colors } from '@/constants/Colors';
import { organTypeToText } from '@/features/home/utils/OrganTypeToText';
import { type HealthyLifeSpan } from '@/types/graphql';
import { styleType } from '@/utils/styleType';

import { type OrganInfo } from '../../types/Organ';
import { HealthLimit } from '../parts/HealthLimit';

type Props = {
  healthLifeSpan: HealthyLifeSpan;
  organInfo: OrganInfo;
  isComparisonOfSameGenerationDisplayed: boolean;
};

export const OrganDetailTemplate: React.FC<Props> = (props) => {
  const { healthLifeSpan, organInfo, isComparisonOfSameGenerationDisplayed } = props;

  return (
    <Container style={styles.container}>
      <HealthLimit
        healthLifeSpan={healthLifeSpan}
        organInfo={organInfo}
        isComparisonOfSameGenerationDisplayed={isComparisonOfSameGenerationDisplayed}
      />
      <View style={styles.contentArea}>
        <Text.Headline style={styles.diseasesListTitle}>
          {organTypeToText(organInfo.organ)}
          {t.home.organDetail.title}
        </Text.Headline>
        {DiseaseItems(getOrganDiseasesTexts(organInfo.organ))}
      </View>
    </Container>
  );
};

const getOrganDiseasesTexts = (organ: string) => {
  switch (organ) {
    case 'Heart':
      return [
        t.home.organDetail.heart.anginaPectoris,
        t.home.organDetail.heart.myocardialInfarction,
        t.home.organDetail.heart.heartFailure,
      ];
    case 'Brain':
      return [
        t.home.organDetail.brain.transientIschemicAttack,
        t.home.organDetail.brain.subarachnoidHemorrhage,
        t.home.organDetail.brain.cerebralInfarction,
      ];
    case 'Liver':
      return [t.home.organDetail.liver.compensatedCirrhosis, t.home.organDetail.liver.liverFailure];
    case 'Kidney':
      return [t.home.organDetail.kidney.dialysis];
    case 'Lung':
      return [t.home.organDetail.lung.chronicObstructivePulmonaryDisease];
  }
  return [];
};

const DiseaseItems = (organ: Array<Disease>) =>
  organ.map((disease) => (
    <View style={styles.diseaseContainer}>
      <Text.Title3 style={styles.diseaseName}>{disease.diseaseName}</Text.Title3>
      <Text.BoldBody style={styles.text}>{t.home.organDetail.commonTendenciesTitle}</Text.BoldBody>
      <Text.Body style={styles.text}>{disease.commonTendencies}</Text.Body>

      <Text.BoldBody style={styles.text}>{t.home.organDetail.recommendedPreventionTitle}</Text.BoldBody>

      {disease.recommendedPreventionPoints.map((point, index) => (
        <Text.Body style={styles.text}>
          <Text.Body style={styles.pointsList}>{index + 1}.</Text.Body>
          <Text.Body>{point}</Text.Body>
        </Text.Body>
      ))}
    </View>
  ));

const styles = StyleSheet.create({
  container: styleType<ViewStyle>({
    backgroundColor: Colors.gray1,
  }),
  contentArea: styleType<ViewStyle>({
    paddingHorizontal: 20,
    marginBottom: 5,
    backgroundColor: Colors.white100,
  }),
  diseaseContainer: styleType<ViewStyle>({
    padding: 20,
    marginBottom: 24,
    backgroundColor: Colors.gray1,
    borderRadius: 8,
  }),
  diseasesListTitle: styleType<TextStyle>({
    marginTop: 32,
    marginBottom: 24,
  }),
  diseaseName: styleType<TextStyle>({
    marginBottom: 16,
  }),
  pointsList: styleType<TextStyle>({
    paddingHorizontal: 4,
  }),
  text: styleType<TextStyle>({
    marginBottom: 12,
  }),
});
