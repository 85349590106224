import { useEffect } from 'react';

import { messages as t } from '@/assets/i18n/ja';
import { LoadingView } from '@/components/LoadingView';
import { NavigationHeader } from '@/components/NavigationHeader';
import { ServerErrorView } from '@/components/ServerErrorView';
import { BuildConfig } from '@/constants/BuildConfig';
import { MEDICAL_HISTORY } from '@/features/questionnaire/constants/QuestionnaireGroupName';
import { useMedicalHistory } from '@/hooks/useMedicalHistory';
import { Logger } from '@/lib/Logger';
import { QuestionnaireBeginEvent, QuestionnaireIndexEvent } from '@/lib/Logger/events';
import { hasGraphQLServerError } from '@/utils/hasGraphQlError';
import { openURL } from '@/utils/OpenUrl';

import { MedicalHistoryTemplate } from './template';

export const MedicalHistory = () => {
  const { fetching, data, error, questionnaireItem, fields, handleSubmit } = useMedicalHistory();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const handleContactUsListItemPress = () => openURL(BuildConfig.PEPUP_CONTACT_URL);

  useEffect(() => {
    Logger.logEvent(QuestionnaireBeginEvent({ group: MEDICAL_HISTORY }));

    if (questionnaireItem)
      Logger.logEvent(QuestionnaireIndexEvent({ group: MEDICAL_HISTORY, index: questionnaireItem.displayNumber }));
  }, [questionnaireItem]);

  useEffect(() => {
    if (error && hasGraphQLServerError(error)) {
      Logger.logException(error.message);
    }
  }, [error]);

  if (fetching) return <LoadingView />;

  return (
    <>
      <NavigationHeader title={t.navigation.healthyLifespanMeasurement} />
      {error || !data || !questionnaireItem || !fields ? (
        <ServerErrorView onContactUsListItemPress={handleContactUsListItemPress} />
      ) : (
        <MedicalHistoryTemplate
          questionNumber={questionnaireItem.displayNumber}
          allQuestionNumber={data.getQuestionnaire.items.length.toString()}
          question={questionnaireItem?.question}
          fields={fields}
          onSubmit={handleSubmit}
        />
      )}
    </>
  );
};
