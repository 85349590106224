import { type ComponentProps } from 'react';
import { type FieldValues, type UseControllerProps, Controller } from 'react-hook-form';
import { type TextStyle } from 'react-native';

import { TextInput } from '@/components/TextInput';

type Props<T extends FieldValues = FieldValues> = UseControllerProps<T> & {
  placeholder?: string;
  unit?: string;
  keyboardType?: ComponentProps<typeof TextInput>['keyboardType'];
  disabled?: boolean;
  style?: TextStyle;
};

export const TextInputControlled = <T extends FieldValues>(props: Props<T>) => {
  const { name, control, placeholder, unit, keyboardType, disabled, style } = props;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, ref } }) => (
        <TextInput
          onChangeText={onChange}
          value={value}
          ref={ref}
          placeholder={placeholder}
          unit={unit}
          keyboardType={keyboardType}
          style={style}
          disabled={disabled}
        />
      )}
    />
  );
};
