import React from 'react';

import { messages as t } from '@/assets/i18n/ja';
import { NavigationHeader } from '@/components/NavigationHeader';

import { AboutSpecialistSupervisionTemplate } from './template';

export const AboutSpecialistSupervision: React.FC = () => (
  <>
    <NavigationHeader title={t.navigation.aboutSpecialistSupervision} headerLeft="back" />
    <AboutSpecialistSupervisionTemplate />
  </>
);
