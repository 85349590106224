import { type ComponentProps } from 'react';
import { type NavigateFunction } from 'react-router-dom';

import type { HealthyLifeSpan } from '@/types/graphql';
import { byNumberAscending } from '@/utils/sort/byNumberAscending';

import { type OrganDetailTemplate } from '../../components/OrganDetail/template';
import { type OrganRankingItem } from '../../components/parts/OrganRankingItem';
import type { OrganInfo } from '../../types/Organ';

export type HealthyLifeSpanItem = HealthyLifeSpan & { organInfo: OrganInfo };

export const createOrganRankingItems = (items: HealthyLifeSpanItem[], navigate: NavigateFunction) => {
  const result: ComponentProps<typeof OrganRankingItem>[] = [];
  const isComparisonOfSameGenerationDisplayed = !items.map((item) => item.isAdjusted).includes(true);

  let prevDisplayRankNumber = 0;
  let prevAgeYear: number;
  let prevAgeMonth: number;

  sortOrganRankingItems(items).forEach((item, index) => {
    const { age, remaining, isAdjusted, comparisonOfSameGeneration, organInfo } = item;

    const isSameAsPrevItemAge = prevAgeYear === age.year && prevAgeMonth === age.month;
    const displayRankNumber = isSameAsPrevItemAge ? prevDisplayRankNumber : index + 1;

    if (!isSameAsPrevItemAge) {
      prevDisplayRankNumber++;
      prevAgeYear = age.year;
      prevAgeMonth = age.month;
    }

    const healthLifeSpan: HealthyLifeSpan = { age, remaining, isAdjusted, comparisonOfSameGeneration };
    const navigationState: ComponentProps<typeof OrganDetailTemplate> = {
      healthLifeSpan,
      organInfo,
      isComparisonOfSameGenerationDisplayed,
    };

    result.push({
      rank: displayRankNumber,
      healthLifeSpan,
      organInfo,
      onPress: () => navigate('/organ-detail', { state: navigationState }),
    });
  });

  return result;
};

const sortOrganRankingItems = (items: HealthyLifeSpanItem[]) =>
  items
    .sort(byNumberAscending((item) => item.comparisonOfSameGeneration.month))
    .sort(byNumberAscending((item) => item.comparisonOfSameGeneration.year))
    .sort(byNumberAscending((item) => item.age.month))
    .sort(byNumberAscending((item) => item.age.year));
