import { zodResolver } from '@hookform/resolvers/zod';
import type { FC } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import type { TextStyle, ViewStyle } from 'react-native';
import { StyleSheet, View } from 'react-native';

import { messages as t } from '@/assets/i18n/ja';
import { Button } from '@/components/Button';
import { Text } from '@/components/Text';
import { TextInputControlled } from '@/components/TextInputControlled';
import { Colors } from '@/constants/Colors';
import type { PastSmokingHabitSchema } from '@/formSchemas/lifeStyleSchema';
import { pastSmokingHabitSchema } from '@/formSchemas/lifeStyleSchema';
import type { PastSmokingHabitFields } from '@/types/questionnaire/lifeStyle';
import { styleType } from '@/utils/styleType';

type Props = {
  question: string;
  fields: PastSmokingHabitFields;
  onSubmit: SubmitHandler<PastSmokingHabitSchema>;
  submitButtonLabel?: string;
  submitButtonPosition?: 'relative' | 'absolute';
};

export const PastSmokingHabit: FC<Props> = (props) => {
  const {
    question,
    fields: { numberOfCigarettesPerDayPast, yearsOfSmokingPast },
    onSubmit,
    submitButtonLabel = t.questionnaire.pagingButtonLabel,
    submitButtonPosition = 'relative',
  } = props;
  const {
    formState: { errors },
    handleSubmit,
    control,
    watch,
  } = useForm<PastSmokingHabitSchema>({
    defaultValues: {
      numberOfCigarettesPerDayPast: (numberOfCigarettesPerDayPast.value || '') as unknown as number,
      yearsOfSmokingPast: (yearsOfSmokingPast.value || '') as unknown as number,
    },
    mode: 'onChange',
    resolver: zodResolver(pastSmokingHabitSchema),
  });

  const watchNumberOfCigarettesPerDayPast = watch('numberOfCigarettesPerDayPast');
  const watchYearsOfSmokingPast = watch('yearsOfSmokingPast');

  return (
    <>
      <Text.Headline>{question}</Text.Headline>
      <View style={styles.answerContainer}>
        <Text.BoldBody>{numberOfCigarettesPerDayPast.title}</Text.BoldBody>
        <TextInputControlled
          keyboardType="number-pad"
          name="numberOfCigarettesPerDayPast"
          placeholder={numberOfCigarettesPerDayPast.placeholder}
          unit={numberOfCigarettesPerDayPast.unit}
          control={control}
          style={styles.input}
        />
        {errors.numberOfCigarettesPerDayPast?.message ? (
          <Text.Subtext style={styles.errorText} testID="numberOfCigarettesPerDayFieldError">
            {errors.numberOfCigarettesPerDayPast?.message}
          </Text.Subtext>
        ) : null}
      </View>
      <View style={styles.answerContainer}>
        <Text.BoldBody>{yearsOfSmokingPast.title}</Text.BoldBody>
        <TextInputControlled
          name="yearsOfSmokingPast"
          keyboardType="number-pad"
          placeholder={yearsOfSmokingPast.placeholder}
          unit={yearsOfSmokingPast.unit}
          control={control}
          style={styles.input}
        />
        {errors.yearsOfSmokingPast?.message ? (
          <Text.Subtext style={styles.errorText} testID="yearsOfSmokingFieldError">
            {errors.yearsOfSmokingPast?.message}
          </Text.Subtext>
        ) : null}
      </View>
      <View style={submitButtonPosition === 'absolute' ? styles.absoluteButtonContainer : null}>
        <Button
          onPress={handleSubmit(onSubmit) /* eslint-disable-line @typescript-eslint/no-misused-promises */}
          type={watchYearsOfSmokingPast && watchNumberOfCigarettesPerDayPast ? 'active' : 'inactive'}
          accessibilityRole="button"
          accessibilityLabel="次へ"
          style={styles.button}
        >
          {submitButtonLabel}
        </Button>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  errorText: styleType<TextStyle>({
    color: Colors.systemRed,
  }),
  answerContainer: styleType<ViewStyle>({
    marginTop: 20,
  }),
  input: styleType<ViewStyle>({
    marginTop: 4,
  }),
  button: styleType<ViewStyle>({
    marginTop: 40,
  }),
  absoluteButtonContainer: styleType<ViewStyle>({
    ...StyleSheet.absoluteFillObject,
    top: 'auto',
    bottom: 0,
  }),
});
