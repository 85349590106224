import type { FC } from 'react';

import { Colors } from '@/constants/Colors';

type Props = {
  size?: number;
  fill?: string;
};

export const CheckboxActive: FC<Props> = (props) => {
  const { size = 24, fill = Colors.healthactionGreen } = props;

  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="2" y="2" width="20" height="20" rx="2" fill={fill} />
      <path d="M7 11L10.3333 15L17 9" stroke={Colors.white100} strokeWidth="2" />
    </svg>
  );
};
