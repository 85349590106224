import { type ViewStyle, Dimensions, Platform, StyleSheet } from 'react-native';

import { ActivityIndicator } from '@/components/ActivityIndicator';
import { Container } from '@/components/Container';
import { styleType } from '@/utils/styleType';

export const LoadingView = () => (
  <Container style={styles.container}>
    <ActivityIndicator />
  </Container>
);

const styles = StyleSheet.create({
  container: styleType<ViewStyle>({
    justifyContent: 'center',
    alignItems: 'center',
    ...Platform.select({
      web: {
        minHeight: Dimensions.get('window').height,
      },
    }),
  }),
});
