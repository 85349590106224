import { type ViewStyle, Dimensions, Platform, StyleSheet } from 'react-native';

import { messages as t } from '@/assets/i18n/ja';
import { Container } from '@/components/Container';
import { Text } from '@/components/Text';
import { styleType } from '@/utils/styleType';

export const RegistrationTemplate = () => (
  <Container style={styles.container}>
    <Text.Body>{t.registration.linkedToExternalSite}</Text.Body>
  </Container>
);

const styles = StyleSheet.create({
  container: styleType<ViewStyle>({
    alignItems: 'center',
    justifyContent: 'center',
    ...Platform.select({
      web: {
        minHeight: Dimensions.get('window').height,
      },
    }),
  }),
});
