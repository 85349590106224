import type { Event } from '@/lib/Logger/types/Event';

type Props = {
  link_url: string;
};

export const ClickEvent = (props: Props): Event => ({
  name: 'click',
  props,
});
