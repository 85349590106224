import { type FC } from 'react';
import { StyleSheet, View } from 'react-native';

import { Radio } from '@/components/Radio';

type Props = {
  index: number;
  label: string;
  isActive: boolean;
  onPress: () => void;
};

export const SelectionRadio: FC<Props> = (props) => {
  const { index, onPress, label, isActive } = props;

  return (
    <View style={index ? styles.container : null}>
      <Radio label={label} isActive={isActive} onPress={onPress} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: 16,
  },
});
