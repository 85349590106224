import { type FC } from 'react';
import { StyleSheet } from 'react-native';

import { Icon } from '@/components/Icon';
import { Text } from '@/components/Text';
import { Touchable } from '@/components/Touchable';

type Props = {
  label: string;
  isActive: boolean;
  onPress: () => void;
};

export const Radio: FC<Props> = (props) => {
  const { onPress, label, isActive } = props;

  return (
    <Touchable onPress={onPress} style={styles.container} withoutEffect={true}>
      <>
        {isActive ? <Icon type="RadioActive" /> : <Icon type="RadioInactive" />}
        <Text.Body style={styles.label}>{label}</Text.Body>
      </>
    </Touchable>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    marginLeft: 8,
  },
});
