import { zodResolver } from '@hookform/resolvers/zod';
import type { FC } from 'react';
import { useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import type { TextStyle, ViewStyle } from 'react-native';
import { StyleSheet, View } from 'react-native';

import { messages as t } from '@/assets/i18n/ja';
import { Button } from '@/components/Button';
import { CheckboxControlled } from '@/components/CheckboxControlled';
import { Text } from '@/components/Text';
import { TextInputControlled } from '@/components/TextInputControlled';
import { Colors } from '@/constants/Colors';
import type { HowToSpendHolidaysSchema } from '@/formSchemas/environmentSchema';
import { howToSpendHolidaysSchema } from '@/formSchemas/environmentSchema';
import type { HowToSpendHolidaysFields } from '@/types/questionnaire/environment';
import { styleType } from '@/utils/styleType';

type Props = {
  question: string;
  fields: HowToSpendHolidaysFields;
  onSubmit: SubmitHandler<HowToSpendHolidaysSchema>;
  submitButtonLabel?: string;
  submitButtonPosition?: 'relative' | 'absolute';
};

export const HowToSpendHolidays: FC<Props> = (props) => {
  const {
    question,
    fields: {
      housework,
      work,
      childcare,
      reading,
      game,
      watchingTvAndVideos,
      hobby,
      netSurfing,
      otherWays,
      supplement,
    },
    onSubmit,
    submitButtonLabel = t.questionnaire.pagingButtonLabel,
    submitButtonPosition = 'relative',
  } = props;
  const [noSelectedValuesError] = useState(false);

  const {
    formState: { errors },
    handleSubmit,
    control,
    setValue,
    watch,
  } = useForm<HowToSpendHolidaysSchema>({
    defaultValues: {
      housework: housework.value,
      work: work.value,
      childcare: childcare.value,
      reading: reading.value,
      game: game.value,
      watchingTvAndVideos: watchingTvAndVideos.value,
      hobby: hobby.value,
      netSurfing: netSurfing.value,
      otherWays: otherWays.value,
      otherWaysSupplement: supplement?.value || '',
    },
    mode: 'onChange',
    resolver: zodResolver(howToSpendHolidaysSchema),
  });

  const watchHousework = watch('housework');
  const watchWork = watch('work');
  const watchChildcare = watch('childcare');
  const watchReading = watch('reading');
  const watchGame = watch('game');
  const watchWatchingTvAndVideos = watch('watchingTvAndVideos');
  const watchHobby = watch('hobby');
  const watchNetSurfing = watch('netSurfing');
  const watchOtherWays = watch('otherWays');
  const watchOtherWaysSupplement = watch('otherWaysSupplement');

  const isSubmitButtonEnabled = watchOtherWays
    ? !!watchOtherWaysSupplement
    : [
        watchHousework,
        watchWork,
        watchChildcare,
        watchReading,
        watchGame,
        watchWatchingTvAndVideos,
        watchHobby,
        watchNetSurfing,
      ].includes(true);

  const handleSubmitButtonPress = () => {
    handleSubmit(onSubmit)(); // eslint-disable-line @typescript-eslint/no-floating-promises
  };

  return (
    <>
      <Text.Headline>{question}</Text.Headline>
      <CheckboxControlled
        name="housework"
        control={control}
        label={housework.label}
        onPress={() => setValue('housework', !watchHousework)}
        style={styles.checkboxFirst}
      />
      <CheckboxControlled
        name="work"
        control={control}
        label={work.label}
        onPress={() => setValue('work', !watchWork)}
        style={styles.checkboxNotFirst}
      />
      <CheckboxControlled
        name="childcare"
        control={control}
        label={childcare.label}
        onPress={() => setValue('childcare', !watchChildcare)}
        style={styles.checkboxNotFirst}
      />
      <CheckboxControlled
        name="reading"
        control={control}
        label={reading.label}
        onPress={() => setValue('reading', !watchReading)}
        style={styles.checkboxNotFirst}
      />
      <CheckboxControlled
        name="game"
        control={control}
        label={game.label}
        onPress={() => setValue('game', !watchGame)}
        style={styles.checkboxNotFirst}
      />
      <CheckboxControlled
        name="watchingTvAndVideos"
        control={control}
        label={watchingTvAndVideos.label}
        onPress={() => setValue('watchingTvAndVideos', !watchWatchingTvAndVideos)}
        style={styles.checkboxNotFirst}
      />
      <CheckboxControlled
        name="hobby"
        control={control}
        label={hobby.label}
        onPress={() => setValue('hobby', !watchHobby)}
        style={styles.checkboxNotFirst}
      />
      <CheckboxControlled
        name="netSurfing"
        control={control}
        label={netSurfing.label}
        onPress={() => setValue('netSurfing', !watchNetSurfing)}
        style={styles.checkboxNotFirst}
      />
      <CheckboxControlled
        name="otherWays"
        control={control}
        label={otherWays.label}
        onPress={() => setValue('otherWays', !watchOtherWays)}
        style={styles.checkboxNotFirst}
      />
      {noSelectedValuesError ? (
        <Text.Subtext style={styles.errorText}>{t.validationError.selectRequired}</Text.Subtext>
      ) : null}
      <TextInputControlled
        placeholder={supplement?.placeholder}
        control={control}
        name="otherWaysSupplement"
        disabled={watchOtherWays !== true}
        style={styles.supplement}
      />
      {errors.otherWaysSupplement?.message ? (
        <Text.Subtext style={styles.errorText} testID="otherWaysSupplementFieldError">
          {errors.otherWaysSupplement.message}
        </Text.Subtext>
      ) : null}
      <View style={submitButtonPosition === 'absolute' ? styles.absoluteButtonContainer : null}>
        <Button
          onPress={handleSubmitButtonPress}
          type={isSubmitButtonEnabled ? 'active' : 'inactive'}
          accessibilityRole="button"
          accessibilityLabel="次へ"
          style={styles.button}
        >
          {submitButtonLabel}
        </Button>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  errorText: styleType<TextStyle>({
    color: Colors.systemRed,
    marginTop: 8,
  }),
  supplement: styleType<TextStyle>({
    marginTop: 14,
  }),
  checkboxFirst: styleType<ViewStyle>({
    marginTop: 20,
  }),
  checkboxNotFirst: styleType<ViewStyle>({
    marginTop: 12,
  }),
  button: styleType<ViewStyle>({
    marginTop: 40,
  }),
  absoluteButtonContainer: styleType<ViewStyle>({
    ...StyleSheet.absoluteFillObject,
    top: 'auto',
    bottom: 0,
  }),
});
