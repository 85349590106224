import { type ComponentProps, type FC } from 'react';

import { messages as t } from '@/assets/i18n/ja';
import { PastSmokingHabit } from '@/components/questionnaire/form/lifeStyle';
import { QuestionnaireContainer } from '@/features/myPage/components/parts/QuestionnaireContainer';

type Props = ComponentProps<typeof PastSmokingHabit>;

export const PastSmokingHabitTemplate: FC<Props> = (props) => {
  const { question, fields, onSubmit } = props;

  return (
    <QuestionnaireContainer>
      <PastSmokingHabit
        question={question}
        fields={fields}
        onSubmit={onSubmit}
        submitButtonLabel={t.myPage.pastQuestionnaireAnswers.save}
        submitButtonPosition="absolute"
      />
    </QuestionnaireContainer>
  );
};
