import * as Types from '../types/graphql';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateOrUpdateLifeStyleAnswerMutationVariables = Types.Exact<{
  hasSmokingHistory: Types.Scalars['Int'];
  hasSmokingHabit?: Types.InputMaybe<Types.Scalars['Int']>;
  numberOfCigarettesPerDayCurrent?: Types.InputMaybe<Types.Scalars['Int']>;
  numberOfCigarettesPerDayPast?: Types.InputMaybe<Types.Scalars['Int']>;
  yearsOfSmokingCurrent?: Types.InputMaybe<Types.Scalars['Int']>;
  yearsOfSmokingPast?: Types.InputMaybe<Types.Scalars['Int']>;
  hasHabitOfWalking: Types.Scalars['Int'];
  canWalkFasterThan: Types.Scalars['Int'];
  canEatFasterThan: Types.Scalars['Int'];
  hasHabitOfEatDinnerBeforeSleep: Types.Scalars['Int'];
  hasHabitOfEatSnackAfterDinner: Types.Scalars['Int'];
  hasHabitOfSkipBreakfast: Types.Scalars['Int'];
  frequencyOfDrinking: Types.Scalars['Int'];
  amountOfDrinkingPerDay: Types.Scalars['Int'];
  canGetEnoughRestWithSleep: Types.Scalars['Int'];
  hasHabitOfSweatyExerciseMoreThanOneYear: Types.Scalars['Int'];
}>;


export type CreateOrUpdateLifeStyleAnswerMutation = { __typename?: 'Mutation', createOrUpdateAnswer: { __typename?: 'CreateOrUpdateAnswerPayload', id: string } };

export type CreateOrUpdateEnvironmentAnswerMutationVariables = Types.Exact<{
  familyStructure: Types.Scalars['Int'];
  familyStructureSupplement?: Types.InputMaybe<Types.Scalars['String']>;
  occupation: Types.Scalars['Int'];
  occupationSupplement?: Types.InputMaybe<Types.Scalars['String']>;
  meansOfTransportation: Types.Scalars['Int'];
  meansOfTransportationSupplement?: Types.InputMaybe<Types.Scalars['String']>;
  housingType: Types.Scalars['Int'];
  housingTypeSupplement?: Types.InputMaybe<Types.Scalars['String']>;
  hasCar: Types.Scalars['Int'];
  frequencyOfDriving: Types.Scalars['Int'];
  frequencyOfDrivingSupplement?: Types.InputMaybe<Types.Scalars['String']>;
  housework?: Types.InputMaybe<Types.Scalars['Boolean']>;
  work?: Types.InputMaybe<Types.Scalars['Boolean']>;
  childcare?: Types.InputMaybe<Types.Scalars['Boolean']>;
  reading?: Types.InputMaybe<Types.Scalars['Boolean']>;
  game?: Types.InputMaybe<Types.Scalars['Boolean']>;
  watchingTvAndVideos?: Types.InputMaybe<Types.Scalars['Boolean']>;
  hobby?: Types.InputMaybe<Types.Scalars['Boolean']>;
  netSurfing?: Types.InputMaybe<Types.Scalars['Boolean']>;
  otherWays?: Types.InputMaybe<Types.Scalars['Boolean']>;
  otherWaysSupplement?: Types.InputMaybe<Types.Scalars['String']>;
  numberOfFriends: Types.Scalars['Int'];
}>;


export type CreateOrUpdateEnvironmentAnswerMutation = { __typename?: 'Mutation', createOrUpdateAnswer: { __typename?: 'CreateOrUpdateAnswerPayload', id: string } };

export type CreateOrUpdateMedicalHistoryAnswerMutationVariables = Types.Exact<{
  stroke?: Types.InputMaybe<Types.Scalars['Boolean']>;
  transientIschemicAttack?: Types.InputMaybe<Types.Scalars['Boolean']>;
  ischemicHeartDisease?: Types.InputMaybe<Types.Scalars['Boolean']>;
  heartFailure?: Types.InputMaybe<Types.Scalars['Boolean']>;
  chronicObstructivePulmonaryDisease?: Types.InputMaybe<Types.Scalars['Boolean']>;
  liverCirrhosis?: Types.InputMaybe<Types.Scalars['Boolean']>;
  liverFailure?: Types.InputMaybe<Types.Scalars['Boolean']>;
  dialysis?: Types.InputMaybe<Types.Scalars['Boolean']>;
  cancer?: Types.InputMaybe<Types.Scalars['String']>;
  highBloodPressure?: Types.InputMaybe<Types.Scalars['Boolean']>;
  diabetes?: Types.InputMaybe<Types.Scalars['Boolean']>;
  dyslipidemia?: Types.InputMaybe<Types.Scalars['Boolean']>;
  otherDisease?: Types.InputMaybe<Types.Scalars['Boolean']>;
  otherDiseaseSupplement?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type CreateOrUpdateMedicalHistoryAnswerMutation = { __typename?: 'Mutation', createOrUpdateAnswer: { __typename?: 'CreateOrUpdateAnswerPayload', id: string } };


export const CreateOrUpdateLifeStyleAnswerDocument = gql`
    mutation CreateOrUpdateLifeStyleAnswer($hasSmokingHistory: Int!, $hasSmokingHabit: Int, $numberOfCigarettesPerDayCurrent: Int, $numberOfCigarettesPerDayPast: Int, $yearsOfSmokingCurrent: Int, $yearsOfSmokingPast: Int, $hasHabitOfWalking: Int!, $canWalkFasterThan: Int!, $canEatFasterThan: Int!, $hasHabitOfEatDinnerBeforeSleep: Int!, $hasHabitOfEatSnackAfterDinner: Int!, $hasHabitOfSkipBreakfast: Int!, $frequencyOfDrinking: Int!, $amountOfDrinkingPerDay: Int!, $canGetEnoughRestWithSleep: Int!, $hasHabitOfSweatyExerciseMoreThanOneYear: Int!) {
  createOrUpdateAnswer(
    answer: {lifeStyleInput: {smokingHistory: {hasSmokingHistory: $hasSmokingHistory, hasSmokingHabit: $hasSmokingHabit, numberOfCigarettesPerDayCurrent: $numberOfCigarettesPerDayCurrent, numberOfCigarettesPerDayPast: $numberOfCigarettesPerDayPast, yearsOfSmokingCurrent: $yearsOfSmokingCurrent, yearsOfSmokingPast: $yearsOfSmokingPast}, hasHabitOfWalking: $hasHabitOfWalking, canWalkFasterThan: $canWalkFasterThan, canEatFasterThan: $canEatFasterThan, hasHabitOfEatDinnerBeforeSleep: $hasHabitOfEatDinnerBeforeSleep, hasHabitOfEatSnackAfterDinner: $hasHabitOfEatSnackAfterDinner, hasHabitOfSkipBreakfast: $hasHabitOfSkipBreakfast, frequencyOfDrinking: $frequencyOfDrinking, amountOfDrinkingPerDay: $amountOfDrinkingPerDay, canGetEnoughRestWithSleep: $canGetEnoughRestWithSleep, hasHabitOfSweatyExerciseMoreThanOneYear: $hasHabitOfSweatyExerciseMoreThanOneYear}}
  ) {
    id
  }
}
    `;

export function useCreateOrUpdateLifeStyleAnswerMutation() {
  return Urql.useMutation<CreateOrUpdateLifeStyleAnswerMutation, CreateOrUpdateLifeStyleAnswerMutationVariables>(CreateOrUpdateLifeStyleAnswerDocument);
};
export const CreateOrUpdateEnvironmentAnswerDocument = gql`
    mutation CreateOrUpdateEnvironmentAnswer($familyStructure: Int!, $familyStructureSupplement: String, $occupation: Int!, $occupationSupplement: String, $meansOfTransportation: Int!, $meansOfTransportationSupplement: String, $housingType: Int!, $housingTypeSupplement: String, $hasCar: Int!, $frequencyOfDriving: Int!, $frequencyOfDrivingSupplement: String, $housework: Boolean, $work: Boolean, $childcare: Boolean, $reading: Boolean, $game: Boolean, $watchingTvAndVideos: Boolean, $hobby: Boolean, $netSurfing: Boolean, $otherWays: Boolean, $otherWaysSupplement: String, $numberOfFriends: Int!) {
  createOrUpdateAnswer(
    answer: {environmentInput: {familyStructure: $familyStructure, familyStructureSupplement: $familyStructureSupplement, occupation: $occupation, occupationSupplement: $occupationSupplement, meansOfTransportation: $meansOfTransportation, meansOfTransportationSupplement: $meansOfTransportationSupplement, housingType: $housingType, housingTypeSupplement: $housingTypeSupplement, hasCar: $hasCar, frequencyOfDriving: $frequencyOfDriving, frequencyOfDrivingSupplement: $frequencyOfDrivingSupplement, howToSpendHolidays: {housework: $housework, work: $work, childcare: $childcare, reading: $reading, game: $game, watchingTvAndVideos: $watchingTvAndVideos, hobby: $hobby, netSurfing: $netSurfing, otherWays: $otherWays, otherWaysSupplement: $otherWaysSupplement}, numberOfFriends: $numberOfFriends}}
  ) {
    id
  }
}
    `;

export function useCreateOrUpdateEnvironmentAnswerMutation() {
  return Urql.useMutation<CreateOrUpdateEnvironmentAnswerMutation, CreateOrUpdateEnvironmentAnswerMutationVariables>(CreateOrUpdateEnvironmentAnswerDocument);
};
export const CreateOrUpdateMedicalHistoryAnswerDocument = gql`
    mutation CreateOrUpdateMedicalHistoryAnswer($stroke: Boolean, $transientIschemicAttack: Boolean, $ischemicHeartDisease: Boolean, $heartFailure: Boolean, $chronicObstructivePulmonaryDisease: Boolean, $liverCirrhosis: Boolean, $liverFailure: Boolean, $dialysis: Boolean, $cancer: String, $highBloodPressure: Boolean, $diabetes: Boolean, $dyslipidemia: Boolean, $otherDisease: Boolean, $otherDiseaseSupplement: String) {
  createOrUpdateAnswer(
    answer: {medicalHistoryInput: {stroke: $stroke, transientIschemicAttack: $transientIschemicAttack, ischemicHeartDisease: $ischemicHeartDisease, heartFailure: $heartFailure, chronicObstructivePulmonaryDisease: $chronicObstructivePulmonaryDisease, liverCirrhosis: $liverCirrhosis, liverFailure: $liverFailure, dialysis: $dialysis, cancer: $cancer, highBloodPressure: $highBloodPressure, diabetes: $diabetes, dyslipidemia: $dyslipidemia, otherDisease: $otherDisease, otherDiseaseSupplement: $otherDiseaseSupplement}}
  ) {
    id
  }
}
    `;

export function useCreateOrUpdateMedicalHistoryAnswerMutation() {
  return Urql.useMutation<CreateOrUpdateMedicalHistoryAnswerMutation, CreateOrUpdateMedicalHistoryAnswerMutationVariables>(CreateOrUpdateMedicalHistoryAnswerDocument);
};