import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import type { MedicalHistorySchema } from '@/formSchemas/medicalHistorySchema';
import { useGetMedicalHistoryQuestionnaireQuery } from '@/hooks/GetQuestionnaire.generated';
import { useQuestionnaire } from '@/hooks/useQuestionnaire';

import { useCreateMedicalHistory } from './useCreateMedicalHistoryFields';

export const useMedicalHistory = () => {
  const [getMedicalHistoryQuestionnaireResult] = useGetMedicalHistoryQuestionnaireQuery();

  const { data, fetching, error } = getMedicalHistoryQuestionnaireResult;

  const { getQuestionnaireItem, perpetuateMedicalHistoryFormValues } = useQuestionnaire();
  const { createFields } = useCreateMedicalHistory();

  const questionnaireItem = useMemo(
    () => data && getQuestionnaireItem(data, 'MEDICAL_HISTORY'),
    [data, getQuestionnaireItem]
  )!;

  const fields = useMemo(() => createFields(questionnaireItem), [createFields, questionnaireItem]);

  const navigate = useNavigate();

  const handleSubmit = useCallback(
    (values: MedicalHistorySchema) => {
      perpetuateMedicalHistoryFormValues(values);

      navigate('/predicting-healthy-life-span');
    },
    [perpetuateMedicalHistoryFormValues, navigate]
  );

  return {
    fetching,
    data,
    error,
    questionnaireItem,
    fields,
    handleSubmit,
  };
};
