import type { ComponentProps, FC } from 'react';
import { useMemo } from 'react';
import type { ViewStyle } from 'react-native';
import { StyleSheet } from 'react-native';
import { Divider } from 'react-native-paper';

import { messages as t } from '@/assets/i18n/ja';
import { Container } from '@/components/Container';
import { Header } from '@/components/SectionList/Header';
import { List } from '@/components/SectionList/List';
import { styleType } from '@/utils/styleType';

import { ListItem } from './ListItem';
import { LogOutModal } from './LogOutModal';

type Props = CreateSectionProps & {
  isLogOutModalVisible: boolean;
  onLogOutModalDismiss: () => void;
  onLogOutModalSubmitButtonPress: () => void;
  onLogOutModalCancelButtonPress: () => void;
};

type Section = {
  title: string;
  data: Array<ComponentProps<typeof ListItem>>;
};

export const MyPageTemplate: FC<Props> = (props) => {
  const {
    isLogOutModalVisible,
    onLogOutModalDismiss,
    onLogOutModalSubmitButtonPress,
    onLogOutModalCancelButtonPress,
    ...restFunctions
  } = props;
  const sections = useMemo(() => createSection(restFunctions), [restFunctions]);

  return (
    <Container style={styles.container}>
      <LogOutModal
        isVisible={isLogOutModalVisible}
        onDismiss={onLogOutModalDismiss}
        onSubmitButtonPress={onLogOutModalSubmitButtonPress}
        onCancelButtonPress={onLogOutModalCancelButtonPress}
      />
      <List
        sections={sections}
        renderItem={renderItem}
        renderSectionHeader={renderSectionHeader}
        renderSectionFooter={renderSectionFooter}
        ItemSeparatorComponent={ItemSeparatorComponent}
      />
    </Container>
  );
};

const ItemSeparatorComponent = () => <Divider />;

type SectionList = ComponentProps<typeof List<ComponentProps<typeof ListItem>, Section>>;

type RenderItem = SectionList['renderItem'];
const renderItem: RenderItem = (info) => <ListItem text={info.item.text} onPress={info.item.onPress} />;

type RenderSectionHeader = SectionList['renderSectionHeader'];
const renderSectionHeader: RenderSectionHeader = (info) => <Header title={info.section.title} />;

type RenderSectionFooter = SectionList['renderSectionFooter'];
const renderSectionFooter: RenderSectionFooter = () => <Divider style={styles.footerSpace} />;

type CreateSectionProps = {
  onPastQuestionnaireAnswersListItemPress: () => void;
  onNotificationsListItemPress: () => void;
  onLogoutListItemPress: () => void;
  onAboutSpecialistSupervisionListItemPress: () => void;
  onAboutAgeExpectancyListItemPress: () => void;
  onContactUsListItemPress: () => void;
  onCompanyListItemPress: () => void;
};
const createSection = (props: CreateSectionProps) => [
  {
    title: t.myPage.healthInformationSection.healthInformation,
    data: [
      {
        text: t.myPage.healthInformationSection.pastQuestionnaireAnswers,
        onPress: props.onPastQuestionnaireAnswersListItemPress,
      },
    ],
  },
  {
    title: t.myPage.accountSettingsSection.accountSettings,
    data: [
      {
        text: t.myPage.accountSettingsSection.logout,
        onPress: props.onLogoutListItemPress,
      },
    ],
  },
  {
    title: t.myPage.othersSection.others,
    data: [
      {
        text: t.myPage.accountSettingsSection.announcements,
        onPress: props.onNotificationsListItemPress,
      },
      {
        text: t.myPage.othersSection.aboutSpecialistSupervision,
        onPress: props.onAboutSpecialistSupervisionListItemPress,
      },
      {
        text: t.myPage.othersSection.aboutAgeExpectancy,
        onPress: props.onAboutAgeExpectancyListItemPress,
      },
      {
        text: t.myPage.othersSection.contactUs,
        onPress: props.onContactUsListItemPress,
      },
      {
        text: t.myPage.othersSection.company,
        onPress: props.onCompanyListItemPress,
      },
    ],
  },
];

const styles = StyleSheet.create({
  container: styleType<ViewStyle>({
    justifyContent: 'center',
    paddingHorizontal: 20,
    paddingTop: 40,
  }),
  footerSpace: styleType<ViewStyle>({
    marginBottom: 30,
  }),
});
