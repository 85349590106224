import { type FC } from 'react';
import { type TextStyle, type ViewStyle, StyleSheet, View } from 'react-native';

import { messages as t } from '@/assets/i18n/ja';
import { Text } from '@/components/Text';
import { Colors } from '@/constants/Colors';
import { type HealthyLifeSpan } from '@/types/graphql';
import { styleType } from '@/utils/styleType';

type Props = {
  healthLifeSpan: HealthyLifeSpan;
};

export const RemainingHealthyLifeItem: FC<Props> = (props) => {
  const { healthLifeSpan } = props;
  const { comparisonOfSameGeneration, remaining } = healthLifeSpan;

  return (
    <View style={styles.container}>
      <View style={styles.verticalTextContainer}>
        <Text.SubtextBold>{t.home.healthyLifeExpectancy.remainingHealthyLife.remaining}</Text.SubtextBold>
        <Text.Title3>
          {t.home.healthyLifeExpectancy.remainingHealthyLife.remainingHealthyLifeExpectancy(
            remaining.year,
            remaining.month
          )}
        </Text.Title3>
      </View>
      <View style={styles.verticalTextContainer}>
        <Text.SubtextBold>{t.home.healthyLifeExpectancy.remainingHealthyLife.sameAgeComparison}</Text.SubtextBold>
        <Text.Title3
          style={
            comparisonOfSameGeneration.isAverage
              ? styles.comparisonAgeAverage
              : comparisonOfSameGeneration.year < 0 || comparisonOfSameGeneration.month < 0
              ? styles.comparisonAgeOlder
              : styles.comparisonAgeYounger
          }
        >
          {createComparisonOfSameGenerationText(healthLifeSpan)}
        </Text.Title3>
      </View>
    </View>
  );
};

const createComparisonOfSameGenerationText = (healthLifeSpan: HealthyLifeSpan) => {
  const { remainingHealthyLife: unitOfDate } = t.home.healthyLifeExpectancy;
  const {
    comparisonOfSameGeneration: { year, month, isAverage },
  } = healthLifeSpan;
  if (isAverage) {
    return `0${unitOfDate.year}`;
  } else {
    if (year === 0) {
      return `${month > 0 ? '+' : ''}${month}${unitOfDate.month}`;
    } else {
      return `${year > 0 ? '+' : ''}${year}${unitOfDate.year}${Math.abs(month)}${unitOfDate.month}`;
    }
  }
};

const styles = StyleSheet.create({
  container: styleType<ViewStyle>({
    flex: 1,
    minHeight: 80,
    flexDirection: 'row',
    borderRadius: 4,
    marginVertical: 4,
    paddingHorizontal: 30,
    paddingVertical: 16,
    backgroundColor: Colors.gray1,
    justifyContent: 'space-between',
  }),
  verticalTextContainer: styleType<ViewStyle>({
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 4,
    flex: 1,
  }),
  comparisonAgeOlder: styleType<TextStyle>({
    color: Colors.systemRed,
    flexDirection: 'row',
  }),
  comparisonAgeYounger: styleType<TextStyle>({
    color: Colors.healthactionGreen,
    flexDirection: 'row',
  }),
  comparisonAgeAverage: styleType<TextStyle>({
    color: Colors.black100,
    flexDirection: 'row',
  }),
});
