import { type FC } from 'react';
import { type TextStyle, type ViewStyle, Platform, StyleSheet } from 'react-native';
import { Modal, Portal } from 'react-native-paper';

import { messages as t } from '@/assets/i18n/ja';
import { Button } from '@/components/Button';
import { Text } from '@/components/Text';
import { Colors } from '@/constants/Colors';
import { styleType } from '@/utils/styleType';

type Props = {
  isVisible: boolean;
  onDismiss: () => void;
  onSubmitButtonPress: () => void;
  onCancelButtonPress: () => void;
};

export const LogOutModal: FC<Props> = (props) => {
  const { isVisible, onDismiss, onSubmitButtonPress, onCancelButtonPress } = props;

  return (
    <Portal>
      <Modal
        visible={isVisible}
        onDismiss={onDismiss}
        contentContainerStyle={styles.modalContentContainerStyle}
        style={styles.modalBackground}
      >
        <Text.Headline style={styles.confirmation}>{t.myPage.logOut.confirmation}</Text.Headline>
        <Button type="active" onPress={onSubmitButtonPress} style={styles.submitButton}>
          {t.myPage.logOut.buttonLabels.submit}
        </Button>
        <Button type="active2" onPress={onCancelButtonPress}>
          {t.myPage.logOut.buttonLabels.cancel}
        </Button>
      </Modal>
    </Portal>
  );
};

const styles = StyleSheet.create({
  modalBackground: styleType<ViewStyle>({
    backgroundColor: Colors.black54,
    padding: 20,
  }),
  modalContentContainerStyle: styleType<ViewStyle>({
    backgroundColor: Colors.white100,
    borderRadius: 8,
    paddingTop: 40,
    paddingHorizontal: 20,
    paddingBottom: 20,
    ...Platform.select({
      web: {
        alignSelf: 'center',
        width: '100%',
        maxWidth: 428,
      },
    }),
  }),
  confirmation: styleType<TextStyle>({
    alignSelf: 'center',
    paddingBottom: 20,
  }),
  submitButton: styleType<ViewStyle>({
    marginBottom: 8,
  }),
});
