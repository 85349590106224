import { graphqlContext, MockedRequest, ResponseResolver } from 'msw';

import { LoginPayload } from '@/types/graphql';

const loginPayload: { login: LoginPayload } = {
  login: {
    userId: 'hoge1234',
  },
};

export const mockLogin: ResponseResolver<MockedRequest, typeof graphqlContext> = (req, res, ctx) =>
  res(ctx.data(loginPayload));
