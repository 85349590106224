import { useAtomValue } from 'jotai';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import type { OccupationSchema } from '@/formSchemas/environmentSchema';
import { useGetEnvironmentQuestionnaireQuery } from '@/hooks/GetQuestionnaire.generated';
import { useQuestionnaire } from '@/hooks/useQuestionnaire';
import { environmentFormValuesAtom } from '@/store/formValues';

export const useOccupation = () => {
  const [getEnvironmentQuestionnaireResult] = useGetEnvironmentQuestionnaireQuery();
  const { data, fetching, error } = getEnvironmentQuestionnaireResult;

  const formValues = useAtomValue(environmentFormValuesAtom);

  const { getQuestionnaireItem, createSelections, getSelectedValue, perpetuateEnvironmentFormValues } =
    useQuestionnaire();

  const questionnaireItem = useMemo(
    () => data && getQuestionnaireItem(data, 'OCCUPATION'),
    [data, getQuestionnaireItem]
  );

  const selections = useMemo(
    () => questionnaireItem?.forms[0].__typename === 'SelectTypeForm' && createSelections(questionnaireItem.forms[0]),
    [questionnaireItem, createSelections]
  );

  const selectedValue = useMemo(() => {
    if (formValues?.occupation) {
      return formValues?.occupation;
    }
    if (questionnaireItem?.forms[0].__typename === 'SelectTypeForm') {
      return getSelectedValue(questionnaireItem.forms[0]);
    }
    return undefined;
  }, [questionnaireItem?.forms, getSelectedValue, formValues?.occupation]);

  const otherItemSupplement = useMemo(() => {
    if (questionnaireItem && questionnaireItem.forms[0].__typename === 'SelectTypeForm') {
      const otherItemSupplement = questionnaireItem.forms[0].items.find(
        (i) => i.otherItemSupplement
      )?.otherItemSupplement;

      return {
        value:
          // 空文字は空文字のまま反映する
          (formValues?.occupationSupplement !== undefined
            ? formValues?.occupationSupplement
            : otherItemSupplement?.value) || '',
        placeholder: otherItemSupplement?.placeholder || '',
      };
    }
    return undefined;
  }, [formValues?.occupationSupplement, questionnaireItem]);

  const navigate = useNavigate();

  const handleSubmit = useCallback(
    (values: OccupationSchema) => {
      perpetuateEnvironmentFormValues(values);

      navigate('/questionnaire/means-of-transportation');
    },
    [perpetuateEnvironmentFormValues, navigate]
  );

  return {
    fetching,
    data,
    error,
    questionnaireItem,
    selections,
    selectedValue,
    otherItemSupplement,
    handleSubmit,
  };
};
