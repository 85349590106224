import React, { type FC, type ReactNode } from 'react';
import {
  type TouchableOpacityProps,
  type TouchableWithoutFeedbackProps,
  Platform,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import { type TouchableRippleProps, TouchableRipple } from 'react-native-paper';

type Props = {
  children: ReactNode;
  withoutEffect?: boolean;
} & TouchableProps;

// TouchableRipple と TouchableOpacity 両方に使える Props だけ受け入れる
type TouchableProps = {
  [K in keyof (TouchableOpacityProps | TouchableRippleProps | TouchableWithoutFeedbackProps)]: (
    | TouchableOpacityProps
    | TouchableRippleProps
    | TouchableWithoutFeedbackProps
  )[K];
};

export const Touchable: FC<Props> = (props) => {
  const { children, style, withoutEffect, ...restProps } = props;

  if (withoutEffect) {
    return (
      <TouchableWithoutFeedback {...restProps}>
        <View style={style}>{children}</View>
      </TouchableWithoutFeedback>
    );
  }

  if (Platform.OS === 'ios') {
    return (
      <TouchableOpacity style={style} {...restProps}>
        {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
        <>{children}</>
      </TouchableOpacity>
    );
  }

  return (
    <TouchableRipple style={style} {...restProps}>
      {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
      <>{children}</>
    </TouchableRipple>
  );
};
