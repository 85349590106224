import type { Event } from '@/lib/Logger/types/Event';

// イベントに追加したいcustom parameterについての型定義をsnake_caseで定義する
type Props = {
  page_location: string;
  page_referrer?: string;
};

export const PageViewEvent = (props: Props): Event => ({
  name: 'page_view',
  props,
});
