import { type FC } from 'react';
import { type TextStyle, type ViewStyle, StyleSheet, View } from 'react-native';

import { Text } from '@/components/Text';
import { Colors } from '@/constants/Colors';
import { styleType } from '@/utils/styleType';

type Props = {
  date: string;
  title: string;
  description: string;
};

export const NotificationItem: FC<Props> = (props) => {
  const { date, title, description } = props;

  return (
    <View style={styles.container}>
      <Text.Subtext style={styles.date}>{date}</Text.Subtext>
      <Text.BoldBody style={styles.title}>{title}</Text.BoldBody>
      <Text.Body>{description}</Text.Body>
    </View>
  );
};

const styles = StyleSheet.create({
  container: styleType<ViewStyle>({
    backgroundColor: Colors.white100,
    minHeight: 140,
    flexDirection: 'column',
    borderRadius: 8,
    padding: 16,
    marginVertical: 6,
  }),
  date: styleType<TextStyle>({
    color: Colors.black54,
  }),
  title: styleType<TextStyle>({
    marginVertical: 4,
  }),
});
