import { useCallback, useState } from 'react';
import type { FieldValues } from 'react-hook-form';

import { messages as t } from '@/assets/i18n/ja';
import { useUpdateQuestionnaireAnswers } from '@/features/myPage/hooks/useUpdateQuestionnaireAnswers';
import type {
  CurrentSmokingHabitSchema,
  HasSmokingHabitSchema,
  HasSmokingHistorySchema,
  PastSmokingHabitSchema,
} from '@/formSchemas/lifeStyleSchema';

import { useCurrentSmokingHabit } from './useCurrentSmokingHabit';
import { useHasSmokingHabit } from './useHasSmokingHabit';
import { useHasSmokingHistory } from './useHasSmokingHistory';
import { usePastSmokingHabit } from './usePastSmokingHabit';

export type FormName = 'HAS_SMOKING_HISTORY' | 'HAS_SMOKING_HABIT' | 'CURRENT_SMOKING_HABIT' | 'PAST_SMOKING_HABIT';

export const useSmokingHistoryEdit = () => {
  const [formValues, setFormValues] = useState<FieldValues>({});
  const [currentFormName, setCurrentFormName] = useState<FormName>();

  const {
    fetching: fetchingHasSmokingHistory,
    questionnaireItem: hasSmokingHistoryItem,
    selections: hasSmokingHistorySelections,
    selectedValue: hasSmokingHistorySelectedValue,
  } = useHasSmokingHistory();
  const {
    fetching: fetchingHasSmokingHabit,
    questionnaireItem: hasSmokingHabitItem,
    selections: hasSmokingHabitSelections,
    selectedValue: hasSmokingHabitSelectedValue,
  } = useHasSmokingHabit();
  const {
    fetching: fetchingCurrentSmokingHabit,
    questionnaireItem: currentSmokingHabitItem,
    fields: currentSmokingHabitFields,
  } = useCurrentSmokingHabit();
  const {
    fetching: fetchingPastSmokingHabit,
    questionnaireItem: pastSmokingHabitItem,
    fields: pastSmokingHabitFields,
  } = usePastSmokingHabit();

  const { updateLifeStyleQuestionnaireAnswers, hasError } = useUpdateQuestionnaireAnswers();

  const handleSubmitHasSmokingHistory = useCallback(
    (values: HasSmokingHistorySchema) => {
      const displayNumber = hasSmokingHistoryItem?.displayNumber ?? '';

      setFormValues({ ...formValues, ...values });

      /*
      設問 "喫煙歴がありますか。" に

      - 「はい」と解答した場合 (values.hasSmokingHistory === 1)
        - 設問 "現在たばこを習慣的に吸っていますか。" に遷移
      - 「いいえ」と解答した場合 (values.hasSmokingHistory === 2)
        - 問診の解答を更新
    */
      if (values.hasSmokingHistory === 1) {
        setCurrentFormName('HAS_SMOKING_HABIT');
      } else {
        // 「喫煙歴がありますか。」に「いいえ」と解答すると、関連のフィールドをnullにする
        updateLifeStyleQuestionnaireAnswers(
          {
            ...formValues,
            ...values,
            hasSmokingHabit: null,
            numberOfCigarettesPerDayCurrent: null,
            yearsOfSmokingCurrent: null,
            numberOfCigarettesPerDayPast: null,
            yearsOfSmokingPast: null,
          },
          displayNumber
        );
      }
    },
    [formValues, hasSmokingHistoryItem, updateLifeStyleQuestionnaireAnswers]
  );

  const handleSubmitHasSmokingHabit = useCallback(
    (values: HasSmokingHabitSchema) => {
      setFormValues({ ...formValues, ...values });

      /*
      設問 "現在たばこを習慣的に吸っていますか。" に

      - 「はい」と解答した場合 (values.hasSmokingHabit === 1)
        - 設問 "1日あたり喫煙する本数と喫煙年数はどのくらいですか。" に遷移
      - 「いいえ」と解答した場合 (values.hasSmokingHabit === 2)
        - 設問 "1日あたり喫煙していた本数と喫煙年数はどのくらいですか。" に遷移
    */
      if (values.hasSmokingHabit === 1) {
        setCurrentFormName('CURRENT_SMOKING_HABIT');
      } else {
        setCurrentFormName('PAST_SMOKING_HABIT');
      }
    },
    [formValues, setFormValues]
  );

  const handleSubmitCurrentSmokingHabit = useCallback(
    (values: CurrentSmokingHabitSchema) => {
      const displayNumber = currentSmokingHabitItem?.displayNumber ?? '';

      // 「現在たばこを習慣的に吸っていますか。」に「はい」と答えると、過去の喫煙本数と年数をnullにする
      updateLifeStyleQuestionnaireAnswers(
        {
          ...formValues,
          ...values,
          numberOfCigarettesPerDayPast: null,
          yearsOfSmokingPast: null,
        },
        displayNumber
      );
    },
    [formValues, currentSmokingHabitItem, updateLifeStyleQuestionnaireAnswers]
  );

  const handleSubmitPastSmokingHabit = useCallback(
    (values: PastSmokingHabitSchema) => {
      const displayNumber = pastSmokingHabitItem?.displayNumber ?? '';

      // 「現在たばこを習慣的に吸っていますか。」に「いいえ」と答えると、現在の喫煙本数と年数をnullにする
      updateLifeStyleQuestionnaireAnswers(
        {
          ...formValues,
          ...values,
          numberOfCigarettesPerDayCurrent: null,
          yearsOfSmokingCurrent: null,
        },
        displayNumber
      );
    },
    [formValues, pastSmokingHabitItem, updateLifeStyleQuestionnaireAnswers]
  );

  return {
    fetching:
      fetchingHasSmokingHistory || fetchingHasSmokingHabit || fetchingCurrentSmokingHabit || fetchingPastSmokingHabit,
    currentFormName,
    hasSmokingHistory: {
      question: hasSmokingHistoryItem?.question || '',
      selections: hasSmokingHistorySelections,
      defaultValue: hasSmokingHistorySelectedValue,
      onSubmit: handleSubmitHasSmokingHistory,
      isSubmitButtonPresent: true,
    },
    hasSmokingHabit: {
      question: hasSmokingHabitItem?.question || '',
      selections: hasSmokingHabitSelections,
      defaultValue: hasSmokingHabitSelectedValue,
      onSubmit: handleSubmitHasSmokingHabit,
      isSubmitButtonPresent: true,
    },
    currentSmokingHabit: {
      question: currentSmokingHabitItem?.question || '',
      fields: currentSmokingHabitFields,
      onSubmit: handleSubmitCurrentSmokingHabit,
      submitButtonLabel: t.myPage.pastQuestionnaireAnswers.save,
      isSubmitButtonPresent: true,
    },
    pastSmokingHabit: {
      question: pastSmokingHabitItem?.question || '',
      fields: pastSmokingHabitFields,
      onSubmit: handleSubmitPastSmokingHabit,
      submitButtonLabel: t.myPage.pastQuestionnaireAnswers.save,
      isSubmitButtonPresent: true,
    },
    hasError,
  };
};
