import * as Types from '../types/graphql';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetUserStatusQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetUserStatusQuery = { __typename?: 'Query', getUserStatus: { __typename?: 'UserStatus', isCreatedUser: boolean, isAnsweredLifestyle: boolean, isAnsweredEnvironment: boolean, isAnsweredMedicalHistory: boolean, isCompleted: boolean, shouldPredictHealthLimit: boolean } };


export const GetUserStatusDocument = gql`
    query GetUserStatus {
  getUserStatus {
    isCreatedUser
    isAnsweredLifestyle
    isAnsweredEnvironment
    isAnsweredMedicalHistory
    isCompleted
    shouldPredictHealthLimit
  }
}
    `;

export function useGetUserStatusQuery(options?: Omit<Urql.UseQueryArgs<GetUserStatusQueryVariables>, 'query'>) {
  return Urql.useQuery<GetUserStatusQuery, GetUserStatusQueryVariables>({ query: GetUserStatusDocument, ...options });
};