import { type ComponentProps, type FC } from 'react';
import { type TextStyle, type ViewStyle, StyleSheet, View } from 'react-native';
import { Divider } from 'react-native-paper';

import { FlatList } from '@/components/FlatList';
import { Text } from '@/components/Text';
import { Colors } from '@/constants/Colors';
import { styleType } from '@/utils/styleType';

import { ListItem } from '../ListItem';

type Props = {
  title: string;
  data: ComponentProps<typeof ListItem>[];
  style?: ViewStyle;
};

export const ListByCategory: FC<Props> = (props) => {
  const { title, data, style } = props;

  return (
    <View style={style}>
      <Text.Headline style={styles.title}>{title}</Text.Headline>
      <FlatList
        data={data}
        renderItem={({ item }) => <ListItem question={item.question} answers={item.answers} onPress={item.onPress} />}
        ItemSeparatorComponent={() => <Divider style={styles.divider} />}
        style={styles.list}
      />
      <Divider style={styles.divider} />
    </View>
  );
};

const styles = StyleSheet.create({
  title: styleType<TextStyle>({
    color: Colors.black54,
  }),
  list: styleType<ViewStyle>({
    marginTop: 12,
  }),
  divider: styleType<ViewStyle>({
    marginVertical: 12,
  }),
});
