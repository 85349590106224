import { useAtomValue } from 'jotai';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import type { HasCarSchema } from '@/formSchemas/environmentSchema';
import { useGetEnvironmentQuestionnaireQuery } from '@/hooks/GetQuestionnaire.generated';
import { useQuestionnaire } from '@/hooks/useQuestionnaire';
import { environmentFormValuesAtom } from '@/store/formValues';

export const useHasCar = () => {
  const [getEnvironmentQuestionnaireResult] = useGetEnvironmentQuestionnaireQuery();
  const { data, fetching, error } = getEnvironmentQuestionnaireResult;

  const formValues = useAtomValue(environmentFormValuesAtom);

  const { getQuestionnaireItem, createSelections, getSelectedValue, perpetuateEnvironmentFormValues } =
    useQuestionnaire();

  const questionnaireItem = useMemo(() => data && getQuestionnaireItem(data, 'HAS_CAR'), [data, getQuestionnaireItem]);
  const selections = useMemo(
    () => questionnaireItem?.forms[0].__typename === 'SelectTypeForm' && createSelections(questionnaireItem.forms[0]),
    [questionnaireItem, createSelections]
  );
  const selectedValue = useMemo(() => {
    if (formValues?.hasCar) {
      return formValues?.hasCar;
    }
    if (questionnaireItem?.forms[0].__typename === 'SelectTypeForm') {
      return getSelectedValue(questionnaireItem.forms[0]);
    }
    return undefined;
  }, [questionnaireItem?.forms, getSelectedValue, formValues?.hasCar]);

  const navigate = useNavigate();

  const handleSubmit = useCallback(
    (values: HasCarSchema) => {
      perpetuateEnvironmentFormValues(values);

      navigate('/questionnaire/frequency-of-driving');
    },
    [perpetuateEnvironmentFormValues, navigate]
  );

  return {
    fetching,
    data,
    error,
    questionnaireItem,
    selections,
    selectedValue,
    handleSubmit,
  };
};
