import { useEffect, useState } from 'react';

import { Logger } from '@/lib/Logger';
import { TutorialIndexEvent } from '@/lib/Logger/events';

export const useTutorial = () => {
  const [index, setIndex] = useState(0);
  const indexForEventLog = index + 1;

  useEffect(() => {
    Logger.logEvent(TutorialIndexEvent({ index: indexForEventLog }));
  }, [indexForEventLog]);

  return {
    index,
    setIndex,
  };
};
