import { type ComponentProps } from 'react';
import { SectionList } from 'react-native';

type Props<ItemT, SectionT> = Pick<
  ComponentProps<typeof SectionList<ItemT, SectionT>>,
  | 'style'
  | 'renderItem'
  | 'sections'
  | 'renderSectionHeader'
  | 'renderSectionFooter'
  | 'ItemSeparatorComponent'
  | 'ListHeaderComponent'
>;

export const List = <ItemT, SectionT>(props: Props<ItemT, SectionT>) => {
  const {
    style,
    sections,
    renderItem,
    renderSectionHeader,
    renderSectionFooter,
    ListHeaderComponent,
    ItemSeparatorComponent,
  } = props;

  return (
    <SectionList<ItemT, SectionT>
      style={style}
      sections={sections}
      stickySectionHeadersEnabled={false}
      keyExtractor={(item, index) => `section-${index}`}
      renderItem={renderItem}
      ItemSeparatorComponent={ItemSeparatorComponent}
      renderSectionHeader={renderSectionHeader}
      renderSectionFooter={renderSectionFooter}
      ListHeaderComponent={ListHeaderComponent}
    />
  );
};
