import { useAtomValue } from 'jotai';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import type { CurrentSmokingHabitSchema } from '@/formSchemas/lifeStyleSchema';
import { useGetLifeStyleQuestionnaireQuery } from '@/hooks/GetQuestionnaire.generated';
import { useQuestionnaire } from '@/hooks/useQuestionnaire';
import { lifeStyleFormValuesAtom } from '@/store/formValues';
import type { CurrentSmokingHabitFields } from '@/types/questionnaire/lifeStyle';

export const useCurrentSmokingHabit = () => {
  const [getLifeStyleQuestionnaireResult] = useGetLifeStyleQuestionnaireQuery();
  const { data, fetching, error } = getLifeStyleQuestionnaireResult;

  const formValues = useAtomValue(lifeStyleFormValuesAtom);

  const { getQuestionnaireSubItem, perpetuateLifeStyleFormValues } = useQuestionnaire();

  const questionnaireItem = useMemo(
    () => data && getQuestionnaireSubItem(data, 'HAS_SMOKING_HISTORY', 'CURRENT_SMOKING_HABIT'),
    [data, getQuestionnaireSubItem]
  );

  const fields: CurrentSmokingHabitFields | undefined = useMemo(
    () =>
      (() => {
        const numberOfCigarettesPerDayCurrent = questionnaireItem?.forms.find(
          (form) => form.name === 'numberOfCigarettesPerDayCurrent'
        );
        const yearsOfSmokingCurrent = questionnaireItem?.forms.find((form) => form.name === 'yearsOfSmokingCurrent');

        if (
          numberOfCigarettesPerDayCurrent?.__typename === 'InputTypeForm' &&
          yearsOfSmokingCurrent?.__typename === 'InputTypeForm'
        ) {
          return {
            numberOfCigarettesPerDayCurrent: {
              title: numberOfCigarettesPerDayCurrent.title || '',
              value:
                formValues?.numberOfCigarettesPerDayCurrent?.toString() || numberOfCigarettesPerDayCurrent.value || '',
              placeholder: numberOfCigarettesPerDayCurrent.placeholder || '',
              unit: numberOfCigarettesPerDayCurrent.unit || '',
            },
            yearsOfSmokingCurrent: {
              title: yearsOfSmokingCurrent.title || '',
              value: formValues?.yearsOfSmokingCurrent?.toString() || yearsOfSmokingCurrent.value || '',
              placeholder: yearsOfSmokingCurrent.placeholder || '',
              unit: yearsOfSmokingCurrent.unit || '',
            },
          };
        }
        return undefined;
      })(),
    [questionnaireItem?.forms, formValues?.numberOfCigarettesPerDayCurrent, formValues?.yearsOfSmokingCurrent]
  );

  const navigate = useNavigate();

  const handleSubmit = useCallback(
    (values: CurrentSmokingHabitSchema) => {
      perpetuateLifeStyleFormValues(values);

      navigate('/questionnaire/has-habit-of-walking');
    },
    [perpetuateLifeStyleFormValues, navigate]
  );

  return {
    data,
    fetching,
    error,
    questionnaireItem,
    fields,
    handleSubmit,
  };
};
