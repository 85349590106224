import { zodResolver } from '@hookform/resolvers/zod';
import type { FC } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import type { TextStyle, ViewStyle } from 'react-native';
import { StyleSheet, View } from 'react-native';

import { messages as t } from '@/assets/i18n/ja';
import { Button } from '@/components/Button';
import type { Selection } from '@/components/SelectionList';
import { SelectionListControlled } from '@/components/SelectionListControlled';
import { Text } from '@/components/Text';
import { TextInputControlled } from '@/components/TextInputControlled';
import { Colors } from '@/constants/Colors';
import type { MeansOfTransportationSchema } from '@/formSchemas/environmentSchema';
import { meansOfTransportationSchema } from '@/formSchemas/environmentSchema';
import { type OtherItemSupplement } from '@/types/questionnaire/common';
import { styleType } from '@/utils/styleType';

type Props = {
  question: string;
  selections: Selection[];
  onSubmit: SubmitHandler<MeansOfTransportationSchema>;
  defaultValue?: number;
  supplement: OtherItemSupplement;
  submitButtonLabel?: string;
  submitButtonPosition?: 'relative' | 'absolute';
};

export const MeansOfTransportation: FC<Props> = (props) => {
  const {
    question,
    selections,
    onSubmit,
    defaultValue,
    supplement,
    submitButtonLabel = t.questionnaire.pagingButtonLabel,
    submitButtonPosition = 'relative',
  } = props;

  const {
    formState: { errors },
    handleSubmit,
    control,
    setValue,
    watch,
  } = useForm<MeansOfTransportationSchema>({
    defaultValues: {
      meansOfTransportation: defaultValue,
      meansOfTransportationSupplement: supplement?.value || '',
    },
    mode: 'onChange',
    resolver: zodResolver(meansOfTransportationSchema),
  });

  const watchMeansOfTransportation = watch('meansOfTransportation');
  const watchMeansOfTransportationSupplement = watch('meansOfTransportationSupplement');

  const handleSelectionListPress = (selection: Selection) => {
    setValue('meansOfTransportation', selection.value);
  };

  const handleSubmitButtonPress = () => {
    handleSubmit(onSubmit)(); // eslint-disable-line @typescript-eslint/no-floating-promises
  };

  return (
    <>
      <Text.Headline>{question}</Text.Headline>
      <View style={styles.answerContainer}>
        <SelectionListControlled
          type="radio"
          name="meansOfTransportation"
          control={control}
          selections={selections}
          onPress={handleSelectionListPress}
        />
      </View>
      {errors.meansOfTransportation?.message ? (
        <Text.Subtext style={styles.errorText} testID="meansOfTransportationFieldError">
          {errors.meansOfTransportation?.message}
        </Text.Subtext>
      ) : null}
      <TextInputControlled
        name="meansOfTransportationSupplement"
        placeholder={supplement?.placeholder}
        control={control}
        disabled={watchMeansOfTransportation !== 99}
        style={styles.supplement}
      />
      {errors.meansOfTransportationSupplement?.message ? (
        <Text.Subtext style={styles.errorText} testID="meansOfTransportationSupplementFieldError">
          {errors.meansOfTransportationSupplement?.message}
        </Text.Subtext>
      ) : null}
      <View style={submitButtonPosition === 'absolute' ? styles.absoluteButtonContainer : null}>
        <Button
          onPress={handleSubmitButtonPress}
          type={
            (watchMeansOfTransportation && watchMeansOfTransportation !== 99) || watchMeansOfTransportationSupplement
              ? 'active'
              : 'inactive'
          }
          accessibilityRole="button"
          accessibilityLabel="次へ"
          style={styles.button}
        >
          {submitButtonLabel}
        </Button>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  errorText: styleType<TextStyle>({
    color: Colors.systemRed,
    marginTop: 8,
  }),
  supplement: styleType<TextStyle>({
    marginTop: 14,
  }),
  answerContainer: styleType<ViewStyle>({
    marginTop: 20,
  }),
  button: styleType<ViewStyle>({
    marginTop: 40,
  }),
  absoluteButtonContainer: styleType<ViewStyle>({
    ...StyleSheet.absoluteFillObject,
    top: 'auto',
    bottom: 0,
  }),
});
