import { type ComponentProps, type FC, type ReactNode } from 'react';
import { type TextStyle, type ViewStyle, StyleSheet, View } from 'react-native';

import { messages as t } from '@/assets/i18n/ja';
import { Container } from '@/components/Container';
import { ScrollView } from '@/components/ScrollView';
import { Text } from '@/components/Text';
import { Colors } from '@/constants/Colors';
import { styleType } from '@/utils/styleType';

import { QuestionNumber } from '../QuestionNumber';

type Props = Omit<ComponentProps<typeof QuestionNumber>, 'style'> & {
  children: ReactNode;
  isRequired?: boolean;
};

export const QuestionnaireContainer: FC<Props> = (props) => {
  const { children, questionNumber, allQuestionNumber, isRequired = true } = props;

  return (
    <Container style={styles.container}>
      <ScrollView>
        <View>
          <Text.Body>
            <QuestionNumber
              questionNumber={questionNumber}
              allQuestionNumber={allQuestionNumber}
              style={styles.questionNumber}
            />
          </Text.Body>
          {!isRequired ? (
            <View style={styles.labelContainer}>
              <Text.Footnote style={styles.label}>{t.questionnaire.requiredLabel.optional}</Text.Footnote>
            </View>
          ) : null}
        </View>
        {children}
      </ScrollView>
    </Container>
  );
};

const styles = StyleSheet.create({
  container: styleType<ViewStyle>({
    paddingTop: 60,
    paddingHorizontal: 20,
  }),
  questionNumber: styleType<TextStyle>({
    marginRight: 8,
    marginBottom: 8,
  }),
  labelContainer: styleType<ViewStyle>({
    backgroundColor: Colors.gray2,
    flexDirection: 'row',
    alignItems: 'center',
    width: 'fit-content',
    paddingHorizontal: 8,
    paddingVertical: 4,
    borderRadius: 4,
    marginBottom: 8,
  }),
  label: styleType<TextStyle>({
    color: Colors.black54,
  }),
});
