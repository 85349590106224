import React from 'react';
import type { ImageStyle, TextStyle, ViewStyle } from 'react-native';
import { Image, StyleSheet } from 'react-native';

import { messages as t } from '@/assets/i18n/ja';
import SPECIALIST_PHOTO from '@/assets/images/specialist_photo.png';
import { Container } from '@/components/Container';
import { FlatList } from '@/components/FlatList';
import { Text } from '@/components/Text';
import { Colors } from '@/constants/Colors';
import { ListItem } from '@/features/myPage/components/AboutSpecialistSupervision/ListItem';
import { styleType } from '@/utils/styleType';

export const AboutSpecialistSupervisionTemplate: React.FC = () => (
  <Container style={styles.container}>
    <Text.Headline style={styles.title}>{t.myPage.aboutSpecialistSupervision.firstSection.title}</Text.Headline>
    <Text.Body style={styles.body}>{t.myPage.aboutSpecialistSupervision.firstSection.text}</Text.Body>

    <Text.Headline style={styles.title}>{t.myPage.aboutSpecialistSupervision.secondSection.title}</Text.Headline>
    <Text.Body style={styles.body}>{t.myPage.aboutSpecialistSupervision.secondSection.text}</Text.Body>
    <Image source={{ uri: SPECIALIST_PHOTO }} resizeMode="contain" style={styles.image} />
    <Text.Headline style={styles.specialist}>
      {t.myPage.aboutSpecialistSupervision.secondSection.specialist}
    </Text.Headline>

    <Text.Headline style={styles.specialistTitle}>
      {t.myPage.aboutSpecialistSupervision.thirdSection.title}
    </Text.Headline>
    <Text.Body style={styles.academic}>{t.myPage.aboutSpecialistSupervision.thirdSection.text}</Text.Body>

    <Text.Headline style={styles.specialistTitle}>
      {t.myPage.aboutSpecialistSupervision.fourthSection.title}
    </Text.Headline>
    <FlatList data={createDataList} renderItem={({ item }) => <ListItem title={item.title} text={item.text} />} />
  </Container>
);

const { careerHistoryList } = t.myPage.aboutSpecialistSupervision.fourthSection;

const createDataList = [
  {
    title: careerHistoryList.firstListItem.title,
    text: careerHistoryList.firstListItem.text,
  },
  {
    title: careerHistoryList.secondListItem.title,
    text: careerHistoryList.secondListItem.text,
  },
  {
    title: careerHistoryList.thirdListItem.title,
    text: careerHistoryList.thirdListItem.text,
  },
  {
    title: careerHistoryList.fourthListItem.title,
    text: careerHistoryList.fourthListItem.text,
  },
  {
    title: careerHistoryList.fifthListItem.title,
    text: careerHistoryList.fifthListItem.text,
  },
  {
    title: careerHistoryList.sixthListItem.title,
    text: careerHistoryList.sixthListItem.text,
  },
  {
    title: careerHistoryList.seventhListItem.title,
    text: careerHistoryList.seventhListItem.text,
  },
  {
    title: careerHistoryList.heighthListItem.title,
    text: careerHistoryList.heighthListItem.text,
  },
  {
    title: careerHistoryList.ninthListItem.title,
    text: careerHistoryList.ninthListItem.text,
  },
  {
    title: careerHistoryList.tenthListItem.title,
    text: careerHistoryList.tenthListItem.text,
  },
  {
    title: careerHistoryList.eleventhListItem.title,
    text: careerHistoryList.eleventhListItem.text,
  },
  {
    title: careerHistoryList.twelfthListItem.title,
    text: careerHistoryList.twelfthListItem.text,
  },
  {
    title: careerHistoryList.thirteenthListItem.title,
    text: careerHistoryList.thirteenthListItem.text,
  },
  {
    title: careerHistoryList.fourteenthListItem.title,
    text: careerHistoryList.fourteenthListItem.text,
  },
  {
    title: careerHistoryList.fifteenthListItem.title,
    text: careerHistoryList.fifteenthListItem.text,
  },
  {
    title: careerHistoryList.sixteenthListItem.title,
    text: careerHistoryList.sixteenthListItem.text,
  },
  {
    title: careerHistoryList.seventeenthListItem.title,
    text: careerHistoryList.seventeenthListItem.text,
  },
  {
    title: careerHistoryList.eighteenthListItem.title,
    text: careerHistoryList.eighteenthListItem.text,
  },
  {
    title: careerHistoryList.nineteenthListItem.title,
    text: careerHistoryList.nineteenthListItem.text,
  },
];

const styles = StyleSheet.create({
  container: styleType<ViewStyle>({
    flex: 1,
    justifyContent: 'center',
    paddingHorizontal: 20,
  }),
  image: styleType<ImageStyle>({
    aspectRatio: 2572 / 2875,
    marginTop: 40,
  }),
  title: styleType<TextStyle>({
    marginTop: 40,
    marginBottom: 20,
    color: Colors.black100,
  }),
  specialistTitle: styleType<TextStyle>({
    marginTop: 24,
    marginBottom: 20,
    color: Colors.black100,
  }),
  body: styleType<TextStyle>({
    color: Colors.black100,
  }),
  specialist: styleType<TextStyle>({
    color: Colors.black100,
    marginTop: 24,
  }),
  academic: styleType<TextStyle>({
    color: Colors.black100,
    lineHeight: 28,
  }),
});
