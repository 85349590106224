import { type FC } from 'react';
import { type TextProps, type TextStyle, StyleSheet, Text as RNText } from 'react-native';

import { styleType } from '@/utils/styleType';

const LargeTitle: FC<TextProps> = (props) => {
  const { children, style, ...restProps } = props;

  return (
    <RNText style={[styles.base, styles.largeTitle, style]} {...restProps}>
      {children}
    </RNText>
  );
};

const Title1: FC<TextProps> = (props) => {
  const { children, style, ...restProps } = props;

  return (
    <RNText style={[styles.base, styles.title1, style]} {...restProps}>
      {children}
    </RNText>
  );
};

const Title2: FC<TextProps> = (props) => {
  const { children, style, ...restProps } = props;

  return (
    <RNText style={[styles.base, styles.title2, style]} {...restProps}>
      {children}
    </RNText>
  );
};

const Title3: FC<TextProps> = (props) => {
  const { children, style, ...restProps } = props;

  return (
    <RNText style={[styles.base, styles.title3, style]} {...restProps}>
      {children}
    </RNText>
  );
};

const Headline: FC<TextProps> = (props) => {
  const { children, style, ...restProps } = props;

  return (
    <RNText style={[styles.base, styles.headline, style]} {...restProps}>
      {children}
    </RNText>
  );
};

const BoldBody: FC<TextProps> = (props) => {
  const { children, style, ...restProps } = props;

  return (
    <RNText style={[styles.base, styles.boldBody, style]} {...restProps}>
      {children}
    </RNText>
  );
};

const Body: FC<TextProps> = (props) => {
  const { children, style, ...restProps } = props;

  return (
    <RNText style={[styles.base, styles.body, style]} {...restProps}>
      {children}
    </RNText>
  );
};

const SubtextBold: FC<TextProps> = (props) => {
  const { children, style, ...restProps } = props;

  return (
    <RNText style={[styles.base, styles.subtextBold, style]} {...restProps}>
      {children}
    </RNText>
  );
};

const Subtext: FC<TextProps> = (props) => {
  const { children, style, ...restProps } = props;

  return (
    <RNText style={[styles.base, styles.subtext, style]} {...restProps}>
      {children}
    </RNText>
  );
};

const Footnote: FC<TextProps> = (props) => {
  const { children, style, ...restProps } = props;

  return (
    <RNText style={[styles.base, styles.footnote, style]} {...restProps}>
      {children}
    </RNText>
  );
};

const Caption1: FC<TextProps> = (props) => {
  const { children, style, ...restProps } = props;

  return (
    <RNText style={[styles.base, styles.caption1, style]} {...restProps}>
      {children}
    </RNText>
  );
};

export const Text = {
  LargeTitle,
  Title1,
  Title2,
  Title3,
  Headline,
  BoldBody,
  Body,
  SubtextBold,
  Subtext,
  Footnote,
  Caption1,
};

const styles = StyleSheet.create({
  base: styleType<TextStyle>({
    // Webブラウザで、YogaがTextViewをinline要素として描画してしまうバグの対応
    // https://github.com/necolas/react-native-web/issues/1227
    display: 'flex',
    fontFamily: 'Noto Sans JP',
  }),
  largeTitle: styleType<TextStyle>({
    fontSize: 32,
    fontWeight: 'bold',
  }),
  title1: styleType<TextStyle>({
    fontSize: 26,
    fontWeight: 'bold',
  }),
  title2: styleType<TextStyle>({
    fontSize: 20,
    fontWeight: 'bold',
  }),
  title3: styleType<TextStyle>({
    fontSize: 18,
    fontWeight: 'bold',
  }),
  headline: styleType<TextStyle>({
    fontSize: 15,
    fontWeight: 'bold',
  }),
  boldBody: styleType<TextStyle>({
    fontSize: 14,
    fontWeight: 'bold',
  }),
  body: styleType<TextStyle>({
    fontSize: 14,
  }),
  subtextBold: styleType<TextStyle>({
    fontSize: 13,
    fontWeight: 'bold',
  }),
  subtext: styleType<TextStyle>({
    fontSize: 13,
  }),
  footnote: styleType<TextStyle>({
    fontSize: 12,
  }),
  caption1: styleType<TextStyle>({
    fontSize: 11,
  }),
});
