import AsyncStorage from '@react-native-async-storage/async-storage';

const STORAGE_KEY = 'userId';

export type UserId = {
  [STORAGE_KEY]: string | null;
};

export const setUserIdToStorage = async (props: UserId) => {
  await AsyncStorage.setItem(STORAGE_KEY, props[STORAGE_KEY] || '');
};

export const getUserIdFromStorage = async () => {
  const result = await AsyncStorage.getItem(STORAGE_KEY);
  return result;
};

export const removeUserIdFromStorage = async () => {
  await AsyncStorage.removeItem(STORAGE_KEY);
};
