import type { Event } from '@/lib/Logger/types/Event';

type Props = {
  // n step目の画面を開いたか
  index: number;
};

export const TutorialIndexEvent = (props: Props): Event => ({
  name: 'tutorial_index',
  props,
});
