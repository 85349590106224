import { messages as t } from '@/assets/i18n/ja';
import { LoadingView } from '@/components/LoadingView';
import { NavigationHeader } from '@/components/NavigationHeader';
import { ServerErrorView } from '@/components/ServerErrorView';
import { BuildConfig } from '@/constants/BuildConfig';
import { useSmokingHistoryEdit } from '@/hooks/useSmokingHistoryEdit';
import { openURL } from '@/utils/OpenUrl';

import { HasSmokingHistoryTemplate } from './template';

export const HasSmokingHistory = () => {
  const {
    fetching,
    currentFormName,
    hasSmokingHistory,
    hasSmokingHabit,
    currentSmokingHabit,
    pastSmokingHabit,
    hasError,
  } = useSmokingHistoryEdit();

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const handleContactUsListItemPress = () => openURL(BuildConfig.PEPUP_CONTACT_URL);

  if (fetching) return <LoadingView />;

  return (
    <>
      <NavigationHeader title={t.navigation.editAnswers} headerLeft="back" />
      {hasError ||
      !hasSmokingHistory.selections ||
      !hasSmokingHabit.selections ||
      !currentSmokingHabit.fields ||
      !pastSmokingHabit.fields ? (
        <ServerErrorView onContactUsListItemPress={handleContactUsListItemPress} />
      ) : (
        <HasSmokingHistoryTemplate
          currentFormName={currentFormName || 'HAS_SMOKING_HISTORY'}
          // バラさないで渡すとtypeガードが効かないよう
          hasSmokingHistory={{ ...hasSmokingHistory, selections: hasSmokingHistory.selections }}
          hasSmokingHabit={{ ...hasSmokingHabit, selections: hasSmokingHabit.selections }}
          currentSmokingHabit={{ ...currentSmokingHabit, fields: currentSmokingHabit.fields }}
          pastSmokingHabit={{ ...pastSmokingHabit, fields: pastSmokingHabit.fields }}
        />
      )}
    </>
  );
};
