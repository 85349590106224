import { authExchange } from '@urql/exchange-auth';
import type { FC } from 'react';
import { cacheExchange, createClient, dedupExchange, fetchExchange, Provider } from 'urql';

import { BuildConfig } from '@/constants/BuildConfig';
import { removeTokenFromStorage } from '@/store/authToken';
import { openURL } from '@/utils/OpenUrl';

import { authConfig } from './authConfig';

type Props = {
  children: React.ReactNode;
};

const client = createClient({
  /* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-argument */
  url: BuildConfig.API_URL,
  suspense: true,
  exchanges: [dedupExchange, cacheExchange, authExchange(authConfig), fetchExchange],
  fetch: (url, options) =>
    fetch(url, options).then((response) => {
      if (response.status === 401) {
        removeTokenFromStorage().catch(() => undefined);
        openURL(BuildConfig.PEPUP_SSO_URL);
      } else if (response.status === 503) {
        openURL(BuildConfig.MAINTENANCE_URL);
      }

      return response;
    }),
  /* eslint-enable */
});

export const ApiClientProvider: FC<Props> = (props) => {
  const { children } = props;

  return <Provider value={client}>{children}</Provider>;
};
