import { type FC, type ReactNode } from 'react';
import { type ViewStyle, StyleSheet } from 'react-native';

import { Container } from '@/components/Container';
import { ScrollView } from '@/components/ScrollView';
import { styleType } from '@/utils/styleType';

type Props = { children: ReactNode };

export const QuestionnaireContainer: FC<Props> = (props) => {
  const { children } = props;
  return (
    <Container>
      <ScrollView style={styles.container} styleContainer={styles.containerScrollView}>
        {children}
      </ScrollView>
    </Container>
  );
};

const styles = StyleSheet.create({
  container: styleType<ViewStyle>({
    paddingTop: 40,
    paddingHorizontal: 20,
  }),
  containerScrollView: styleType<ViewStyle>({
    flexGrow: 1,
  }),
});
