import { Integrations } from '@sentry/tracing';
import { Platform } from 'react-native';
import * as Sentry from 'sentry-expo';

import { BuildConfig } from '@/constants/BuildConfig';
import { analytics } from '@/lib/Analytics';
import type { Event } from '@/lib/Logger/types/Event';
import { getUserIdFromStorage, removeUserIdFromStorage, setUserIdToStorage } from '@/store/userId';

import { Config } from './Config';

export const Logger = {
  init() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Sentry.init({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ...Platform.select({
        web: {
          ...Config,
          integrations: [new Integrations.BrowserTracing()],
        },
        default: {
          ...Config,
        },
      }),
    });
  },

  logEvent(event: Event) {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      const userId = await getUserIdFromStorage();
      const eventWithCommonProps = {
        ...event,
        props: {
          ...event.props,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          environment: BuildConfig.ENVIRONMENT,
          // "user_id"は予約語なので使えない
          custom_user_id: userId,
        },
      };
      // eslint-disable-next-line no-console
      console.log(eventWithCommonProps);
      analytics.logEvent(eventWithCommonProps);
    })();
  },

  logException(exception: unknown) {
    if (Platform.OS === 'web') {
      Sentry.Browser.captureException(exception);
    } else {
      Sentry.Native.captureException(exception);
    }
    // eslint-disable-next-line no-console
    console.error(exception);
  },

  setUserId(id: string | null) {
    // eslint-disable-next-line no-console
    console.log('UserId:', id);
    analytics.setUserId(id);

    if (id) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      setUserIdToStorage({ userId: id });
    } else {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      removeUserIdFromStorage();
    }

    if (Platform.OS === 'web') {
      Sentry.Browser.setUser(id ? { id } : null);
    } else {
      Sentry.Native.setUser(id ? { id } : null);
    }
  },
};

export type { Event };
