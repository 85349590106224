import { useEffect } from 'react';

import { messages as t } from '@/assets/i18n/ja';
import { LoadingView } from '@/components/LoadingView';
import { NavigationHeader } from '@/components/NavigationHeader';
import { ServerErrorView } from '@/components/ServerErrorView';
import { BuildConfig } from '@/constants/BuildConfig';
import { LIFE_STYLE } from '@/features/questionnaire/constants/QuestionnaireGroupName';
import { useAmountOfDrinkingPerDay } from '@/hooks/useAmountOfDrinkingPerDay';
import { Logger } from '@/lib/Logger';
import { QuestionnaireIndexEvent } from '@/lib/Logger/events';
import { hasGraphQLServerError } from '@/utils/hasGraphQlError';
import { openURL } from '@/utils/OpenUrl';

import { AmountOfDrinkingPerDayTemplate } from './template';

export const AmountOfDrinkingPerDay = () => {
  const { fetching, data, error, questionnaireItem, selections, selectedValue, handleSubmit } =
    useAmountOfDrinkingPerDay();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const handleContactUsListItemPress = () => openURL(BuildConfig.PEPUP_CONTACT_URL);

  useEffect(() => {
    if (questionnaireItem)
      Logger.logEvent(QuestionnaireIndexEvent({ group: LIFE_STYLE, index: questionnaireItem.displayNumber }));
  }, [questionnaireItem]);

  useEffect(() => {
    if (error && hasGraphQLServerError(error)) {
      Logger.logException(error.message);
    }
  }, [error]);

  if (fetching) return <LoadingView />;

  return (
    <>
      <NavigationHeader title={t.navigation.healthyLifespanMeasurement} headerLeft="back" />
      {error || !data || !questionnaireItem || !selections ? (
        <ServerErrorView onContactUsListItemPress={handleContactUsListItemPress} />
      ) : (
        <AmountOfDrinkingPerDayTemplate
          questionNumber={questionnaireItem.displayNumber}
          allQuestionNumber={data.getQuestionnaire.items.length.toString()}
          question={questionnaireItem?.question}
          supplement={questionnaireItem.supplement || ''}
          selections={selections}
          defaultValue={selectedValue}
          onSubmit={handleSubmit}
        />
      )}
    </>
  );
};
