import { type FC, useMemo } from 'react';
import { type ViewStyle, View } from 'react-native';
import {
  VictoryAxis,
  VictoryChart,
  VictoryContainer,
  VictoryLabel,
  VictoryLegend,
  VictoryLine,
  VictoryScatter,
} from 'victory';

import { messages as t } from '@/assets/i18n/ja';
import { Colors } from '@/constants/Colors';
import type { HealthyLifeSpan } from '@/types/graphql';

const GRAPH_MIN_AGE = 50;
const GRAPH_MAX_AGE = 90;

type Props = {
  healthLifeSpan: HealthyLifeSpan;
  isComparisonOfSameGenerationDisplayed: boolean;
  style?: ViewStyle;
};

type GraphData = Array<{
  x: string;
  y: number;
}>;

type GraphLegend = Array<{
  name: string;
  symbol: {
    fill: string;
    type: string;
  };
}>;

export const HorizontalBarGraph: FC<Props> = (props) => {
  const { healthLifeSpan, isComparisonOfSameGenerationDisplayed, style } = props;

  // 同世代比較（同世代比較 = 健康寿命 - 同世代の健康寿命）
  const othersAverageAge = useMemo(
    () => healthLifeSpan.age.year - healthLifeSpan.comparisonOfSameGeneration.year,
    [healthLifeSpan.age.year, healthLifeSpan.comparisonOfSameGeneration.year]
  );

  const userAgeData: GraphData = [
    { y: GRAPH_MIN_AGE, x: t.home.healthyLifeExpectancy.horizontalGraph.userBar },
    { y: healthLifeSpan.age.year, x: t.home.healthyLifeExpectancy.horizontalGraph.userBar },
  ];

  const otherUsersAgeData: GraphData = [
    { y: GRAPH_MIN_AGE, x: t.home.healthyLifeExpectancy.horizontalGraph.otherBar },
    { y: othersAverageAge, x: t.home.healthyLifeExpectancy.horizontalGraph.otherBar },
  ];

  const legendData: GraphLegend = [
    {
      name: t.home.healthyLifeExpectancy.horizontalGraph.legendCurrentLife,
      symbol: { fill: Colors.healthactionGreen, type: 'square' },
    },
    {
      name: t.home.healthyLifeExpectancy.horizontalGraph.legendRequireHospitalization,
      symbol: { fill: Colors.systemRed, type: 'square' },
    },
  ];

  // VictoryLineのLabelのValue
  type Datum = {
    x: number;
    y: number;
  };

  return (
    <View style={style}>
      <VictoryChart
        containerComponent={
          <VictoryContainer
            style={{
              touchAction: 'auto',
            }}
          />
        }
        horizontal
        height={130}
        width={300}
        domain={{ y: [GRAPH_MIN_AGE, GRAPH_MAX_AGE] }}
        domainPadding={{ x: 15 }}
        padding={{ top: 10, bottom: 50, right: 0, left: 50 }}
      >
        <VictoryAxis
          tickValues={
            isComparisonOfSameGenerationDisplayed
              ? [
                  t.home.healthyLifeExpectancy.horizontalGraph.otherBar,
                  t.home.healthyLifeExpectancy.horizontalGraph.userBar,
                ]
              : [t.home.healthyLifeExpectancy.horizontalGraph.userBar]
          }
          // The red line is drawn using grid's style
          style={{
            tickLabels: {
              fontSize: 13,
              fontFamily: 'Noto Sans JP',
            },
            axis: { stroke: 'none' },
            grid: { stroke: 'red', strokeWidth: 5 },
          }}
        />

        {/* Green bars, user's age */}
        <VictoryLine
          labels={({ datum }: { datum: Datum }) =>
            datum.y === GRAPH_MIN_AGE ? '' : `${Math.round(datum.y)}${t.home.healthyLifeExpectancy.horizontalGraph.age}`
          }
          labelComponent={
            <VictoryLabel renderInPortal style={[{ fontSize: 12, fontFamily: 'Noto Sans JP' }]} dx={-8} dy={15} />
          }
          data={userAgeData}
          style={{ data: { stroke: Colors.healthactionGreen, strokeWidth: 5 } }}
        />
        {isComparisonOfSameGenerationDisplayed ? (
          <VictoryLine
            labels={({ datum }: { datum: Datum }) =>
              datum.y === GRAPH_MIN_AGE
                ? ''
                : `${Math.round(datum.y)}${t.home.healthyLifeExpectancy.horizontalGraph.age}`
            }
            labelComponent={
              <VictoryLabel renderInPortal style={[{ fontSize: 12, fontFamily: 'Noto Sans JP' }]} dx={-8} dy={15} />
            }
            data={otherUsersAgeData}
            style={{ data: { stroke: Colors.healthactionGreen, strokeWidth: 5 } }}
          />
        ) : null}
        {/* Rounded view, 1 at the min value and 1 more at the prop value */}
        <VictoryScatter data={userAgeData} size={5} style={{ data: { fill: Colors.healthactionGreen } }} />
        {isComparisonOfSameGenerationDisplayed ? (
          <VictoryScatter data={otherUsersAgeData} size={5} style={{ data: { fill: Colors.healthactionGreen } }} />
        ) : null}
        <VictoryLegend
          style={{
            labels: { fontSize: 12, fontFamily: 'Noto Sans JP' },
          }}
          y={100}
          x={115}
          orientation="horizontal"
          gutter={50}
          symbolSpacer={8}
          data={legendData}
        />
      </VictoryChart>
    </View>
  );
};
