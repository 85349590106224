import type { QuestionnaireGroupName } from '@/features/questionnaire/constants/QuestionnaireGroupName';
import type { Event } from '@/lib/Logger/types/Event';

type Props = {
  group: QuestionnaireGroupName;
  index: string;
};

export const QuestionnaireIndexEvent = (props: Props): Event => ({
  name: 'questionnaire_index',
  props,
});
