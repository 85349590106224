import { z } from 'zod';

import { messages as t } from '@/assets/i18n/ja';

export const halfWidthNumbersRequired = z
  .string({ required_error: t.validationError.inputRequired })
  .min(1, { message: t.validationError.inputRequired })
  .regex(/^[0-9]+$/g, { message: t.validationError.halfWidthNumber });

export const withInOneHundredLength = z
  .string()
  .max(100, { message: t.validationError.withInOneHundredLength })
  .optional();
