import { useCallback } from 'react';

import { BuildConfig } from '@/constants/BuildConfig';
import { openURL } from '@/utils/OpenUrl';

export const useCannotPredictHealthData = () => {
  const handleReturnToPepUpPress = useCallback(() => {
    /* eslint-disable-next-line @typescript-eslint/no-unsafe-argument */
    openURL(BuildConfig.PEPUP_URL);
  }, []);

  return {
    handleReturnToPepUpPress,
  };
};
