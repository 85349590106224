import * as Types from '../../../../types/graphql';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type VerifyAccessTokenMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type VerifyAccessTokenMutation = { __typename?: 'Mutation', verifyAccessToken: { __typename?: 'VerifyAccessTokenPayload', token: string, expiresAt: any } };


export const VerifyAccessTokenDocument = gql`
    mutation VerifyAccessToken {
  verifyAccessToken {
    token
    expiresAt
  }
}
    `;

export function useVerifyAccessTokenMutation() {
  return Urql.useMutation<VerifyAccessTokenMutation, VerifyAccessTokenMutationVariables>(VerifyAccessTokenDocument);
};