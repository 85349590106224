import { type FC } from 'react';

import { AgeMark } from './AgeMark';
import { ArrowLeft } from './ArrowLeft';
import { ArrowRight } from './ArrowRight';
import { Bell } from './Bell';
import { Brain } from './Brain';
import { Cancel } from './Cancel';
import { CheckboxActive } from './CheckboxActive';
import { CheckboxInactive } from './CheckboxInactive';
import { CheckCircle } from './CheckCircle';
import { Chevron } from './Chevron';
import { Heart } from './Heart';
import { Kidney } from './Kidney';
import { Liver } from './Liver';
import { Lung } from './Lung';
import { Menu } from './Menu';
import { RadioActive } from './RadioActive';
import { RadioInactive } from './RadioInactive';
import { RankFifth } from './RankFifth';
import { RankFirst } from './RankFirst';
import { RankFourth } from './RankFourth';
import { RankSecond } from './RankSecond';
import { RankThird } from './RankThird';

const Icons = {
  CheckCircle,
  ArrowLeft,
  ArrowRight,
  RadioActive,
  RadioInactive,
  CheckboxActive,
  CheckboxInactive,
  Chevron,
  Cancel,
  Menu,
  RankFirst,
  RankSecond,
  RankThird,
  RankFourth,
  RankFifth,
  Brain,
  Heart,
  Lung,
  Liver,
  Kidney,
  AgeMark,
  Bell,
};

type IconProps = {
  type: keyof typeof Icons;
  size?: number;
  fill?: string;
  stroke?: string;
  hasBadge?: boolean;
};

export const Icon: FC<IconProps> = (props) => {
  const { type } = props;
  const IconSvg = Icons[type];

  return <IconSvg {...props} />;
};
