import { type TextStyle, type ViewStyle, StyleSheet } from 'react-native';

import { messages as t } from '@/assets/i18n/ja';
import { Container } from '@/components/Container';
import { Text } from '@/components/Text';
import { styleType } from '@/utils/styleType';

export const ForbiddenErrorView = () => (
  <Container style={styles.container}>
    <Text.Headline style={styles.title}>{t.error.forbidden.title}</Text.Headline>
    <Text.Body style={styles.text}>{t.error.forbidden.text}</Text.Body>
  </Container>
);

const styles = StyleSheet.create({
  container: styleType<ViewStyle>({
    alignItems: 'center',
    paddingHorizontal: 20,
    paddingTop: 40,
  }),
  title: styleType<TextStyle>({
    paddingBottom: 12,
    textAlign: 'center',
  }),
  text: styleType<TextStyle>({
    textAlign: 'center',
  }),
});
