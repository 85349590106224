/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import * as Application from 'expo-application';
import Constants from 'expo-constants';
import type { FirebaseOptions } from 'firebase/app';

const EXPO_APPLICATION_ID = 'host.exp.Exponent';

const ENVIRONMENT = Constants.manifest?.extra?.environment;
const ENABLE_MSW = Constants.manifest?.extra?.enableMsw;
const PEPUP_URL = Constants.manifest?.extra?.pepupUrl;
const PEPUP_SSO_URL = Constants.manifest?.extra?.pepupSsoUrl;
const PEPUP_CONTACT_URL = Constants.manifest?.extra?.pepupContactUrl;
const API_URL = Constants.manifest?.extra?.apiUrl;
const MAINTENANCE_URL = Constants.manifest?.extra?.maintenanceUrl;
const FIREBASE_WEB_CONFIG = Constants.manifest?.extra?.firebaseWebConfig as FirebaseOptions;
const isExpoClient = Application.applicationId === EXPO_APPLICATION_ID;
const isDebug = __DEV__;

export const BuildConfig = {
  ENVIRONMENT,
  ENABLE_MSW,
  PEPUP_URL,
  PEPUP_SSO_URL,
  PEPUP_CONTACT_URL,
  API_URL,
  MAINTENANCE_URL,
  FIREBASE_WEB_CONFIG,
  isExpoClient,
  isDebug,
};
