import { messages as t } from '@/assets/i18n/ja';
import { LoadingView } from '@/components/LoadingView';
import { NavigationHeader } from '@/components/NavigationHeader';
import { ServerErrorView } from '@/components/ServerErrorView';
import { BuildConfig } from '@/constants/BuildConfig';
import { useUpdateQuestionnaireAnswers } from '@/features/myPage/hooks/useUpdateQuestionnaireAnswers';
import { useCurrentSmokingHabit } from '@/hooks/useCurrentSmokingHabit';
import { hasGraphQLServerError } from '@/utils/hasGraphQlError';
import { openURL } from '@/utils/OpenUrl';

import { CurrentSmokingHabitTemplate } from './template';

export const CurrentSmokingHabit = () => {
  const { data, fetching, error, questionnaireItem, fields } = useCurrentSmokingHabit();
  const { updateLifeStyleQuestionnaireAnswers, hasError } = useUpdateQuestionnaireAnswers();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const handleContactUsListItemPress = () => openURL(BuildConfig.PEPUP_CONTACT_URL);

  if (fetching) return <LoadingView />;

  return (
    <>
      <NavigationHeader title={t.navigation.editAnswers} headerLeft="back" />
      {hasError || (error && hasGraphQLServerError(error)) || !data || !questionnaireItem || !fields ? (
        <ServerErrorView onContactUsListItemPress={handleContactUsListItemPress} />
      ) : (
        <CurrentSmokingHabitTemplate
          question={questionnaireItem.question}
          fields={fields}
          onSubmit={(props) => updateLifeStyleQuestionnaireAnswers(props, questionnaireItem.displayNumber)}
        />
      )}
    </>
  );
};
