import { type FC } from 'react';
import { type ViewStyle, StyleSheet, View } from 'react-native';

import { messages as t } from '@/assets/i18n/ja';
import { ActivityIndicator } from '@/components/ActivityIndicator';
import { Container } from '@/components/Container';
import { Text } from '@/components/Text';
import { styleType } from '@/utils/styleType';

export const PredictingHealthyLifeSpanTemplate: FC = () => (
  <Container style={styles.container}>
    <View style={styles.textContainer}>
      <Text.Body>{t.questionnaire.predictingHealthyLifeSpan}</Text.Body>
      <ActivityIndicator style={styles.activityIndicator} />
    </View>
  </Container>
);

const styles = StyleSheet.create({
  container: styleType<ViewStyle>({
    justifyContent: 'center',
    alignItems: 'center',
  }),
  textContainer: styleType<ViewStyle>({
    flexDirection: 'row',
  }),
  activityIndicator: styleType<ViewStyle>({
    marginLeft: 8,
  }),
});
