import type { ComponentProps, FC } from 'react';

import { MedicalHistory } from '@/components/questionnaire/form/medicalHistory';

import { QuestionnaireContainer } from '../parts/QuestionnaireContainer';
import type { QuestionNumber } from '../parts/QuestionNumber';

type Props = Omit<ComponentProps<typeof QuestionNumber>, 'style'> & ComponentProps<typeof MedicalHistory>;

export const MedicalHistoryTemplate: FC<Props> = (props) => {
  const { questionNumber, allQuestionNumber, question, fields, onSubmit } = props;

  return (
    <QuestionnaireContainer questionNumber={questionNumber} allQuestionNumber={allQuestionNumber} isRequired={false}>
      <MedicalHistory question={question} fields={fields} onSubmit={onSubmit} />
    </QuestionnaireContainer>
  );
};
