import { type ComponentProps, type FC } from 'react';

import { HasHabitOfSkipBreakfast } from '@/components/questionnaire/form/lifeStyle';
import { QuestionnaireContainer } from '@/features/myPage/components/parts/QuestionnaireContainer';

type Props = ComponentProps<typeof HasHabitOfSkipBreakfast>;

export const HasHabitOfSkipBreakfastTemplate: FC<Props> = (props) => {
  const { question, selections, defaultValue, onSubmit } = props;

  return (
    <QuestionnaireContainer>
      <HasHabitOfSkipBreakfast
        question={question}
        selections={selections}
        defaultValue={defaultValue}
        onSubmit={onSubmit}
        isSubmitButtonPresent={true}
      />
    </QuestionnaireContainer>
  );
};
