import { graphqlContext, MockedRequest, ResponseResolver } from 'msw';

import { CreateUserPayload } from '@/types/graphql';

const createUserPayload: { createUser: CreateUserPayload } = {
  createUser: {
    id: 'hogehoge12345678',
  },
};

export const mockCreateUser: ResponseResolver<MockedRequest, typeof graphqlContext> = (req, res, ctx) =>
  res(ctx.data(createUserPayload));
