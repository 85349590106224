import { type FC } from 'react';

import { Colors } from '@/constants/Colors';

type Props = {
  size?: number;
  fill?: string;
};

export const RankFirst: FC<Props> = (props) => {
  const { size = 24, fill = Colors.black100 } = props;

  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="transparent" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2_308)">
        <path
          d="M1 3.73913L7.31053 5.74783L12 1L16.6895 5.74783L23 3.73913L21.3789 22H2.62105L1 3.73913Z"
          fill={fill}
        />
        <path
          d="M9.43294 18H14.7849V16.572H13.1169V9.12H11.8089C11.2329 9.468 10.6329 9.696 9.73294 9.864V10.956H11.3529V16.572H9.43294V18Z"
          fill="white"
        />
      </g>

      <defs>
        <clipPath id="clip0_2_308">
          <rect width={size} height={size} fill="transparent" transform="matrix(-1 0 0 1 24 0)" />
        </clipPath>
      </defs>
    </svg>
  );
};
