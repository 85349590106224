import { type FC } from 'react';
import { type ImageStyle, type TextStyle, type ViewStyle, Image, StyleSheet } from 'react-native';

import { messages as t } from '@/assets/i18n/ja';
import IMAGE_URI from '@/assets/images/apologies_figure.png';
import { Button } from '@/components/Button';
import { Container } from '@/components/Container';
import { Text } from '@/components/Text';
import { styleType } from '@/utils/styleType';

type Props = {
  onReturnToPepUpPress: () => void;
};

export const CannotPredictHealthLimitTemplate: FC<Props> = (props) => {
  const { onReturnToPepUpPress } = props;

  return (
    <Container style={styles.container}>
      <Image source={{ uri: IMAGE_URI }} resizeMode="contain" style={styles.image} />

      <Text.Headline style={styles.title}>{t.tutorial.cannotPredictHealthLimit.title}</Text.Headline>
      <Text.Body style={styles.detail}>{t.tutorial.cannotPredictHealthLimit.detail}</Text.Body>
      <Button type="active" onPress={onReturnToPepUpPress} uppercase={false}>
        {t.home.noHealthData.returnToPepUp}
      </Button>
    </Container>
  );
};

const styles = StyleSheet.create({
  container: styleType<ViewStyle>({
    flex: 1,
    paddingHorizontal: 20,
    paddingTop: 40,
  }),
  image: styleType<ImageStyle>({
    alignSelf: 'center',
    width: 60,
    height: 90,
  }),
  title: styleType<TextStyle>({
    marginTop: 20,
    textAlign: 'center',
    justifyContent: 'center',
  }),
  detail: styleType<TextStyle>({
    marginVertical: 20,
  }),
});
