import type { ComponentProps, FC } from 'react';
import type { TextStyle, ViewStyle } from 'react-native';
import { StyleSheet } from 'react-native';
import { Button as RNPButton } from 'react-native-paper';

import { Text } from '@/components/Text';
import { Colors } from '@/constants/Colors';
import { styleType } from '@/utils/styleType';

type Props = {
  type: 'active' | 'active2' | 'inactive' | 'text';
  textContentStyle?: TextStyle;
} & Omit<ComponentProps<typeof RNPButton>, 'theme' | 'dark'>;

export const Button: FC<Props> = (props) => {
  const { type, children, onPress, ...restProps } = props;

  switch (type) {
    case 'active':
      return (
        <RNPButton
          mode="contained"
          onPress={onPress}
          color={Colors.healthactionGreen}
          style={styles.containedContainerStyle}
          contentStyle={styles.contentStyle}
          labelStyle={styles.labelStyle}
          {...restProps}
        >
          {children}
        </RNPButton>
      );
    case 'active2':
      return (
        <RNPButton
          mode="outlined"
          onPress={onPress}
          color={Colors.healthactionGreen}
          style={styles.outlinedContainerStyle}
          contentStyle={styles.contentStyle}
          labelStyle={styles.labelStyle}
          {...restProps}
        >
          {children}
        </RNPButton>
      );
    case 'inactive':
      return (
        <RNPButton
          onPress={onPress}
          mode="contained"
          color={Colors.black26}
          style={[styles.containedContainerStyle, styles.inactiveContainerStyle]}
          contentStyle={styles.contentStyle}
          labelStyle={[styles.labelStyle, styles.inactiveLabelStyle]}
          disabled
          {...restProps}
        >
          {children}
        </RNPButton>
      );
    case 'text':
      return (
        <Text.BoldBody onPress={onPress} style={[styles.labelStyle, styles.textLabelStyle]}>
          {children}
        </Text.BoldBody>
      );
    default:
      return null;
  }
};

const styles = StyleSheet.create({
  containedContainerStyle: styleType<ViewStyle>({
    shadowColor: 'transparent',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0,
    shadowRadius: 0,
  }),
  outlinedContainerStyle: styleType<ViewStyle>({
    borderWidth: 1,
    borderColor: Colors.healthactionGreen,
  }),
  contentStyle: styleType<ViewStyle>({
    marginVertical: 16,
  }),
  labelStyle: styleType<TextStyle>({
    fontFamily: 'Noto Sans JP',
    fontSize: 14,
    fontWeight: 'bold',
    marginVertical: 0,
    marginHorizontal: 0,
  }),
  inactiveContainerStyle: styleType<ViewStyle>({
    backgroundColor: Colors.black26,
  }),
  inactiveLabelStyle: styleType<TextStyle>({
    color: Colors.white100,
  }),
  textLabelStyle: styleType<TextStyle>({
    color: Colors.healthactionGreen,
  }),
});
