import type { QuestionnaireGroupName } from '@/features/questionnaire/constants/QuestionnaireGroupName';
import type { Event } from '@/lib/Logger/types/Event';

type Props = {
  group: QuestionnaireGroupName;
};

export const QuestionnaireBeginEvent = (props: Props): Event => ({
  name: 'questionnaire_begin',
  props,
});
