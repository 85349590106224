import { type FC } from 'react';
import { StyleSheet, View } from 'react-native';

import { Button } from '@/components/Button';

type Props = {
  index: number;
  label: string;
  isActive: boolean;
  onPress: () => void;
};

export const SelectionButton: FC<Props> = (props) => {
  const { index, label, isActive, onPress } = props;

  return (
    <View style={index ? styles.buttonContainer : null}>
      <Button type={isActive ? 'active' : 'active2'} onPress={onPress} accessibilityRole="radio">
        {label}
      </Button>
    </View>
  );
};

const styles = StyleSheet.create({
  buttonContainer: {
    marginTop: 8,
  },
});
