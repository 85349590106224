import type { FC } from 'react';
import type { ImageSourcePropType, TextStyle, ViewStyle } from 'react-native';
import { ImageBackground, ScrollView, StyleSheet, View } from 'react-native';

import { messages as t } from '@/assets/i18n/ja';
import IMAGE_URI from '@/assets/images/tutorial_1.png';
import { Text } from '@/components/Text';
import { Colors } from '@/constants/Colors';
import { Footer } from '@/features/tutorial/components/Tutorial/parts/Footer';
import { styleType } from '@/utils/styleType';

type Props = {
  onPressRight: () => void;
};

export const TutorialFirstPage: FC<Props> = (props) => {
  const { onPressRight } = props;

  const imageSource: ImageSourcePropType = { uri: IMAGE_URI };

  return (
    <View style={styles.container}>
      <ScrollView style={styles.scrollView}>
        <ImageBackground source={imageSource} resizeMode="cover" style={styles.image}>
          <Text.Title3 style={styles.imageText}>{t.tutorial.firstPage.imageTitle}</Text.Title3>
        </ImageBackground>
        <View style={styles.content}>
          <Text.Headline style={styles.title}>{t.tutorial.firstPage.goalTitle}</Text.Headline>
          <Text.Body>{t.tutorial.firstPage.goalText}</Text.Body>
          <Text.Footnote style={styles.footNote}>{t.tutorial.firstPage.footNoteText}</Text.Footnote>
        </View>
      </ScrollView>
      <View style={styles.footerContainer}>
        <Footer pageIndicatorIndex={1} onPressRight={onPressRight} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: styleType<ViewStyle>({
    flex: 1,
    justifyContent: 'flex-start',
  }),
  scrollView: styleType<ViewStyle>({
    paddingBottom: 92,
  }),
  image: styleType<ViewStyle>({
    paddingTop: 240,
  }),
  imageText: styleType<TextStyle>({
    color: Colors.white100,
    paddingHorizontal: 20,
    paddingBottom: 16,
  }),
  content: styleType<ViewStyle>({
    paddingHorizontal: 20,
  }),
  title: styleType<TextStyle>({
    paddingTop: 42,
    paddingBottom: 8,
  }),
  footNote: styleType<TextStyle>({
    paddingTop: 8,
    color: Colors.black54,
  }),
  footerContainer: styleType<ViewStyle>({
    bottom: 0,
  }),
});
