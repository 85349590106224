import { useCallback } from 'react';
import { type FlatListProps, FlatList as RNFlatList } from 'react-native';

type Props<T> = FlatListProps<T>;

export const FlatList = <T,>(props: Props<T>) => {
  const { data, renderItem, ItemSeparatorComponent, style } = props;
  const keyExtractor = useCallback((item: T, index: number) => `item-${index}`, []);

  return (
    <RNFlatList
      data={data}
      keyExtractor={keyExtractor}
      renderItem={renderItem}
      ItemSeparatorComponent={ItemSeparatorComponent}
      style={style}
    />
  );
};
