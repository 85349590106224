import { type ComponentProps, type FC } from 'react';

import { messages as t } from '@/assets/i18n/ja';
import {
  CurrentSmokingHabit,
  HasSmokingHabit,
  HasSmokingHistory,
  PastSmokingHabit,
} from '@/components/questionnaire/form/lifeStyle';
import { QuestionnaireContainer } from '@/features/myPage/components/parts/QuestionnaireContainer';
import type { FormName } from '@/hooks/useSmokingHistoryEdit';

type Props = {
  currentFormName: FormName;
  hasSmokingHistory: ComponentProps<typeof HasSmokingHistory>;
  hasSmokingHabit: ComponentProps<typeof HasSmokingHabit>;
  currentSmokingHabit: ComponentProps<typeof CurrentSmokingHabit>;
  pastSmokingHabit: ComponentProps<typeof PastSmokingHabit>;
};

export const HasSmokingHistoryTemplate: FC<Props> = (props) => {
  const { currentFormName, hasSmokingHistory, hasSmokingHabit, currentSmokingHabit, pastSmokingHabit } = props;

  const Form = () =>
    renderForm(currentFormName, hasSmokingHistory, hasSmokingHabit, currentSmokingHabit, pastSmokingHabit);

  return (
    <QuestionnaireContainer>
      <Form />
    </QuestionnaireContainer>
  );
};

const renderForm = (
  formName: FormName,
  hasSmokingHistory: ComponentProps<typeof HasSmokingHistory>,
  hasSmokingHabit: ComponentProps<typeof HasSmokingHabit>,
  currentSmokingHabit: ComponentProps<typeof CurrentSmokingHabit>,
  pastSmokingHabit: ComponentProps<typeof PastSmokingHabit>
) => {
  switch (formName) {
    case 'HAS_SMOKING_HISTORY':
      return <HasSmokingHistory {...hasSmokingHistory} isSubmitButtonPresent={true} />;
    case 'HAS_SMOKING_HABIT':
      return <HasSmokingHabit {...hasSmokingHabit} isSubmitButtonPresent={true} />;
    case 'CURRENT_SMOKING_HABIT':
      return (
        <CurrentSmokingHabit
          {...currentSmokingHabit}
          submitButtonLabel={t.myPage.pastQuestionnaireAnswers.save}
          submitButtonPosition="absolute"
        />
      );
    case 'PAST_SMOKING_HABIT':
      return (
        <PastSmokingHabit
          {...pastSmokingHabit}
          submitButtonLabel={t.myPage.pastQuestionnaireAnswers.save}
          submitButtonPosition="absolute"
        />
      );
    default:
      throw new Error('formName is undefined or illegal name');
  }
};
