import type { FC } from 'react';
import type { TextStyle } from 'react-native';
import { StyleSheet } from 'react-native';

import { messages as t } from '@/assets/i18n/ja';
import { Text } from '@/components/Text';
import { Colors } from '@/constants/Colors';
import { styleType } from '@/utils/styleType';

type Props = {
  questionNumber: string;
  allQuestionNumber: string;
  style?: TextStyle;
};

export const QuestionNumber: FC<Props> = (props) => {
  const { questionNumber, allQuestionNumber, style } = props;

  return (
    <Text.SubtextBold
      style={[styles.questionNumber, style]}
    >{`${questionNumber}${t.questionnaire.currentIndexLabel} / ${allQuestionNumber}${t.questionnaire.allIndexLabel}`}</Text.SubtextBold>
  );
};

const styles = StyleSheet.create({
  questionNumber: styleType({
    color: Colors.healthactionGreen,
  }),
});
