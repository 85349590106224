import type { Messages } from './index';

export const messages: Messages = {
  registration: {
    linkedToExternalSite: '外部サイトへ連携しています...',
    goToPepUpConnection: 'Pep Up連携へすすむ'
  },
  tutorial: {
    firstPage: {
      imageTitle: 'ヘルスリミットを予測して、健康リスクを理解しよう。',
      goalTitle: 'ヘルスリミット（β版） とは',
      goalText: 'あなたの健診値とこれまでの生活習慣から、あなたが要通院となるまでの平均的な時間を予測する、それがヘルスリミットです。今後、あなた自身が健康でいられる時間があとどのくらいあるのかを算出してみましょう。',
      footNoteText: '本機能において特定の疾患に紐づくリスクはなく、病気の特定も行われません。',
    },
    secondPage: {
      meritTitle: 'ヘルスリミット (β版)でできること',
      meritFirstPointTitle: '・ヘルスリミット予測',
      meritFirstPointText: '　あなたのヘルスリミットが予測できます',
      meritSecondPointTitle: '・ヘルスリミットの同世代比較',
      meritSecondPointText: '　同世代と比較した時のヘルスリミットの差を確認できます',
      meritThirdPointTitle: '・重要臓器ごとのヘルスリミット予測',
      meritThirdPointText: '　脳・肺・心臓・肝臓・腎臓それぞれの臓器のヘルスリミットを予測できます',
    },
    thirdPage: {
      completeButton: '予測へ',
      termsPleaseReadText: '下記の注意事項をスクロールして読了すると、ヘルスリミット予測をはじめられます。\n\n【注意事項】\nヘルスリミット算出には健診値が必要となります。お客様の健診値が不足している場合は、ヘルスリミットが算出ができない場合もございますのでご了承ください。',
      termsTitle: '【ヘルスリミット予測における特則】',
      termsAccuracyTitle: '■個人情報の取扱い',
      termsTopText: 'ヘルスリミット予測は、お客様から取得した情報に基づき、当社独自のアルゴリズムで分析したうえで、お客様に対してヘルスリミット（ ）の算出その他関連情報の提供を行うサービスです。※ Pep Up の利用規約（https://pepup.life/terms）及びプライバシーポリシー（https://pepup.life/privacy）が適用されるほか、特則として以下の定めが適用されます。（ ）ヘルスリミットとは、お客様の健診等情報と生活習慣の情報に基づき、要通院を必要とする特定疾患が起こり得る臓器の可能性を※算出し、お客様が健康でいられる時間を表示するものです。',
      termsAccuracyText: '・当社は、ヘルスリミット予測上でお客様が登録した情報（以下「登録情報」といいます。）を取得し、Pep Upに登録された健診等情報と紐づけたうえで分析し、ヘルスリミット予測の提供を行います。\n・お客様の登録情報及びヘルスリミットについて、広告配信には使用せず、ヘルスリミット予測のサービス提供に必要な範囲を超えてパーソナライズその他コンテンツ最適化のためには使用せず、また、第三者（健康保険組合等及び勤務先を含みます。）に提供することはありません。',
      useOfDataTitle: '■注意事項',
      useOfDataText: '・ヘルスリミット予測は、お客様の健康管理のために参考情報の提供を行うことを目的とするものであり、疾病の診断、治療又は予防に使用されること及び医業を行うことを目的としておりません。\n・ヘルスリミットその他のヘルスリミット予測で提供された情報についてのいかなる事項（その客観性、正確性、妥当性を含みます。）についても当社は保証せず、その適合性、妥当性及び正確性の判断、お客様の健康状態の判断並びに医療機関及び医療従事者への受診及び相談は、お客様自身の責任において行われるものとし、当社は関与いたしません。\n・ヘルスリミット予測上で別途定める場合を除き、当社は、ヘルスリミット算出のために使用するアルゴリズムについて開示いたしません。',
    },
    cannotPredictHealthLimit: {
      title: '申し訳ありません。\n予測ができないようです。',
      detail: '・予測に必要な健診データが連携がされていませんでした\n\n・健診データの反映を待つか、健診データがアップデートされた際にご利用を再開ください\n\n・なお、Pep Upの他の機能はこれまで通りご利用いただけます。今後ともPep Upをよろしくお願いいたします',
    },
    nextButton: '次へ',
    backButton: '戻る',
  },
  questionnaire: {
    start: {
      title: '次のページから始まる、3つのカテゴリのアンケートに回答しましょう',
      description: '1. 生活に関するアンケート（必須）\n2. 環境に関するアンケート（必須）\n3. 既往歴に関するアンケート（任意）',
      buttonText: 'さっそく予測を開始する'
    },
    currentIndexLabel: '問目',
    allIndexLabel: '問中',
    requiredLabel: {
      optional: '任意',
    },
    pagingButtonLabel: '次へ',
    lifestyleAnswersComplete: 'お疲れ様でした。\n次は生活環境についての予測になります。',
    environmentAnswersComplete: 'お疲れ様でした。\n次は既往歴についての予測になります。',
    medicalHistoryAnswersComplete: 'お疲れ様でした。\n回答が完了しました。\nヘルスリミットを予測しています。',
    failedToSendAnswers: '回答の送信に失敗しました。\n通信状態を確認した上で再度お試しください。',
    medicalHistoryCategory: {
      brain: '脳',
      heart: '心臓',
      lung: '肺',
      liver: '肝臓',
      kidney: '腎臓',
      cancer: '癌',
      otherDisease: 'そのほか疾患'
    },
    predictingHealthyLifeSpan: 'ヘルスリミットを予測しています'
  },
  myPage: {
   healthInformationSection: {
     healthInformation: '健康情報',
     pastQuestionnaireAnswers: '過去のヘルスリミット予測回答',
   },
    accountSettingsSection: {
     accountSettings: 'アカウント設定',
     announcements: 'お知らせ',
     logout: 'ログアウト',
   },
    othersSection: {
      others: 'そのほか',
      aboutSpecialistSupervision: '情報提供について',
      aboutAgeExpectancy: '算出根拠について',
      contactUs: 'お問い合わせ',
      company: '運営会社',
    },
    pastQuestionnaireAnswers: {
      lifeStyle: '生活',
      environment: '環境',
      medicalHistory: '既往歴',
      edit: '編集する',
      toMyPage: 'マイページへ',
      save: '保存する',
      yes: 'あり',
      no: 'なし',
      replied: '回答：'
    },
    logOut: {
      confirmation: 'ログアウトしますか？',
      buttonLabels: {
        submit: 'はい',
        cancel: 'いいえ'
      }
    },
    aboutSpecialistSupervision: {
      firstSection: {
        title: 'ヘルスリミットの情報',
        text: 'Pep Up では、ヘルスリミットにおいて正確な情報を提供するために、ご提供するコンテンツに関して専門医の先生に監修いただいています。',
      },
      secondSection: {
        title: '監修者のご紹介',
        text: 'ヘルスリミットの算出ロジック、またコンテンツ全体については、専門の医師に監修いただいています。なお、算出ロジックに関しては、JMDCデータラボ研究室が開発したロジックを監修いただいています。\n2023年4月監修。',
        specialist: '阪和病院・阪和記念病院　\n統括院長・総長\n北風 政史',
      },
      thirdSection: {
        title: '学会',
        text: '日本循環器学会\n日本内科学会\n日本心不全学会\n日本冠疾患学会\nAmerican Heart Association\nAmerican College of Cardiology\nEuropean Society of Cardiology等',
      },
      fourthSection: {
        title: '略歴',
        careerHistoryList: {
          firstListItem: {
            title: "昭和52年3月",
            text: "京都大学工学部3年次 修了"
          },
          secondListItem: {
            title: "昭和52年4月",
            text: "大阪大学医学部 編入学",
          },
          thirdListItem:{
            title: "昭和56年3月",
            text: "大阪大学医学部 卒業",
          },
          fourthListItem: {
            title: "昭和60年3月",
            text: "大阪大学医学部博士課程（第一内科） 修了",
          },
          fifthListItem: {
            title: "昭和61年10月",
            text: "米国Johns Hopkins University医学部附属病院内科心臓部門 留学",
          },
          sixthListItem: {
            title: "平成9年4月",
            text: "大阪大学医学部助手（第一内科）",
          },
          seventhListItem: {
            title: "平成11年10月",
            text: "大阪大学大学院医学研究科（病態情報内科学）学部内講師",
          },
          heighthListItem: {
            title: "平成13年6月",
            text: "国立循環器病センター 心臓血管内科部長",
          },
          ninthListItem: {
            title: "平成17年10月",
            text: "国立循環器病センター臨床研究開発部長及び臨床研究センター副センター長 (併任)",
          },
          tenthListItem: {
            title: "平成18年4月",
            text: "大阪大学医学部 臨床教授 （併任）（平成22年3月末まで）",
          },
          eleventhListItem: {
            title: "平成18年4月",
            text: "鳥取大学大学院医学系研究科 客員教授（併任）（平成21年3月末まで）",
          },
          twelfthListItem: {
            title: "平成18年6月",
            text: "中国第４陸軍軍医大学 心臓血管内科 客員教授（併任）",
          },
          thirteenthListItem: {
            title: "平成19年4月",
            text: "大阪市立大学医学部 非常勤講師（併任）（平成26年3月末まで）",
          },
          fourteenthListItem: {
            title: "平成20年9月",
            text: "中国南方医科大学 客員教授 (併任）",
          },
          fifteenthListItem: {
            title: "平成29年6月",
            text: "滋賀医科大学 客員教授 (併任）",
          },
          sixteenthListItem: {
            title: "平成31年4月",
            text: "国立循環器病研究センター 臨床研究開発部長（心臓血管内科併任）",
          },
          seventeenthListItem: {
            title: "令和2年4月",
            text: "阪和第二泉北病院 院長",
          },
          eighteenthListItem: {
            title: "令和2年4月",
            text: "大阪大学大学院医学系研究科・生命機能研究科 医化学講座 招へい教授（併任）",
          },
          nineteenthListItem: {
            title: "令和4年6月",
            text: "阪和病院・阪和記念病院 統括院長・総長",
          },
          twentiethListItem: {
            title: "令和5年4月",
            text: "大阪公立大学大学院医学研究科 心血管先端医療開発寄付講座 特任教授 （併任）",
          }
        }
      }
    },
    aboutAgeExpectancy: {
      introSection: {
        title: '算出根拠について',
        text: 'ヘルスリミットの算出根拠につきましては、JMDCデータイノベーションラボの研究により展開しています。\n当社は、「データと ICT の力で持続可能なヘルスケアシステムを実現する」をミッションとし、信頼性の高い情報を提供するために体制を整え研究を推進しています。',
      },
      firstSection: {
        title: '１．対象疾患の選定',
        text: '各主要臓器（心臓、脳、肝臓、腎臓、肺）に対し、2条件\n・生活習慣と深い関わりがあることが先行研究より判明している。\n・臓器を正常な状態に戻す治療が難しい。\nをみたす疾患として以下の疾患を選定。',
        note: '表1　臓器ごとの対象疾患の選定',
      },
      secondSection: {
        title: '２．ヘルスリミット算出モデルの構築',
        text: '「健診値推移モデル」、「発生率予測モデル」の2つの補助モデルを含む「残存脱退モデル」を構築した。',
        note: '図1　ヘルスリミット算出モデル全体の流れ',
      },
      thirdSection: {
        title: '３．残存脱退モデル',
        text: '最新の健診受診日をt=0、そのn年後をt=n（n=1,2,…）とする。\nt=0では残存割合1とし、そこから毎年、対象疾患発生と死亡で脱退させていく。',
        note: '図2　残存脱退モデル',
        secondText: 'ここで、対象疾患発生率は「発生率予測モデル」で得られるもの、死亡率は「第三分野標準生命表2018」の性別・年齢別死亡率とした。\n第三分野標準生命表2018は、民間の医療保険などについて将来の保険給付債務を推定するために保険会社が使用している死亡率である。',
      },
      fourthSection: {
        title: '４．健診項目の選定',
        text: '健診値推移モデル、発生率予測モデルに用いる健診項目を、先行研究の知見（例：高血圧は脳卒中のリスク因子）を踏まえ以下のように選定した。\n多くの健診で実施されている主要な健診計測項目であって、「１．対象疾患の選定」と関わりが深い健診項目として、以下の健診計測項目を選定した。',
        note: '表2　健診計測項目の選定',
        secondText: '生活習慣としては、多くの健診で生活習慣に関する予測項目を選定し、それぞれについて良い回答を選定した。',
        secondNote: '表3 予測項目の選定'
      },
      fifthSection: {
        title: '５．発生率予測モデル',
        text: '以下の2ステップにより、発生率予測モデルを構築した。\n・ステップ1：　JMDCの健康保険組合由来データをもとに、ある時点の2年分の健診とその後3年間の対象疾患発生率との関連を分析し、予測モデルを構築。\n・ステップ2：　JMDCの健康保険組合由来データに含まれない因子について、先行研究のリスク比を用いて調整。\nステップ1は下図の通り。なお、得られた係数が先行研究からの知見と概ね整合するかも確認した。',
        note: '図3　発生率予測モデル（他の因子の調整前）',
        secondText: 'ステップ2としては、以下の2つの対応を実施。\n・肺のヘルスリミットについて、喫煙量に応じたリスクへの調整。\n・各臓器のヘルスリミットについて、他のリスク因子の調整。\n\n前者は、日本人を対象に行なわれた以下の研究結果をもとに調整率を算出。\nKojima S, Sakakibara H, Motani S, et al. Incidence of chronic obstructive pulmonary disease, and the relationship between age and smoking in a Japanese population. J Epidemiol. 2007;17(2):54-60. doi:10.2188/jea.17.54\n\n後者は、米国CDC（Centers for Disease Control and Prevention、https://www.cdc.gov/genomics/）による生活習慣病のリスク因子（遺伝、環境等の因子）の調査結果を踏まえて調整率を算出。'
      },
      sixthSection: {
        title: '６．健診値推移モデル',
        text: 'JMDCの健康保険組合由来データをもとに、ある時点の2年分の健診とその約1年後の健診との関連を分析し、予測モデルを構築することで、健診計測値の平均的な推移をシミュレートする。',
        note: '図4　健診値推移モデル',
      },
    }
  },
  validationError: {
    inputRequired: '入力してください',
    selectRequired: '当てはまるものを選択してください',
    halfWidthNumber: '半角数字を入力してください',
    sevenLength: '7桁で入力してください',
    withInOneHundredLength: '100文字以内で入力してください',
  },
  drawer: {
    home: 'ヘルスリミット予測（β版）',
    myPage: 'マイページ',
    aboutSpecialistSupervision: '情報提供について',
    aboutAgeExpectancy: '算出根拠について',
    company: '運営会社',
    contactUs: 'お問い合わせ',
  },
  navigation: {
    home: 'ヘルスリミット予測（β版）',
    organDetail: 'のヘルスリミット',
    myPage: 'マイページ',
    notifications: 'お知らせ',
    healthyLifespanMeasurement: 'ヘルスリミット予測（β版）',
    completeAnswers: '回答完了',
    pastQuestionnaireAnswers: '過去のヘルスリミット予測回答',
    editAnswers: '回答の編集',
    aboutSpecialistSupervision: '情報提供について',
    aboutAgeExpectancy: '算出根拠について',
    cannotPredictHealthLimit: '健診データの確認',
  },
  home: {
    healthyLifeExpectancy: {
      ageExpectancy: {
        your: 'あなた',
        ageIs: 'のヘルスリミットは...',
        age: '歳',
        is: 'です',
      },
      remainingHealthyLife: {
        remaining: '残り',
        remainingHealthyLifeExpectancy: (year: number, month: number) => `${year}年${month}ヶ月`,
        sameAgeComparison: '同年代比較',
        year: '年',
        month: 'ヶ月',
      },
      horizontalGraph: {
        userBar: 'あなた',
        otherBar: '同年代',
        age: '歳',
        legendCurrentLife: 'ヘルスリミット',
        legendRequireHospitalization: '要通院',
      },
    },
    organRanking: {
      title: '重要臓器のヘルスリミット（短い順）',
      organs: {
        brain: '脳',
        heart: '心臓',
        lung: '肺',
        liver: '肝臓',
        kidney: '腎臓',
      },
      healthyLifeExpectancy: (year: number, month: number) => `${year}歳${month}ヶ月`,
      remainingHealthyLifeExpectancy: (year: number, month: number) => `残り${year !== 0 ? `${year}年` : ''}${month !== 0 ? `${month}ヶ月` : ''}`,
      shorterThanAverage: (year: number, month: number) => `同世代より${year !== 0 ? `${year}年` : ''}${month !== 0 ? `${month}ヶ月` : ''}短い`,
      longerThanAverage: (year: number, month: number) => `同世代より${year !== 0 ? `${year}年` : ''}${month !== 0 ? `${month}ヶ月` : ''}長い`,
      average: '平均的なヘルスリミット',
    },
    organDetail: {
      title: 'に影響の大きい疾患',
      commonTendenciesTitle: 'よくある発症傾向',
      recommendedPreventionTitle: 'おすすめの予防',
      heart: {
        anginaPectoris: {
          diseaseName: '狭心症',
          commonTendencies: '狭心症の多くは生活習慣病で、その多くは動脈硬化が進行して起こります。',
          recommendedPreventionPoints: [
            '動脈硬化は、脂質異常、血糖高値、高血圧によって生じます。これらの指摘を受けた方はまず受診して状況を把握しましょう。',
            '喫煙や肥満は、動脈硬化や狭心症のリスクとなるため、禁煙・減量を心がけましょう。',
            '既に診断を受けている人は処方薬を欠かさず服用し、発作時に取るべき対応を理解しましょう。',
          ]
        },
        myocardialInfarction: {
          diseaseName: '心筋梗塞',
          commonTendencies: '心筋梗塞の多くは生活習慣病で、その一部は狭心症が進行して起こります。',
          recommendedPreventionPoints: [
            '動脈硬化は、脂質異常、血糖高値、高血圧によって生じます。これらの指摘を受けた方はまず受診して状況を把握しましょう。',
            '喫煙や肥満は、動脈硬化や狭心症のリスクとなるため、禁煙・減量を心がけましょう。',
            '既に診断を受けている人は処方薬を欠かさず服用し、発作時に取るべき対応を理解しましょう。',
          ]
        },
        heartFailure: {
          diseaseName: '心不全',
          commonTendencies: '心不全の多くは生活習慣病で、高血圧や心筋梗塞などが原因で起こります。',
          recommendedPreventionPoints: [
            '血圧が高い方は、塩分を控え、野菜や青魚を積極的に食べましょう。有酸素運動も効果的です。',
            '喫煙や肥満は、動脈硬化や狭心症のリスクとなるため、禁煙・減量を心がけましょう。',
            '心不全の診断を受けている人は、塩分摂取量・飲水量・運動量について医師の指示を守りましょう。',
          ]
        },
      },
      brain: {
        transientIschemicAttack: {
          diseaseName: '一過性脳虚血発作',
          commonTendencies: '一過性脳虚血発作の多くは生活習慣病で、動脈硬化や不整脈に関連して起こります。',
          recommendedPreventionPoints: [
            '脂質異常、血糖高値、高血圧の指摘を受けた場合、まずは受診をするようにしましょう。生活習慣では禁煙や減量も効果的です。',
            '睡眠不足、アルコールやカフェインの過剰摂取、ストレスは、不整脈を招くので気を付けましょう。',
            '発作が起きた場合、2日以内に脳梗塞を発症するリスクが高いため、軽快したとしてもただちに受診するようにしましょう。',
          ]
        },
        subarachnoidHemorrhage: {
          diseaseName: 'くも膜下出血',
          commonTendencies: 'くも膜下出血はの多くは、脳血管に動脈瘤（りゅう）ができ、そこが破裂することで起こります。',
          recommendedPreventionPoints: [
            '瘤ができる体質には遺伝的な要素もあります。親族に発症歴がある方は脳ドックを受けましょう。',
            '動脈硬化は瘤ができるリスク、高血圧は瘤が破裂するリスクとなるので、生活習慣を改善しリスクを軽減しましょう。',
            '脳動脈瘤を指摘されている方は、血圧管理、禁煙、節酒につとめ、定期的に検査を受けましょう。',
          ]
        },
        cerebralInfarction: {
          diseaseName: '脳梗塞/脳出血',
          commonTendencies: '脳梗塞/脳出血の多くは生活習慣病で、動脈硬化や不整脈、高血圧に関連して起こります。',
          recommendedPreventionPoints: [
            '高血圧、脂質異常、血糖高値の指摘を受けた場合は速やかに受診しましょう。禁煙や減量も効果的です。',
            '睡眠不足、アルコールやカフェインの過剰摂取、ストレスは、不整脈を招くので気を付けましょう。',
            '治療開始までの時間が短いほど後遺症が少なくなります。疑わしい症状があった場合は直ちに受診するようにしましょう。',
          ]
        },
      },
      liver: {
        compensatedCirrhosis: {
          diseaseName: '代償性肝硬変',
          commonTendencies: '肝硬変の多くは慢性肝炎を経て発症します。肝炎発症には、ウイルス感染歴や飲酒歴が関係します。',
          recommendedPreventionPoints: [
            '肝炎ウイルスに自分が感染しているかどうかわからない場合、まずは検査を受けましょう。',
            '飲酒の習慣がある方は肝臓の検査値に気を付け、飲酒以外で気分転換できる方法も見つけましょう。',
            'ウイルスを抑制する治療は進歩しています。感染していて近年受診していない人は受診を心がけましょう。',
          ]
        },
        liverFailure: {
          diseaseName: '肝不全',
          commonTendencies: '慢性肝不全の多くは肝硬変を経て発症します。生活習慣ではアルコール依存との関連もあります。',
          recommendedPreventionPoints: [
            '慢性肝炎や肝硬変・肝がんと診断を受けている人は、継続的に治療を受けるようにしましょう。',
            'アルコール依存に心当たりがある方は断酒しましょう。断酒に困難を感じる場合は、受診しましょう。',
            '薬剤が重篤な肝障害の原因となることもあります。国内で未承認の薬剤の服用は控えましょう。',
          ]
        },
      },
      kidney: {
        dialysis: {
          diseaseName: '人工透析（を要する状態）',
          commonTendencies: '人工透析を要する状態の多くは、糖尿病・高血圧などの生活習慣病や腎疾患が続くことで生じます。',
          recommendedPreventionPoints: [
            '塩分やアルコールは適量にとどめ、適性体重を維持しましょう。禁煙も効果的です。',
            '糖尿病、 高血圧、 脂質異常症、 高尿酸血症の方は継続的に治療を受けるようにしましょう。',
            '鎮痛薬などの市販薬が腎臓の負担となることもあります。自己判断での長期常用は控えましょう。',
          ]
        },
      },
      lung: {
        chronicObstructivePulmonaryDisease: {
          diseaseName: '慢性閉塞性肺疾患',
          commonTendencies: '慢性閉塞性肺疾患は、呼吸器が有害物質に長年さらされ慢性的に炎症が生じることで起こります。',
          recommendedPreventionPoints: [
            '喫煙者は禁煙をし、非喫煙者の方はたばこの煙を避けましょう。',
            '禁煙が難しい方は、依存の可能性もありますので受診をしましょう。',
            '呼吸機能が低下している人は治療を継続し、風邪をひかないように心がけましょう。',
          ]
        },
      }
    },
    noHealthData: {
      cantPredictAge: 'ヘルスリミットを予測できませんでした。以下の理由が考えられます。',
      reasons: '・予測に必要な健診データが不足している',
      pleaseWait: '反映を待つか、健康診断データがアップデートされた際にご利用を再開してください。',
      returnToPepUp: 'Pep Upにもどる',
    },
    explanation: {
      title: 'ヘルスリミット（β版）とは',
      text: 'あなたの健診値とこれまでの生活習慣から、あなたが要通院となるまでの平均的な時間を予測する、それがヘルスリミットです。\n\nなお、本機能において特定の疾患に紐づくリスクはなく、病気の特定も行われませんのでご了承ください。',
    },
    returnToPepUp: {
      title: 'Pep Up へ戻る',
      caption: 'まずはPep Up で健康に関する情報を集め、健康維持を目指しましょう。',
      returnToPepUpButton: 'Pep Up にもどる',
    },
  },
  error: {
    forbidden: {
      title: 'アクセスが拒否されました。',
      text: 'アクセスしようとしたページはアクセスが制限されています。ご不便をおかけし申し訳ございません。'
    },
    notFound: {
      title: 'ご指定のページが見つかりませんでした。',
      text: 'アクセスしようとしたページは削除、変更されたか、現在利用できない可能性があります。\n\nお手数をおかけしますが、URLにお間違いがないかご確認ください。\n',
      textWithLink1: '解決しない場合は、',
      textWithLink2: 'ください。',
      linkText: 'お問い合わせ',
    },
    serverError: {
      title: 'サーバーエラーが発生しました。',
      text: 'ご迷惑をおかけし申し訳ございません。しばらく時間をおいて、再度お試しください。',
      textWithLink1: '時間をおいても復旧しない場合は、',
      textWithLink2: 'ください。',
      linkText: 'お問い合わせ',
    }
  }
}
