import { useAtomValue } from 'jotai';
import { useCallback, useMemo } from 'react';

import { messages as t } from '@/assets/i18n/ja';
import type { QuestionnaireItemFieldsFragment } from '@/hooks/GetQuestionnaire.generated';
import { useQuestionnaire } from '@/hooks/useQuestionnaire';
import { medicalHistoryFormValuesAtom } from '@/store/formValues';
import type { MedicalHistoryFields } from '@/types/questionnaire/medicalHistory';

export const useCreateMedicalHistory = () => {
  const { createMultiSelectTypeField, createMultiSelectTypeOtherItemSupplementField } = useQuestionnaire();

  const medicalHistoryFormValues = useAtomValue(medicalHistoryFormValuesAtom);

  const isAnswerInStorage = useMemo(
    () =>
      [
        medicalHistoryFormValues?.stroke,
        medicalHistoryFormValues?.transientIschemicAttack,
        medicalHistoryFormValues?.ischemicHeartDisease,
        medicalHistoryFormValues?.heartFailure,
        medicalHistoryFormValues?.chronicObstructivePulmonaryDisease,
        medicalHistoryFormValues?.liverCirrhosis,
        medicalHistoryFormValues?.liverFailure,
        medicalHistoryFormValues?.dialysis,
        medicalHistoryFormValues?.highBloodPressure,
        medicalHistoryFormValues?.diabetes,
        medicalHistoryFormValues?.dyslipidemia,
        medicalHistoryFormValues?.otherDisease,
      ].includes(true) || medicalHistoryFormValues?.cancer,
    [medicalHistoryFormValues]
  );

  const createFields = useCallback(
    (questionnaireItem: QuestionnaireItemFieldsFragment): MedicalHistoryFields | undefined =>
      isAnswerInStorage
        ? {
            brain: {
              title: t.questionnaire.medicalHistoryCategory.brain,
              stroke: {
                ...createMultiSelectTypeField(questionnaireItem, 'stroke'),
                value: medicalHistoryFormValues?.stroke || false,
              },
              transientIschemicAttack: {
                ...createMultiSelectTypeField(questionnaireItem, 'transientIschemicAttack'),
                value: medicalHistoryFormValues?.transientIschemicAttack || false,
              },
            },
            heart: {
              title: t.questionnaire.medicalHistoryCategory.heart,
              ischemicHeartDisease: {
                ...createMultiSelectTypeField(questionnaireItem, 'ischemicHeartDisease'),
                value: medicalHistoryFormValues?.ischemicHeartDisease || false,
              },
              heartFailure: {
                ...createMultiSelectTypeField(questionnaireItem, 'heartFailure'),
                value: medicalHistoryFormValues?.heartFailure || false,
              },
            },
            lung: {
              title: t.questionnaire.medicalHistoryCategory.lung,
              chronicObstructivePulmonaryDisease: {
                ...createMultiSelectTypeField(questionnaireItem, 'chronicObstructivePulmonaryDisease'),
                value: medicalHistoryFormValues?.chronicObstructivePulmonaryDisease || false,
              },
            },
            liver: {
              title: t.questionnaire.medicalHistoryCategory.liver,
              liverCirrhosis: {
                ...createMultiSelectTypeField(questionnaireItem, 'liverCirrhosis'),
                value: medicalHistoryFormValues?.liverCirrhosis || false,
              },
              liverFailure: {
                ...createMultiSelectTypeField(questionnaireItem, 'liverFailure'),
                value: medicalHistoryFormValues?.liverFailure || false,
              },
            },
            kidney: {
              title: t.questionnaire.medicalHistoryCategory.kidney,
              dialysis: {
                ...createMultiSelectTypeField(questionnaireItem, 'dialysis'),
                value: medicalHistoryFormValues?.dialysis || false,
              },
            },
            cancer: {
              title: t.questionnaire.medicalHistoryCategory.cancer,
              cancer: {
                ...createMultiSelectTypeOtherItemSupplementField(questionnaireItem, 'cancer'),
                value: medicalHistoryFormValues?.cancer || '',
              },
            },
            otherDisease: {
              title: t.questionnaire.medicalHistoryCategory.otherDisease,
              highBloodPressure: {
                ...createMultiSelectTypeField(questionnaireItem, 'highBloodPressure'),
                value: medicalHistoryFormValues?.highBloodPressure || false,
              },
              diabetes: {
                ...createMultiSelectTypeField(questionnaireItem, 'diabetes'),
                value: medicalHistoryFormValues?.diabetes || false,
              },
              dyslipidemia: {
                ...createMultiSelectTypeField(questionnaireItem, 'dyslipidemia'),
                value: medicalHistoryFormValues?.dyslipidemia || false,
              },
              otherDisease: {
                ...createMultiSelectTypeField(questionnaireItem, 'otherDisease'),
                value: medicalHistoryFormValues?.otherDisease || false,
              },
              otherDiseaseSupplement: {
                ...createMultiSelectTypeOtherItemSupplementField(questionnaireItem, 'otherDisease'),
                value: medicalHistoryFormValues?.otherDiseaseSupplement || '',
              },
            },
          }
        : {
            brain: {
              title: t.questionnaire.medicalHistoryCategory.brain,
              stroke: createMultiSelectTypeField(questionnaireItem, 'stroke'),
              transientIschemicAttack: createMultiSelectTypeField(questionnaireItem, 'transientIschemicAttack'),
            },
            heart: {
              title: t.questionnaire.medicalHistoryCategory.heart,
              ischemicHeartDisease: createMultiSelectTypeField(questionnaireItem, 'ischemicHeartDisease'),
              heartFailure: createMultiSelectTypeField(questionnaireItem, 'heartFailure'),
            },
            lung: {
              title: t.questionnaire.medicalHistoryCategory.lung,
              chronicObstructivePulmonaryDisease: createMultiSelectTypeField(
                questionnaireItem,
                'chronicObstructivePulmonaryDisease'
              ),
            },
            liver: {
              title: t.questionnaire.medicalHistoryCategory.liver,
              liverCirrhosis: createMultiSelectTypeField(questionnaireItem, 'liverCirrhosis'),
              liverFailure: createMultiSelectTypeField(questionnaireItem, 'liverFailure'),
            },
            kidney: {
              title: t.questionnaire.medicalHistoryCategory.kidney,
              dialysis: createMultiSelectTypeField(questionnaireItem, 'dialysis'),
            },
            cancer: {
              title: t.questionnaire.medicalHistoryCategory.cancer,
              cancer: createMultiSelectTypeOtherItemSupplementField(questionnaireItem, 'cancer'),
            },
            otherDisease: {
              title: t.questionnaire.medicalHistoryCategory.otherDisease,
              highBloodPressure: createMultiSelectTypeField(questionnaireItem, 'highBloodPressure'),
              diabetes: createMultiSelectTypeField(questionnaireItem, 'diabetes'),
              dyslipidemia: createMultiSelectTypeField(questionnaireItem, 'dyslipidemia'),
              otherDisease: createMultiSelectTypeField(questionnaireItem, 'otherDisease'),
              otherDiseaseSupplement: createMultiSelectTypeOtherItemSupplementField(questionnaireItem, 'otherDisease'),
            },
          },
    [
      createMultiSelectTypeField,
      createMultiSelectTypeOtherItemSupplementField,
      medicalHistoryFormValues,
      isAnswerInStorage,
    ]
  );

  return {
    createFields,
  };
};
