import { type FC } from 'react';
import { type ViewStyle, StyleSheet, View } from 'react-native';

import { Icon } from '@/components/Icon';
import { Text } from '@/components/Text';
import { Touchable } from '@/components/Touchable';
import { Colors } from '@/constants/Colors';
import { styleType } from '@/utils/styleType';

type Props = {
  onPress: () => void;
  text: string;
};

export const ListItem: FC<Props> = (props) => {
  const { onPress, text } = props;

  return (
    <Touchable style={styles.container} onPress={onPress} disabled={!onPress}>
      <View style={styles.contents}>
        <Text.BoldBody>{text}</Text.BoldBody>
      </View>
      <Chevron />
    </Touchable>
  );
};

const Chevron = () => (
  <View style={styles.chevron}>
    <Icon type="Chevron" size={60} />
  </View>
);

const styles = StyleSheet.create({
  container: styleType<ViewStyle>({
    backgroundColor: Colors.white100,
    height: 52,
    flexDirection: 'row',
    alignItems: 'center',
  }),
  contents: styleType<ViewStyle>({
    flex: 1,
  }),
  chevron: styleType<ViewStyle>({
    height: 24,
    width: 24,
    marginRight: 16,
  }),
});
