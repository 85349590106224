import { type FC } from 'react';
import { type TextStyle, type ViewStyle, StyleSheet, View } from 'react-native';

import { Button } from '@/components/Button';
import { Icon } from '@/components/Icon';
import { Text } from '@/components/Text';
import { styleType } from '@/utils/styleType';

type Props = {
  text: string;
  buttonLabel?: string;
  onPress?: () => void;
};

export const AnswersComplete: FC<Props> = (props) => {
  const { text, buttonLabel, onPress } = props;

  return (
    <View style={styles.container}>
      <Icon type="CheckCircle" size={60} />
      <Text.Body style={styles.text}>{text}</Text.Body>
      {buttonLabel && onPress ? (
        <Button type="active" onPress={onPress} style={styles.button}>
          {buttonLabel}
        </Button>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  container: styleType<ViewStyle>({
    alignItems: 'center',
  }),
  text: styleType<TextStyle>({
    textAlign: 'center',
    marginTop: 24,
  }),
  button: styleType<ViewStyle>({
    width: '100%',
    marginTop: 40,
  }),
});
