import { type ComponentProps, type FC } from 'react';

import { messages as t } from '@/assets/i18n/ja';
import { Occupation } from '@/components/questionnaire/form/environment';
import { QuestionnaireContainer } from '@/features/myPage/components/parts/QuestionnaireContainer';

type Props = ComponentProps<typeof Occupation>;

export const OccupationTemplate: FC<Props> = (props) => {
  const { question, selections, defaultValue, supplement, onSubmit } = props;

  return (
    <QuestionnaireContainer>
      <Occupation
        question={question}
        selections={selections}
        onSubmit={onSubmit}
        defaultValue={defaultValue}
        supplement={supplement}
        submitButtonLabel={t.myPage.pastQuestionnaireAnswers.save}
      />
    </QuestionnaireContainer>
  );
};
