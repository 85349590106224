import type { ComponentProps, FC } from 'react';

import { CanWalkFasterThan } from '@/components/questionnaire/form/lifeStyle';

import { QuestionnaireContainer } from '../parts/QuestionnaireContainer';
import type { QuestionNumber } from '../parts/QuestionNumber';

type Props = Omit<ComponentProps<typeof QuestionNumber>, 'style'> & ComponentProps<typeof CanWalkFasterThan>;

export const CanWalkFasterThanTemplate: FC<Props> = (props) => {
  const { questionNumber, allQuestionNumber, question, selections, defaultValue, onSubmit } = props;

  return (
    <QuestionnaireContainer questionNumber={questionNumber} allQuestionNumber={allQuestionNumber}>
      <CanWalkFasterThan question={question} selections={selections} defaultValue={defaultValue} onSubmit={onSubmit} />
    </QuestionnaireContainer>
  );
};
