import { type FC } from 'react';

import { Colors } from '@/constants/Colors';

type Props = {
  size?: number;
  fill?: string;
};

export const RankFourth: FC<Props> = (props) => {
  const { size = 24, fill = Colors.black100 } = props;

  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="transparent" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2_308)">
        <path
          d="M1 3.73913L7.31053 5.74783L12 1L16.6895 5.74783L23 3.73913L21.3789 22H2.62105L1 3.73913Z"
          fill={fill}
        />
        <path
          d="M12.4929 18H14.1369V9.12H12.0129L8.71294 14.496V15.708H15.1929V14.352H10.4289L11.7969 12.144C12.0489 11.664 12.2889 11.172 12.5169 10.692H12.5649C12.5409 11.22 12.4929 12.024 12.4929 12.54V18Z"
          fill="white"
        />
      </g>

      <defs>
        <clipPath id="clip0_2_308">
          <rect width={size} height={size} fill="transparent" transform="matrix(-1 0 0 1 24 0)" />
        </clipPath>
      </defs>
    </svg>
  );
};
