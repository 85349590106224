import { useAtomValue } from 'jotai';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import type { FrequencyOfDrivingSchema } from '@/formSchemas/environmentSchema';
import { useGetEnvironmentQuestionnaireQuery } from '@/hooks/GetQuestionnaire.generated';
import { useQuestionnaire } from '@/hooks/useQuestionnaire';
import { environmentFormValuesAtom } from '@/store/formValues';

export const useFrequencyOfDriving = () => {
  const [getEnvironmentQuestionnaireResult] = useGetEnvironmentQuestionnaireQuery();
  const { data, fetching, error } = getEnvironmentQuestionnaireResult;

  const formValues = useAtomValue(environmentFormValuesAtom);

  const {
    getQuestionnaireItem,
    createSelections,
    getSelectedValue,
    getOtherItemSupplement,
    perpetuateEnvironmentFormValues,
  } = useQuestionnaire();

  const questionnaireItem = useMemo(
    () => data && getQuestionnaireItem(data, 'FREQUENCY_OF_DRIVING'),
    [data, getQuestionnaireItem]
  );
  const selections = useMemo(
    () => questionnaireItem?.forms[0].__typename === 'SelectTypeForm' && createSelections(questionnaireItem.forms[0]),
    [questionnaireItem, createSelections]
  );

  const selectedValue = useMemo(() => {
    if (formValues?.frequencyOfDriving) {
      return formValues?.frequencyOfDriving;
    }
    if (questionnaireItem?.forms[0].__typename === 'SelectTypeForm') {
      return getSelectedValue(questionnaireItem.forms[0]);
    }
    return undefined;
  }, [questionnaireItem?.forms, getSelectedValue, formValues?.frequencyOfDriving]);

  const otherItemSupplement = useMemo(() => {
    if (questionnaireItem) {
      const otherItemSupplement = getOtherItemSupplement(questionnaireItem);

      // 空文字は空文字のまま反映する
      return formValues?.frequencyOfDrivingSupplement !== undefined
        ? {
            value: formValues?.frequencyOfDrivingSupplement || '',
            placeholder: otherItemSupplement?.placeholder || '',
          }
        : otherItemSupplement;
    }

    return undefined;
  }, [formValues?.frequencyOfDrivingSupplement, questionnaireItem, getOtherItemSupplement]);

  const navigate = useNavigate();

  const handleSubmit = useCallback(
    (values: FrequencyOfDrivingSchema) => {
      perpetuateEnvironmentFormValues(values);

      navigate('/questionnaire/how-to-spend-holidays');
    },
    [perpetuateEnvironmentFormValues, navigate]
  );

  return {
    fetching,
    data,
    error,
    questionnaireItem,
    selections,
    selectedValue,
    otherItemSupplement,
    handleSubmit,
  };
};
