import 'react-modern-drawer/dist/index.css';

import type { HeaderOptions } from '@react-navigation/elements';
import { Header } from '@react-navigation/elements';
import { type FC, useState } from 'react';
import Drawer from 'react-modern-drawer';
import { type ImageStyle, type TextStyle, type ViewStyle, Image, StyleSheet } from 'react-native';
import type { NavigateFunction } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import LOGO_URI from '@/assets/images/pepup_logo.png';
import { Drawer as DrawerContent } from '@/components/Drawer';
import { Icon } from '@/components/Icon';
import { Touchable } from '@/components/Touchable';
import { BuildConfig } from '@/constants/BuildConfig';
import { Colors } from '@/constants/Colors';
import { COMPANY_URL } from '@/constants/URL';
import { Logger } from '@/lib/Logger';
import { HomeDrawerVisibleEvent } from '@/lib/Logger/events';
import { openURL } from '@/utils/OpenUrl';
import { styleType } from '@/utils/styleType';

type Props = {
  title: string;
  headerLeft?: HeaderLeft;
  headerRight?: HeaderRight;
  headerColor?: HeaderColor;
};

type HeaderLeft = HeaderLeftType | HeaderOptions['headerLeft'];
type HeaderRight = HeaderOptions['headerRight'];

type HeaderLeftType = 'menu' | 'back' | 'logo';
type HeaderColor = 'green' | 'white';

export const NavigationHeader: FC<Props> = (props) => {
  const { title, headerLeft, headerRight: headerRightItem, headerColor = 'green' } = props;
  const navigate = useNavigate();

  const [isDrawerOpened, setIsDrawerOpened] = useState(false);

  const openDrawer = () => {
    setIsDrawerOpened(true);
    Logger.logEvent(HomeDrawerVisibleEvent());
  };

  const closeDrawer = () => {
    setIsDrawerOpened(false);
  };

  const headerLeftItem = createHeaderLeftItem(headerLeft, navigate, openDrawer);

  const handlePressOperatingCompany = () => {
    openURL(COMPANY_URL);
  };

  const handlePressContactUs = () => {
    /* eslint-disable-next-line @typescript-eslint/no-unsafe-argument */
    openURL(BuildConfig.PEPUP_CONTACT_URL);
  };

  return (
    <>
      <Drawer open={isDrawerOpened} onClose={closeDrawer} direction="left" duration={250}>
        <DrawerContent
          onPressCloseButton={closeDrawer}
          onPressHome={() => navigate('/')}
          onPressMyPage={() => navigate('/my-page')}
          onPressAboutSpecialistSupervision={() => navigate('/about-specialist-supervision')}
          onPressAboutAgeExpectancy={() => navigate('/about-age-expectancy')}
          onPressCompany={handlePressOperatingCompany}
          onPressContactUs={handlePressContactUs}
        />
      </Drawer>
      <Header
        headerStyle={[styles.headerStyle, headerColor === 'green' ? styles.headerColorGreen : styles.headerColorWhite]}
        headerTintColor={Colors.white100}
        headerTitleStyle={styles.headerTitleStyle}
        headerTitleAlign="center"
        title={title}
        headerLeft={headerLeftItem}
        headerRight={headerRightItem}
      />
    </>
  );
};

const createHeaderLeftItem = (headerLeft: HeaderLeft, navigate: NavigateFunction, openDrawer: () => void) => {
  const handlePressPepupLogo = () => {
    /* eslint-disable-next-line @typescript-eslint/no-unsafe-argument */
    openURL(BuildConfig.PEPUP_URL);
  };

  switch (headerLeft) {
    case 'menu':
      return () => (
        <Touchable onPress={openDrawer} style={styles.headerLeftIconContainer}>
          <Icon type="Menu" stroke={Colors.white100} />
        </Touchable>
      );
    case 'back':
      return () => (
        <Touchable onPress={() => navigate(-1)} style={styles.headerLeftIconContainer}>
          <Icon type="ArrowLeft" stroke={Colors.white100} />
        </Touchable>
      );
    case 'logo':
      return () => (
        <Touchable onPress={handlePressPepupLogo} style={styles.headerLeftIconContainer}>
          <Image source={{ uri: LOGO_URI }} resizeMode="contain" style={styles.logo} />
        </Touchable>
      );
    default:
      return headerLeft;
  }
};

const styles = StyleSheet.create({
  headerStyle: styleType<ViewStyle>({
    height: 60,
    borderBottomWidth: 0,
  }),
  headerColorGreen: styleType<ViewStyle>({
    backgroundColor: Colors.healthactionGreen,
  }),
  headerColorWhite: styleType<ViewStyle>({
    backgroundColor: Colors.white100,
  }),
  logo: styleType<ImageStyle>({
    height: 34,
    width: 80, // originalWith / originalHeight * styleHeight: 279 / 118 * 34 = 80
  }),
  headerTitleStyle: styleType<TextStyle>({
    fontSize: 15,
    fontWeight: 'bold',
  }),
  headerLeftIconContainer: styleType<ViewStyle>({
    marginLeft: 16,
  }),
});
