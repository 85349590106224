/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useSetAtom } from 'jotai';
import { paramCase } from 'param-case';
import { type ComponentProps, useCallback, useEffect, useState } from 'react';
import { type NavigateFunction, useNavigate } from 'react-router-dom';
import { type CombinedError } from 'urql';

import { messages as t } from '@/assets/i18n/ja';
import {
  type GetEnvironmentQuestionnaireQuery,
  type GetLifeStyleQuestionnaireQuery,
  type GetMedicalHistoryQuestionnaireQuery,
  type InputTypeFormFieldsFragment,
  type MultiSelectTypeItemFieldsFragment,
  type QuestionnaireItemFieldsFragment,
  type SelectTypeFormFieldsFragment,
  useGetEnvironmentQuestionnaireQuery,
  useGetLifeStyleQuestionnaireQuery,
  useGetMedicalHistoryQuestionnaireQuery,
} from '@/hooks/GetQuestionnaire.generated';
import { Logger } from '@/lib/Logger';
import { environmentFormValuesAtom, lifeStyleFormValuesAtom, medicalHistoryFormValuesAtom } from '@/store/formValues';
import { hasGraphQLServerError } from '@/utils/hasGraphQlError';
import { byNumberAscending } from '@/utils/sort/byNumberAscending';

import type { PastQuestionnaireAnswersTemplate } from '../components/PastQuestionnaireAnswers/template';

type QuestionnaireQueries =
  | GetLifeStyleQuestionnaireQuery
  | GetEnvironmentQuestionnaireQuery
  | GetMedicalHistoryQuestionnaireQuery;

export const useFetchPastQuestionnaireAnswers = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [result, setResult] = useState<ComponentProps<typeof PastQuestionnaireAnswersTemplate>['data']>();
  const [hasError, setHasError] = useState(false);

  const [lifeStyleResult, executeLifeStyle] = useGetLifeStyleQuestionnaireQuery({
    requestPolicy: 'cache-and-network',
    pause: true,
  });
  const [environmentResult, executeEnvironment] = useGetEnvironmentQuestionnaireQuery({
    requestPolicy: 'cache-and-network',
    pause: true,
  });
  const [medicalHistoryResult, executeMedicalHistory] = useGetMedicalHistoryQuestionnaireQuery({
    requestPolicy: 'cache-and-network',
    pause: true,
  });

  const { data: lifeStyleData } = lifeStyleResult;
  const { data: environmentData } = environmentResult;
  const { data: medicalHistoryData } = medicalHistoryResult;

  const navigate = useNavigate();

  const setLifeStyleFormValues = useSetAtom(lifeStyleFormValuesAtom);
  const setEnvironmentFormValues = useSetAtom(environmentFormValuesAtom);
  const setMedicalHistoryFormValues = useSetAtom(medicalHistoryFormValuesAtom);

  const refresh = useCallback(() => {
    setIsLoading(true);
    executeLifeStyle();
    executeEnvironment();
    executeMedicalHistory();
  }, [executeEnvironment, executeLifeStyle, executeMedicalHistory]);

  const handleError = useCallback((error: CombinedError) => {
    setHasError(true);
    setIsLoading(false);

    if (error && hasGraphQLServerError(error)) {
      Logger.logException(error.message);
    }
  }, []);

  // for Template
  useEffect(() => {
    if (lifeStyleData && environmentData && medicalHistoryData) {
      setIsLoading(false);
      setResult({
        lifeStyle: createLifeStyleData(lifeStyleData, navigate),
        environment: createEnvironmentData(environmentData, navigate),
        medicalHistory: createMedicalHistoryData(medicalHistoryData, navigate),
      });
    }
  }, [environmentData, lifeStyleData, medicalHistoryData, navigate]);

  // for Update Mutation
  useEffect(() => {
    if (lifeStyleData) {
      setLifeStyleFormValues(createLifeStyleFormValues(lifeStyleData)).catch(() => undefined);
    }
    if (environmentData) {
      setEnvironmentFormValues(createEnvironmentFormValues(environmentData)).catch(() => undefined);
    }
    if (medicalHistoryData) {
      setMedicalHistoryFormValues(createMedicalHistoryFormValues(medicalHistoryData)).catch(() => undefined);
    }
  }, [
    setLifeStyleFormValues,
    lifeStyleData,
    setEnvironmentFormValues,
    environmentData,
    setMedicalHistoryFormValues,
    medicalHistoryData,
  ]);

  useEffect(() => {
    if (lifeStyleResult.error) {
      handleError(lifeStyleResult.error);
    }
  }, [lifeStyleResult.error, handleError]);

  useEffect(() => {
    if (environmentResult.error) {
      handleError(environmentResult.error);
    }
  }, [environmentResult.error, handleError]);

  useEffect(() => {
    if (medicalHistoryResult.error) {
      handleError(medicalHistoryResult.error);
    }
  }, [medicalHistoryResult.error, handleError]);

  return {
    isLoading,
    result,
    refresh,
    hasError,
  };
};

const getSelectTypeItems = (data: QuestionnaireQueries, fieldName: string) =>
  concatSubItems(data)
    .filter((i) => i.name === fieldName)
    .pop()
    ?.forms.map((j) => {
      if (j.__typename === 'SelectTypeForm') {
        return j.items;
      }
      return undefined;
    })
    .pop();

const getSelectTypeValue = (data: QuestionnaireQueries, fieldName: string) =>
  getSelectTypeItems(data, fieldName)
    ?.filter((k) => k.selected)
    .pop()?.value;

const getSelectTypeSupplementValue = (data: QuestionnaireQueries, fieldName: string) =>
  getSelectTypeItems(data, fieldName)
    ?.filter((k) => k.value === 99)
    .pop()?.otherItemSupplement?.value;

const getMultiSelectTypeItem = (data: QuestionnaireQueries, categoryName: string, fieldName: string) =>
  getSelectTypeItems(data, categoryName)
    ?.filter((i) => {
      if (i.__typename === 'MultiSelectTypeItem') {
        return i.name === fieldName;
      }
      return undefined;
    })
    .pop();

const getMultiSelectTypeValue = (data: QuestionnaireQueries, categoryName: string, fieldName: string) =>
  getMultiSelectTypeItem(data, categoryName, fieldName)?.selected;

const getMultiSelectTypeSupplementValue = (data: QuestionnaireQueries, categoryName: string, fieldName: string) =>
  getMultiSelectTypeItem(data, categoryName, fieldName)?.otherItemSupplement?.value;

const getInputTypeValue = (data: QuestionnaireQueries, formName: string, fieldName: string) => {
  const concatedData = concatSubItems(data);
  const formItem = concatedData.filter((i) => i.name === formName).pop();
  const fieldItem = formItem?.forms.filter((i) => i.name === fieldName).pop();

  return fieldItem?.__typename === 'InputTypeForm' ? fieldItem.value : undefined;
};

const createLifeStyleFormValues = (data: GetLifeStyleQuestionnaireQuery) => ({
  hasSmokingHistory: getSelectTypeValue(data, 'HAS_SMOKING_HISTORY')!,
  hasSmokingHabit: getSelectTypeValue(data, 'HAS_SMOKING_HABIT'),
  numberOfCigarettesPerDayCurrent:
    Number(getInputTypeValue(data, 'CURRENT_SMOKING_HABIT', 'numberOfCigarettesPerDayCurrent')) || undefined,
  numberOfCigarettesPerDayPast:
    Number(getInputTypeValue(data, 'PAST_SMOKING_HABIT', 'numberOfCigarettesPerDayPast')) || undefined,
  yearsOfSmokingCurrent: Number(getInputTypeValue(data, 'CURRENT_SMOKING_HABIT', 'yearsOfSmokingCurrent')) || undefined,
  yearsOfSmokingPast: Number(getInputTypeValue(data, 'PAST_SMOKING_HABIT', 'yearsOfSmokingPast')) || undefined,
  hasHabitOfWalking: getSelectTypeValue(data, 'HAS_HABIT_OF_WALKING')!,
  canWalkFasterThan: getSelectTypeValue(data, 'CAN_WALK_FASTER_THAN')!,
  canEatFasterThan: getSelectTypeValue(data, 'CAN_EAT_FASTER_THAN')!,
  hasHabitOfEatDinnerBeforeSleep: getSelectTypeValue(data, 'HAS_HABIT_OF_EAT_DINNER_BEFORE_SLEEP')!,
  hasHabitOfEatSnackAfterDinner: getSelectTypeValue(data, 'HAS_HABIT_OF_EAT_SNACK_AFTER_DINNER')!,
  hasHabitOfSkipBreakfast: getSelectTypeValue(data, 'HAS_HABIT_OF_SKIP_BREAKFAST')!,
  frequencyOfDrinking: getSelectTypeValue(data, 'FREQUENCY_OF_DRINKING')!,
  amountOfDrinkingPerDay: getSelectTypeValue(data, 'AMOUNT_OF_DRINKING_PER_DAY')!,
  canGetEnoughRestWithSleep: getSelectTypeValue(data, 'CAN_GET_ENOUGH_REST_WITH_SLEEP')!,
  hasHabitOfSweatyExerciseMoreThanOneYear: getSelectTypeValue(data, 'HAS_HABIT_OF_SWEATY_EXERCISE_MORE_THAN_ONE_YEAR')!,
});

const createEnvironmentFormValues = (data: GetEnvironmentQuestionnaireQuery) => ({
  familyStructure: getSelectTypeValue(data, 'FAMILY_STRUCTURE')!,
  familyStructureSupplement: getSelectTypeSupplementValue(data, 'FAMILY_STRUCTURE'),
  occupation: getSelectTypeValue(data, 'OCCUPATION')!,
  occupationSupplement: getSelectTypeSupplementValue(data, 'OCCUPATION'),
  meansOfTransportation: getSelectTypeValue(data, 'MEANS_OF_TRANSPORTATION')!,
  meansOfTransportationSupplement: getSelectTypeSupplementValue(data, 'MEANS_OF_TRANSPORTATION'),
  housingType: getSelectTypeValue(data, 'HOUSING_TYPE')!,
  housingTypeSupplement: getSelectTypeSupplementValue(data, 'HOUSING_TYPE'),
  hasCar: getSelectTypeValue(data, 'HAS_CAR')!,
  frequencyOfDriving: getSelectTypeValue(data, 'FREQUENCY_OF_DRIVING')!,
  frequencyOfDrivingSupplement: getSelectTypeSupplementValue(data, 'FREQUENCY_OF_DRIVING'),
  housework: getMultiSelectTypeValue(data, 'HOW_TO_SPEND_HOLIDAYS', 'housework'),
  work: getMultiSelectTypeValue(data, 'HOW_TO_SPEND_HOLIDAYS', 'work'),
  childcare: getMultiSelectTypeValue(data, 'HOW_TO_SPEND_HOLIDAYS', 'childcare'),
  reading: getMultiSelectTypeValue(data, 'HOW_TO_SPEND_HOLIDAYS', 'reading'),
  game: getMultiSelectTypeValue(data, 'HOW_TO_SPEND_HOLIDAYS', 'game'),
  watchingTvAndVideos: getMultiSelectTypeValue(data, 'HOW_TO_SPEND_HOLIDAYS', 'watchingTvAndVideos'),
  hobby: getMultiSelectTypeValue(data, 'HOW_TO_SPEND_HOLIDAYS', 'hobby'),
  netSurfing: getMultiSelectTypeValue(data, 'HOW_TO_SPEND_HOLIDAYS', 'netSurfing'),
  otherWays: getMultiSelectTypeValue(data, 'HOW_TO_SPEND_HOLIDAYS', 'otherWays'),
  otherWaysSupplement: getMultiSelectTypeSupplementValue(data, 'HOW_TO_SPEND_HOLIDAYS', 'otherWays'),
  numberOfFriends: getSelectTypeValue(data, 'NUMBER_OF_FRIENDS')!,
});

const createMedicalHistoryFormValues = (data: GetMedicalHistoryQuestionnaireQuery) => ({
  stroke: getMultiSelectTypeValue(data, 'MEDICAL_HISTORY', 'stroke'),
  transientIschemicAttack: getMultiSelectTypeValue(data, 'MEDICAL_HISTORY', 'transientIschemicAttack'),
  ischemicHeartDisease: getMultiSelectTypeValue(data, 'MEDICAL_HISTORY', 'ischemicHeartDisease'),
  heartFailure: getMultiSelectTypeValue(data, 'MEDICAL_HISTORY', 'heartFailure'),
  chronicObstructivePulmonaryDisease: getMultiSelectTypeValue(
    data,
    'MEDICAL_HISTORY',
    'chronicObstructivePulmonaryDisease'
  ),
  liverCirrhosis: getMultiSelectTypeValue(data, 'MEDICAL_HISTORY', 'liverCirrhosis'),
  liverFailure: getMultiSelectTypeValue(data, 'MEDICAL_HISTORY', 'liverFailure'),
  dialysis: getMultiSelectTypeValue(data, 'MEDICAL_HISTORY', 'dialysis'),
  cancer: getMultiSelectTypeSupplementValue(data, 'MEDICAL_HISTORY', 'cancer'),
  highBloodPressure: getMultiSelectTypeValue(data, 'MEDICAL_HISTORY', 'highBloodPressure'),
  diabetes: getMultiSelectTypeValue(data, 'MEDICAL_HISTORY', 'diabetes'),
  dyslipidemia: getMultiSelectTypeValue(data, 'MEDICAL_HISTORY', 'dyslipidemia'),
  otherDisease: getMultiSelectTypeValue(data, 'MEDICAL_HISTORY', 'otherDisease'),
  otherDiseaseSupplement: getMultiSelectTypeSupplementValue(data, 'MEDICAL_HISTORY', 'otherDisease'),
});

const concatSubItems = (data: GetLifeStyleQuestionnaireQuery) =>
  data.getQuestionnaire.items.concat(
    data.getQuestionnaire.items
      .map((i) => i.subItems)
      .filter((i) => i?.length)
      .pop() || []
  );

const orderData = (data: GetLifeStyleQuestionnaireQuery) =>
  concatSubItems(data).sort(byNumberAscending((i) => i.sortIndex));

const createInputTypeFormAnswer = (form: InputTypeFormFieldsFragment) => ({
  subQuestion: form.title || '',
  answer: form.value || '',
  unit: form.unit || '',
});

const createSelectTypeFormAnswer = (form: SelectTypeFormFieldsFragment) => {
  const selectedItem = form.items.find((i) => i.selected);
  const label = selectedItem?.label;
  const supplement = selectedItem?.otherItemSupplement?.value;
  const isOthersSelection = selectedItem?.value === 99 && label && supplement;

  return {
    answer: (isOthersSelection && `${label}（${supplement}）`) || label || '',
  };
};

const createMultiSelectTypeFormAnswer = (form: SelectTypeFormFieldsFragment) => {
  const selectedItemsLabels = form.items
    .filter((i) => i.selected)
    .map((i) => i.label)
    .join('、');
  const otherItemSupplementValue = form.items.filter((i) => i.selected && i.value === 99).pop()
    ?.otherItemSupplement?.value;
  const supplement = otherItemSupplementValue ? `（${otherItemSupplementValue}）` : undefined;

  return {
    answer: supplement ? `${selectedItemsLabels}${supplement}` : selectedItemsLabels,
  };
};

const createLifeStyleData = (data: GetLifeStyleQuestionnaireQuery, navigate: NavigateFunction) =>
  orderData(data)
    .map((i) => {
      const answers = createLifeStyleAnswers(i);
      const path = `/past-questionnaire/${paramCase(i.name)}`;

      // 解答されてない設問はスキップする
      if (answers[0].answer) {
        return {
          question: i.question,
          answers,
          onPress: () => navigate(path),
        };
      }
      return undefined;
    })
    .filter((x): x is Exclude<typeof x, undefined> => x !== undefined);

const createLifeStyleAnswers = (data: QuestionnaireItemFieldsFragment) =>
  data.forms
    .map((form) => {
      if (form.__typename === 'InputTypeForm') {
        return createInputTypeFormAnswer(form);
      } else if (form.__typename === 'SelectTypeForm') {
        return createSelectTypeFormAnswer(form);
      }
      return undefined;
    })
    .filter((x): x is Exclude<typeof x, undefined> => x !== undefined);

const createEnvironmentData = (data: GetEnvironmentQuestionnaireQuery, navigate: NavigateFunction) =>
  orderData(data)
    .map((i) => {
      const answer = createEnvironmentAnswers(i);
      const path = `/past-questionnaire/${paramCase(i.name)}`;

      if (answer[0].answer) {
        return {
          question: i.question,
          answers: createEnvironmentAnswers(i),
          onPress: () => navigate(path),
        };
      }
      return undefined;
    })
    .filter((x): x is Exclude<typeof x, undefined> => x !== undefined);

const createEnvironmentAnswers = (data: QuestionnaireItemFieldsFragment) =>
  data.forms
    .map((form) => {
      if (form.__typename === 'InputTypeForm') {
        return createInputTypeFormAnswer(form);
      } else if (form.__typename === 'SelectTypeForm') {
        if (form.name === 'howToSpendHolidays') {
          return createMultiSelectTypeFormAnswer(form);
        }
        return createSelectTypeFormAnswer(form);
      }
      return undefined;
    })
    .filter((x): x is Exclude<typeof x, undefined> => x !== undefined);

const createMedicalHistoryData = (data: GetMedicalHistoryQuestionnaireQuery, navigate: NavigateFunction) =>
  orderData(data).map((i) => {
    const answers = createMedicalHistoryAnswers(i);
    const path = `/past-questionnaire/${paramCase(i.name)}`;

    return {
      question: i.question,
      answers,
      onPress: () => navigate(path),
    };
  });

const createMedicalHistoryItemAnswer = (item: MultiSelectTypeItemFieldsFragment) => {
  if (item.name === 'cancer') {
    return item.otherItemSupplement?.value || t.myPage.pastQuestionnaireAnswers.no;
  } else if (item.name === 'otherDisease') {
    return item.otherItemSupplement?.value || t.myPage.pastQuestionnaireAnswers.no;
  }
  return item.selected ? t.myPage.pastQuestionnaireAnswers.yes : t.myPage.pastQuestionnaireAnswers.no;
};

const createMedicalHistoryAnswers = (data: QuestionnaireItemFieldsFragment) =>
  data.forms
    .flatMap((form) => {
      if (form.__typename === 'SelectTypeForm') {
        return form.items.flatMap((item) => {
          if (item.__typename === 'MultiSelectTypeItem') {
            return {
              subQuestion: item.label,
              answer: createMedicalHistoryItemAnswer(item),
            };
          }
          return undefined;
        });
      }
      return undefined;
    })
    .filter((x): x is Exclude<typeof x, undefined> => x !== undefined);
