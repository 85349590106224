import { useEffect } from 'react';
import type { ViewStyle } from 'react-native';
import { StyleSheet, View } from 'react-native';
import { Link } from 'react-router-dom';

import { messages as t } from '@/assets/i18n/ja';
import { Icon } from '@/components/Icon';
import { LoadingView } from '@/components/LoadingView';
import { NavigationHeader } from '@/components/NavigationHeader';
import { ServerErrorView } from '@/components/ServerErrorView';
import { BuildConfig } from '@/constants/BuildConfig';
import { useNotifications } from '@/features/notifications/hooks/useNotifications';
import { Logger } from '@/lib/Logger';
import { HomePredictErrorStatusVisibleEvent } from '@/lib/Logger/events';
import { hasGraphQLServerError } from '@/utils/hasGraphQlError';
import { openURL } from '@/utils/OpenUrl';
import { styleType } from '@/utils/styleType';

import { useHome } from '../../hooks/useHome';
import { HomeTemplate } from './template';

export const Home = () => {
  const { isLoaded, error, worstOrganItem, organRankingItems, handleReturnToPepUpPress } = useHome();
  const { checkForUnreadNotifications, hasUnreadNotifications } = useNotifications();

  /* eslint-disable-next-line @typescript-eslint/no-unsafe-argument */
  const handleContactUsListItemPress = () => openURL(BuildConfig.PEPUP_CONTACT_URL);

  useEffect(() => {
    checkForUnreadNotifications();
  }, [checkForUnreadNotifications]);

  useEffect(() => {
    if (!worstOrganItem && !organRankingItems) {
      Logger.logEvent(HomePredictErrorStatusVisibleEvent());
    }
  }, [organRankingItems, worstOrganItem]);

  useEffect(() => {
    if (error && hasGraphQLServerError(error)) {
      Logger.logException(error.message);
    }
  }, [error]);

  if (!isLoaded) return <LoadingView />;

  return (
    <>
      <NavigationHeader
        title={t.navigation.home}
        headerLeft="menu"
        headerRight={() => (
          <Link to="/notifications">
            <View style={styles.iconContainer}>
              <Icon type="Bell" hasBadge={hasUnreadNotifications} />
            </View>
          </Link>
        )}
      />
      {error && hasGraphQLServerError(error) ? (
        <ServerErrorView onContactUsListItemPress={handleContactUsListItemPress} />
      ) : (
        <HomeTemplate
          worstOrganItem={worstOrganItem}
          organRankingItems={organRankingItems}
          onReturnToPepUpPress={handleReturnToPepUpPress}
        />
      )}
    </>
  );
};

const styles = StyleSheet.create({
  iconContainer: styleType<ViewStyle>({
    marginRight: 16,
  }),
});
