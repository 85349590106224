import { useAtomValue } from 'jotai';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import type { HasSmokingHabitSchema } from '@/formSchemas/lifeStyleSchema';
import { useGetLifeStyleQuestionnaireQuery } from '@/hooks/GetQuestionnaire.generated';
import { useQuestionnaire } from '@/hooks/useQuestionnaire';
import { lifeStyleFormValuesAtom } from '@/store/formValues';

export const useHasSmokingHabit = () => {
  const [getLifeStyleQuestionnaireResult] = useGetLifeStyleQuestionnaireQuery();
  const { data, fetching, error } = getLifeStyleQuestionnaireResult;

  const formValues = useAtomValue(lifeStyleFormValuesAtom);

  const { getQuestionnaireSubItem, createSelections, getSelectedValue, perpetuateLifeStyleFormValues } =
    useQuestionnaire();

  const questionnaireItem = useMemo(
    () => data && getQuestionnaireSubItem(data, 'HAS_SMOKING_HISTORY', 'HAS_SMOKING_HABIT'),
    [data, getQuestionnaireSubItem]
  );
  const selections = useMemo(
    () => questionnaireItem?.forms[0].__typename === 'SelectTypeForm' && createSelections(questionnaireItem.forms[0]),
    [questionnaireItem, createSelections]
  );
  const selectedValue = useMemo(() => {
    if (formValues?.hasSmokingHabit) {
      return formValues?.hasSmokingHabit;
    }
    if (questionnaireItem?.forms[0].__typename === 'SelectTypeForm') {
      return getSelectedValue(questionnaireItem.forms[0]);
    }
    return undefined;
  }, [questionnaireItem?.forms, getSelectedValue, formValues?.hasSmokingHabit]);

  const navigate = useNavigate();

  const handleSubmit = useCallback(
    (values: HasSmokingHabitSchema) => {
      if (values.hasSmokingHabit === 1) {
        // 「現在たばこを習慣的に吸っていますか。」に「はい」と答えると、過去の喫煙本数と年数をnullにする
        perpetuateLifeStyleFormValues({
          ...values,
          numberOfCigarettesPerDayPast: null,
          yearsOfSmokingPast: null,
        });
        navigate('/questionnaire/current-smoking-habit');
      } else {
        // 「現在たばこを習慣的に吸っていますか。」に「いいえ」と答えると、現在の喫煙本数と年数をnullにする
        perpetuateLifeStyleFormValues({
          ...values,
          numberOfCigarettesPerDayCurrent: null,
          yearsOfSmokingCurrent: null,
        });
        navigate('/questionnaire/past-smoking-habit');
      }
    },
    [perpetuateLifeStyleFormValues, navigate]
  );

  return {
    data,
    fetching,
    error,
    questionnaireItem,
    selections,
    selectedValue,
    handleSubmit,
  };
};
