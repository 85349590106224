import { z } from 'zod';

import { messages as t } from '@/assets/i18n/ja';

import { withInOneHundredLength } from './validationRules';

export const familyStructureSchema = z
  .object({
    familyStructure: z.number({ required_error: t.validationError.selectRequired }).gte(1).lte(99),
    familyStructureSupplement: withInOneHundredLength,
  })
  .transform((values) => (values.familyStructure === 99 ? values : { ...values, familyStructureSupplement: '' }));
export type FamilyStructureSchema = z.infer<typeof familyStructureSchema>;

export const occupationSchema = z
  .object({
    occupation: z.number({ required_error: t.validationError.selectRequired }).gte(1).lte(99),
    occupationSupplement: withInOneHundredLength,
  })
  .transform((values) => (values.occupation === 99 ? values : { ...values, occupationSupplement: '' }));
export type OccupationSchema = z.infer<typeof occupationSchema>;

export const meansOfTransportationSchema = z
  .object({
    meansOfTransportation: z.number({ required_error: t.validationError.selectRequired }).gte(1).lte(99),
    meansOfTransportationSupplement: withInOneHundredLength,
  })
  // 「そのほか」を選択していないときは「そのほか」の補足入力欄を送信しない
  .transform((values) =>
    values.meansOfTransportation === 99 ? values : { ...values, meansOfTransportationSupplement: '' }
  );
export type MeansOfTransportationSchema = z.infer<typeof meansOfTransportationSchema>;

export const housingTypeSchema = z
  .object({
    housingType: z.number({ required_error: t.validationError.selectRequired }).gte(1).lte(99),
    housingTypeSupplement: withInOneHundredLength,
  })
  // 「そのほか」を選択していないときは「そのほか」の補足入力欄を送信しない
  .transform((values) => (values.housingType === 99 ? values : { ...values, housingTypeSupplement: '' }));
export type HousingTypeSchema = z.infer<typeof housingTypeSchema>;

export const hasCarSchema = z.object({
  hasCar: z.number().gte(1).lte(2),
});
export type HasCarSchema = z.infer<typeof hasCarSchema>;

export const frequencyOfDrivingSchema = z
  .object({
    frequencyOfDriving: z.number({ required_error: t.validationError.selectRequired }).gte(1).lte(99),
    frequencyOfDrivingSupplement: withInOneHundredLength,
  })
  // 「そのほか」を選択していないときは「そのほか」の補足入力欄を送信しない
  .transform((values) => (values.frequencyOfDriving === 99 ? values : { ...values, frequencyOfDrivingSupplement: '' }));
export type FrequencyOfDrivingSchema = z.infer<typeof frequencyOfDrivingSchema>;

export const howToSpendHolidaysSchema = z
  .object({
    housework: z.boolean(),
    work: z.boolean(),
    childcare: z.boolean(),
    reading: z.boolean(),
    game: z.boolean(),
    watchingTvAndVideos: z.boolean(),
    hobby: z.boolean(),
    netSurfing: z.boolean(),
    otherWays: z.boolean(),
    otherWaysSupplement: withInOneHundredLength,
  })
  // 「そのほか」を選択していないときは「そのほか」の補足入力欄を送信しない
  .transform((values) => (values.otherWays ? values : { ...values, otherWaysSupplement: '' }));

export type HowToSpendHolidaysSchema = z.infer<typeof howToSpendHolidaysSchema>;

export const numberOfFriendsSchema = z.object({
  numberOfFriends: z.number({ required_error: t.validationError.selectRequired }).gte(1).lte(6),
});
export type NumberOfFriendsSchema = z.infer<typeof numberOfFriendsSchema>;

export type EnvironmentSchema = {
  familyStructure: FamilyStructureSchema['familyStructure'];
  familyStructureSupplement?: FamilyStructureSchema['familyStructureSupplement'];
  occupation: OccupationSchema['occupation'];
  occupationSupplement?: OccupationSchema['occupationSupplement'];
  meansOfTransportation: MeansOfTransportationSchema['meansOfTransportation'];
  meansOfTransportationSupplement?: MeansOfTransportationSchema['meansOfTransportationSupplement'];
  housingType: HousingTypeSchema['housingType'];
  housingTypeSupplement?: HousingTypeSchema['housingTypeSupplement'];
  hasCar: HasCarSchema['hasCar'];
  frequencyOfDriving: FrequencyOfDrivingSchema['frequencyOfDriving'];
  frequencyOfDrivingSupplement?: FrequencyOfDrivingSchema['frequencyOfDrivingSupplement'];
  numberOfFriends: NumberOfFriendsSchema['numberOfFriends'];
} & HowToSpendHolidaysSchema;
