import type { FC } from 'react';
import { type TextStyle, type ViewStyle, StyleSheet } from 'react-native';

import { messages as t } from '@/assets/i18n/ja';
import { Container } from '@/components/Container';
import { Text } from '@/components/Text';
import { Touchable } from '@/components/Touchable';
import { Colors } from '@/constants/Colors';
import { styleType } from '@/utils/styleType';

type Props = {
  onContactUsListItemPress: () => void;
};

export const ServerErrorView: FC<Props> = (props) => {
  const { onContactUsListItemPress } = props;

  return (
    <Container style={styles.container}>
      <Text.Headline style={styles.title}>{t.error.serverError.title}</Text.Headline>
      <Text.Body style={styles.paragraph}>{t.error.serverError.text}</Text.Body>
      <Text.Body style={[styles.paragraph, styles.inlineText]}>
        <Text.Body style={styles.inlineText}>{t.error.serverError.textWithLink1}</Text.Body>
        <Touchable onPress={onContactUsListItemPress}>
          <Text.Body style={styles.link}>{t.error.serverError.linkText}</Text.Body>
        </Touchable>
        <Text.Body style={styles.inlineText}>{t.error.serverError.textWithLink2}</Text.Body>
      </Text.Body>
    </Container>
  );
};

const styles = StyleSheet.create({
  container: styleType<ViewStyle>({
    alignItems: 'center',
    paddingHorizontal: 20,
    paddingTop: 40,
  }),
  title: styleType<TextStyle>({
    paddingBottom: 12,
  }),
  paragraph: styleType<TextStyle>({
    textAlign: 'center',
  }),
  link: styleType<TextStyle>({
    color: Colors.healthactionGreen,
    textDecorationLine: 'underline',
  }),
  inlineText: styleType({
    display: 'inline' as TextStyle['display'],
  }),
});
