import React from 'react';
import type { ImageStyle, TextStyle, ViewStyle } from 'react-native';
import { Image, StyleSheet } from 'react-native';

import { messages as t } from '@/assets/i18n/ja';
import ABOUT_AGE_EXPECTANCY_1 from '@/assets/images/about_age_expectancy_1.png';
import ABOUT_AGE_EXPECTANCY_2 from '@/assets/images/about_age_expectancy_2.png';
import ABOUT_AGE_EXPECTANCY_3 from '@/assets/images/about_age_expectancy_3.png';
import ABOUT_AGE_EXPECTANCY_4 from '@/assets/images/about_age_expectancy_4.png';
import ABOUT_AGE_EXPECTANCY_5 from '@/assets/images/about_age_expectancy_5.png';
import ABOUT_AGE_EXPECTANCY_6 from '@/assets/images/about_age_expectancy_6.png';
import ABOUT_AGE_EXPECTANCY_7 from '@/assets/images/about_age_expectancy_7.png';
import ABOUT_AGE_EXPECTANCY_8 from '@/assets/images/about_age_expectancy_8.png';
import ABOUT_AGE_EXPECTANCY_9 from '@/assets/images/about_age_expectancy_9.png';
import ABOUT_AGE_EXPECTANCY_10 from '@/assets/images/about_age_expectancy_10.png';
import { Container } from '@/components/Container';
import { Text } from '@/components/Text';
import { Colors } from '@/constants/Colors';
import { styleType } from '@/utils/styleType';

export const AboutAgeExpectancyTemplate: React.FC = () => (
  <Container style={styles.container}>
    <Text.Headline style={styles.title}>{t.myPage.aboutAgeExpectancy.introSection.title}</Text.Headline>
    <Text.Body style={styles.body}>{t.myPage.aboutAgeExpectancy.introSection.text}</Text.Body>
    <Image source={{ uri: ABOUT_AGE_EXPECTANCY_1 }} resizeMode="cover" style={styles.firstImage} />

    <Text.Headline style={styles.title}>{t.myPage.aboutAgeExpectancy.firstSection.title}</Text.Headline>
    <Text.Body style={styles.body}>{t.myPage.aboutAgeExpectancy.firstSection.text}</Text.Body>
    <Text.Footnote style={styles.footnote}>{t.myPage.aboutAgeExpectancy.firstSection.note}</Text.Footnote>
    <Image source={{ uri: ABOUT_AGE_EXPECTANCY_2 }} resizeMode="contain" style={styles.secondImage} />

    <Text.Headline style={styles.title}>{t.myPage.aboutAgeExpectancy.secondSection.title}</Text.Headline>
    <Text.Body style={styles.body}>{t.myPage.aboutAgeExpectancy.secondSection.text}</Text.Body>
    <Text.Footnote style={styles.footnote}>{t.myPage.aboutAgeExpectancy.secondSection.note}</Text.Footnote>
    <Image source={{ uri: ABOUT_AGE_EXPECTANCY_3 }} resizeMode="contain" style={styles.thirdImage} />

    <Text.Headline style={styles.title}>{t.myPage.aboutAgeExpectancy.thirdSection.title}</Text.Headline>
    <Text.Body style={styles.body}>{t.myPage.aboutAgeExpectancy.thirdSection.text}</Text.Body>
    <Text.Footnote style={styles.footnote}>{t.myPage.aboutAgeExpectancy.thirdSection.note}</Text.Footnote>
    <Image source={{ uri: ABOUT_AGE_EXPECTANCY_4 }} resizeMode="contain" style={styles.fourthImage} />
    <Text.Body style={styles.body}>{t.myPage.aboutAgeExpectancy.thirdSection.secondText}</Text.Body>

    <Text.Headline style={styles.title}>{t.myPage.aboutAgeExpectancy.fourthSection.title}</Text.Headline>
    <Text.Body style={styles.body}>{t.myPage.aboutAgeExpectancy.fourthSection.text}</Text.Body>
    <Text.Footnote style={styles.footnote}>{t.myPage.aboutAgeExpectancy.fourthSection.note}</Text.Footnote>
    <Image source={{ uri: ABOUT_AGE_EXPECTANCY_5 }} resizeMode="contain" style={styles.fifthImage} />
    <Text.Body style={styles.body}>{t.myPage.aboutAgeExpectancy.fourthSection.secondText}</Text.Body>
    <Text.Footnote style={styles.footnote}>{t.myPage.aboutAgeExpectancy.fourthSection.secondNote}</Text.Footnote>
    <Image source={{ uri: ABOUT_AGE_EXPECTANCY_6 }} resizeMode="contain" style={styles.sixthImage} />

    <Text.Headline style={styles.title}>{t.myPage.aboutAgeExpectancy.fifthSection.title}</Text.Headline>
    <Text.Body style={styles.body}>{t.myPage.aboutAgeExpectancy.fifthSection.text}</Text.Body>
    <Text.Footnote style={styles.footnote}>{t.myPage.aboutAgeExpectancy.fifthSection.note}</Text.Footnote>
    <Image source={{ uri: ABOUT_AGE_EXPECTANCY_7 }} resizeMode="contain" style={styles.seventhImage} />
    <Image source={{ uri: ABOUT_AGE_EXPECTANCY_8 }} resizeMode="contain" style={styles.heighthImage} />
    <Text.Body style={styles.body}>{t.myPage.aboutAgeExpectancy.fifthSection.secondText}</Text.Body>

    <Text.Headline style={styles.title}>{t.myPage.aboutAgeExpectancy.sixthSection.title}</Text.Headline>
    <Text.Body style={styles.body}>{t.myPage.aboutAgeExpectancy.sixthSection.text}</Text.Body>
    <Text.Footnote style={styles.footnote}>{t.myPage.aboutAgeExpectancy.sixthSection.note}</Text.Footnote>
    <Image source={{ uri: ABOUT_AGE_EXPECTANCY_9 }} resizeMode="contain" style={styles.ninthImage} />
    <Image source={{ uri: ABOUT_AGE_EXPECTANCY_10 }} resizeMode="contain" style={styles.tenthImage} />
  </Container>
);

const styles = StyleSheet.create({
  container: styleType<ViewStyle>({
    flex: 1,
    justifyContent: 'center',
    paddingHorizontal: 20,
  }),
  firstImage: styleType<ImageStyle>({
    aspectRatio: 1284 / 666,
    marginTop: 16,
  }),
  secondImage: styleType<ImageStyle>({
    aspectRatio: 1284 / 702,
  }),
  thirdImage: styleType<ImageStyle>({
    aspectRatio: 1284 / 1016,
  }),
  fourthImage: styleType<ImageStyle>({
    aspectRatio: 1284 / 681,
    marginBottom: 16,
  }),
  fifthImage: styleType<ImageStyle>({
    aspectRatio: 1284 / 687,
    marginBottom: 16,
  }),
  sixthImage: styleType<ImageStyle>({
    aspectRatio: 1284 / 948,
  }),
  seventhImage: styleType<ImageStyle>({
    aspectRatio: 1284 / 293,
    marginBottom: 16,
  }),
  heighthImage: styleType<ImageStyle>({
    aspectRatio: 1284 / 359,
    marginBottom: 16,
  }),
  ninthImage: styleType<ImageStyle>({
    aspectRatio: 1284 / 386,
    marginBottom: 16,
  }),
  tenthImage: styleType<ImageStyle>({
    aspectRatio: 1284 / 344,
  }),
  title: styleType<TextStyle>({
    marginTop: 40,
    marginBottom: 20,
    color: Colors.black100,
  }),
  body: styleType<TextStyle>({
    color: Colors.black100,
  }),
  footnote: styleType<TextStyle>({
    color: Colors.black100,
    marginTop: 16,
    marginBottom: 8,
  }),
});
