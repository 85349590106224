import type { FC } from 'react';
import type { ViewStyle } from 'react-native';
import { StyleSheet, View } from 'react-native';

import { Text } from '@/components/Text';
import { Touchable } from '@/components/Touchable';
import { Colors } from '@/constants/Colors';
import { styleType } from '@/utils/styleType';

type Props = {
  onPress: () => void;
  text: string;
};

export const ListItem: FC<Props> = (props) => {
  const { onPress, text } = props;

  return (
    <Touchable style={styles.container} onPress={onPress} disabled={!onPress}>
      <View style={styles.contents}>
        <Text.BoldBody>{text}</Text.BoldBody>
      </View>
    </Touchable>
  );
};

const styles = StyleSheet.create({
  container: styleType<ViewStyle>({
    backgroundColor: Colors.white100,
    height: 44,
    flexDirection: 'row',
    alignItems: 'center',
  }),
  contents: styleType<ViewStyle>({
    marginHorizontal: 4,
    flex: 1,
  }),
});
