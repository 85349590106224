import type { ComponentProps, FC } from 'react';

import { AmountOfDrinkingPerDay } from '@/components/questionnaire/form/lifeStyle';

import { QuestionnaireContainer } from '../parts/QuestionnaireContainer';
import type { QuestionNumber } from '../parts/QuestionNumber';

type Props = Omit<ComponentProps<typeof QuestionNumber>, 'style'> & ComponentProps<typeof AmountOfDrinkingPerDay>;

export const AmountOfDrinkingPerDayTemplate: FC<Props> = (props) => {
  const { questionNumber, allQuestionNumber, question, supplement, selections, defaultValue, onSubmit } = props;

  return (
    <QuestionnaireContainer questionNumber={questionNumber} allQuestionNumber={allQuestionNumber}>
      <AmountOfDrinkingPerDay
        question={question}
        supplement={supplement}
        selections={selections}
        defaultValue={defaultValue}
        onSubmit={onSubmit}
      />
    </QuestionnaireContainer>
  );
};
