import { useSetAtom } from 'jotai';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { messages as t } from '@/assets/i18n/ja';
import { NavigationHeader } from '@/components/NavigationHeader';
import { BuildConfig } from '@/constants/BuildConfig';
import { COMPANY_URL } from '@/constants/URL';
import { useLogoutMutation } from '@/hooks/Logout.generated';
import { Logger } from '@/lib/Logger';
import { LogoutEvent } from '@/lib/Logger/events';
import { removeTokenFromStorage } from '@/store/authToken';
import { environmentFormValuesAtom, lifeStyleFormValuesAtom, medicalHistoryFormValuesAtom } from '@/store/formValues';
import { openURL } from '@/utils/OpenUrl';

import { MyPageTemplate } from './template';

export const Top = () => {
  const [, logout] = useLogoutMutation();
  const [isLogOutModalVisible, setIsLogOutModalVisible] = useState(false);
  const setLifeStyleFormValues = useSetAtom(lifeStyleFormValuesAtom);
  const setEnvironmentFormValues = useSetAtom(environmentFormValuesAtom);
  const setMedicalHistoryFormValues = useSetAtom(medicalHistoryFormValuesAtom);

  const openModal = () => setIsLogOutModalVisible(true);
  const closeModal = () => setIsLogOutModalVisible(false);

  const navigate = useNavigate();

  const handlePastQuestionnaireAnswersListItemPress = () => navigate('/past-questionnaire');

  const handleNotificationsListItemPress = () => navigate('/notifications');

  const handleAboutSpecialistSupervisionListItemPress = () => navigate('/about-specialist-supervision');

  const handleAboutAgeExpectancyListItemPress = () => navigate('/about-age-expectancy');

  const handleContactUsListItemPress = () => {
    /* eslint-disable-next-line @typescript-eslint/no-unsafe-argument */
    openURL(BuildConfig.PEPUP_CONTACT_URL);
  };

  const handleCompanyListItemPress = () => {
    openURL(COMPANY_URL);
  };

  const handleLogOutModalSubmitButtonPress = () => {
    logout({})
      .then(async () => {
        await removeTokenFromStorage();

        setLifeStyleFormValues(undefined).catch(() => undefined);
        setEnvironmentFormValues(undefined).catch(() => undefined);
        setMedicalHistoryFormValues(undefined).catch(() => undefined);

        Logger.logEvent(LogoutEvent());
        Logger.setUserId(null);

        /* eslint-disable-next-line @typescript-eslint/no-unsafe-argument */
        openURL(BuildConfig.PEPUP_URL);
      })
      .catch((e) => Logger.logException(e));
  };

  return (
    <>
      <NavigationHeader title={t.navigation.myPage} headerLeft="menu" />
      <MyPageTemplate
        onPastQuestionnaireAnswersListItemPress={handlePastQuestionnaireAnswersListItemPress}
        onNotificationsListItemPress={handleNotificationsListItemPress}
        onLogoutListItemPress={openModal}
        onAboutSpecialistSupervisionListItemPress={handleAboutSpecialistSupervisionListItemPress}
        onAboutAgeExpectancyListItemPress={handleAboutAgeExpectancyListItemPress}
        onContactUsListItemPress={handleContactUsListItemPress}
        onCompanyListItemPress={handleCompanyListItemPress}
        isLogOutModalVisible={isLogOutModalVisible}
        onLogOutModalDismiss={closeModal}
        onLogOutModalSubmitButtonPress={handleLogOutModalSubmitButtonPress}
        onLogOutModalCancelButtonPress={closeModal}
      />
    </>
  );
};
