import { graphqlContext, MockedRequest, ResponseResolver } from 'msw';

import { GenerateAccessTokenPayload } from '@/types/graphql';

const date = new Date();
date.setDate(date.getDate() + 14);

const accessToken =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6ImtXcEhqNmpFR2JCbjVCMnY0RkprZWZhWGxrYXJuakZPNkZ2VERFb3dlTmcifQ.eyJpc3MiOiJodHRwczovL2ptZGNvcGVucGxhdGZvcm0uYjJjbG9naW4uY29tLzhmNzNjNzM1LWExNjYtNDI5OC05MGQyLWViMTM4NWY2OWM5NS92Mi4wLyIsImV4cCI6MTY3NjM3NzAwNSwibmJmIjoxNjc2MzczNDA1LCJhdWQiOiI2MjMwNjgxYS1kZjJmLTRhZDItOTI1ZC00YzUzYjE1Nzc5NmQiLCJvaWQiOiIwYTA4OTU2NS1lY2YzLTQwZmMtYjNlMy0yNjUxOTRiMTRhOTkiLCJzdWIiOiIwYTA4OTU2NS1lY2YzLTQwZmMtYjNlMy0yNjUxOTRiMTRhOTkiLCJuYW1lIjoidW5rbm93biIsInRpZCI6IjhmNzNjNzM1LWExNjYtNDI5OC05MGQyLWViMTM4NWY2OWM5NSIsImlzRm9yZ290UGFzc3dvcmQiOmZhbHNlLCJuZXdVc2VyIjpmYWxzZSwibm9uY2UiOiI0NjIwYWNlMC1hNjZiLTRiM2ItOTFkZi0wNzA0MDRkMWJkYmIiLCJzY3AiOiJwcm9maWxlLnJlYWQiLCJhenAiOiI2MjMwNjgxYS1kZjJmLTRhZDItOTI1ZC00YzUzYjE1Nzc5NmQiLCJ2ZXIiOiIxLjAiLCJpYXQiOjE2NzYzNzM0MDV9.XNau8wu7ksc7037Pimw3GDHZglhQqVkwk8Qf25qIxdY7n3TeFMWqnuCfIIl6H2CTNj51L0CP5lszstLAKa9rx9xgr6wb8yepTeaYOtw_jYCujmGva9g_PCNd1JDS08HGm4-nEl6oCEYmJEYJPMU8v7KUQdO5EjNLeJOGO-CPG4fMwqNzo4nG5T_SuNA8M0g_kKLJsvLZJI0MII10A644i6yfF0SxnVvdKJr28UndYvLMgD7y0fg8yLFWlOXTXcBiQQ64sLxIkFGLMyIn4a8Gj7KJzquS4vyaMXEpk3Ul7G5hwSnE2Z-aSL6xNWJfmkJEWVlWea4zDWq1SsmGtOjGuQ';

const generateAccessTokenPayload: {
  generateAccessToken: GenerateAccessTokenPayload;
} = {
  generateAccessToken: {
    token: accessToken,
    expiresAt: date as unknown as string, // 仮に14日後が期限の場合
  },
};

export const mockGenerateAccessToken: ResponseResolver<MockedRequest, typeof graphqlContext> = (req, res, ctx) =>
  res(ctx.data(generateAccessTokenPayload));
